/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : index page
**/
/* react import */
import React from 'react';
import ReactDOM from 'react-dom';
import { hot } from 'react-hot-ts';
import { Provider } from "react-redux";
/* redux import */
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
/* service import */
import App from './App';
import reducer from './taurus/utils/redux/reducers';

/* redux */
let middleware = [thunk]
const store = createStore(reducer, applyMiddleware(...middleware))

hot(module)(
  ReactDOM.render((
    <BrowserRouter>
      {/* // 개발자 도구를 사용하기 위한 설정 */}
      <Provider store={store}> 
        <App />
      </Provider>
    </BrowserRouter>
  ), document.getElementById('root'))
);
