/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Consult List Page
**/
/* @material import */
import {
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  DataGrid
} from '@material-ui/data-grid';
import {
  List,
  Search
} from '@material-ui/icons';
import { Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import { getCnsltSrch } from "../../utils/redux/actions/CnsltAction";
import {
  _isEmpty,
  _setCodeFilter,
  _setDateYYYYMMDD,
  _setFormatCategory,
  _setFormatCode,
  _setFormatComma,
  _setFormatDate,
  _setUTCDate
} from "./../../utils/Common";
import FormDate from "./../../utils/form/FormDate";
       
/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
  },
  grid: {
    height: '100%',
    width: '98%',
    maxHeight: '70%',
    minWidth: '98%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#9fa8da',
      color: '#ffffff'
    },
  },
  // 데이터 그리드 테이블 해더
  search: {
    margin: theme.spacing(1)
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 30,
    marginTop: 13
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  searchTypography: {
    marginTop: 12
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ConsultList = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const consult = useSelector((state: any) => state.consult, shallowEqual);    // consult
  const common = useSelector((state: any) => state.common, shallowEqual);      // common
  const search = useSelector((state: any) => state.common.search, shallowEqual);      // common

  // [Events] ######################################################################
  //@ 최초 실행
  const handleInit = useCallback(() => {
    let body = {};
    if (!_isEmpty(search)) body = search;
    getCnsltSrch(body).then((d) => {
      dispatch(d);
      dispatch({ type: 'CNSLT_GET_INFO', payload: {} });
    }).catch((e) => console.log(e));
  }, [search, dispatch]);

  //@ 선택
  const handleChange = useCallback((e) => {
    console.log(e.data);
    dispatch({ type: 'CNSLT_GET_INFO', payload: e.data });
  }, [dispatch]);

  //@ 검색
  const handleSearch = useCallback((v) => {
    // consult.srch = v;
    let body: any = {};
    if (!_isEmpty(v.st_dt)) body['st_dt'] = _setUTCDate(v.st_dt);
    if (!_isEmpty(v.ed_dt)) body['ed_dt'] = _setUTCDate(v.ed_dt);
    if (!_isEmpty(v.cnslt_stat)) body['cnslt_stat'] = v.cnslt_stat;
    if (!_isEmpty(v.rcpt_chnl)) body['rcpt_chnl'] = v.rcpt_chnl;
    if (!_isEmpty(v.srch_val)) body[v.srch_key.toString()] = v.srch_val;

    getCnsltSrch(body).then((d) => dispatch(d))
      .catch((e) => console.log(e));
  }, [dispatch]);

  // [init] ######################################################################
  // 콤마 타입 설정
  const _setComma = _setFormatComma();
  // 데이트 타입 설정
  const _setDate = _setFormatDate();
  // 카테고리명 조회
  const _setCateName = _setFormatCategory(common.category);
  // 상담경로명 조회
  const _setCodeName = _setFormatCode(common.code, 'G024');
  // 수선종류 조회
  const _setItemName = _setFormatCode(common.code, 'G025');

  //@ 초기 데이터 (Table 컬럼)
  const columns = [
    { field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
    { field: 'cnslt_no', headerAlign: 'center', headerName: '상담번호', width: 130 },
    { field: 'cust_no', headerAlign: 'center', headerName: '고객번호', hide: true },
    { field: 'cust_name', headerAlign: 'center', headerName: '고객명', width: 140 },
    { field: 'cnslt_dtime', headerAlign: 'center', headerName: '상담일', width: 120, ..._setDate },
    { field: 'rcpt_chnl', headerAlign: 'center', headerName: '상담경로', ..._setCodeName },
    { field: 'brand_cd', headerAlign: 'center', headerName: '브랜드', width: 180, ..._setCateName, hide: true },
    { field: 'brand_name', headerAlign: 'center', headerName: '브랜드', width: 180 },
    // { field: 'item_cd', headerAlign: 'center', headerName: '품목', width: 100, ..._setCateName },
    { field: 'item_cd', headerAlign: 'center', headerName: '수선종류', width: 120, ..._setItemName, hide: true  },
    { field: "cate_name", headerAlign: "center", headerName: "수선종류", width: 120, },
    { field: 'repr_pay', headerAlign: 'center', headerName: '수선비', ..._setComma },
    { field: 'galy_url', headerAlign: 'center', headerName: '이미지', hide: true },
    { field: 'qst_cont', headerAlign: 'center', headerName: '문의', hide: true, width: 150 },
    { field: 'ans_cont', headerAlign: 'center', headerName: '답변', hide: true },
    { field: 'cnslt_stat', headerAlign: 'center', headerName: '상태', hide: true },
    { field: 'reg_user', headerAlign: 'center', headerName: '작성자', width: 80 },
    { field: 'last_mod_user', headerAlign: 'center', headerName: '수정자', width: 80 }
  ];

  //@ 최초 실행
  useEffect(() => {
    handleInit();
  }, [handleInit]);

  // [Grid] ######################################################################    
  let datagrid;

  if (_isEmpty(consult.rows)) {
    datagrid = <Typography
      variant="h6"
      align="center"
    >
      데이터가 존재하지 않습니다.
    </Typography>;
  } else {
    datagrid = <DataGrid
      headerHeight={40}
      rowHeight={30}
      columns={columns}
      rows={consult.rows}
      pageSize={5}
      onRowClick={handleChange}
    />;
  }

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography variant="h6">상담이력</Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <Grid
              container
              justify="center"
              alignItems="center"
            >
              <Grid item xs={5}></Grid>
              <Grid item xs={7}>
                {/* 전체목록 */}
                <IconButton
                  className={classes.iconButton}
                  aria-label="전체목록"
                  color="inherit"
                  onClick={() => {
                    common.search = {};
                    // consult.srch = {};
                    handleInit();
                  }}
                >
                  <List /><Typography variant="h6">전체</Typography>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      {/* 검색기능 */}
      <div
        className={classes.search}
      >
        <Formik
          enableReinitialize
          initialValues={{
            // 기본정보
            st_dt: _isEmpty(consult.srch) ? '' :
              _isEmpty(consult.srch.st_dt) ? '' : _setDateYYYYMMDD(consult.srch.st_dt, '-'), // 검색시작일
            ed_dt: _isEmpty(consult.srch) ? _setDateYYYYMMDD('', '-') :
              _isEmpty(consult.srch.ed_dt) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(consult.srch.ed_dt, '-'), // 검색종료일

            cnslt_stat: _isEmpty(consult.srch) ? '' :
              _isEmpty(consult.srch.cnslt_stat) ? '' : consult.srch.cnslt_stat,              // 상담상태
            rcpt_chnl: _isEmpty(consult.srch) ? '' :
              _isEmpty(consult.srch.rcpt_chnl) ? '' : consult.srch.rcpt_chnl,                // 상담경로

            srch_key: _isEmpty(consult.srch) ? 'cnslt_no' :
              _isEmpty(consult.srch.srch_key) ? 'cnslt_no' : consult.srch.srch_key,           // 검색키
            srch_val: _isEmpty(consult.srch) ? '' :
              _isEmpty(consult.srch.srch_val) ? '' : consult.srch.srch_val                   // 검색어
          }}
          validationSchema={Yup.object().shape({
            // 밸리데이션 불필요
          })}
          onSubmit={(v) => {
            handleSearch(v);
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
            <form onSubmit={handleSubmit}>
              {/* 그리드 */}
              <Grid
                container
                justify="center"
                alignContent="flex-end"
              >
                {/* 기간 */}
                <Grid item xs={5}>
                  <Grid
                    container
                    justify="center"
                  >
                    <Grid item xs={5}>
                      <FormDate
                        id='st_dt'
                        label='상담일(시작)'
                        value={values.st_dt}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        variant="h3"
                        className={classes.searchTypography}
                        align="center">~ </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <FormDate
                        id='ed_dt'
                        label='상담일(종료)'
                        value={values.ed_dt}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Grid>
                {/* 구분 */}
                <Grid item xs={2}>
                  <Grid
                    container
                    justify="center"
                  >
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <FormSelect
                        id='rcpt_chnl'
                        label='상담경로'
                        value={values.rcpt_chnl}
                        item={_setCodeFilter(common.code, 'G024')}
                        firstDefault={true}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Grid>
                {/* 검색어 */}
                <Grid item xs={5}>
                  {/* 구분 */}
                  <Grid
                    container
                    justify="center"
                  >
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}>
                      <FormSelect
                        id='srch_key'
                        label='검색구분'
                        firstDefault={false}
                        value={values.srch_key}
                        item={[
                          { code: 'cnslt_no', name: '상담번호' },
                          { code: 'cust_name', name: '고객명' }
                        ]}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={7}>
                      <Paper className={classes.paper}>
                        <InputBase
                          className={classes.input}
                          placeholder="검색어를 입력해주세요"
                          id='srch_val'
                          name='srch_val'
                          value={values.srch_val}
                          type='text'
                          error={Boolean(touched.srch_val && errors.srch_val)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <IconButton
                          type="submit"
                          className={classes.iconButton}
                          aria-label="search">
                          <Search />
                        </IconButton>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
      <div
        className={classes.grid}
      >
        {datagrid}
      </div>
    </React.Fragment>
  );
};

export default ConsultList;
