/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Delivery List Page
**/
/* @material import */
import {
	Button, Grid,
	IconButton,
	InputBase,
	makeStyles,
	Paper,
	Toolbar,
	Typography,
	Tooltip
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {
	GetApp,
	Publish,
	Search
} from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import XLSX from "xlsx";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import FormText from '../../utils/form/FormText';
import { getExcel } from "../../utils/redux/actions/ExcelDownloadAction";
import { getProd, getProdSrch, updProd } from "../../utils/redux/actions/ProdAction";
import {
	_getCategoryName,
	_getCodeName,
	_isEmpty,
	_setCodeFilter,
	_setDateYYYYMMDD,
	_setDayYYYYMMDD,
	_setFormatCategory,
	_setFormatCode,
	_setFormatDate,
	_setUTCDate,
	_setFormatPhone,
	_getNowDate,
	_setKakaoState,
	_getPhone,
	_getSession,
	_getCategoryName2,
	_getCodeName2
} from "./../../utils/Common";
import FormDate from "./../../utils/form/FormDate";
 
/* ######################################################################
 * style
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
	root: {},
	grid: {
		height: "100%",
		width: "98%",
		maxHeight: "82%",
		minWidth: "98%",
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
		"& .MuiDataGrid-columnsContainer": {
			backgroundColor: "#9fa8da",
			color: "#ffffff",
		},
	},
	// 데이터 그리드 테이블 해더
	search: {
		margin: theme.spacing(1),
	},
	toolbar: {
		minHeight: 40,
		color: "#fff",
		backgroundColor: "#1976d2",
	},
	iconButton: {
		padding: 10,
	},
	paper: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "100%",
		height: 30,
		marginTop: 13,
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	searchTypography: {
		marginTop: 12,
	},
	files: {
		position: "absolute",
		width: 1,
		height: 1,
		padding: 0,
		margin: -1,
		overflow: "hidden",
		clip: "rect(0,0,0,0)",
		border: 0,
	},
}));

/* ######################################################################
 * component
 * ###################################################################### */
// let excel_info: any = [];
const DeliveryList = () => {
	const classes = useStyles(); // CSS
	const dispatch = useDispatch(); // dispatch hook

	// [state] ######################################################################
	const product = useSelector((state: any) => state.product); // product
	const common = useSelector((state: any) => state.common); // common

	// [Events] ######################################################################
	//@ 최초 실행
	const handleInit = useCallback(() => {
		let body: any = {};
		body['limit'] = '100'
		getProd(body)
			.then((d) => dispatch(d))
			// .then((d) => {
			// 	excel_info = [];
			// 	excel_info = d.payload;
			// })
			.catch((e) => console.log(e));
		product.srch['limit'] = '100'
	}, [dispatch]);

	//@ 출고이력선택
	const handleChange = useCallback(
		(e) => {
			// 출고 선택 개별 조회
			let body = {};
			body = {
				prod_no: e.data.prod_no,
			};
			getProdSrch(body)
				.then((res: any) => dispatch(res))
				.catch((e) => console.log(e));
		},
		[dispatch]
	);

	//@ 검색
	const handleSearch = useCallback(
		(v) => {
			product.srch = v;

			let body: any = {};
			if (!_isEmpty(v.date_key)) body["date_key"] = v.date_key;
			if (!_isEmpty(v.st_dt)) body["st_dt"] = _setUTCDate(v.st_dt);
			if (!_isEmpty(v.ed_dt)) body["ed_dt"] = _setUTCDate(v.ed_dt);
			if (!_isEmpty(v.rcpt_chnl)) body["rcpt_chnl"] = v.rcpt_chnl;
			if (!_isEmpty(v.prod_stat)) body["prod_stat"] = v.prod_stat;
			if (!_isEmpty(v.send_typ)) body["send_typ"] = v.send_typ;
			if (!_isEmpty(v.send_org)) body["send_org"] = v.send_org;
			if (!_isEmpty(v.srch_val)) body[v.srch_key.toString()] = v.srch_val;
			if (!_isEmpty(v.limit)) body["limit"] = v.limit;

			getProd(body)
				.then((d) => dispatch(d))
				// .then((d) => {
				// 	excel_info = d.payload;
				// })
				.catch((e) => console.log(e));
		},
		[product, dispatch]
	);

	//@ Excel downlaod
	const excelDownload = useCallback(() => {
		let _code_data = _getSession('CODE_DATA');
		_code_data = _code_data.payload;
		let _category_data = _getSession('CATEGOORY_DATA');
		_category_data = _category_data.payload;
		let excel_table: any = [];
		let excel_info = product.excel;
		excel_info.map((excel: any) => {
			excel_table.push({
				수선품번호: excel.prod_no,
				고객명: excel.cust_name,
				연락처: excel.phone,
				주소: excel.addr,
				브랜드: _getCategoryName2(_category_data, excel.brand_cd),
				수선종류: _getCodeName2(_code_data, excel.cate_cd, "G025"),
				접수일: _setDayYYYYMMDD(excel.rcpt_dtime, "-"),
				검수완료일: _setDayYYYYMMDD(excel.check_ed_dt, "-"),
				출고요청일: _setDayYYYYMMDD(excel.repr_req_dt, "-"),
				출고일: _setDayYYYYMMDD(excel.prod_ed_dt, "-"),
				발송방식: _getCodeName2(_code_data, excel.send_typ, "G007"),
				택배사: _getCodeName2(_code_data, excel.send_org, "G026"),
				송장번호: _isEmpty(excel.send_no) ? "" : excel.send_no.toString(),
				내역: _isEmpty(excel.req_cont) ? "" : excel.req_cont.toString(),
				// 상태: _isEmpty(excel.prod_stat) ? "" : excel.prod_stat.toString(),
				상태: _getCodeName2(_code_data, excel.prod_stat, "G008")
			});
		});
		if (_isEmpty(excel_table)) {
			alert('데이터를 선택해주세요')
		} else {
			getExcel(excel_table);
		}
	}, [product, dispatch]);

	// const excelToJson = require("convert-excel-to-json");

	//@ Excel Upload
	const excelUpload = useCallback(async (e) => {
		let update_flag = false;

		let _f = e.target.files[0];      // 파일
		let reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		console.log(e.target.result);
		// alert("onload");
		// let excel_array:any = [];

		// ["수선품번호", "고객명", "연락처", "브랜드", "수선종류", "접수일", "검수완료일", "출고요청일", "출고일", "택배사", "송장번호", "상태"],
		reader.onload = (event: any) => {
			let body = {};
			// @엑셀 읽기
			let bstr = event.target.result;
			let wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
			let wsname = wb.SheetNames[0];
			let ws = wb.Sheets[wsname];
			let excel_array = XLSX.utils.sheet_to_json(ws);
			console.log(excel_array);
			excel_array.forEach((elements: any, idx: number) => {
				if (idx >= 0) {
					let prodNo = _isEmpty(elements["수선품번호"]) ? "" : elements["수선품번호"].toString();
					let prodStat = _isEmpty(elements["상태"]) ? "" : elements["상태"].toString();			// 제품 상태
					try {
						if (!_isEmpty(prodNo) && (prodStat === "01" || prodStat === "02" || prodStat === "03" || prodStat === "04" || prodStat === "05")) {				// 상태코드가 정의된 영역에 있는지 판단.
							body = {
								prod_no: prodNo,			// 수선품 번호
								// repr_req_dt: _isEmpty(elements["출고요청일"]) ? _getNowDate() : _setUTCDate(elements["출고요청일"]),	//출고 요청일
								prod_ed_dt: _isEmpty(elements["출고일"]) ? _getNowDate() : _setUTCDate(elements["출고일"]),	//출고일
								send_org: _isEmpty(elements["택배사"]) ? "" : elements["택배사"].toString(),			// 택배사
								send_no: _isEmpty(elements["송장번호"]) ? "" : elements["송장번호"].toString(),			// 송장번호
								prod_stat: prodStat,		// 제품 상태
							};
							// 출고 정보 업데이트
							updProd(body).then((res: any) => dispatch(res))
								.then(() => getProd(product.srch).then((d) => dispatch(d)));
							update_flag = true;
						}
					}
					catch (err) {
						console.log(err);
						common.msg = "엑셀을 확인해주세요." + err//.toString();
						common.isMsg = true;
						return dispatch({ type: 'COM_ALERT' });       // 안내메시지
					}
				}
			});
			if (update_flag) {
				common.msg = "수정에 성공 하였습니다.";
				common.isMsg = true;
				return dispatch({ type: "COM_ALERT" }); // 안내메시지
			} else {
				common.msg = "엑셀에 정의된 코드값을 벗어난 값이 있습니다. .";
				common.isMsg = true;
				return dispatch({ type: "COM_ALERT" }); // 안내메시지
			}
		};
		reader.readAsBinaryString(_f);
	}, [common, dispatch]);

	// [init] ######################################################################
	useEffect(() => {
		handleInit();
	}, [handleInit]);

	// 데이트 타입 설정
	const _setDate = _setFormatDate();
	// 카테고리명 조회
	const _setCateName = _setFormatCategory(common.category);
	// 접수상태명 조회
	const _setStateName = _setFormatCode(common.code, "G008");
	// 수선종류 조회
	const _setItemName = _setFormatCode(common.code, 'G025');
	// 발송방식 조회
	const _setCodeName = _setFormatCode(common.code, 'G007');
	// 배송형태 조회
	const _setOrgName = _setFormatCode(common.code, 'G026');
	// 전화번호 조회
	const _setGridPhone = _setFormatPhone();
	// 카카오톡 조회
	const _setGridKakaoStatus = _setKakaoState();
	// 결제 상태 조회
	const _setCalStateName = _setFormatCode(common.code, 'G013');

	//@ 초기 데이터 (Table 컬럼)
	const columns = [
		{ field: "id", headerAlign: "center", headerName: "번호", hide: true, },
		{ field: "prod_no", headerAlign: "center", headerName: "수선품번호", width: 130, },
		{ field: "cust_name", headerAlign: "center", headerName: "고객명", width: 140, },
		{ field: "phone", headerAlign: "center", headerName: "연락처", width: 130, ..._setGridPhone },
		{ field: "req_cont", headerAlign: "center", headerName: "내역", width: 250 },
		{ field: "brand_cd", headerAlign: "center", headerName: "브랜드", width: 150, ..._setCateName, hide: true },
		{ field: "brand_name", headerAlign: "center", headerName: "브랜드", width: 150 },
		// { field: 'cate_cd', headerAlign: 'center', headerName: '품목', ..._setCateName },
		{ field: 'cate_cd', headerAlign: 'center', headerName: '수선종류', width: 120, ..._setItemName, hide: true },
		{ field: 'cate_name', headerAlign: 'center', headerName: '수선종류', width: 120 },
		{ field: "rcpt_dtime", headerAlign: "center", headerName: "접수일", width: 110, ..._setDate, },
		{ field: "check_ed_dt", headerAlign: "center", headerName: "검수완료일", width: 110, ..._setDate, },
		{ field: "repr_req_dt", headerAlign: "center", headerName: "출고요청일", width: 110, ..._setDate, },
		{ field: 'calc_stat', headerAlign: "center", headerName: "납부", width: 80, ..._setCalStateName, },
		{ field: "prod_ed_dt", headerAlign: "center", headerName: "출고일", width: 110, ..._setDate, },
		{ field: "send_typ", headerAlign: "center", headerName: "발송방식", width: 100, ..._setCodeName, },
		{ field: "send_org", headerAlign: "center", headerName: "택배사", width: 100, ..._setOrgName, },
		{ field: "send_no", headerAlign: "center", headerName: "송장번호", width: 100, },
		{ field: "prod_stat", headerAlign: "center", headerName: "상태", width: 90, ..._setStateName, },
		{ field: "chk_kakao_dliv", headerAlign: "center", headerName: "알림톡", width: 80, ..._setGridKakaoStatus },
		{ field: 'reg_user', headerAlign: 'center', headerName: '작성자', width: 80 },
		{ field: 'last_mod_user', headerAlign: 'center', headerName: '수정자', width: 80 }
	];
	// [Grid] ######################################################################
	let datagrid;

	if (_isEmpty(product.rows)) {
		datagrid = (
			<Typography variant="h6" align="center">
				데이터가 존재하지 않습니다.
			</Typography>
		);
	} else {
		datagrid = (
			<DataGrid
				headerHeight={40}
				rowHeight={30}
				columns={columns}
				rows={product.rows}
				pageSize={15}
				onRowClick={handleChange}
			/>
		);
	}

	// [Render] ######################################################################
	return (
		<React.Fragment>
			<Toolbar className={classes.toolbar}>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={9}>
						<Typography variant="h6">출고</Typography>
					</Grid>
					<Grid item xs={3}>
						<Grid container justify="center" alignItems="center">
							<Grid item xs={6}>
								{/* 엑셀다운로드 */}
								<IconButton
									className={classes.iconButton}
									aria-label="엑셀다운로드"
									color="inherit"
									onClick={excelDownload}
								>
									<GetApp />
									<Typography variant="h6">엑셀다운로드</Typography>
								</IconButton>
							</Grid>
							<Grid item xs={6}>
								{/* 엑셀업로드 */}
								<div>
									<label htmlFor="raised-button-file">
										<Button
											component="span"
											className={classes.iconButton}
											color="inherit"
										>
											<Publish />
											<Typography variant="h6">엑셀업로드</Typography>
										</Button>
									</label>
									<input
										style={{ display: "none" }}
										id="raised-button-file"
										name="raised-button-file"
										placeholder={"파일을 첨부해주세요"}
										type="file"
										required={false}
										onChange={(e: any) => excelUpload(e)}
									/>
								</div>
								{/* <Input type="file"></Input> */}
								{/* <input
									style={{ display: "none" }}
									id="raised-button-file"
									multiple
									type="file"
									onChange={(e: any) => excelUpload(e)}
								/>
								<label htmlFor="raised-button-file">
									<Button
										component="span"
										className={classes.iconButton}
										color="inherit"
									>
										<Publish />
										<Typography variant="h6">엑셀업로드</Typography>
									</Button>
								</label> */}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
			{/* 검색기능 */}
			<div className={classes.search}>
				<Formik
					enableReinitialize
					initialValues={{
						// 기본정보
						date_key: _isEmpty(product.srch)
							? "repr_st_dt"
							: _isEmpty(product.srch.date_key)
								? "repr_st_dt"
								: product.srch.date_key, // 검색날짜키
						st_dt: _isEmpty(product.srch)
							? ""
							: _isEmpty(product.srch.st_dt)
								? ""
								: _setDateYYYYMMDD(product.srch.st_dt, "-"), // 검색시작일
						ed_dt: _isEmpty(product.srch)
							? _setDateYYYYMMDD("", "-")
							: _isEmpty(product.srch.ed_dt)
								? _setDateYYYYMMDD("", "-")
								: _setDateYYYYMMDD(product.srch.ed_dt, "-"), // 검색종료일

						prod_stat: _isEmpty(product.srch)
							? ""
							: _isEmpty(product.srch.prod_stat)
								? ""
								: product.srch.prod_stat, // 수선품상태
						rcpt_chnl: _isEmpty(product.srch)
							? ""
							: _isEmpty(product.srch.rcpt_chnl)
								? ""
								: product.srch.rcpt_chnl, // 접수경로

						send_typ: _isEmpty(product.srch)
							? ""
							: _isEmpty(product.srch.send_typ)
								? ""
								: product.send_typ,      // 발송방식
						send_org: _isEmpty(product.srch)
							? ""
							: _isEmpty(product.srch.send_org)
								? ""
								: product.send_org,      // 택배사
						srch_key: _isEmpty(product.srch)
							? "prod_no"
							: _isEmpty(product.srch.srch_key)
								? "prod_no"
								: product.srch.srch_key, // 검색키
						srch_val: _isEmpty(product.srch)
							? ""
							: _isEmpty(product.srch.srch_val)
								? ""
								: product.srch.srch_val, // 검색어
						limit: '100' // 노출 데이터 수
					}}
					validationSchema={Yup.object().shape({
						// 밸리데이션 불필요
					})}
					onSubmit={(v) => {
						handleSearch(v);
					}}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						touched,
						values,
					}) => (
						<form onSubmit={handleSubmit}>
							{/* 그리드 */}
							<Grid container justify="center" alignItems="center">
								{/* 기간 */}
								<Grid item xs={5}>
									<Grid container justify="center">
										<Grid item xs={1}></Grid>
										<Grid item xs={2}>
											<FormSelect
												id="date_key"
												label="구분"
												firstDefault={false}
												value={values.date_key}
												item={[
													{ code: "repr_st_dt", name: "접수일" },
													{ code: "prod_ed_dt", name: "출고일" },
													{ code: "repr_req_dt", name: "출고요청일" },
													{ code: "frst_pay_dtime", name: "결제일" },
												]}
												required={true}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={3}>
											<FormDate
												id="st_dt"
												label="시작일"
												value={values.st_dt}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={1}>
											<Typography
												className={classes.searchTypography}
												variant="h3"
												align="center"
											>
												~{" "}
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<FormDate
												id="ed_dt"
												label="종료일"
												value={values.ed_dt}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={2}>
											{/* 택배사 */}
											<FormSelect
												id="send_org"
												label="택배사"
												value={values.send_org}
												item={_setCodeFilter(common.code, "G026")}
												firstDefault={true}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
									</Grid>
								</Grid>
								{/* 구분 */}
								<Grid item xs={3}>
									<Grid container justify="center">
										<Grid item xs={4}>
											{/* 수선품상태 */}
											<FormSelect
												id="prod_stat"
												label="수선품상태"
												value={values.prod_stat}
												item={_setCodeFilter(common.code, "G008")}
												firstDefault={true}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={4}>
											{/* 접수경로 */}
											<FormSelect
												id="rcpt_chnl"
												label="접수경로"
												value={values.rcpt_chnl}
												item={_setCodeFilter(common.code, "G006")}
												firstDefault={true}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={4}>
											{/* 발송방식 */}
											<FormSelect
												id="send_typ"
												label="발송방식"
												value={values.send_typ}
												item={_setCodeFilter(common.code, "G007")}
												firstDefault={true}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
									</Grid>
								</Grid>
								{/* 검색어 */}
								<Grid item xs={4}>
									{/* 구분 */}
									<Grid
										container
										justify="center"
										alignItems="center">
										<Grid item xs={2}>
											<FormText
												id='limit'
												label='데이터 수'
												value={values.limit}
												// disabled={true}
												required={true}
												blur={handleBlur}
												change={handleChange} />
										</Grid>
										<Grid item xs={3}>
											<FormSelect
												id="srch_key"
												label="구분"
												firstDefault={false}
												value={values.srch_key}
												item={[
													{ code: "prod_no", name: "수선품번호" },
													{ code: "rcpt_name", name: "고객명" },
													{ code: "send_no", name: "송장번호" },
													{ code: "phone", name: "연락처" }
												]}
												required={true}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper}>
												<InputBase
													className={classes.input}
													placeholder="검색어를 입력해주세요"
													id="srch_val"
													name="srch_val"
													value={values.srch_val}
													type="text"
													error={Boolean(touched.srch_val && errors.srch_val)}
													onBlur={handleBlur}
													onChange={handleChange}
												/>
												<IconButton
													type="submit"
													className={classes.iconButton}
													aria-label="search"
												>
													<Search />
												</IconButton>
											</Paper>
										</Grid>
										<Grid item xs={1}></Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			</div>
			<div className={classes.grid}>{datagrid}</div>
		</React.Fragment>
	);
};

export default DeliveryList;
