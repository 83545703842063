/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Users page
**/
/* @material import */
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	makeStyles,
	Toolbar,
	Typography
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { Add } from "@material-ui/icons";
import { Formik } from 'formik';
/* react import */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import { _clearSession, _setCodeFilter } from "./../../utils/Common";
import {
	addUsers, getUsers
} from "./../../utils/redux/actions/UsersAction";

/* styles */
const useStyles = makeStyles((theme) => ({	
	root: {
		height: "100%",
		width: "100%",
		maxHeight: "90%",
		minWidth: "90%",
		margin: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
	},
	toolbar: {
		minHeight: 40,
		color: "#fff",
		backgroundColor: "#1976d2",
	},
	dialogToolbar: {
		minHeight: 40,
		width: 400,
		color: "#fff",
		backgroundColor: "#1976d2",
	},
	iconButton: {
		padding: 10,
	},
	label: {
		fontSize: 20,
		color: "primary",
		marginTop: theme.spacing(2),
	},
	userMessage: {
		fontSize: 10
	},
}));

/* ######################################################################
 * component
 * ###################################################################### */
const UsersAdd = () => {
				const classes = useStyles(); // CSS
				const dispatch = useDispatch(); // dispatch hook
				const navigate = useNavigate(); // link

				// [state] ######################################################################
				// state 상태 관리
				const common = useSelector((state: any) => state.common); // common

				// [Events] ######################################################################
				//@ 사용자 등록
				const handleAdd = useCallback(
					(v) => {
						addUsers(v)
							.then((res) => dispatch(res))
							.then((v: any) => {									
								if (v.type == "USERS_FAIL") {									
									if (v.status == "401") {									// 권한 없음
										common.msg = "세션이 만료되어 로그 아웃됩니다.";
										common.isMsg = true;
										_clearSession();
										navigate("/login", { replace: true });
										return dispatch({ type: "COM_ALERT" }); // 안내메시지
									} else if (v.status === 400) {
										common.msg = "존재하는 email 입니다.";
										common.isMsg = true;
										return dispatch({ type: "COM_ALERT" }); // 안내메시지
									} 
									else {
										common.msg = v.status.toString();
										common.isMsg = true;
										return dispatch({ type: "COM_ALERT" }); // 안내메시지
									}									
								} else {
									common.msg = "저장에 성공 하였습니다.";
									common.isMsg = true;
									return dispatch({ type: "COM_ALERT" }); // 안내메시지
								}
							})							
							.then(() => getUsers().then((d) => dispatch(d)));
					},
					[common, navigate, dispatch]
				);
				
				//@ 팝업 속성 상태 선언
				const [open, setOpen] = React.useState(false);
				//@ 팝업 닫기
				const handleClose = useCallback(() => {
					setOpen(false);
				}, []);
				//@ 팝업 열기
				const handleClickOpen = () => {
					setOpen(true);
				};

				//@ 체크박스 속성 선언
				// const [checked, setChecked] = React.useState(true);
				// const chkBoxHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
				//     setChecked(event.target.checked);
				// };

				// [Render] ######################################################################
				return (
					<React.Fragment>
						<Toolbar className={classes.toolbar}>
							<Grid container justify="center" alignItems="center">
								<Grid item xs={6}>
									<Grid container justify="flex-start">										
										<Typography variant="h6">사용자</Typography>
									</Grid>
								</Grid>
								{/* <Grid item xs={4} /> */}
								<Grid item xs={6}>
									<Grid container justify="flex-end">
										{/* 신규 등록 */}
										<IconButton
											className={classes.iconButton}
											aria-label="추가"
											color="inherit"
											onClick={handleClickOpen}
										>
											<Add />
											<Typography variant="h6">추가</Typography>
										</IconButton>
									</Grid>
								</Grid>
							</Grid>
						</Toolbar>
						<div>
							<Dialog
								open={open}
								onClose={handleClose}
								aria-labelledby="form-dialog-title"
							>
								<DialogTitle
									id="form-dialog-title"
									className={classes.dialogToolbar}
								>
									사용자 추가
								</DialogTitle>
								<Formik
									initialValues={{
										// org_no: _isEmpty(users.info.org_no) ? '' : users.info.org_no,      // 지점 코드
										// email: _isEmpty(users.info.email) ? '' : users.info.email,   // 이메일
										org_no: "01",
										email: "", // 이메일
										user_name: "",
										phone: "",
										is_superuser: true,
										password: "",
										is_active: true,
										user_grd: 1,
										// user_name: _isEmpty(users.info.cust_name) ? '' : users.info.cust_name,   // 사용자 이름
										// phone: _isEmpty(users.info.rcpt_chnl) ? '' : users.info.rcpt_chnl,   // 전화번호
										// is_superuser: _isEmpty(users.info.brand_cd) ? '' : users.info.brand_cd,      // 관리자 권한
										// hashed_password: _isEmpty(users.info.hashed_password) ? '' : users.info.hashed_password         // 비밀번호
										// is_active: _isEmpty(users.info.is_active) ? '' : users.info.is_active         // 활동 여부
									}}
									validationSchema={Yup.object().shape({
										user_name: Yup.string()
											.min(2, "2자 이상 입력해주세요")
											.required("사용자 이름을 입력해주세요"),
										email: Yup.string()
											.required("이메일을 입력해주세요")
											.email("이메일을 입력해주세요."),
										password: Yup.string()
											.min(4, "4자 이상 입력해주세요.")
											.required("비밀번호를 입력해주세요."),
										phone: Yup.string().min(4, "4자 이상 입력해주세요."),
									})}
									onSubmit={(v) => {
										// 등록
										handleAdd(v);
										handleClose();
									}}
								>
									{({
										errors,
										handleBlur,
										handleChange,
										handleSubmit,
										touched,
										values,
									}) => (
										<form onSubmit={handleSubmit}>
											<DialogContent>
												<Box ml={1} mb={1} mt={1} justifyContent="center">
													{/* email 입력 영역 */}
													<Box display="flex" justifyContent="center">
														<TextField
															autoFocus
															margin="dense"
															name="email"
															label="email 입력"
															type="text"
															fullWidth
															error={Boolean(touched.email && errors.email)}
															helperText={touched.email && errors.email}
															onBlur={handleBlur}
															onChange={handleChange}
															value={values.email}
														/>
													</Box>
													{/* 비밀번호 입력 영역*/}
													<Box display="flex" justifyContent="center">
														<TextField
															margin="dense"
															name="password"
															label="비밀번호 입력"
															type="password"
															fullWidth
															error={Boolean(
																touched.password && errors.password
															)}
															helperText={touched.password && errors.password}
															onBlur={handleBlur}
															onChange={handleChange}
															value={values.password}
														/>
													</Box>
													{/* 이름 입력 영역 */}
													<Box display="flex" justifyContent="center">
														<TextField
															margin="dense"
															name="user_name"
															label="이름 입력"
															type="text"
															fullWidth
															error={Boolean(
																touched.user_name && errors.user_name
															)}
															helperText={touched.user_name && errors.user_name}
															onBlur={handleBlur}
															onChange={handleChange}
															value={values.user_name}
														/>
													</Box>
													{/* 전화번호 입력 영역 */}
													<Box display="flex" justifyContent="center">
														<TextField
															margin="dense"
															name="phone"
															label="전화번호 입력"
															type="text"
															fullWidth
															// error={Boolean(touched.phone && errors.phone)}
															// helperText={touched.phone && errors.phone}
															onBlur={handleBlur}
															onChange={handleChange}
															value={values.phone}
														/>
													</Box>
													{/* 사용권한 입력 영역 */}
													<Box display="flex" justifyContent="left">
														<FormSelect
															mb={1}
															className={classes.label}
															id="user_grd"
															label="관리자 권한"
															value={values.user_grd}
															item={_setCodeFilter(common.code, "G022")}
															errors={errors.user_grd}
															blur={handleBlur}
															change={handleChange}
															touched={touched.user_grd}
														/>
													</Box>
												</Box>
											</DialogContent>
											<DialogActions>
												{/* 취소버튼 */}
												<Button onClick={handleClose} color="primary">
													취소
												</Button>
												{/* 추가 버튼*/}
												<Button
													type="submit"
													variant="contained"
													color="primary"
												>
													사용자 추가
												</Button>
											</DialogActions>
										</form>
									)}
								</Formik>
							</Dialog>
						</div>
					</React.Fragment>
				);
			};
export default UsersAdd;
