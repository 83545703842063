/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : KaKao Rcpt List Page
**/
/* @material import */
import {
	Grid,
	IconButton,
	InputBase,
	makeStyles,
	Paper,
	Toolbar,
	Typography
} from '@material-ui/core';
import {
	DataGrid
} from '@material-ui/data-grid';
import {
	GetApp,
	List,
	Search
} from '@material-ui/icons';
import { Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import {
	_getCodeName,
	_isEmpty,
	_setCodeFilter,
	_setDateYYYYMMDD,
	_setDayYYYYMMDD,
	_setFormatCode,
	_setFormatDate,
	_setUTCDate,
	_setFormatPhone,
	_getSession,
	_getCodeName2
} from "../../utils/Common";
import FormDate from "../../utils/form/FormDate";
import FormSelect from "../../utils/form/FormSelect";
import { getBotRcpt, getBotRcptInfo } from "../../utils/redux/actions/BotRcptAction";
import { getExcel } from "../../utils/redux/actions/ExcelDownloadAction";
import KaKaoRcptView from './KaKaoRcptView';

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
	root: {
	},
	grid: {
		height: '100%',
		width: '98%',
		maxHeight: '70%',
		minWidth: '98%',
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
		'& .MuiDataGrid-columnsContainer': {
			backgroundColor: '#9fa8da',
			color: '#ffffff'
		},
	},
	// 데이터 그리드 테이블 해더
	search: {
		margin: theme.spacing(1)
	},
	toolbar: {
		minHeight: 40,
		color: '#fff',
		backgroundColor: '#1976d2'
	},
	iconButton: {
		padding: 10,
	},
	paper: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'flex-end',
		width: '100%',
		height: 30,
		marginTop: 13
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	button: {
		margin: theme.spacing(1),
	},
	searchTypography: {
		marginTop: 12
	}
}));

/* ######################################################################
 * component 
 * ###################################################################### */
let excel_info: any = [];
const KaKaoRcptList = () => {
	const classes = useStyles();     // CSS
	const dispatch = useDispatch();  // dispatch hook

	// [state] ######################################################################
	const botRcpt = useSelector((state: any) => state.botRcpt);    // botRcpt
	const common = useSelector((state: any) => state.common);      // common

	// [Events] ######################################################################
	//@ 최초 실행
	const handleInit = useCallback(() => {
		let body = {};
		getBotRcpt(body)
			.then((d) => dispatch(d))
			.then((d) => {
				excel_info = [];
				excel_info = d.payload;
			})
			.catch((e) => console.log(e));
	}, [dispatch]);

	//@ 선택
	const handleChange = useCallback((v) => {
		botRcpt.isKakaoRcpt = true;
		let body = {
			bot_receipt_no: v.data.bot_receipt_no
		}
		getBotRcptInfo(body).then((d) => dispatch(d))
			.catch((e) => console.log(e));
	}, [botRcpt, dispatch]);

	//@ 검색
	const handleSearch = useCallback((v) => {

		let body: any = {};
		if (!_isEmpty(v.st_dt)) body['st_dt'] = _setUTCDate(v.st_dt);
		if (!_isEmpty(v.ed_dt)) body['ed_dt'] = _setUTCDate(v.ed_dt);
		if (!_isEmpty(v.rcpt_stat)) body['rcpt_stat'] = v.rcpt_stat;
		if (!_isEmpty(v.srch_val)) body[v.srch_key.toString()] = v.srch_val;

		getBotRcpt(body)
			.then((d) => dispatch(d))
			.then((d) => {
				excel_info = d.payload;
			})
			.catch((e) => console.log(e));
	}, [dispatch]);

	//@ Excel downlaod
	const excelDownload = useCallback(() => {
		let excel_table: any = [];
		let _code_data = _getSession('CODE_DATA');
		_code_data = _code_data.payload;
		excel_info.forEach((excel: any) => {
			excel_table.push({
				접수번호: excel.bot_receipt_no,
				카카오ID: excel.kakao_id,
				닉네임: excel.kakao_name,
				고객명: excel.cust_name,
				연락처: excel.phone,
				이메일: _isEmpty(excel.email) ? "" : excel.email.toString(),
				주소: excel.addr,
				수거요청사항: _isEmpty(excel.rcpt_cont) ? "" : excel.rcpt_cont.toString(),
				품목: excel.rcpt_cate,
				접근일시: _setDayYYYYMMDD(excel.reg_dtime, "-"),
				수거요청일: _setDayYYYYMMDD(excel.rcpt_dtime, "-"),
				// 수량: excel.rcpt_cnt,
				// 결재비용: _isEmpty(excel.rcpt_pay) ? "" : excel.rcpt_pay.toString(),
				// 결재상태: _getCodeName2(_code_data, excel.pay_stat, "G021"),
				접수상태: _getCodeName2(_code_data, excel.rcpt_stat, "G020"),
			});
		});
		if (_isEmpty(excel_table)) {
			alert('데이터를 선택해주세요')
		} else {
			getExcel(excel_table);
		}
	}, []);

	// [init] ######################################################################
	// 데이트 타입 설정
	const _setDate = _setFormatDate();
	// 상담경로명 조회
	const _setCodeName = _setFormatCode(common.code, 'G020');
	// 연락처 조회
	const _setGridPhone = _setFormatPhone();

	//@ 초기 데이터 (Table 컬럼)
	const columns = [
		{ field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
		{ field: 'bot_receipt_no', headerAlign: 'center', headerName: '접수번호', hide: true, width: 130 },
		{ field: 'kakao_id', headerAlign: 'center', headerName: '카카오ID', width: 140 },
		{ field: 'kakao_name', headerAlign: 'center', headerName: '닉네임', hide: true, width: 80 },
		{ field: 'cust_name', headerAlign: 'center', headerName: '고객명', width: 140 },
		{ field: 'phone', headerAlign: 'center', headerName: '연락처', width: 130, ..._setGridPhone },
		{ field: 'email', headerAlign: 'center', headerName: '이메일', width: 150, hide: true },
		{ field: 'addr', headerAlign: 'center', headerName: '주소', hide: true },
		{ field: 'rcpt_cont', headerAlign: 'center', headerName: '수거요청사항', hide: true },
		{ field: 'rcpt_cate', headerAlign: 'center', headerName: '수선종류', width: 120 },
		{ field: 'reg_dtime', headerAlign: 'center', headerName: '접근일시', width: 120, ..._setDate },
		{ field: 'rcpt_dtime', headerAlign: 'center', headerName: '수거요청일', width: 130, ..._setDate },
		// { field: 'rcpt_cnt', headerAlign: 'center', headerName: '수량', hide: true },
		// { field: 'rcpt_pay', headerAlign: 'center', headerName: '결재비용', hide: true },
		// { field: 'pay_stat', headerAlign: 'center', headerName: '결재상태', hide: true },
		{ field: 'rcpt_stat', headerAlign: 'center', headerName: '접수상태', width: 100, ..._setCodeName }
	];

	//@ 최초 실행
	useEffect(() => {
		handleInit();
	}, [handleInit]);

	// [Grid] ######################################################################    
	let datagrid;

	if (_isEmpty(botRcpt.rows)) {
		datagrid = <Typography
			variant="h6"
			align="center"
		>
			데이터가 존재하지 않습니다.
		</Typography>;
	} else {
		datagrid = <DataGrid
			headerHeight={40}
			rowHeight={30}
			columns={columns}
			rows={botRcpt.rows}
			pageSize={5}
			onRowClick={handleChange}
		/>;
	}

	// [Render] ######################################################################  
	return (
		<React.Fragment>
			<Toolbar className={classes.toolbar}>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={6}>
						<Typography variant="h6">카카오 수거 접수</Typography>
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={3}>
						<Grid container justify="center" alignItems="center">
							<Grid item xs={4}></Grid>
							<Grid item xs={8}>
								<Grid container justify="flex-end">
									{/* 엑셀다운로드 */}
									<IconButton
										className={classes.iconButton}
										aria-label="엑셀다운로드"
										color="inherit"
										onClick={excelDownload}
									>
										<GetApp />
										<Typography variant="h6">엑셀다운로드</Typography>
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={2}>
						<Grid container justify="center" alignItems="center">
							<Grid item xs={5}></Grid>
							<Grid item xs={7}>
								{/* 전체목록 */}
								<IconButton
									className={classes.iconButton}
									aria-label="전체목록"
									color="inherit"
									onClick={() => {
										handleInit();
									}}
								>
									<List />
									<Typography variant="h6">전체</Typography>
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
			{/* 검색기능 */}
			<div className={classes.search}>
				<Formik
					enableReinitialize
					initialValues={{
						// 기본정보
						st_dt: _isEmpty(botRcpt.srch)
							? ""
							: _isEmpty(botRcpt.srch.st_dt)
								? ""
								: _setDateYYYYMMDD(botRcpt.srch.st_dt, "-"), // 검색시작일
						ed_dt: _isEmpty(botRcpt.srch)
							? _setDateYYYYMMDD("", "-")
							: _isEmpty(botRcpt.srch.ed_dt)
								? _setDateYYYYMMDD("", "-")
								: _setDateYYYYMMDD(botRcpt.srch.ed_dt, "-"), // 검색종료일

						rcpt_stat: _isEmpty(botRcpt.srch)
							? ""
							: _isEmpty(botRcpt.srch.rcpt_stat)
								? ""
								: botRcpt.srch.rcpt_stat, // 접수진행상태

						srch_key: _isEmpty(botRcpt.srch)
							? "kakao_id"
							: _isEmpty(botRcpt.srch.srch_key)
								? "kakao_id"
								: botRcpt.srch.srch_key, // 검색키
						srch_val: _isEmpty(botRcpt.srch)
							? ""
							: _isEmpty(botRcpt.srch.srch_val)
								? ""
								: botRcpt.srch.srch_val, // 검색어
					}}
					validationSchema={Yup.object().shape({
						// 밸리데이션 불필요
					})}
					onSubmit={(v) => {
						handleSearch(v);
					}}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						touched,
						values,
					}) => (
						<form onSubmit={handleSubmit}>
							{/* 그리드 */}
							<Grid container justify="center" alignItems="center">
								{/* 기간 */}
								<Grid item xs={5}>
									<Grid container justify="center">
										<Grid item xs={5}>
											<FormDate
												id="st_dt"
												label="접근일(시작)"
												value={values.st_dt}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={1}>
											<Typography
												variant="h3"
												className={classes.searchTypography}
												align="center"
											>
												~{" "}
											</Typography>
										</Grid>
										<Grid item xs={5}>
											<FormDate
												id="ed_dt"
												label="접근일(종료)"
												value={values.ed_dt}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={1}></Grid>
									</Grid>
								</Grid>
								{/* 구분 */}
								<Grid item xs={2}>
									<Grid container justify="center">
										<Grid item xs={1}></Grid>
										<Grid item xs={10}>
											<FormSelect
												id="rcpt_stat"
												label="진행상태"
												value={values.rcpt_stat}
												item={_setCodeFilter(common.code, "G020")}
												firstDefault={true}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={1}></Grid>
									</Grid>
								</Grid>
								{/* 검색어 */}
								<Grid item xs={5}>
									{/* 구분 */}
									<Grid container justify="center">
										<Grid item xs={1}></Grid>
										<Grid item xs={4}>
											<FormSelect
												id="srch_key"
												label="검색구분"
												firstDefault={false}
												value={values.srch_key}
												item={[
													{ code: "kakao_id", name: "카카오ID" },
													{ code: "cust_name", name: "고객명" },
													{ code: "phone", name: "연락처" },
												]}
												required={true}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={7}>
											<Paper className={classes.paper}>
												<InputBase
													className={classes.input}
													placeholder="검색어를 입력해주세요"
													id="srch_val"
													name="srch_val"
													value={values.srch_val}
													type="text"
													error={Boolean(touched.srch_val && errors.srch_val)}
													onBlur={handleBlur}
													onChange={handleChange}
												/>
												<IconButton
													type="submit"
													className={classes.iconButton}
													aria-label="search"
												>
													<Search />
												</IconButton>
											</Paper>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			</div>
			<div className={classes.grid}>{datagrid}</div>
			<KaKaoRcptView />
		</React.Fragment>
	);
};

export default KaKaoRcptList;
