/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux chatbot receipt log store
**/
// TYPES
import {
  BOT_RCPT_FAIL,
  BOT_RCPT_GET,
  BOT_RCPT_GET_INFO
} from "../types";
import { _dataAddID, _isEmpty } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows:[],
  info:{},
  srch:{},            // 검색  
  isEdit: false,
  isKakaoRcpt: false  // 상세보기 팝업
};

// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case BOT_RCPT_GET:         // 챗봇 수거 접수 로그 전체 조회     
      return { 
        ...state,
        rows: _dataAddID(action.payload)
       };
    case BOT_RCPT_GET_INFO:    // 챗봇 수거 접수 로그 개별 조회    
      return { 
        ...state,
        info: action.payload[0],
        isEdit: _isEmpty(action.payload) ? false : true
       };        
    case BOT_RCPT_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}