/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux customer actions
**/
import { _ajaxPostJsonAsync } from "../../axios/axios";
import {
  CUST_ADD,
  CUST_FAIL,
  CUST_GET,
  CUST_GET_INFO,
  CUST_UPD,
  CUST_SEND,
  CUST_UPD_STAT
} from "../types";

/* 고객 경로 정보 */
const API_PATH = "/api/v1/customers";

/* 고객 목록 조회 */
export const getCust = async (body:any) => {
  // IN Data 구성
  //body['limit'] = '1000';
  
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, CUST_GET, CUST_FAIL);
}

/* 고객 개별 조회 */
export const getCustInfo = async (d:string) => {

  // IN Data 구성
  let body = {
    limit: '1',
    cust_no: d
  }
  // ajax 통신
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, CUST_GET_INFO, CUST_FAIL);
}

/* 고객 신규 등록 */
export const addCust = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['reg_user'] = user_name;       // 최초작성자
  d['last_mod_user'] = user_name;  // 수정자

  return await _ajaxPostJsonAsync("post", API_PATH+"/", d, CUST_ADD, CUST_FAIL);
}

/* 고객 수정 */
export const updCust = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH+"/"+d.cust_no, d, CUST_UPD, CUST_FAIL);
}

/* 접수 카카오톡 전송 */
export const sendKakaoCust = async (body: any) => {
  return await _ajaxPostJsonAsync("post",API_PATH + "/send/",body, CUST_SEND, CUST_FAIL);
} 

/* 카카오 전송 상태값 수정 */
export const updCustStat = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['chk_kakao_cust'] ='02'; //카카오 전송 상태
  d['last_mod_user'] =user_name; //수정자
  return await _ajaxPostJsonAsync("put",API_PATH +"/stat/"+d.cust_no, d, CUST_UPD_STAT,CUST_FAIL);
}