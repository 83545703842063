/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux consult store
**/
// TYPES
import {
  CNSLT_ADD,
  CNSLT_FAIL,
  CNSLT_GET,
  CNSLT_GET_INFO,
  CNSLT_UPD
} from "../types";
import { _dataAddID, _isEmpty, _dataAddIDAndPay } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows:[],
  info:{},
  srch:{},   // 검색  
  search:{}, // 고객검색 
  isEdit: false
};
                                      
// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case CNSLT_GET:         // 상담 전체 조회     
      return { 
        ...state,
        rows: _dataAddIDAndPay(action.payload)
       };
    case CNSLT_GET_INFO:    // 상담 개별 조회    
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload.cnslt_no) ? false : true
       };       
    case CNSLT_ADD:         // 상담 저장
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload.cnslt_no) ? false : true
       };    
    case CNSLT_UPD:         // 상담 수정
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload.cnslt_no) ? false : true
       };      
    case CNSLT_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}