/**
 * date         : 2020.12.29
 * creater      : nuriggum
 * description  : Delivery Edit Page
**/
/* @material import */
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  Message,
  Save
} from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import {
  _isEmpty,
  _setCateCodeFilter,
  _setCodeFilter,
  _setDateYYYYMMDD,
  _setDateYYYYMMDD2,
  _setUTCDate
} from "./../../utils/Common";
import FormDate from "./../../utils/form/FormDate";
import FormText from "./../../utils/form/FormText";
import { getProd, sendKakaoDliv, updKakaoDliv, updProd } from "./../../utils/redux/actions/ProdAction";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
    height: '100%',
    width: '100%',
    maxHeight: '90%',
    minWidth: '90%',
    overflow: 'auto',
    margin: theme.spacing(1)
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1),
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const DeliveryEdit = forwardRef((props, ref) => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook
  const formRef = useRef<any>(null);

  // 부모 컴포넌트 사용
  useImperativeHandle(ref, () => formRef.current);

  // [state] ######################################################################  
  const common = useSelector((state: any) => state.common);              // common
  const product = useSelector((state: any) => state.product);            // product

  // [Events] ######################################################################
  //@ 초기화
  // const handleinit = useCallback(() => {
  //   dispatch({
  //     type:'PROD_GET_INFO',
  //     payload: {},
  //   });
  // },[dispatch])

  //@ 수정
  const handleUpdate = useCallback((v) => {
    updProd(v).then((res: any) => dispatch(res))
      .then(() => {
        let body: any = {};
        if (!_isEmpty(product.srch.date_key)) body["date_key"] = product.srch.date_key;
        if (!_isEmpty(product.srch.st_dt)) body["st_dt"] = _setUTCDate(product.srch.st_dt);
        if (!_isEmpty(product.srch.ed_dt)) body["ed_dt"] = _setUTCDate(product.srch.ed_dt);
        if (!_isEmpty(product.srch.rcpt_chnl)) body["rcpt_chnl"] = product.srch.rcpt_chnl;
        if (!_isEmpty(product.srch.prod_stat)) body["prod_stat"] = product.srch.prod_stat;
        if (!_isEmpty(product.srch.send_typ)) body["send_typ"] = product.srch.send_typ;
        if (!_isEmpty(product.srch.send_org)) body["send_org"] = product.srch.send_org;
        if (!_isEmpty(product.srch.srch_val)) body[product.srch.srch_key.toString()] = product.srch.srch_val;
        if (!_isEmpty(product.srch.limit)) body["limit"] = product.srch.limit;

        getProd(body).then((d) => dispatch(d))
          .catch((e) => console.log(e));
      })
      .then(() => {
        common.msg = '수정에 성공 하였습니다.';
        common.isMsg = true;
        return dispatch({ type: 'COM_ALERT' });       // 안내메시지
      })
    // .then(() => getProd({}).then((d) => dispatch(d)));
  }, [product, common, dispatch]);

  const sendKakao = () => {
    sendKakaoDliv(product.info).then((res: any) => dispatch(res))
      .then((v: any) => {
        if (v.type === "PROD_FAIL") {
          common.msg = '알림톡 전송이 실패했습니다'
          common.isMsg = true;
          return dispatch({ type: 'COM_ALERT' })
        } else {
          common.msg = '출고 알림톡이 전송되었습니다.';
          common.isMsg = true;
          updKakaoDliv(product.info)
          return dispatch({ type: 'COM_ALERT' });
        }
      })
      .then(() => getProd(product.srch).then((d) => dispatch(d)));
  };

  // [init] ######################################################################
  //@ 최초 실행
  useEffect(() => {
  }, []);

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h6">출고 정보</Typography>
          </Grid>
        </Grid>
      </Toolbar>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          //# 수선품
          prod_no: _isEmpty(product.info) ? '' : product.info.prod_no,             // 수선품 번호
          rcpt_name: _isEmpty(product.info) ? '' : product.info.rcpt_name,       // 고객명
          phone: _isEmpty(product.info) ? '' : product.info.phone,                   // 연락처
          addr: _isEmpty(product.info) ? '' : product.info.addr,                      // 주소
          brand_cd: _isEmpty(product.info) ? '' : product.info.brand_cd,          // 브랜드
          brand_name: _isEmpty(product.info) ? '' : product.info.brand_cd,          // 브랜드이름(한글)
          cate_cd: _isEmpty(product.info) ? '' : product.info.cate_cd,             // 수선종류
          cate_name: _isEmpty(product.info) ? '' : product.info.cate_name,             // 수선종류(한글)
          rcpt_dtime: _isEmpty(product.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(product.info.rcpt_dtime, '-'),      // 접수일
          check_ed_dt: _isEmpty(product.info) ? null : _setDateYYYYMMDD(product.info.check_ed_dt, '-'),   // 검수완료일
          repr_req_dt: _isEmpty(product.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(product.info.repr_req_dt, '-'),      // 출고요청일
          prod_ed_dt: _isEmpty(product.info) ? null : _setDateYYYYMMDD(product.info.prod_ed_dt, '-'),      // 출고완료일
          send_typ: _isEmpty(product.info) ? '' : product.info.send_typ,          // 발송방식
          send_org: _isEmpty(product.info.send_org) ? '' : product.info.send_org,          // 택배사
          send_no: _isEmpty(product.info.send_no) ? '' : product.info.send_no,             // 송장번호
          prod_stat: _isEmpty(product.info) ? '' : product.info.prod_stat,        // 처리상태
          chk_kakao_dliv: _isEmpty(product.info) ? '' : product.info.chk_kakao_dliv  // 알림톡전송상태
        }}
        validationSchema={Yup.object().shape({
          repr_req_dt: Yup.string()
            .required('수선유형을 선택해주세요'),
          prod_stat: Yup.string()
            .required('수선(대분류)를 선택해주세요')
        })}
        onSubmit={(v) => {
          // UTC 포멧 변경
          v.rcpt_dtime = _setUTCDate(v.rcpt_dtime);
          v.check_ed_dt = _isEmpty(v.check_ed_dt) ? null : _setUTCDate(v.check_ed_dt);
          v.repr_req_dt = _setUTCDate(v.repr_req_dt);
          v.prod_ed_dt = _isEmpty(v.prod_ed_dt) ? null : _setUTCDate(v.prod_ed_dt);

          if (product.isEdit) {
            handleUpdate(v); // 저장
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
          <form onSubmit={handleSubmit}>
            {/*############################## 출고 ##############################*/}
            <Box ml={2} mr={3} mt={1} borderTop={2} borderColor="primary.main" justifyContent="center">
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}>
                {/* 고객명 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormText
                    id='rcpt_name'
                    label='고객명'
                    value={values.rcpt_name}
                    errors={errors.rcpt_name}
                    touched={touched.rcpt_name}
                    disabled={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 핸드폰번호 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormText
                    id='phone'
                    label='연락처'
                    value={values.phone}
                    errors={errors.phone}
                    touched={touched.phone}
                    disabled={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 주소 */}
                <Grid item xs={12} className={classes.grid}>
                  <FormText
                    id='addr'
                    label='주소'
                    value={values.addr}
                    errors={errors.addr}
                    touched={touched.addr}
                    disabled={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 브랜드 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='brand_cd'
                    label='브랜드'
                    value={values.brand_cd}
                    item={_setCateCodeFilter(common.category, '0100000000')}
                    errors={errors.brand_cd}
                    touched={touched.brand_cd}
                    disabled={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 수선종류 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='cate_cd'
                    label='수선종류'
                    value={values.cate_cd}
                    item={_setCodeFilter(common.code, 'G025')}
                    errors={errors.cate_cd}
                    touched={touched.cate_cd}
                    disabled={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 접수일 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormDate
                    id='rcpt_dtime'
                    label='접수일'
                    value={values.rcpt_dtime}
                    required={true}
                    disabled={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 검수완료일 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormDate
                    id='check_ed_dt'
                    label='검수완료일'
                    value={values.check_ed_dt}
                    // disabled={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 출고요청일 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormDate
                    id='repr_req_dt'
                    label='출고요청일'
                    value={values.repr_req_dt}
                    required={true}
                    disabled={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 출고일 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormDate
                    id='prod_ed_dt'
                    label='출고일'
                    value={values.prod_ed_dt}
                    // required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 발송방식 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='send_typ'
                    label='발송방식'
                    value={values.send_typ}
                    item={_setCodeFilter(common.code, 'G007')}
                    required={true}
                    disabled={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 택배사 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='send_org'
                    label='택배사'
                    value={values.send_org}
                    item={_setCodeFilter(common.code, 'G026')}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 송장번호 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormText
                    id='send_no'
                    label='송장번호'
                    value={values.send_no}
                    errors={errors.send_no}
                    touched={touched.send_no}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 상태 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='prod_stat'
                    label='처리상태'
                    value={values.prod_stat}
                    item={_setCodeFilter(common.code, 'G008')}
                    errors={errors.prod_stat}
                    touched={touched.prod_stat}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
              </Grid>
            </Box>
            {/* 버튼영역 */}
            <Box
              ml={1} mr={1} mb={1}
              display="flex"
              justifyContent="flex-end"
            >
              {/* 알림톡 */}
              <Button
                onClick={sendKakao}
                disabled={
                  !((values.chk_kakao_dliv === "01") && !(values.phone === null) && !(values.phone === '') &&
                    ((!(product.info.send_no === '') && !(product.info.send_no === null)) || (product.info.send_org === '03'))) ? true : false
                }
                // disabled={!((values.chk_kakao_dliv === "01") && !(product.info.send_no === '') && !(values.phone === null) && !(values.phone === '')) ? true : false}
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<Message />}
              >
                알림톡
              </Button>
              {/* 저장 */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<Save />}
              >
                저장
              </Button>
            </Box>
            <Box ml={1} mr={1} display="flex" justifyContent="flex-end">
              <p>* 정보 저장 후 알림톡을 전송해주세요</p>
            </Box>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
});

export default DeliveryEdit;
