/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : common function
**/

/* 카테고리 코드 변환 */
export const _CodeConvert = (_code: any, _node: any) => {

    let rtn = '';  // return

    // 코드 그룹 문자열
    let s_val = _code.substr(0, (_node * 2));
    // 코드 계산
    let code = ('' + (Number(_code.substr((_node * 2), 2)) + 1));
    let m_val = code.length > 1 ? code : '0' + code;
    // 마지막 코드 계산
    let e_val = _code.substr((_node * 2) + 2, _code.length);

    rtn = s_val + m_val + e_val;
    // console.log("rtn");
    // console.log(rtn);
    return rtn;
}

/* 세션 스토리지 저장 */
export const _getSession = (_key: any) => {
    let rtn: any = window.sessionStorage.getItem(_key);
    if (_isEmpty(rtn)) {
        rtn = '';
    } else {
        rtn = JSON.parse(rtn);
    }
    return rtn;
}

/* 세션 스토리지 조회 */
export const _setSession = (_key: any, _val: any) => {
    _val = JSON.stringify(_val)
    return window.sessionStorage.setItem(_key, _val);
}

/* 세션 스토리지 제거 */
export const _removeSession = (_key: any) => {
    return window.sessionStorage.removeItem(_key);
}

/* 세션 스토리지 전체 제거 */
export const _clearSession = () => {
    return window.sessionStorage.clear();
}

/* Null 체크 */
export const _isEmpty = (_data: any) => {

    let rtn = false;

    if (_data === null) {                          // server side
        rtn = true;
    } else if (_data === undefined) {              // script
        rtn = true;
    } else if (_data === 'undefined') {            // script
        rtn = true;
    } else if (_data === '') {                     // string
        rtn = true;
    } else if (Object.keys(_data).length === 0) {  // json 형태
        rtn = true;
    }

    return rtn;
}

/* 데이터 정제 */
export const _dataFilter = (_datas: any, _keys: any) => {

    let rtn: Element[] = [];   // return
    let field: Element[] = []; // 필드 추출

    // 필드 추출
    _keys.forEach((v: any) => {
        field.push(v.field);
    });

    // 데이터 정제
    _datas.forEach((v: any, i: number) => {
        let json: any = {};
        // 필요 컬럼만 생성 (data-grid 때문)
        field.forEach((d) => {
            if (d.toString() === 'id') {
                // 유니크 번호 생성 (data-grid 때문)
                json[d.toString()] = (i + 1).toString();
            } else {
                json[d.toString()] = v[d.toString()];
            }
        });
        rtn.push(json);
    });

    return rtn;
}

/* 데이터 ID 추가 (21.08.12 추가)*/
export const _BotdataAddID = (_datas: any) => {

    let rtn: Element[] = [];   // return

    // 21.08.05 브랜드 한글명 추가(브랜드명으로 정렬 위함)
    let _data = _getSession('CATEGOORY_DATA');
    _data = _data?.payload
    let name = '';
    let payload: any = {};
    // 까지 (21.08.05)

    // 데이터 정제
    _datas.forEach((v: any, i: number) => {
        // 21.08.05 브랜드 한글명 추가(브랜드명으로 정렬 위함)
        if (!_isEmpty(v.bot_cate_1)) {
            payload = _data?.filter((d: any) => d.cate_cd === v.bot_cate_1);
            if (!_isEmpty(payload)) {
                name = payload[0].cate_name;
            }
        } else {
            name = ''
        }
        v['brand_name'] = name.toString();
        // 까지 (21.08.05)
        v['id'] = (i + 1).toString();
        rtn.push(v);
    });
    return rtn;
}

/* 데이터 ID 추가 */
export const _dataAddID = (_datas: any) => {

    let rtn: Element[] = [];   // return

    // 21.08.05 브랜드 한글명 추가(브랜드명으로 정렬 위함)
    let _data = _getSession('CATEGOORY_DATA');
    _data = _data?.payload;
    let name = '';
    let payload: any = {};
    // 까지 (21.08.05)

    // 데이터 정제
    _datas.forEach((v: any, i: number) => {
        // 21.08.05 브랜드 한글명 추가(브랜드명으로 정렬 위함)
        if (!_isEmpty(v.brand_cd)) {
            payload = _data?.filter((d: any) => d.cate_cd === v.brand_cd);
            if (!_isEmpty(payload)) {
                name = payload[0].cate_name;
            }
        } else {
            name = ''
        }
        v['brand_name'] = name.toString();
        // 까지 (21.08.05)
        v['id'] = (i + 1).toString();
        rtn.push(v);
    });
    return rtn;
}

// 요기 (요걸로 밑에 세션에서 카테고리 데이터 받아온거 참고해서 상태값에 한글 브랜드이름 추가해보기)
// export const _dataAddbrandName = (_datas: any) => {

//     let rtn: Element[] = [];   // return
//     let _data = _getSession('CATEGOORY_DATA');
//     _data = _data.payload;
//     let name = '';
//     let payload: any = {};
//     _datas.forEach((v: any) => {
//         if (!_isEmpty(v.brand_cd)) {
//             payload = _data.filter((d: any) => d.cate_cd === v.brand_cd);
//             if (!_isEmpty(payload)) {
//                 name = payload[0].cate_name;
//             }
//         }
//         v['brand_name'] = name.toString();
//         rtn.push(v);
//     });
//     return rtn;
// }

/* 숫자 콤마 추가 */
export const _setAddComma = (_val: any) => {

    if (_isEmpty(_val)) {
        _val = '0';
    } else {
        let regx = new RegExp(/(-?\d+)(\d{3})/);
        let bExists = _val.indexOf(".", 0);
        let strArr = _val.split('.');

        while (regx.test(strArr[0])) {   //문자열에 정규식 특수문자가 포함되어 있는지 체크
            //정수 부분에만 콤마 달기 
            strArr[0] = strArr[0].replace(regx, "$1,$2"); //콤마추가하기
        }
        if (bExists > -1) {  //. 소수점 문자열이 발견되지 않을 경우 -1 반환
            _val = strArr[0] + "." + strArr[1];
        } else {             //정수만 있을경우 //소수점 문자열 존재하면 양수 반환 
            _val = strArr[0];
        }
    }
    return _val; //문자열 반환
};

/* 숫자 콤마 제거 */
export const _setRemoveComma = (_val: any) => {
    _val = "" + _val.replace(/,/gi, '');        // 콤마 제거 
    _val = _val.replace(/(^\s*)|(\s*$)/g, "");  // trim()공백,문자열 제거 
    return _val;
};

/* DataGrid 숫자 콤마 추가 */
export const _setFormatComma: any = () => {

    let currencyFormatter = new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW',
    });

    return {
        type: 'number',
        valueFormatter: ({ value }: any) => {
            if (_isEmpty(value)) {
                return currencyFormatter.format(0);
            } else {
                return currencyFormatter.format(Number(value));
            }
        },
        cellClassName: 'font-tabular-nums',
    };
};

/* DataGrid 날짜 형식 변환 */
export const _setFormatDate: any = () => {

    let currencyFormatter = new Intl.DateTimeFormat('ko-KR');
    let rtn = "";
    return {
        type: 'date',
        valueFormatter: ({ value }: any) => {
            let DATE;
            // let rtn = "";            // HSH 2021.02.03 - OP 서버 기준 UTC 시간 미적용

            if (_isEmpty(value)) {
                return '';
            } else {
                DATE = new Date(value);

                // let UTC = Date.UTC(DATE.getFullYear(), 
                //         DATE.getMonth(), 
                //         DATE.getDate(), 
                //         DATE.getHours(), 
                //         DATE.getMinutes(), 
                //         DATE.getSeconds(), 
                //         DATE.getMilliseconds()
                //     );
                // return currencyFormatter.format(UTC);  

                // HSH 2021.02.03 - OP 서버 기준 UTC 시간 미적용
                let year = DATE.getFullYear().toString();
                let month = (DATE.getMonth() + 1).toString();
                let day = DATE.getDate().toString();

                month = month.length === 1 ? "0" + month : month;
                day = day.length === 1 ? "0" + day : day;
                rtn = year + "." + month + "." + day + ".";
                return rtn;
            }
        }
    };
};

/* DataGrid 날짜와 시간 형식 변환 */
export const _setFormatTime: any = () => {

    let currencyFormatter = new Intl.DateTimeFormat('ko-KR');
    let rtn = "";
    return {
        type: 'date',
        valueFormatter: ({ value }: any) => {
            let DATE;
            // let rtn = "";            // HSH 2021.02.03 - OP 서버 기준 UTC 시간 미적용

            if (_isEmpty(value)) {
                return '';
            } else {
                DATE = new Date(value);

                // let UTC = Date.UTC(DATE.getFullYear(), 
                //         DATE.getMonth(), 
                //         DATE.getDate(), 
                //         DATE.getHours(), 
                //         DATE.getMinutes(), 
                //         DATE.getSeconds(), 
                //         DATE.getMilliseconds()
                //     );
                // return currencyFormatter.format(UTC);  

                // HSH 2021.02.03 - OP 서버 기준 UTC 시간 미적용
                let year = DATE.getFullYear().toString();
                let month = (DATE.getMonth() + 1).toString();
                let day = DATE.getDate().toString();
                let hour = DATE.getHours().toString();
                let min = DATE.getMinutes().toString();


                month = month.length === 1 ? "0" + month : month;
                day = day.length === 1 ? "0" + day : day;
                hour = hour.length === 1 ? "0" + hour : hour;
                min = min.length === 1 ? "0" + min : min;
                rtn = year + "/" + month + "/" + day + " " + hour + ":" + min;
                return rtn;
            }
        }
    };
};

/* DataGrid 날짜 형식 변환 */
export const _setFormatDateandTime: any = () => {

    let rtn = "";
    return {
        type: 'date',
        valueFormatter: ({ value }: any) => {
            let DATE;
            // let rtn = "";            // HSH 2021.02.03 - OP 서버 기준 UTC 시간 미적용

            if (_isEmpty(value)) {
                return '';
            } else {
                DATE = new Date(value);

                // HSH 2021.02.03 - OP 서버 기준 UTC 시간 미적용
                let year = DATE.getUTCFullYear().toString();
                let month = (DATE.getUTCMonth() + 1).toString();
                let day = DATE.getUTCDate().toString();
                let hour = DATE.getUTCHours().toString();
                let minute = DATE.getUTCMinutes().toString();

                month = month.length === 1 ? "0" + month : month;
                day = day.length === 1 ? "0" + day : day;
                hour = hour.length === 1 ? "0" + hour : hour;
                minute = minute.length === 1 ? "0" + minute : minute;
                rtn = year + "/" + month + "/" + day + " " + hour + ":" + minute;
                return rtn;
            }
        }
    };
};

/* // HSH 2021.02.03 - OP 서버 DataGrid 날짜 형식 변환 */
export const _setOPFormatDate: any = () => {
    let rtn = "";
    return {
        type: 'date',
        valueFormatter: ({ value }: any) => {
            let DATE;

            if (_isEmpty(value)) {
                return '';
            } else {
                DATE = new Date(value);
                let year = DATE.getFullYear().toString();
                let month = (DATE.getMonth() + 1).toString();
                let day = DATE.getDate().toString();

                month = month.length === 1 ? "0" + month : month;
                day = day.length === 1 ? "0" + day : day;
                rtn = year + "." + month + "." + day + ".";
                return rtn;
            }
        }
    };
};

/* DataGrid 카테고리 코드 변환 */
export const _setFormatCategory = (_data: any) => {
    return {
        valueGetter: ({ value }: any) => {
            let name = '';
            let payload: any = {};
            if (!_isEmpty(value)) {
                payload = _data.filter((v: any) => v.cate_cd === value);
                if (!_isEmpty(payload)) {
                    name = payload[0].cate_name;
                }
            }
            return name;
        }
    };
};



/* 카테고리 코드명 변환 */
export const _getCategoryName = (value: string) => {

    let _data = _getSession('CATEGOORY_DATA');
    _data = _data.payload;
    let name = '';
    let payload: any = {};

    if (!_isEmpty(_data)) {
        payload = _data.filter((v: any) => v.cate_cd === value);
        if (!_isEmpty(payload)) {
            name = payload[0].cate_name;
        }
    }

    return name;
};

export const _getCategoryName2 = (_data: any, value: string) => {

    let name = '';
    let payload: any = {};

    payload = _data.filter((v: any) => v.cate_cd === value);
    if (!_isEmpty(payload)) {
        name = payload[0].cate_name;
    }

    return name;
};

export const _dust_bag_convert = (_data: any) => {

    if (_data) {
        return '있음'
    }
    else {
        return '없음'
    }

}


/* 코드 데이터 코드명 변환 */
export const _getCodeName = (value: string, grp: string) => {

    let _data = _getSession('CODE_DATA');
    _data = _data.payload;
    let name = '';
    let payload: any = {};

    if (!_isEmpty(_data)) {
        payload = _data.filter((v: any) => v.cd_id === value && v.grp_cd_id === grp);
        if (!_isEmpty(payload)) {
            name = payload[0].cd_name;
        }
    }

    return name;
};


export const _getCodeName2 = (_data: any, value: string, grp: string) => {

    let name = '';
    let payload: any = {};

    payload = _data.filter((v: any) => v.cd_id === value && v.grp_cd_id === grp);
    if (!_isEmpty(payload)) {
        name = payload[0].cd_name;
    }

    return name;
};


/* DataGrid 코드 변환 */
export const _setFormatCode = (_data: any, _group: string) => {
    return {
        valueGetter: ({ value }: any) => {
            let name = '';
            let payload: any = {};
            if (!_isEmpty(value)) {
                payload = _data.filter((v: any) => v.grp_cd_id === _group && v.cd_id === value);
                if (!_isEmpty(payload)) {
                    name = payload[0].cd_name;
                }
            }
            return name;
        }
    };
};

/* 코드 정제 */
export const _setCodeFilter = (_data: any, _filter: string) => {

    let rows = _data.filter((v: any) => v.grp_cd_id === _filter);

    return rows.map((value: any) => ({
        code: value.cd_id,
        name: value.cd_name
    }));
};


/* 카테고리 코드 정제 */
export const _setCateCodeFilter = (_data: any, _filter: string) => {

    let rows = _data.filter((v: any) => v.cate_grp_cd === _filter);
     rows = rows.sort((a:any,b:any)=>{
         a = a.cate_name || '';
         b = b.cate_name || '';
         return a.localeCompare(b);
     });
    
    return rows.map((value: any) => ({
        code: value.cate_cd,
        name: value.cate_name
    }));
};

/* 카테고리 코드 정제 */
export const _setCateCodeFilter2 = (_data: any, _filter: string) => {

    let rows = _data;
    rows = rows.filter((v: any) => v.cate_grp_cd === _filter);
    rows = rows.sort((a:any,b:any) => (a.cate_name > b.cate_name) ? 1 : -1);
        
    return rows.map((value: any) => ({
        code: value.cate_cd,
        name: value.cate_name        
    }));
};

/* 카테고리 코드 존재 여부 확인 validation */
export const _setCateCodeExist = (_data: any, _filter: string, _code: string) => {
    let rows = _data.filter((v: any) => v.cate_grp_cd === _filter);
    let exit_value = [];
    exit_value = rows.map((value: any) => (value.cate_cd));

    return exit_value.includes(_code);
};

/* 공통 코드 존재 여부 확인 validation */
export const _setCodeExist = (_data: any, _filter: string, _code: string) => {
    let rows = _data.filter((v: any) => v.grp_cd_id === _filter);
    let exit_value = [];
    exit_value = rows.map((value: any) => value.cd_id);

    return exit_value.includes(_code);
};

/* DataGrid 연락처 변환 */
export const _setFormatPhone = () => {
    return {
        valueGetter: ({ value }: any) => {
            let phone = '';
            if (!_isEmpty(value)) {
                phone = _getPhone(value);
            }
            return phone;
        }
    };
};

/* 날짜 형식 변환 (YYYYMMDD) */
export const _setDateYYYYMMDD = (_date: any, _format: string) => {

    let rtn = '';  // 반환
    let DATE;      // 날짜

    if (_isEmpty(_date)) {
        DATE = new Date()
    } else {
        DATE = new Date(_date);
        // if(DATE.getDate() !== 1) {
        //     DATE.setDate(DATE.getDate() + 1);
        // }
    }

    let year = DATE.getFullYear().toString();
    let month = (DATE.getMonth() + 1).toString();
    let day = DATE.getDate().toString();

    month = (month.length === 1) ? '0' + month : month;
    day = (day.length === 1) ? '0' + day : day;

    rtn = year + _format + month + _format + day;
    return rtn;
};

/* 검수완료일용 날짜 형식 변환 (YYYYMMDD) */
export const _setDateYYYYMMDD2 = (_date: any, _format: string) => {

    let rtn = '';  // 반환
    let DATE;      // 날짜

    if (_isEmpty(_date)) {
        return ''
    } else {
        DATE = new Date(_date);
        // if(DATE.getDate() !== 1) {
        //     DATE.setDate(DATE.getDate() + 1);
        // }
    }

    let year = DATE.getFullYear().toString();
    let month = (DATE.getMonth() + 1).toString();
    let day = DATE.getDate().toString();

    month = (month.length === 1) ? '0' + month : month;
    day = (day.length === 1) ? '0' + day : day;

    rtn = year + _format + month + _format + day;
    return rtn;
};

/* 날짜 형식 변환 (YYYY-MM-DD) */
export const _setDayYYYYMMDD = (_date: any, _format: string) => {

    let rtn = '';  // 반환
    let DATE;      // 날짜
    if (_isEmpty(_date)) {
        rtn = "";
    } else {
        DATE = new Date(_date);
        let year = DATE.getFullYear().toString();
        let month = (DATE.getMonth() + 1).toString();
        let day = DATE.getDate().toString();

        month = month.length === 1 ? "0" + month : month;
        day = day.length === 1 ? "0" + day : day;
        rtn = year + _format + month + _format + day;
    }
    return rtn;
};

/* 출고요청날짜 형식 변환 (YYYYMMDD) */
export const _setReqDateYYYYMMDD = (_date: any, _format: string) => {

    let rtn = '';  // 반환
    let DATE;      // 날짜

    if (_isEmpty(_date)) {
        DATE = new Date()
    } else {
        DATE = new Date(_date);
        // if(DATE.getDate() !== 1) {
        //     DATE.setDate(DATE.getDate() + 1);
        // }
    }
    // let afterDATE = new Date(DATE.setDate(DATE.getDate() + 7))
    // console.log(afterDATE)
    DATE.setDate(DATE.getDate() + 7)

    let year = DATE.getFullYear().toString();
    let month = (DATE.getMonth() + 1).toString();
    let day = DATE.getDate().toString();

    month = (month.length === 1) ? '0' + month : month;
    day = (day.length === 1) ? '0' + day : day;

    rtn = year + _format + month + _format + day;
    // let year = DATE.getFullYear().toString();
    // let day = (DATE.getDate() + 7);
    // let month = '';

    // if (day < 8) {
    //     month = (DATE.getMonth() + 2).toString();
    // } else {
    //     month = (DATE.getMonth() + 1).toString();
    // }

    // let day_ = day.toString();

    // month = (month.length === 1) ? '0' + month : month;
    // day_ = (day_.length === 1) ? '0' + day_ : day_;

    // rtn = year + _format + month + _format + day_;
    return rtn;
};

/* 날짜 데이터 DATE > UTC 변환 */
export const _setUTCDate = (_date: any) => {
    let DATE = new Date(_date);
    // let UTC = Date.UTC(DATE.getFullYear(), 
    //         DATE.getMonth(), 
    //         DATE.getDate(), 
    //         DATE.getHours(), 
    //         DATE.getMinutes(), 
    //         DATE.getSeconds(), 
    //         DATE.getMilliseconds()
    //     );
    // console.log(' ### _setUTCDate ### ');
    // console.log(UTC.toString());
    // console.log(' ### NOW ### ');
    // console.log(new Date().toUTCString());
    // console.log(new Date().toISOString());
    // console.log(new Date().toString());
    // console.log(' ### SET ### ');
    // console.log(new Date(_date).toUTCString());
    // console.log(new Date(_date).toISOString());  // 선택
    // console.log(new Date(_date).toString());

    return DATE.toISOString();
    // return UTC.toString();
};

/* 날짜 데이터 DATE > UTC 변환 */
export const _setNowUTCDate = () => {

    let DATE = new Date();
    let UTC = Date.UTC(DATE.getFullYear(),
        DATE.getMonth(),
        DATE.getDate(),
        DATE.getHours(),
        DATE.getMinutes(),
        DATE.getSeconds(),
        DATE.getMilliseconds()
    );
    return UTC.toString();
};

/* 현재 날짜 변환 2020-01-01 00:00:00 */
export const _getNowDate = () => {
    let today = new Date();         //현재 날짜.
    let year = today.getFullYear(); // 년도
    let month = today.getMonth() + 1;  // 월
    let date = today.getDate();     // 날짜
    let srch_date = year + "-" + month + "-" + date + " 00:00:00";

    return srch_date.toString();
};

/* 전화번호 치환(010-1234-5678 > 01012345678) */
export const _setPhone = (_date: any) => {

    let phone = '';

    phone = _date.replace(/-/gi, "")

    return phone;
};

/* 전화번호 치환(01012345678 > 010-1234-5678) */
export const _getPhone = (_val: any) => {

    let regx = new RegExp(/(-?\d+)(\d{4})/);
    let bExists = _val.indexOf(".", 0);
    let strArr = _val.split('.');

    while (regx.test(strArr[0])) {   //문자열에 정규식 특수문자가 포함되어 있는지 체크
        //정수 부분에만 콤마 달기 
        strArr[0] = strArr[0].replace(regx, "$1-$2"); //콤마추가하기
    }
    if (bExists > -1) {  //. 소수점 문자열이 발견되지 않을 경우 -1 반환
        _val = strArr[0] + "." + strArr[1];
    } else {             //정수만 있을경우 //소수점 문자열 존재하면 양수 반환 
        _val = strArr[0];

    }
    return _val; //문자열 반환
    //
};

/* 수선품 카테고리 유형별 데이터 조회 */
export const _getProdCate = (_data: any, _type: string) => {

    let rtn = '';  // 카테고리 코드 반환

    if (!_isEmpty(_data)) {
        let payload = _data.filter((v: any) => v.prod_cate_typ === _type);
        if (!_isEmpty(payload)) {
            rtn = payload[0].prod_cate_cd;
        }
    }

    return rtn;
};

/* 수선품 카테고리 유형별 데이터 변환 */
export const _setProdCate = (_data: any, _odata: any) => {

    let rtn: any = [];

    if (!_isEmpty(_data)) {

        if (_isEmpty(_odata)) {   // 신규
            for (let i = 1; i < 15; i++) {
                let json: any = {};
                let no = (i > 9) ? i.toString() : '0' + i;
                json['prod_cate_no'] = '';
                json['prod_no'] = _data.prod_no;
                json['prod_cate_typ'] = no;
                json['prod_cate_cd'] = _data['cate_' + no];
                json['prod_cate_ord'] = i.toString();
                rtn.push(json);
            }
        } else {                 // 수정
            _odata.forEach((v: any) => {
                for (let i = 1; i < 15; i++) {
                    let no = (i > 9) ? i.toString() : '0' + i;
                    if (Number(v.prod_cate_typ) === i) {
                        v.prod_cate_cd = _data['cate_' + no];
                        rtn.push(v);
                    }
                }
            });
        }
    }

    return rtn;
};

/* Data-grid 목록에서 JSON 데이터 특정 요소 삭제(id 이용) */
export const _setJsonFilter = (_data: any, _id: string) => {

    if (!_isEmpty(_data)) {
        let payload = _data.filter((v: any) => v.id === _id);
        let index;
        if (!_isEmpty(payload)) {
            index = _data.indexOf(payload[0]);
        }
        _data.splice(index, 1);
    }
    return _data;
};

/* 이미지 데이터 파싱 */
export const _getImageParse = (_datas: any) => {

    let rtn: Element[] = [];   // return
    // 데이터 정제
    _datas.forEach((v: any, i: number) => {
        let json: any = {};
        json['id'] = (i + 1).toString();
        json['image_id'] = v.id;
        json['image'] = v.resizedImageUrl;
        json['brand'] = v.brand;
        json['customerName'] = v.customerName;
        json['category'] = v.item.name;
        json['otherTag'] = v.otherTag;
        if (!(_isEmpty(v.alteration))) {
            json['repair1'] = v.alteration[0].name;
            json['repair2'] = v.alteration[0].division1;
            json['repair3'] = v.alteration[0].division2;
        }
        json['isAltered'] = v.isAltered;
        json['altPosition'] = v.altPosition;

        // console.log(v.imgPosition)

        if (v.imgPosition == '본사' || v.imgPosition == '지점' || v.imgPosition == '완료' || v.imgPosition == '취소'
            || v.imgPosition == '출고대기' || v.imgPosition == '출고완료' || v.imgPosition == '소재' || v.imgPosition == '소재2'
            || v.imgPosition == '소재3' || v.imgPosition == '소재(중분류)' || v.imgPosition == '소재2(중분류)') {
            json['imgPosition'] = '-'
        } else {
            json['imgPosition'] = v.imgPosition
        }
        // json['imgPosition'] = v.imgPosition

        json['dtime'] = v.timestamp;
        json['url'] = '복사';
        rtn.push(json);
    });
    return rtn;
}

/* 챗봇 이미지 아이디 검색 */
export const _getBotImgID = (_datas: any, _key: number) => {
    let rtn = '';   // return
    if (_datas.length > Number(_key)) {
        rtn = _datas[_key].bot_galy_no;
    }
    return rtn;
}

/* 챗봇 이미지 URL 검색 */
export const _getBotImgURL = (_datas: any, _key: number) => {
    let rtn = '';   // return
    if (_datas.length > _key) {
        rtn = _datas[_key].bot_galy_url;
    }
    return rtn;
}

/* 이미지 아이디 검색 */
export const _getImgID = (_datas: any, _key: string) => {
    let rtn = '';   // return
    if (!_isEmpty(_datas)) {
        let v = _datas.filter((v: any) => v.galy_cate === _key);
        if (v.length > 0) {
            rtn = v[0].galy_id;
        }
    }
    return rtn;
}

/* 이미지 URL 검색 */
export const _getImgURL = (_datas: any, _key: string) => {
    let rtn = '';   // return
    if (!_isEmpty(_datas)) {
        let v = _datas.filter((v: any) => v.galy_cate === _key);
        if (v.length > 0) {
            rtn = v[0].galy_url;
        }
    }
    return rtn;
}

/* 갤러리 뷰 이미지 데이터 포멧 변경() */
export const _setImgFormat = (_datas: any) => {

    let rtn: any = {};   // return

    // 데이터 정제
    _datas.forEach((v: any, i: number) => {
        // console.log(v)
        rtn['image_' + v.galy_cate.toString()] = v.galy_id;
        rtn['image_' + v.galy_cate.toString() + '_url'] = v.galy_url;
        rtn['imgPosition_' + v.galy_cate.toString()] = v.imgPosition;
    });

    return rtn;
}

/* 챗봇 뷰 이미지 데이터 포멧 변경() */
export const _setGallyImgFormat = (_datas: any) => {

    let rtn: any = {};   // return

    // 데이터 정제
    _datas.forEach((v: any, i: number) => {
        rtn['image_0' + (i + 1).toString() + '_no'] = v.bot_galy_no;
        rtn['image_0' + (i + 1).toString()] = v.bot_galy_id;
        rtn['image_0' + (i + 1).toString() + '_url'] = v.bot_galy_url;
    });

    return rtn;
}

/* 미수금 계산 */
export const _getPayDiff = (_tPay: any, _fPay: any) => {

    let tPay = 0;   // 수선비
    let fPay = 0;   // 납부액

    if (!_isEmpty(_tPay)) {
        tPay = Number(_setRemoveComma(_tPay));
    }
    if (!_isEmpty(_fPay)) {
        fPay = Number(_setRemoveComma(_fPay));
    }

    return _setAddComma((tPay - fPay).toString());
}

/* 이미지 JSON Setting */
export const _setImgJson = (_v: any, _p: any, _c: any, _r: any, _t: any) => {

    let rtn: any = {};   // return

    // 1. project 정보 구성
    let customerName = '';
    if (!_isEmpty(_p.rcpt_name)) {
        customerName = _p.rcpt_name;
    }
    rtn['customerName'] = customerName;

    // 2. project 카테고리 구성
    // - 브랜드
    let brand = '';
    if (!_isEmpty(_c)) {
        brand = _getProdCate(_c, '01');   // 카테고리코드
        brand = _getCategoryName(brand);  // 카테고리명변환
    }
    rtn['brand'] = brand;

    // - 품목
    let itemjson: any = {};
    let item_name = '';
    if (!_isEmpty(_c)) {
        item_name = _getProdCate(_c, '02');      // 카테고리코드
        item_name = _getCategoryName(item_name);  // 카테고리명변환
    }
    itemjson['name'] = item_name;
    // - 품목(중분류)
    let item_division1 = '';
    if (!_isEmpty(_c)) {
        item_division1 = _getProdCate(_c, '03');      // 카테고리코드
        item_division1 = _getCategoryName(item_division1);  // 카테고리명변환
    }
    itemjson['division1'] = item_division1;
    // - 품목(소분류)
    let item_division2 = '';
    if (!_isEmpty(_c)) {
        item_division2 = _getProdCate(_c, '04');      // 카테고리코드
        item_division2 = _getCategoryName(item_division2);  // 카테고리명변환
    }
    itemjson['division2'] = item_division2;
    rtn['item'] = itemjson;

    // - 스타일
    let style: any = [];
    if (!_isEmpty(_c)) {
        let name = _getProdCate(_c, '05');      // 카테고리코드
        name = _getCategoryName(name);  // 카테고리명변환
        style.push(name);
    }
    // - 스타일2
    if (!_isEmpty(_c)) {
        let name = _getProdCate(_c, '06');      // 카테고리코드
        name = _getCategoryName(name);  // 카테고리명변환
        style.push(name);
    }
    rtn['style'] = style;

    // - 소재
    let fabric: any = [];
    let fabricjson: any = {};
    let fabric_name = '';
    if (!_isEmpty(_c)) {
        fabric_name = _getProdCate(_c, '07');      // 카테고리코드
        fabric_name = _getCategoryName(fabric_name);  // 카테고리명변환
    }
    fabricjson['name'] = fabric_name;
    // - 소재(중분류)
    let fabric_division1 = '';
    if (!_isEmpty(_c)) {
        fabric_division1 = _getProdCate(_c, '08');      // 카테고리코드
        fabric_division1 = _getCategoryName(fabric_division1);  // 카테고리명변환
    }
    fabricjson['division1'] = fabric_division1;

    fabric.push(fabricjson);

    // - 소재2
    fabricjson = {};
    fabric_name = '';
    if (!_isEmpty(_c)) {
        fabric_name = _getProdCate(_c, '09');      // 카테고리코드
        fabric_name = _getCategoryName(fabric_name);  // 카테고리명변환
    }
    fabricjson['name'] = fabric_name;
    // - 소재2(중분류)
    fabric_division1 = '';
    if (!_isEmpty(_c)) {
        fabric_division1 = _getProdCate(_c, '10');      // 카테고리코드
        fabric_division1 = _getCategoryName(fabric_division1);  // 카테고리명변환
    }
    fabricjson['division1'] = fabric_division1;

    fabric.push(fabricjson);

    // - 소재3
    fabricjson = {};
    fabric_name = '';
    if (!_isEmpty(_c)) {
        fabric_name = _getProdCate(_c, '11');      // 카테고리코드
        fabric_name = _getCategoryName(fabric_name);  // 카테고리명변환
    }
    fabricjson['name'] = fabric_name;
    // - 소재3(중분류)
    fabric_division1 = '';
    if (!_isEmpty(_c)) {
        fabric_division1 = _getProdCate(_c, '12');      // 카테고리코드
        fabric_division1 = _getCategoryName(fabric_division1);  // 카테고리명변환
    }
    fabricjson['division1'] = fabric_division1;

    fabric.push(fabricjson);

    rtn['fabric'] = fabric;

    // - 색상
    let color: any = [];
    if (!_isEmpty(_c)) {
        let name = _getProdCate(_c, '13');      // 카테고리코드
        name = _getCategoryName(name);  // 카테고리명변환
        color.push(name);
    }
    // - 색상2
    if (!_isEmpty(_c)) {
        let name = _getProdCate(_c, '14');      // 카테고리코드
        name = _getCategoryName(name);  // 카테고리명변환
        color.push(name);
    }
    rtn['color'] = color;

    // 3. repair 수선 위치
    let altPosition = '';
    if (!_isEmpty(_r)) {
        altPosition = _getCategoryName(_r.repr_loc);  // 카테고리명변환
    }
    rtn['altPosition'] = altPosition;

    // 4. repair 등록 유형
    let isAltered = '';
    if (!_isEmpty(_v)) {
        isAltered = _getCodeName(_v.galy_typ, 'G009');  // 코드명변환
    }
    rtn['isAltered'] = isAltered;

    // 5. gallery 태그
    let otherTag: any = [];
    if (!_isEmpty(_t)) {
        _t.forEach((v: any, i: number) => {
            otherTag.push(v.label);
        });
    }
    rtn['otherTag'] = otherTag;

    // 6. 작업지시서
    // - 수선(대분류)
    let alteration: any = [];
    let alterationjson: any = {};
    let alteration_name = '';
    if (!_isEmpty(_r)) {
        alteration_name = _getCategoryName(_r.cate_02);  // 카테고리명변환
    }
    alterationjson['name'] = alteration_name;
    // - 수선(중분류)
    let alteration_division1 = '';
    if (!_isEmpty(_r)) {
        alteration_division1 = _getCategoryName(_r.cate_03);  // 카테고리명변환
    }
    alterationjson['division1'] = alteration_division1;
    // - 수선(소분류)
    let alteration_division2 = '';
    if (!_isEmpty(_r)) {
        alteration_division2 = _getCategoryName(_r.cate_04);  // 카테고리명변환
    }
    alterationjson['division2'] = alteration_division2;
    // - 기타요구사항
    let alteration_requirement = '';
    if (!_isEmpty(_r)) {
        alteration_requirement = _getCategoryName(_r.req_cont);  // 카테고리명변환
    }
    alterationjson['requirement'] = alteration_requirement;

    alterationjson['etc'] = alteration_requirement;
    alteration.push(alterationjson);

    rtn['alteration'] = alteration;

    rtn['isSearchable'] = true;      // 검색여부

    rtn['timestamp'] = _setNowUTCDate();

    return rtn;
}

/* Base64 > FormData 변환 */
export const _setDataURIToBlob = (_base64: string) => {

    const splitDataURI = _base64.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}

/* 수선비 합계 */
export const _setSumTotal = (_datas: any) => {
    let rtn: any = 0;
    if (!_isEmpty(_datas)) {
        _datas.forEach((v: any) => {
            rtn += Number(v.tot_pay);  // 수선비
        });
    }
    return _setAddComma(rtn.toString());
}

/* 결재액 합계 */
export const _setSumFrst = (_datas: any) => {
    let rtn: any = 0;
    if (!_isEmpty(_datas)) {
        _datas.forEach((v: any) => {
            rtn += Number(v.frst_pay);  // 수선비
        });
    }
    return _setAddComma(rtn.toString());
}

/* 미수금 합계 */
export const _setSumLast = (_datas: any) => {
    let rtn: any = 0;
    if (!_isEmpty(_datas)) {
        _datas.forEach((v: any) => {
            rtn += (Number(v.tot_pay) - Number(v.frst_pay));  // 수선비
        });
    }
    return _setAddComma(rtn.toString());
}

/* 카테고리 데이터 업데이트 */
export const _setCategoryData = (_state: any, _action: any) => {

    let rtn: any = 0;

    rtn = { ..._state }

    let _n = _state.cateNode;

    // 카테고리 단계별 적재
    // if (!_isEmpty(_action.payload)) {
    if (_n === 0) {
        rtn['cData1'] = _action.payload;
    } else if (_n === 1) {
        rtn['cData2'] = _action.payload;
    } else if (_n === 2) {
        rtn['cData3'] = _action.payload;
    } else if (_n === 3) {
        rtn['cData4'] = _action.payload;
    }
    // } 
    return rtn;
}

/* 권한 체크 */
export const _accessCheck = (_link: any) => {
    let rtn: any = false;
    let user_grd = window.sessionStorage.getItem('user_grd');
    if (user_grd === '01') {
        rtn = true;
    } else if (user_grd === '02' || user_grd === '09') {
        if (_link.indexOf('/main') > -1) {
            rtn = true;
        } else if (_link.indexOf('/consult') > -1) {
            rtn = true;
        } else if (_link.indexOf('/receipt') > -1) {
            rtn = true;
        } else if (_link.indexOf('/gallery') > -1) {
            rtn = true;
        } else if (_link.indexOf('/product') > -1) {
            rtn = true;
        } else if (_link.indexOf('/repair') > -1) {
            rtn = true;
        } else if (_link.indexOf('/delivery') > -1) {
            rtn = true;
        }
    }
    return rtn;
}

/* 수선품 카테고리 데이터 포멧 변경() */
export const _setProdCateFormat = (_datas: any) => {

    let rtn: any = {};   // return
    // 데이터 정제
    _datas.forEach((v: any, i: number) => {
        rtn['cate_' + v.prod_cate_typ.toString()] = v.prod_cate_cd;
    });
    return rtn;
}

/* 데이터 ID/잔액 추가 */
export const _dataAddIDAndPay = (_datas: any) => {

    let rtn: Element[] = [];   // return

    // 21.08.05 브랜드 한글명 추가(브랜드명으로 정렬 위함)
    let _data = _getSession('CATEGOORY_DATA');
    _data = _data?.payload; // 카테고리 data
    let _codes = _getSession('CODE_DATA');
    _codes = _codes?.payload;
    let name = '';
    let cate_name = '';
    let case2_name = '';
    let case3_name = '';
    let case4_name = '';
    let payload: any = {};
    let payloadt: any = {};
    // 까지 (21.08.05)

    // 데이터 정제
    _datas.forEach((v: any, i: number) => {
        // 21.08.05 브랜드 한글명 추가(브랜드명으로 정렬 위함)
        if (!_isEmpty(v.brand_cd)) {
            payload = _data?.filter((d: any) => d.cate_cd === v.brand_cd);
            if (!_isEmpty(payload)) {
                name = payload[0].cate_name;
            }
        } else {
            name = ''
        }
        v['brand_name'] = name.toString();
        // // 까지 (21.08.05)
        // // 21.10.05 알림톡 브랜드, 수선종류 추가요청에 의한 한글명 추가
        if (!_isEmpty(v.cate_cd)) {
            payloadt = _codes?.filter((d: any) => d.grp_cd_id === "G025" && d.cd_id === v.cate_cd );
            if (!_isEmpty(payloadt)) {
                cate_name = payloadt[0].cd_name;
            }
        } else if(!_isEmpty(v.item_cd)){
            payloadt = _codes?.filter((d: any) => d.grp_cd_id === "G025" && d.cd_id === v.item_cd);
            if (!_isEmpty(payloadt)) {
                cate_name = payloadt[0].cd_name;
            }
        } else {
            cate_name = ''
            
        }
        
        // // 까지 (21.11.03)
        // // 21.11.03 유형(작업page) 정렬 추가요청에 의한 한글명 추가
        if (!_isEmpty(v.cate_02)){
            payload = _data.filter((d: any) => d.cate_cd === v.cate_02)
            if(!_isEmpty(payload)){
                case2_name = payload[0].cate_name;
            }
        }
        else{
            case2_name = ''
        }

        if (!_isEmpty(v.cate_03)) {
            payload = _data.filter((d: any) => d.cate_cd === v.cate_03)
            if (!_isEmpty(payload)) {
                case3_name = payload[0].cate_name;
            }
        }
        else {
            case3_name = ''
        }
        if (!_isEmpty(v.cate_04)) {
            payload = _data.filter((d: any) => d.cate_cd === v.cate_04)
            if (!_isEmpty(payload)) {
                case4_name = payload[0].cate_name;
            }
        }
        else {
            case4_name = ''
        }
    
        v['cate_name'] = cate_name.toString();
        v['case2_name'] = case2_name.toString();
        v['case3_name'] = case3_name.toString();
        v['case4_name'] = case4_name.toString();
        // // 까지 (21.10.05)
        v['id'] = (i + 1).toString();
        let tot_pay = _isEmpty(v.tot_pay) ? '0' : v.tot_pay;
        let frst_pay = _isEmpty(v.frst_pay) ? '0' : v.frst_pay;
        v.last_pay = (Number(tot_pay) - Number(frst_pay)).toString();
        rtn.push(v);
    });
    return rtn;
}


/* DataGrid 카카오 전송상태 변환 */
export const _setKakaoState = () => {

    return {
        valueGetter: ({ value }: any) => {
            let status = '';
            if (value === "01") {
                status = "미전송";
            }
            else {
                status = "전송"
            }
            return status;
        }
    };
}