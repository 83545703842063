/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux categories store
**/
// TYPES
import { _setCategoryData } from "./../../Common";
import {
  CATE_ADD,
  CATE_ADD_GET,
  CATE_ADD_STAT,
  CATE_DEL,
  CATE_FAIL,
  CATE_GET,
  CATE_DEL_RESET
} from "./../types";

// state 초기 데이터
const initialState = {
  cateTyp: "01",            // 카테고리 유형
  initCate: "0000000000",           // 초기 카테고리 코드
  cateNode: 0,            // 카테고리 노드
  cateInfo: [],            // 개별 카테고리 정보
  cData1: [],
  cData2: [],
  cData3: [],
  cData4: [],
  isAdd: false,            // 추가 레이어팝업 오픈 여부
  isRemove: false          // 삭제 레이어팝업 오픈 여부
};

// action type 별 상태 관리
export default (state = initialState, action: any) => {

  switch (action.type) {
    case CATE_GET:    // 카테고리 조회
      return _setCategoryData(state, action)
    case CATE_ADD:                // 카테고리 추가
      return { ...state, isAdd: false };
    case CATE_ADD_STAT:           // 카테고리 전체 상태
      return { ...state };
    case CATE_ADD_GET:            // 카테고리 개별 데이터
      return {
        ...state,
        cateInfo: action.payload  // 개별 카테고리 정보
      };
    case CATE_DEL:                // 카테고리 삭제
      return { ...state, isRemove: false };
    case CATE_DEL_RESET:          // 카테고리 삭제후 리셋
      return {
        ...state,
        initCate: '0000000000'
      };
    case CATE_FAIL:               // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}