/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Users page
**/
/* @material import */
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Paper
} from '@material-ui/core';
/* react import */
import React from 'react';
import Page from './../Page';
import UserAdd from './UserAdd';
import UsersList from './UsersList';

/* styles */
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		height: "90%",
		overflow: "hidden",
		marginTop: 0,
		paddingBottom: theme.spacing(1),
		paddingTop: theme.spacing(3),
	},
	paper: {
		width: "100%",
		height: 540,
		margin: theme.spacing(1),
		backgroundColor: theme.palette.background.paper,
	},
	toolbar: {
		minHeight: 40,
		color: "#fff",
		backgroundColor: "#1976d2",
	},
}));

/* component */
const Users = () => {
  const classes = useStyles();      // CSS  
  return (
		<Page className={classes.root} title="사용자 관리">
			<Box
				display="flex"
				flexDirection="column"
				height="100%"
				alignItems="flex-start"
			>
				<Container maxWidth="xl">
					{/* 신규사용자 등록 */}
					<Box justifyContent="center">
						<Grid container justify="center" spacing={1}>
							<Grid item xs={12}>
								<Paper className={classes.paper} elevation={4}>
									<UserAdd />
									<UsersList />
								</Paper>
							</Grid>
						</Grid>
					</Box>
					<Box mb={1} justifyContent="center">
						<Grid container justify="center" spacing={1}>
							<Grid item xs={8} />							
							<Grid item xs={4}>
								{/* <Grid container justify="flex-end">									
									<UsersEdit />
								</Grid> */}
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</Page>
	);
};

export default Users;
