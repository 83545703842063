/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Product List Page
**/
/* @material import */
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	makeStyles,
	Toolbar,
	Typography,
} from "@material-ui/core";
import {
	DataGrid
} from '@material-ui/data-grid';
import {
	Add,
	Delete,
	Print,
	Publish,
	Update
} from '@material-ui/icons';
/* react import */
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import XLSX from "xlsx";
import {
	_isEmpty,
	_setFormatCategory,
	_setFormatCode,
	_setFormatComma,
	_setFormatDate,
	_setUTCDate,
	_setProdCate,
	_getNowDate,
	_setCateCodeExist,
	_setCodeExist,
} from "../../utils/Common";
import { addPay, delPay, getPay, getPayInfo } from "./../../utils/redux/actions/PayAction";
import { delProd, getProd, getProdInfo, addProd } from "./../../utils/redux/actions/ProdAction";
import { delProdCate, getProdCate, addProdCate } from "./../../utils/redux/actions/ProdCateAction";
import { getRcpt } from "./../../utils/redux/actions/RcptAction";
import { delRepr, getRepr, getReprWork } from "./../../utils/redux/actions/ReprAction";
import ProductEdit from "./ProductEdit";
import WorkOrder from "./WorkOrder";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
	root: {},
	grid: {
		height: "100%",
		width: "98%",
		maxHeight: "78%",
		minWidth: "98%",
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
		"& .MuiDataGrid-columnsContainer": {
			backgroundColor: "#9fa8da",
			color: "#ffffff",
		},
	},
	// 데이터 그리드 테이블 해더
	search: {
		margin: theme.spacing(1),
	},
	toolbar: {
		minHeight: 40,
		color: "#fff",
		backgroundColor: "#1976d2",
	},
	iconButton: {
		padding: 10,
	},
	paper: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "100%",
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	button: {
		margin: theme.spacing(1),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	formLink: {
		textDecoration: 'none',
		color: "#fff",
		fontSize: 8
	},
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ProductList = () => {
	const classes = useStyles();     // CSS
	const dispatch = useDispatch();  // dispatch hook

	// [state] ######################################################################
	const product = useSelector((state: any) => state.product);    // product
	const receipt = useSelector((state: any) => state.receipt);    // receipt
	const common = useSelector((state: any) => state.common);      // common
	const repair = useSelector((state: any) => state.repair);      // common

	//   const prod_rcpt_no = useSelector((state:any) => state.product.info.rcpt_no);    // product
	//   const rcpt_rcpt_no = useSelector((state:any) => state.receipt.info.rcpt_no);    // receipt

	const [open, setOpen] = React.useState(false);

	// [Events] ######################################################################
	//@ 최초 실행
	const handleInit = useCallback(() => {
		product.rows = [];
		repair.rows = [];
	}, []);
	// const handleInit = useCallback(() => {
	//   product.rows = [];
	//   // 수선품 조회
	//   getProd({}).then((d) => dispatch(d));   
	// },[]);

	const handleToggle = () => {
		setOpen(!open);
	};

	//@ 목록 선택
	const handleChange = useCallback((e) => {
		dispatch({ type: 'PROD_GET_INFO', payload: e.data });
	}, [dispatch]);

	//@ 신규 등록
	const handleAdd = useCallback(() => {
		if (_isEmpty(receipt.info.rcpt_no)) {    // 접수번호가 없을 경우
			common.msg = '접수이력을 선택해 주세요.';
			common.isMsg = true;
			dispatch({ type: 'COM_ALERT' });  // 안내메시지      
		} else {
			product.isOpen = true;
			dispatch({ type: 'PROD_GET_INFO', payload: [] });
			dispatch({ type: 'PAY_GET_INFO', payload: [] });
			dispatch({ type: 'PROD_CATE_GET', payload: [] });
		}
	}, [common, receipt, product, dispatch]);

	//@ 정보 수정
	const handleUpd = useCallback(() => {

		if (_isEmpty(receipt.info.rcpt_no)) {    // 접수번호가 없을 경우
			common.msg = '접수이력을 선택해 주세요.';
			common.isMsg = true;
			return dispatch({ type: 'COM_ALERT' });  // 안내메시지      
		} else {
			if (!_isEmpty(product.info)) {
				let prod_no = product.info.prod_no;
				if (_isEmpty(prod_no)) {    // 수선품번호 없을 경우
					common.msg = '수선품을 선택해 주세요.';
					common.isMsg = true;
					return dispatch({ type: 'COM_ALERT' });  // 안내메시지  
				} else {
					product.isOpen = true;
					getProdInfo(prod_no).then((d) => dispatch(d))
						.catch((e) => console.log(e));
					let body = {
						prod_no: prod_no
					}
					// 수선품 정산 조회
					getPayInfo(body).then((res) => dispatch(res))
						.catch((e) => console.log(e));
					// 수선품 카테고리 정보 조회
					getProdCate(body).then((res) => dispatch(res))
						.catch((e) => console.log(e));
				}
			} else {
				common.msg = '수선품을 선택해 주세요.';
				common.isMsg = true;
				return dispatch({ type: 'COM_ALERT' });  // 안내메시지  
			}
		}
	}, [common, receipt, product, dispatch]);

	//@ 수선품정보삭제
	const handleDel = useCallback(() => {

		if (_isEmpty(product.info.prod_no)) {    // 수선품번호가 없을 경우
			common.msg = '수선품을 선택해 주세요.';
			common.isMsg = true;
			return dispatch({ type: 'COM_ALERT' });  // 안내메시지      
		} else {

			let body = product.info;

			// 작업지시서 삭제
			getRepr(body).then((d) => {
				let rows = d.payload;
				rows.forEach((v: any) => delRepr(v));
				return;
			}).catch((e) => console.log(e));
			// 정산 삭제
			getPay(body).then((d) => {
				let rows = d.payload;
				rows.forEach((v: any) => delPay(v));
				return;
			}).catch((e) => console.log(e));
			// 수선품 카테고리 삭제
			getProdCate(body).then((d) => {
				let rows = d.payload;
				rows.forEach((v: any) => delProdCate(v));
				return;
			}).catch((e) => console.log(e));
			// 수선품 삭제
			delProd(body).then((v) => dispatch(v))
				.then(() => {  // 수선품 목록 변경
					let body = {
						rcpt_no: product.info.rcpt_no
					}
					dispatch({ type: 'PROD_GET', payload: [] });
					return getProd(body).then((d) => dispatch(d));
				})
				.then(() => {  // 접수 목록 변경
					dispatch({ type: 'RCPT_GET', payload: [] });
					return getRcpt(receipt.srch).then((d) => dispatch(d));
				})
				.catch((e) => console.log(e));
		}

	}, [product, common, dispatch]);

	//@ 작업지시서 출력
	const handleClickOpen = useCallback(() => {
		if (_isEmpty(receipt.info)) {    // 접수 이력 선택되지 않은 경우
			common.msg = '접수이력을 선택해 주세요.';
			common.isMsg = true;
			return dispatch({ type: 'COM_ALERT' });  // 안내메시지
		} else {
			if (_isEmpty(product.info)) {   // 수선품 이력 선택되지 않은 경우
				common.msg = '수선품을 선택해 주세요.';
				common.isMsg = true;
				return dispatch({ type: 'COM_ALERT' });  // 안내메시지
			} else {
				product.isView = true;

				dispatch({ type: 'PROD_GET_INFO', payload: product.info });

				// 수선품 카테고리 정보 조회
				let body = {
					prod_no: product.info.prod_no
				}
				getProdCate(body).then((res) => dispatch(res))
					.catch((e) => console.log(e));

				// 수선 목록 조회
				getReprWork(body).then((res) => dispatch(res))
					.catch((e) => console.log(e));
			}
		}
	}, [receipt, common, product, dispatch]);

	const productExcelUpload = useCallback(async (e) => {
		let valid_flag = true;                    // 엑셀 검사 Flag
		let _f = e.target.files[0]; // 파일
		let reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		console.log("receipt.info.rcpt_no");
		console.log(receipt.info.rcpt_no);
		console.log("receipt.info.cust_no");
		console.log(receipt.info.cust_no);
		console.log("receipt.info.reg_user");
		console.log(receipt.info.reg_user);

		reader.onload = (event: any) => {
			handleToggle();
			let count = 0;
			let err_product: any = [];
			// @엑셀 읽기
			let bstr = event.target.result;
			let wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
			let wsname = wb.SheetNames[0];
			let ws = wb.Sheets[wsname];
			let excel_array = XLSX.utils.sheet_to_json(ws);
			console.log(excel_array);
			excel_array.forEach((elements: any, idx: number) => {
				if (idx >= 0) {
					if (Object.keys(elements).length === 13) {
						if (_isEmpty(receipt.info.rcpt_no)) {
							setOpen(false);
							common.msg = "고객을 선택해주세요.";
							common.isMsg = true;
							return dispatch({ type: "COM_ALERT" }); // 안내메시지
						} else {
							let product_json = {
								// row_count: elements["No"],
								// 고객정보
								prod_no: "",
								rcpt_no: receipt.info.rcpt_no,
								org_no: "01",
								cust_no: receipt.info.cust_no,
								rcpt_name: receipt.info.cust_name,
								// 수선품
								rcpt_chnl: _isEmpty(elements["접수경로"]) || !_setCodeExist(common.code, 'G007', elements["접수경로"]) ? "01" : elements["접수경로"].toString(),
								repr_st_dt: _isEmpty(elements["입고일"]) ? _getNowDate() : _setUTCDate(elements["입고일"].toString()),
								repr_req_dt: _isEmpty(elements["출고요청일"]) ? _getNowDate() : _setUTCDate(elements["출고요청일"].toString()),
								send_typ: _isEmpty(elements["발송방식"]) || !_setCodeExist(common.code, 'G007', elements["발송방식"]) ? "01" : elements["발송방식"].toString(),
								is_dust_bag: elements["더스트백여부"] === "Y" ? true : false,
								req_cont: _isEmpty(elements["고객 요청사항"]) ? "" : elements["고객 요청사항"].toString(),
								brand_cd: _isEmpty(elements["브랜드"]) || !_setCateCodeExist(common.category, '0100000000', elements["브랜드"]) ? (valid_flag = false) : elements["브랜드"].toString(),
								cate_cd: _isEmpty(elements["수선종류"]) || !_setCodeExist(common.code, 'G025', elements["수선종류"]) ? (valid_flag = false) : elements["수선종류"].toString(),
								// 정산
								calc_typ: _isEmpty(elements["납입방식"]) || !_setCodeExist(common.code, 'G011', elements["납입방식"]) ? (valid_flag = false) : elements["납입방식"].toString(),
								tot_pay: _isEmpty(elements["수선금"]) ? "0" : elements["수선금"].toString(),
								frst_pay: _isEmpty(elements["선입금"]) ? "0" : elements["선입금"].toString(),
								last_pay: "0",
								prod_stat: "01",
								calc_stat: "01",
								calc_acc: "01",
								frst_pay_dtime: _getNowDate(),
								last_pay_dtime: _getNowDate(),
								calc_no: "",
								// category
								cate_01: _isEmpty(elements["브랜드"]) || !_setCateCodeExist(common.category, '0100000000', elements["브랜드"]) ? (valid_flag = false) : elements["브랜드"].toString(), // 브랜드 (0100000000)
								cate_02: _isEmpty(elements["품목"]) || !_setCateCodeExist(common.category, '0200000000', elements["품목"]) ? "" : elements["품목"].toString(),		// 0200000000 품목
								cate_03: "",
								cate_04: "",
								cate_05: "",
								cate_06: "",
								cate_07: "",
								cate_08: "",
								cate_09: "",
								cate_10: "",
								cate_11: "",
								cate_12: "",
								cate_13: "",
								cate_14: "",
							};
							console.log("product_json");
							console.log(product_json);
							if (valid_flag) {
								// 신규등록
								addProd(product_json)
									.then((res: any) => {
										product_json.prod_no = res.payload.prod_no;
										console.log(product_json);
										// 정산 등록
										addPay(product_json).catch((e) => console.log(e));
										let rows = _setProdCate(product_json, "");
										console.log(rows);
										count = count + 1;
										console.log(count);
										rows.forEach((v: any, index_cont: number) =>
											addProdCate(v)
												.then(() => {
													if (index_cont === 13 && idx + 1 === excel_array.length) {
														// getRcpt(receipt.search).then((d) => dispatch(d));
														// 수선품
														let body = {
															rcpt_no: receipt.info.rcpt_no,
														};
														// dispatch({ type: "PROD_GET", payload: [] });
														getProd(body).then((d) => dispatch(d))
															.catch((e) => console.log(e));
														// 수선 접수 목록 조회
														getRcpt(receipt.search)
															.then((d) => dispatch(d))
															.catch((e) => console.log(e));

														setOpen(false);
														common.msg = " 등록이 완료되었습니다.";
														common.isMsg = true;
														return dispatch({ type: "COM_ALERT" }); // 안내메시지
													}
												})
												.catch((e) => {
													setOpen(false);
													err_product.push(product_json.cust_no);
													console.log(e);
												})
										);
									}).catch((e) => console.log(e));
							} else {
								setOpen(false);
								let body = {
									rcpt_no: receipt.info.rcpt_no,
								};
								getProd(body)
									.then((d) => dispatch(d))
									.catch((e) => console.log(e));
								getRcpt(receipt.search)
									.then((d) => dispatch(d))
									.catch((e) => console.log(e));
								common.msg = "엑셀 데이터를 확인해주세요.";
								common.isMsg = true;
								return dispatch({ type: "COM_ALERT" }); // 안내메시지
							}
						}
					} else {
						setOpen(false);
						getRcpt(receipt.search)
							.then((d) => dispatch(d))
							.catch((e) => console.log(e));
						common.msg = (idx + 1).toString() + " 행을 확인해주세요.";
						common.isMsg = true;
						return dispatch({ type: "COM_ALERT" }); // 안내메시지
					}
				} else {
					setOpen(false);
					getRcpt(receipt.search)
						.then((d) => dispatch(d))
						.catch((e) => console.log(e));
					common.msg = "엑셀 데이터를 확인해주세요.";
					common.isMsg = true;
					return dispatch({ type: "COM_ALERT" }); // 안내메시지
				}
			});
		};
		reader.readAsBinaryString(_f);
	}, [product, receipt, common, dispatch]);


	// [init] ######################################################################
	// 콤마 타입 설정
	const _setComma = _setFormatComma();
	// 데이트 타입 설정
	const _setDate = _setFormatDate();
	// 카테고리명 조회
	const _setCateName = _setFormatCategory(common.category);
	// 접수상태명 조회
	const _setStateName = _setFormatCode(common.code, 'G008');
	// 수선종류 조회
	const _setItemName = _setFormatCode(common.code, 'G025');
	// 발송방식 조회
	const _setCodeName = _setFormatCode(common.code, 'G007');

	//@ 초기 데이터 (Table 컬럼)
	const columns = [
		{ field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
		{ field: 'prod_no', headerAlign: 'center', headerName: '수선품번호', width: 130 },
		{ field: 'brand_cd', headerAlign: 'center', headerName: '브랜드', width: 180, ..._setCateName, hide: true },
		{ field: 'brand_name', headerAlign: 'center', headerName: '브랜드', width: 180 },
		// { field: 'cate_cd', headerAlign: 'center', headerName: '품목', ..._setCateName },
		{ field: 'cate_cd', headerAlign: 'center', headerName: '수선종류', width: 120, ..._setItemName, },
		{ field: 'repr_st_dt', headerAlign: 'center', headerName: '입고일', width: 110, ..._setDate },
		{ field: 'repr_req_dt', headerAlign: 'center', headerName: '출고요청일', width: 110, ..._setDate },
		{ field: "send_typ", headerAlign: "center", headerName: "수령방식", width: 110, ..._setCodeName, },	
		{ field: 'req_cont', headerAlign: 'center', headerName: '내역', width: 380 },
		{ field: 'tot_pay', headerAlign: 'center', headerName: '수선비', width: 120, ..._setComma },
		{ field: 'frst_pay', headerAlign: 'center', headerName: '선금', hide: true, ..._setComma },
		{ field: 'prod_stat', headerAlign: 'center', headerName: '상태', width: 100, ..._setStateName },
		{ field: 'reg_user', headerAlign: 'center', headerName: '작성자', width: 80 },
		{ field: 'last_mod_user', headerAlign: 'center', headerName: '수정자', width: 80 }
	];

	//@ 최초 실행
	useEffect(() => {
		//1. 상담 조회
		handleInit();
	}, [handleInit]);

	// [Grid] ######################################################################    
	let datagrid;

	if (_isEmpty(product.rows)) {
		datagrid = <Typography
			variant="h6"
			align="center"
		>
			접수이력을 선택하고 수선품을 등록 해주세요.
		</Typography>;
	} else {
		datagrid = <DataGrid
			headerHeight={40}
			rowHeight={30}
			columns={columns}
			rows={product.rows}
			pageSize={5}
			onRowClick={handleChange}
		/>;
	}
	// [Render] ######################################################################  
	return (
		<React.Fragment>
			<Toolbar className={classes.toolbar}>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={2}>
						<Typography variant="h6">수선품</Typography>
					</Grid>
					<Grid item xs={5}>
						<a href='https://kr.object.iwinv.kr/exceluploadfrom/%EC%88%98%EC%84%A0%ED%92%88%20%EB%93%B1%EB%A1%9D_20210204.xlsx' download className={classes.formLink}>
							엑셀양식
						</a>
					</Grid>
					<Grid item xs={5}>
						<Grid container justify="center" alignItems="center">
							{/* <Grid item xs={2}></Grid> */}
							<Grid item xs={12}>
								{/* 신규 */}
								<IconButton
									className={classes.iconButton}
									disabled={receipt.info.rcpt_stat === "02" || _isEmpty(receipt.info.rcpt_no)}
									aria-label="신규"
									color="inherit"
									onClick={handleAdd}
								>
									<Add />
									<Typography variant="h6">신규</Typography>
								</IconButton>
								{/* 수정 */}
								<IconButton
									className={classes.iconButton}
									disabled={_isEmpty(receipt.info.rcpt_no)}
									aria-label="수정"
									color="inherit"
									onClick={handleUpd}
								>
									<Update />
									<Typography variant="h6">수정</Typography>
								</IconButton>
								{/* 삭제 */}
								<IconButton
									className={classes.iconButton}
									disabled={_isEmpty(receipt.info.rcpt_no)}
									aria-label="삭제"
									color="inherit"
									onClick={handleDel}
								>
									<Delete />
									<Typography variant="h6">삭제</Typography>
								</IconButton>
								{/* 엑셀업로드 */}
								<label htmlFor="raised-product_button-file">
									<Button
										component="span"
										className={classes.iconButton}
										color="inherit"
										disabled={receipt.info.rcpt_stat === "02" || _isEmpty(receipt.info.rcpt_no)}
									// onClick={handleToggleProduct}
									>
										<Publish />
										<Typography variant="h6">엑셀업로드</Typography>
									</Button>
									<Backdrop className={classes.backdrop} open={open}>
										<CircularProgress color="inherit" />
									</Backdrop>
								</label>
								<input
									style={{ display: "none" }}
									id="raised-product_button-file"
									name="raised-product_button-file"
									placeholder={"파일을 첨부해주세요"}
									type="file"
									required={false}
									onChange={(e: any) => productExcelUpload(e)}
								/>
								{/* <IconButton
									className={classes.iconButton}
									aria-label="엑셀업로드"
									color="inherit"
								>
									<Publish />
									<Typography variant="h6">엑셀업로드</Typography>
								</IconButton> */}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
			<div className={classes.grid}>{datagrid}</div>
			{/* 버튼영역 */}
			<Box ml={1} mr={1} mb={1} display="flex" justifyContent="flex-end">
				{/* 작업지시서 */}
				<Button
					variant="contained"
					color="primary"
					size="small"
					className={classes.button}
					// disabled={_isEmpty(product.rows) || _isEmpty(receipt.rows) || _isEmpty(product.info) || !(prod_rcpt_no === rcpt_rcpt_no)}
					startIcon={<Print />}
					onClick={handleClickOpen}
				>
					작업지시서
				</Button>
			</Box>
			<ProductEdit />
			{/* 작업지시서 */}
			<WorkOrder />
		</React.Fragment>
	);
};

export default ProductList;
