/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux recipt store
**/
// TYPES
import {
  RCPT_ADD,
  RCPT_FAIL,
  RCPT_GET,
  RCPT_GET_INFO,
  RCPT_SEND,
  RCPT_UPD,
  RCPT_UPD_STAT
} from "../types";
import { _dataAddID, _isEmpty, _dataAddIDAndPay } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows: [],
  info: [],
  srch: {},   // 검색
  search: {}, // 고객검색 
  isEdit: false,
  isOpen: false
};
 
// action type 별 상태 관리
export default (state = initialState, action: any) => {
  switch (action.type) {
    case RCPT_GET:         // 접수 전체 조회
      return {
        ...state,
        rows: _dataAddIDAndPay(action.payload)
      };
    case RCPT_GET_INFO:    // 접수 개별 조회    
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload.rcpt_no) ? false : true
      };
    case RCPT_ADD:         // 접수 저장
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload.rcpt_no) ? false : true
      };
    case RCPT_UPD:         // 접수 수정
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload.rcpt_no) ? false : true
      };
    case RCPT_SEND:         // 카카오 알림톡 전송
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload.rcpt_no) ? false : true
      };
    case RCPT_UPD_STAT:         // 카카오 알림톡 전송
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload.rcpt_no) ? false : true
      };
    case RCPT_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}