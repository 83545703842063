/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux payments Action
**/
import { _ajaxPostJsonAsync } from "../../axios/axios";
import {
  REPR_ADD,
  REPR_DEL,
  REPR_FAIL,
  REPR_GET,
  // REPR_GET_SRCH,
  REPR_GET_INFO,
  REPR_UPD
} from "../types";

/* 수선 경로 정보 */
const API_PATH = "/api/v1/repairs";

/* 수선 전체 조회 */
export const getRepr = async (body: any) => {
  // IN Data 구성
  // body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH + "/search/", body, REPR_GET, REPR_FAIL);
}
/* 작업 수정페이지 수선 전체 조회 */
// export const getReprSrch = async (body: any) => {
//   // IN Data 구성
//   body['limit'] = '1';
//   return await _ajaxPostJsonAsync("post", API_PATH + "/search/", body, REPR_GET_SRCH, REPR_FAIL);
// }
/* 작업지시서 수선조회*/
export const getReprWork = async (body: any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH + "/searchWork/", body, REPR_GET, REPR_FAIL);
}

/* 수선 개별 조회 */
export const getReprInfo = async (body: any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH + "/search/", body, REPR_GET_INFO, REPR_FAIL);
}

/* 수선 등록 */
export const addRepr = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['reg_user'] = user_name;       // 최초작성자
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("post", API_PATH + "/", d, REPR_ADD, REPR_FAIL);
}

/* 수선 수정 */
export const updRepr = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH + "/" + d.repr_no, d, REPR_UPD, REPR_FAIL);
}

/* 수선 삭제 */
export const delRepr = async (d: any) => {
  return await _ajaxPostJsonAsync("delete", API_PATH + "/" + d.repr_no, d, REPR_DEL, REPR_FAIL);
}