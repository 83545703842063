/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux repairs store
**/
// TYPES
import {
  REPR_ADD,
  REPR_DEL,
  REPR_FAIL,
  REPR_GET,
  REPR_GET_INFO,
  // REPR_GET_SRCH,
  REPR_GET_TEMP,
  REPR_UPD
} from "../types";
import { _dataAddIDAndPay, _isEmpty } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows: [],
  info: [],
  temp: [],
  remove: [],
  srch: {},  // 검색
  isEdit: false
};

// action type 별 상태 관리
export default (state = initialState, action: any) => {
  switch (action.type) {
    case REPR_GET:         // 수선 전체 조회     
      return {
        ...state,
        rows: _dataAddIDAndPay(action.payload),
        excel: _dataAddIDAndPay(action.payload)
      };
    case REPR_GET_INFO:    // 수선 개별 조회    
      return {
        ...state,
        info: action.payload[0],
        isEdit: _isEmpty(action.payload) ? false : true
      };
    // case REPR_GET_SRCH:    // 수선 개별 조회    
    //   return {
    //     ...state,
    //     srch: action.payload[0],
    //     isEdit: _isEmpty(action.payload) ? false : true
    //   };
    case REPR_GET_TEMP:    // 수선 임시 데이터
      return {
        ...state,
        temp: action.payload
      };
    case REPR_ADD:         // 수선 저장
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
      };
    case REPR_UPD:         // 수선 수정
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
      };
    case REPR_DEL:         // 수선 삭제
      return {
        ...state,
        remove: []
      };
    case REPR_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}