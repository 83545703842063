/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Users page
**/
/* @material import */
import {
    Box,
    Button,
    makeStyles,
    Typography
} from '@material-ui/core';
import {
    DataGrid
} from '@material-ui/data-grid';
import { DoubleArrow } from '@material-ui/icons';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getDashboardProd } from "../../utils/redux/actions/ProdAction";
import { getRcpt } from "../../utils/redux/actions/RcptAction";
import { _getNowDate, _isEmpty, _setFormatCategory, _setFormatCode, _setFormatComma, _setFormatDate, _setFormatPhone } from "./../../utils/Common";

/* ######################################################################
 * style
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
    root: {
    },
    grid: {
        height: '100%',
        width: '98%',
        maxHeight: '78%',
        minWidth: '98%',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#9fa8da',
            color: '#ffffff'
        },
    },
    toolbar: {
        minHeight: 40,
        color: '#fff',
        backgroundColor: '#1976d2'
    },
    iconButton: {
        padding: 10,
    },
    button: {
        margin: theme.spacing(1),
    },
}));

/* ######################################################################
 * component
 * ###################################################################### */
const MainCalendarList = () => {
    const classes = useStyles();      // CSS
    const dispatch = useDispatch();   // dispatch hook   
    const navigate = useNavigate();   // link

    // [state] ######################################################################
    const product = useSelector((state: any) => state.product);    // product
    const common = useSelector((state: any) => state.common);      // common
    // [Events] ######################################################################
    // 수선품 정보 선택
    const handleChange = useCallback((e) => {
        console.log(e);
        dispatch({ type: 'PROD_GET_INFO', payload: e.data });
    }, [dispatch]);

    // 수선품 접수 페이지 이동
    const handleMove = useCallback(() => {
        if (_isEmpty(product.info)) {
            common.msg = '수선품을 선택해주세요.';
            common.isMsg = true;
            dispatch({ type: 'COM_ALERT' });       // 안내메시지
            return;
        }
        common.search = {
            rcpt_no: product.info.rcpt_no
        };
        getRcpt(common.search).then((d) => dispatch(d))
            .catch((e) => console.log(e))
            .then(() => {
                dispatch({ type: 'PROD_GET', payload: [] });
                navigate('/receipt', { replace: true });
                // 접수 이동시 탭 위치 스타일 변경
                dispatch({ type: 'COM_TABS_LOCATION', payload: 2 });
            })
            .catch((e) => console.log(e));
    }, [navigate, common, product, dispatch]);

    // [init] ######################################################################
    // 콤마 타입 설정
    const _setComma = _setFormatComma();
    // 데이트 타입 설정
    const _setDate = _setFormatDate();
    // 카테고리명 조회
    const _setCateName = _setFormatCategory(common.category);
    // 접수상태명 조회
    const _setStateName = _setFormatCode(common.code, 'G008');
    // 수선종류 조회
    const _setItemName = _setFormatCode(common.code, 'G025');
    // 전화번호 '-' 추가
    const _setGridPhone = _setFormatPhone();
    //@ 초기 데이터 (Table 컬럼)
    const columns = [
        { field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
        { field: 'prod_no', headerAlign: 'center', headerName: '수선품번호', width: 130 },
        { field: 'cust_name', headerAlign: 'center', headerName: '고객명', width: 140 },
        { field: 'phone', headerAlign: 'center', headerName: '연락처', width: 130, ..._setGridPhone },
        { field: 'brand_cd', headerAlign: 'center', headerName: '브랜드', width: 150, ..._setCateName, hide: true },
        { field: 'brand_name', headerAlign: 'center', headerName: '브랜드', width: 150 },
        // { field: 'cate_cd', headerAlign: 'center', headerName: '품목', ..._setCateName },
        { field: 'cate_cd', headerAlign: 'center', headerName: '수선종류', width: 120, ..._setItemName },
        { field: 'repr_st_dt', headerAlign: 'center', headerName: '입고일', width: 120, ..._setDate },
        { field: 'repr_req_dt', headerAlign: 'center', headerName: '출고요청일', width: 120, ..._setDate },
        { field: 'prod_ed_dt', headerAlign: 'center', headerName: '출고일', width: 120, ..._setDate },
        { field: 'tot_pay', headerAlign: 'center', headerName: '수선비', hide: true, width: 150, ..._setComma },
        { field: 'frst_pay', headerAlign: 'center', headerName: '선금', hide: true, ..._setComma },
        { field: 'prod_stat', headerAlign: 'center', headerName: '상태', width: 100, ..._setStateName },
    ];

    // [Grid] ######################################################################        
    const handleInit = useCallback(() => {
        let srch_date = _getNowDate();        //당일 날짜        
        let body = {
            repr_req_dt: srch_date              // 당일 날짜 검색
        }
        // 사용자 조회
        getDashboardProd(body).then((d) => dispatch(d));
    }, [dispatch]);

    let datagrid;

    //@ 최초 실행
    useEffect(() => {
        handleInit();
    }, [handleInit]);

    if (_isEmpty(product.dashboard)) {
        datagrid = <Typography
            variant="h6"
            align="center"
        >
            데이터가 존재하지 않습니다.
        </Typography>;
    } else {
        datagrid = <DataGrid
            headerHeight={40}
            rowHeight={30}
            columns={columns}
            rows={product.dashboard}
            pageSize={8}
            onRowClick={handleChange}
        />;
    }
    // [Render] ######################################################################  
    return (
        <React.Fragment>
            {/* <Toolbar className={classes.toolbar}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={6}>
                        <Typography variant="subtitle1">선택일 출고 수선품</Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>
            </Toolbar> */}
            <div
                className={classes.grid}
            >
                {datagrid}
            </div>
            {/* 버튼영역 */}
            <Box
                ml={1} mr={1} mb={1}
                display="flex"
                justifyContent="flex-end"
            >
                {/* 접수이동 */}
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<DoubleArrow />}
                    onClick={handleMove}
                >
                    접수이동
                </Button>
            </Box>
        </React.Fragment>
    );
};

export default MainCalendarList;