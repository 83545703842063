/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : main dashboard page
**/
/* @material import */
import {
  Box, Container,





  Grid, makeStyles,


  Paper,
  Toolbar, Typography
} from '@material-ui/core';
/* react import */
import React from 'react';
import Page from '../Page';
import CalendarView from './MainCalendar';
import MainCalendarList from './MainCalendarList';
import MainList from './MainList';

/* styles */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
    marginTop: 0,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  paper: {
    width: '100%',
    height: 380,
    maxWidth: '90%',
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  paper2: {
    width: '100%',
    height: 450,
    maxWidth: '59%',
    // margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  paper3: {
    width: '100%',
    height: 450,
    maxWidth: '31%',
    // margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  }
}));

/* component */
const Main = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Page
        className={classes.root}
        title="Main"
      >      
      <Box
          display="flex"
          flexDirection="column"
          height="100%"
          alignItems="flex-start"
        >
          <Container maxWidth="xl">
            <Box mb={2} justifyContent="center">
              <Grid
                container
                justify="center"
              >
                {/* 수선품 */}
                <Paper className={classes.paper} elevation={4}>
                  <MainList />
                </Paper>
              </Grid>
            </Box>
            <Box justifyContent="center">
              <Grid
                container
                justify="center"
              >                
                {/* 달력 */}
                <Paper className={classes.paper3} elevation={4}>
                  <Toolbar className={classes.toolbar}>
                    <Typography variant="h6">월 출고 요청 수선품</Typography>
                  </Toolbar>                  
                  <CalendarView />
                </Paper>
                <Paper className={classes.paper2} elevation={4}>
                  <Toolbar className={classes.toolbar}>
                    <Typography variant="h6">선택일 출고 수선품</Typography>                    
                  </Toolbar>
                  <MainCalendarList />
                </Paper>                
              </Grid>
            </Box>                      
          </Container>
      </Box>
    </Page>
    </React.Fragment>    
  );
};

export default Main;
