/**--
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Users page
**/

/* @material import */
import {
    Box, Card,
    CardContent,
    Container,
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core";
/* react import */
import React, { useCallback, useEffect } from 'react';
/* Calendar import */
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // CSS 주석처리함 
import { useDispatch, useSelector } from "react-redux";
import { _getNowDate, _isEmpty, _setDateYYYYMMDD } from "../../utils/Common";
import { getDashboardProd } from "../../utils/redux/actions/ProdAction";

const useStyles = makeStyles((theme) => ({
    /* select style */
    root: {
        height: '100%',
        width: '100%',
        maxHeight: '84%',
        minWidth: '90%',
        margin: theme.spacing(1)
    },
    toolbar: {
        minHeight: 40,
        color: '#fff',
        backgroundColor: '#1976d2'
    },
    // grid: {
    //     height: '300',
    //     width: '400'        

    // }, 
    // bullet: {
    //     display: 'inline-block',
    //     margin: '0 2px',
    //     transform: 'scale(0.8)',
    // },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    typo: {
        height: '300',
        width: '200',
        maxHeight: '300',
        minWidth: '200'
    },
    calendar: {
        height: '330px',
        width: '330px',
        maxHeight: '330px',
        minWidth: '330px'
    }
}));


const CalendarView = () => {
    const classes = useStyles();     // CSS
    const dispatch = useDispatch();  // dispatch hook    
    // [state] ######################################################################

    const product = useSelector((state: any) => state.product);    // product
    const common = useSelector((state: any) => state.common);      // common

    let state = {
        date: new Date()
    };
    let prductCount;                                               // 출고 예정 수선품 수        
    let calenDate;
    let cateCount;

    // [Events] ######################################################################
    const handleChange = useCallback((e) => {
        let testDate = new Date(e);
        let srchDateChange = _setDateYYYYMMDD(testDate.toString(), "-") + " 00:00:00";        // 검색 날짜로 형 변환        

        product.srchDate = srchDateChange;        // 검색 날짜 product에 선언
        let body = {
            repr_req_dt: srchDateChange           // 출고 요청일 컬럼
        }
        // 사용자 조회        
        getDashboardProd(body).then((res: any) => dispatch(res));
    }, [product, dispatch]);

    // 21.08.18 추가 (해당 날짜 수선종류별 갯수 노출)
    const cateCounter = () => {
        let comcode = common.code
        let cateList: any = []
        let resultArray: any = [];

        for (const code of comcode) {
            if (code.grp_cd_id === "G025") {
                for (const dash of product.dashboard) {
                    if (code.cd_id === dash.cate_cd) {
                        cateList.push(code)
                    }
                }
            }
        }
        cateList.map((d: any) => {
            if (resultArray.find((object: any) => {
                if (object.cd_id === d.cd_id) {
                    object.times++;
                    return true;
                } else {
                    return false;
                }
            })) { } else {
                d.times = 1;
                resultArray.push(d);
            }
        })
        resultArray.forEach((v: any, i: any) => {
            v.id = i + 1
        });
        return resultArray
    }
    // 21.08.18

    //@ 최초 실행    
    const handleInit = useCallback(() => {
        let srchDate = _getNowDate();        //당일 날짜           
        let body = {
            repr_req_dt: srchDate           // 출고 요청일 컬럼
        }
        product.srchDate = srchDate;        // 검색 날짜 product에 선언
        // 사용자 조회        
        getDashboardProd(body).then((d: any) => dispatch(d));
    }, []);
    // [init] ######################################################################


    //@ 최초 실행
    useEffect(() => {
        handleInit();
    }, [handleInit]);

    if (_isEmpty(product.dashboard)) {
        prductCount = <Typography
            variant="h6"
            align="center"
        >
            데이터가 <br />
            존재하지 않습니다.
        </Typography>;
    } else {
        let srDate = product.srchDate;
        calenDate = <Typography variant="h6" style={{ fontWeight: 600 }}>
            {_setDateYYYYMMDD(srDate, '-')}
        </Typography>;
        prductCount = <Typography variant="h5" style={{ fontWeight: 600 }}>
            출고 요청: {product.dashboard.length} 개<br />
        </Typography>;
        // 21.08.18 추가 (해당 날짜 수선종류별 갯수 노출)
        cateCount =
            <Typography variant="h6">
                {cateCounter().map((data: any) => {
                    return (
                        <span key={data.id}>{data.cd_name} : {data.times}개<br /></span>
                    )
                })}
            </Typography>;
        // 21.08.18
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                &nbsp;
            </Grid>
            <Container maxWidth="xl">
                <Box p={1}>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                    >
                        <Grid
                            container
                            item xs={7}
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Calendar
                                className={classes.calendar}
                                defaultView="month"
                                onChange={handleChange}
                                value={state.date}
                                calendarType={"US"}
                            />
                        </Grid>
                        <Grid item xs={1} />
                        <Grid
                            container
                            item xs={4}
                            justify="center"
                            alignItems="center">
                            <Card variant="outlined">
                                <CardContent>
                                    <Box component="div">
                                        {/* 선택날짜 */}
                                        {calenDate}
                                    </Box>
                                    <Box>
                                        {/* 출고 수선품 수*/}
                                        {prductCount}
                                    </Box>
                                    <Box>
                                        {/*출고 수선품 수선종류 수(21.08.18 추가)*/}
                                        {cateCount}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* <Grid item xs={8}>                            
                            {datagrid}                            
                        </Grid> */}
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
};

export default CalendarView;