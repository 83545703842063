/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux Rcpt Action
**/
import { _ajaxGetJsonAsync, _ajaxPostJsonAsync } from "../../axios/axios";
import {
  RCPT_ADD,
  RCPT_FAIL,
  RCPT_GET,
  RCPT_GET_INFO,
  RCPT_SEND,
  RCPT_UPD,
  RCPT_UPD_STAT
} from "../types";

/* 접수 경로 정보 */
const API_PATH = "/api/v1/receipts";

/* 접수 전체 조회 */
export const getRcpt = async (body: any) => {
  // IN Data 구성
  // body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH + "/search/", body, RCPT_GET, RCPT_FAIL);
}

/* 접수 개별 조회 */
export const getRcptInfo = async (d: any) => {
  // ajax 통신
  return await _ajaxGetJsonAsync("get", API_PATH + "/" + d, {}, RCPT_GET_INFO, RCPT_FAIL);
}

/* 접수 등록 */
export const addRcpt = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['reg_user'] = user_name;       // 최초작성자
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("post", API_PATH + "/", d, RCPT_ADD, RCPT_FAIL);
}

/* 접수 수정 */
export const updRcpt = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH + "/" + d.rcpt_no, d, RCPT_UPD, RCPT_FAIL);
}

/* 접수 카카오톡 전송 */
export const sendKakaoRcpt = async (body: any) => {
  return await _ajaxPostJsonAsync("post", API_PATH + "/send/", body, RCPT_SEND, RCPT_FAIL);
}

/* 카카오 전송 상태값 수정 */
export const updRcptStat = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['chk_kakao_rcpt'] = '02';      // 카카오 전송 상태
  d['last_mod_user'] = user_name;  // 수정자
  return await _ajaxPostJsonAsync("put", API_PATH + "/stat/" + d.rcpt_no, d, RCPT_UPD_STAT, RCPT_FAIL);
}