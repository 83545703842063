/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux ComAction actions
**/
import { _ajaxGetJsonAsync } from "../../axios/axios";
import { _getSession, _isEmpty, _setSession } from "../../Common";
import {
  COM_CODE,
  COM_DASHBOARD,
  COM_FAIL
} from "../types";

/* 카테고리 API 경로 정보 */
const API_PATH = "/api/v1/codes";
const DASH_PATH = "/api/v1/dashboard";
const key = 'CODE_DATA';

/* 카테고리 전체 조회 */
export const getComCode = async () => {
  
  // IN Data 구성
  let body = {
     limit: '1000',  // 개수
  }; 
  let data;
  data = _getSession(key);

  if(_isEmpty(data)) {  // 세션 스토리지 확인
    return await _ajaxGetJsonAsync("get", API_PATH+"/", body, COM_CODE, COM_FAIL).then(async (res) => {

      // 세션 설정
      if(!_isEmpty(res)) {
        _setSession(key, res);
      }

      return await res;
    });

  } else {

    return await data;
  }
}

/* 대시보드 데이터 조회 */
export const getDashboard = async () => {
  // IN Data 구성
  let body = {
     limit: '1',  // 개수
  }; 

  // ajax 통신
  return await _ajaxGetJsonAsync("get", DASH_PATH+"/", body, COM_DASHBOARD, COM_FAIL);
}