/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux products category Action
**/
import { _ajaxGetJsonAsync, _ajaxPostJsonAsync } from "../../axios/axios";
import {
  PROD_CATE_ADD,
  PROD_CATE_DEL,
  PROD_CATE_FAIL,
  PROD_CATE_GET,
  PROD_CATE_GET_INFO,
  PROD_CATE_UPD
} from "../types";

/* 수선품 카테고리 경로 정보 */
const API_PATH = "/api/v1/productCates";

/* 수선품 카테고리 전체 조회 */
export const getProdCate = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, PROD_CATE_GET, PROD_CATE_FAIL);
}

/* 수선품 카테고리 개별 조회 */
export const getProdCateInfo = async (d:any) => {
  // ajax 통신
  return await _ajaxGetJsonAsync("get", API_PATH+"/"+d, {}, PROD_CATE_GET_INFO, PROD_CATE_FAIL);
}

/* 수선품 카테고리 등록 */
export const addProdCate = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['reg_user'] = user_name;       // 최초작성자
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("post", API_PATH+"/", d, PROD_CATE_ADD, PROD_CATE_FAIL);
}

/* 수선품 카테고리 수정 */
export const updProdCate = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH+"/"+d.prod_cate_no, d, PROD_CATE_UPD, PROD_CATE_FAIL);
}

/* 수선품 카테고리 삭제 */
export const delProdCate = async (d:any) => {
  return await _ajaxPostJsonAsync("delete", API_PATH+"/"+d.prod_cate_no, d, PROD_CATE_DEL, PROD_CATE_FAIL);
}