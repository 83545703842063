/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux chatbot products Action
**/
import { _ajaxPostJsonAsync } from "../../axios/axios";
import {
  BOT_PROD_ADD,
  BOT_PROD_DEL,
  BOT_PROD_FAIL,
  BOT_PROD_GET,
  BOT_PROD_GET_INFO,
  BOT_PROD_UPD
} from "../types";

/* 챗봇 상품 경로 정보 */
const API_PATH = "/api/v1/botProducts";

/* 챗봇 상품 전체 조회 */
export const getBotProd = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, BOT_PROD_GET, BOT_PROD_FAIL);
}

/* 챗봇 상품 개별 조회 */
export const getBotProdInfo = async (body:any) => {
  // ajax 통신
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, BOT_PROD_GET_INFO, BOT_PROD_FAIL);
}

/* 챗봇 상품 등록 */
export const addBotProd = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['reg_user'] = user_name;       // 최초작성자
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("post", API_PATH+"/", d, BOT_PROD_ADD, BOT_PROD_FAIL);
}

/* 챗봇 상품 수정 */
export const updBotProd = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH+"/"+d.bot_prod_no, d, BOT_PROD_UPD, BOT_PROD_FAIL);
}

/* 챗봇 상품 삭제 */
export const delBotProd = async (d:any) => {
  return await _ajaxPostJsonAsync("delete", API_PATH+"/"+d.bot_prod_no, d, BOT_PROD_DEL, BOT_PROD_FAIL);
}