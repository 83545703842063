/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : login page
**/
/* @material import */
import {
  Box,
  Button,
  Container,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
/* react import */
import React, { createRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
/* form support import */
import * as Yup from 'yup';
import { signup } from "./../../utils/redux/actions/UserAction";
/* service import */
import Page from './../Page';

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden'
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const Login = () => {
  const classes = useStyles();      // css
  const dispatch = useDispatch();   // dispatch hook
  const navigate = useNavigate();   // link
  
  // [state] ######################################################################  
  const common = useSelector((state:any) => state.common);              // common

  // [Render] ######################################################################  
  return (
    <Page
      className={classes.root}
      title="로그인"
      ref={createRef()}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(v, { setSubmitting }) => {
            
              let body = new FormData();
              body.append('grant_type', 'password');
              body.append('username', v.email);
              body.append('password', v.password);

              signup(body).then((res:any) => {
                if(res.type === 'SIGNUP_SUCCESS') {
                  if(res.payload.user_grd === '01' || res.payload.user_grd === '02' || res.payload.user_grd === '09') {
                    window.sessionStorage.setItem('access_token', res.payload.access_token);
                    window.sessionStorage.setItem('user_grd', res.payload.user_grd);          // 사용자 등급
                    window.sessionStorage.setItem('user_name', res.payload.user_name);         // 사용자 이름
                    navigate('/', { replace: true });
                  } else {
                    setSubmitting(false);
                    window.sessionStorage.setItem('access_token', '');        
                    window.sessionStorage.setItem('user_grd', '');   
                    window.sessionStorage.setItem('user_name', '');         // 사용자 이름     
                    common.msg = '접근 권한이 없습니다.';
                    common.isMsg = true;        
                    return dispatch({type:'COM_ALERT'});  // 안내메시지                        
                  }
                } else {
                  setSubmitting(false);
                  window.sessionStorage.setItem('access_token', '');        
                  window.sessionStorage.setItem('user_grd', '');       
                  window.sessionStorage.setItem('user_name', '');         // 사용자 이름     
                  common.msg = '로그인 정보가 일치하지 않습니다';
                  common.isMsg = true;        
                  return dispatch({type:'COM_ALERT'});  // 안내메시지                   
                }      
              })              
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>               
                {/* 로그인타이틀 */}
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    로그인
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    투엘다움 관리자는 로그인 후 이용이 가능합니다.
                  </Typography>
                </Box>
                {/* 이메일 입력 */}
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                {/* 비밀번호 입력 */}
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                {/* 로그인버튼 */}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    로그인
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default Login;
