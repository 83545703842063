/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux Gally store
**/
// TYPES
import {
  GALLY_ADD,
  GALLY_DEL,
  GALLY_FAIL,
  GALLY_GET,
  GALLY_GET_INFO,
  GALLY_GET_TAG,
  GALLY_UPD
} from "../types";
import { _dataAddID, _isEmpty, _setImgFormat } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows:[],
  images:{},    // 성능 개선을 위한 이미지 저장소
  info:[],
  tag:[],
  isEdit: false
};

// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case GALLY_GET:         // 이미지 조회    
      return { 
        ...state,
        rows: _dataAddID(action.payload),
        images: _setImgFormat(action.payload), 
        isEdit: _isEmpty(action.payload) ? false : true
       };
    case GALLY_GET_INFO:    // 이미지 개별 조회    
      return { 
        ...state,
        info: action.payload[0],
        isEdit: _isEmpty(action.payload) ? false : true
       };  
    case GALLY_GET_TAG:     // 이미지 태그
      return { 
        ...state,
        tag: action.payload
       };             
    case GALLY_ADD:         // 이미지 저장
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
       };          
    case GALLY_UPD:         // 이미지 수정
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
       };   
    case GALLY_DEL:         // 이미지 삭제
      return { 
        ...state
      };                  
    case GALLY_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}