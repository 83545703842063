/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux user actions
**/
import { _ajaxFormAsync } from "./../../axios/axios";
import { SIGNUP_FAIL, SIGNUP_SUCCESS } from "./../types";

/* 사용자 API 경로 정보 */
const API_PATH = "/api/v1/login";

/* 사용자 인증 */
export const signup = async (_data:any) => {
  // ajax 통신
  return await _ajaxFormAsync("post", API_PATH+"/access-token", _data, SIGNUP_SUCCESS, SIGNUP_FAIL);
}
