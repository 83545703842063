/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : KaKao Receipt View page
**/
/* @material import */
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Close, DoubleArrow } from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import {
  _getCodeName,
  _isEmpty,
  _setAddComma,
  _setCodeFilter,
  _setDateYYYYMMDD,
  _setNowUTCDate
} from "./../../utils/Common";
import FormText from "./../../utils/form/FormText";
import { addCust } from "./../../utils/redux/actions/CustAction";
import { addProd } from "./../../utils/redux/actions/ProdAction";
import { addRcpt } from "./../../utils/redux/actions/RcptAction";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1)
  },
  toolbar: {
    width: 700,
    minHeight: 35,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  button: {
    margin: theme.spacing(1),
  },
  box: {
    width: 650,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  box2: {
    width: 650,
    height: 60,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  box3: {
    width: 650,
    height: 440,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  datagrid: {
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    minWidth: '100%'
  },
  datagrid2: {
    height: '100%',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#9fa8da',
      color: '#ffffff'
    },
  },
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const KaKaoRcptView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const botRcpt = useSelector((state: any) => state.botRcpt, shallowEqual);    // botRcpt
  const common = useSelector((state: any) => state.common, shallowEqual);      // common

  // [Events] ######################################################################
  //@ 팝업 닫기
  const handleClose = useCallback(() => {
    botRcpt.isKakaoRcpt = false;
    dispatch({ type: 'BOT_RCPT_GET_INFO', payload: {} });
  }, [botRcpt, dispatch]);

  //@ 페이지 이동
  const handleAdd = useCallback((v) => {

    if (v.cust_stat === '01') {          // 회원  
      if (_isEmpty(v.cust_no)) {         // 회원번호가 없을 경우
        common.msg = '회원을 선택해 주세요';
        common.isMsg = true;
        dispatch({ type: 'COM_ALERT' });   // 안내메시지
        return;
      }

      let inRcpt = {
        rcpt_no: '',
        cust_no: v.cust_no,
        rcpt_dtime: _setNowUTCDate(),
        cust_name: v.cust_name,
        rcpt_chnl: '02',
        rcpt_stat: '01' // 접수대기
      };
      addRcpt(inRcpt).then((r: any) => {       // 접수 등록
        console.log(' ### addCust ### ');
        console.log(r);
        let inProd = {
          prod_no: '',
          rcpt_no: r.payload.rcpt_no,
          org_no: '01',
          rcpt_name: v.cust_name,
          cust_no: r.payload.cust_no,
          repr_st_dt: _setNowUTCDate(),
          rcpt_chnl: '02',
          send_typ: '01',  // 21.09.02  요청에 따라 기본값을 02(강남본점수령) -> 01(택배) 로 변경
          etc_cont: v.rcpt_cate + ', ' + v.rcpt_dtime,
          req_cont: v.req_cont,
          prod_stat: '01'
        };
        addProd(inProd).then(() => {
          common.msg = '접수 이동 처리 되었습니다.';
          common.isMsg = true;
          return dispatch({ type: 'COM_ALERT' });       // 안내메시지
        }).catch((e) => console.log(e));
      }).catch((e) => console.log(e));

    } else if (v.cust_stat === '02') {   // 비회원

      let inCust = {
        email: v.email,
        cust_name: v.cust_name,
        phone: v.phone,
        addr: v.addr,
        join_dtime: _setNowUTCDate(),
        join_chnl: '02',
        cust_stat: v.cust_stat
      };
      // 고객 등록
      addCust(inCust).then((c: any) => {                   // 고객 등록
        console.log(' ### addCust ### ');
        console.log(c);
        if (_isEmpty(c.payload.cust_no)) {    // 회원번호가 없을 경우
          common.msg = '회원 정보가 존재 합니다.';
          common.isMsg = true;
          dispatch({ type: 'COM_ALERT' });       // 안내메시지
          return;
        }
        let inRcpt = {
          rcpt_no: '',
          cust_no: c.payload.cust_no,
          rcpt_dtime: _setNowUTCDate(),
          cust_name: v.cust_name,
          rcpt_chnl: '02',
          rcpt_stat: '01' // 접수대기
        };
        addRcpt(inRcpt).then((r: any) => {       // 접수 등록
          console.log(' ### addCust ### ');
          console.log(r);
          let inProd = {
            prod_no: '',
            rcpt_no: r.payload.rcpt_no,
            org_no: '01',
            rcpt_name: v.cust_name,
            cust_no: r.payload.cust_no,
            repr_st_dt: _setNowUTCDate(),
            rcpt_chnl: '02',
            send_typ: '01',  // 21.09.02  요청에 따라 기본값을 02(강남본점수령) -> 01(택배) 로 변경
            etc_cont: v.rcpt_cate + ', ' + v.rcpt_dtime,
            req_cont: v.req_cont,
            prod_stat: '01'
          };
          addProd(inProd).then(() => {
            common.msg = '접수 이동 처리 되었습니다.';
            common.isMsg = true;
            return dispatch({ type: 'COM_ALERT' });       // 안내메시지
          }).catch((e) => console.log(e));
        }).catch((e) => console.log(e));
      }).catch((e) => console.log(e));
    }
  }, [common, dispatch]);

  const handleCustSrch = useCallback((e) => {
    // 고객검색레이어 오픈
    common.isCustAdd = true;
    common.custPage = '03';
    dispatch({ type: 'COM_CUST_SEARCH', payload: common });
    // 고객검색정보 초기화
    dispatch({ type: 'CUST_GET', payload: {} });
    dispatch({ type: 'CUST_GET_INFO', payload: {} });
  }, [common, dispatch]);

  // [Render] ######################################################################  
  return (
    <Dialog
      className={classes.root}
      open={botRcpt.isKakaoRcpt}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      {/* 타이틀 */}
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={11}>
            <Typography variant="h6">카카오 수거 접수 상세</Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid
              container
              justify="center"
              alignItems="center"
            >
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Formik
        enableReinitialize
        initialValues={{
          bot_receipt_no: _isEmpty(botRcpt.info) ? '' : botRcpt.info.bot_receipt_no,   // KakaoID
          kakao_id: _isEmpty(botRcpt.info) ? '' : botRcpt.info.kakao_id,               // KakaoID
          email: _isEmpty(botRcpt.info) ? '' : botRcpt.info.email,                     // 이메일
          cust_no: _isEmpty(botRcpt.info) ?
            (_isEmpty(common.search.cust_no) ? '' : common.search.cust_no) : botRcpt.info.cust_no,         // 고객번호              
          cust_name: _isEmpty(botRcpt.info) ?
            (_isEmpty(common.search.cust_name) ? '' : common.search.cust_name) : botRcpt.info.cust_name,   // 고객명
          phone: _isEmpty(botRcpt.info) ? '' : botRcpt.info.phone,                     // 연락처
          addr: _isEmpty(botRcpt.info) ? '' : botRcpt.info.addr,                       // 주소
          rcpt_cont: _isEmpty(botRcpt.info) ? '' : botRcpt.info.rcpt_cont,             // 수거요청사항
          rcpt_dtime: _isEmpty(botRcpt.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(botRcpt.info.rcpt_dtime, '-'),   // 수거요청일
          rcpt_cate: _isEmpty(botRcpt.info) ? '' : botRcpt.info.rcpt_cate,             // 품목
          // rcpt_cnt: _isEmpty(botRcpt.info) ? '' : botRcpt.info.rcpt_cnt,               // 수량
          // rcpt_pay: _isEmpty(botRcpt.info) ? _setAddComma('0') : _setAddComma(botRcpt.info.rcpt_pay),                        // 결재비용
          // pay_stat: _isEmpty(botRcpt.info) ? _getCodeName('01', 'G021') : _getCodeName(botRcpt.info.pay_stat, 'G021'),       // 결재상태(01:대기, 02:진행, 03:완료, 04:실패)
          rcpt_stat: _isEmpty(botRcpt.info) ? _getCodeName('01', 'G020') : _getCodeName(botRcpt.info.rcpt_stat, 'G020'),     // 접수상태(01:진행, 02:완료)
          cust_stat: _isEmpty(botRcpt.info) ? '02' :
            _isEmpty(botRcpt.info.cust_stat) ? '02' : botRcpt.info.cust_stat   // 회원구분           
        }}
        validationSchema={Yup.object().shape({

        })}
        onSubmit={(v) => {
          handleAdd(v);
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
          <form onSubmit={handleSubmit}>
            {/* 상세내용 */}
            <Box
              mt={1}
              display="flex"
              border={1}
              borderColor="primary.main"
              justifyContent="center"
              className={classes.box2}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}>
                {/* 회원여부 */}
                <Grid item xs={6}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">회원구분:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormSelect
                        id='cust_stat'
                        value={values.cust_stat}
                        item={_setCodeFilter(common.code, 'G004')}
                        firstDefault={false}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
                {/* 회원명 */}
                <Grid item xs={6}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">회원명:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormText
                        id='cust_name'
                        value={values.cust_name}
                        errors={errors.cust_name}
                        touched={touched.cust_name}
                        disabled={true}
                        required={true}
                        blur={handleBlur}
                        click={handleCustSrch}
                        change={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box
              mt={1}
              display="flex"
              border={1}
              borderColor="primary.main"
              justifyContent="center"
              className={classes.box3}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}>
                {/* 카카오ID */}
                <Grid item xs={6}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">카카오ID:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormText
                        id='kakao_id'
                        value={values.kakao_id}
                        disabled={true}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
                {/* 고객명 */}
                <Grid item xs={6}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">고객명:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormText
                        id='cust_name'
                        value={values.cust_name}
                        disabled={true}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
                {/* 연락처 */}
                <Grid item xs={6}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">연락처:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormText
                        id='phone'
                        value={values.phone}
                        disabled={true}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
                {/* 이메일 */}
                <Grid item xs={6}>
                  {/* <Grid 
                      container
                      justify="center"
                      alignItems="center">
                        <Grid item xs={3}>
                          <Typography variant="subtitle2">이메일:</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormText 
                            id='email'
                            value={values.email}
                            disabled={true}
                            required={true}
                            blur={handleBlur}
                            change={handleChange} />
                        </Grid>
                    </Grid> */}
                </Grid>
                {/* 주소 */}
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={1}>
                      <Typography variant="subtitle2">주소:</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <FormText
                        id='addr'
                        value={values.addr}
                        disabled={true}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
                {/* 품목 */}
                <Grid item xs={6}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">품목:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormText
                        id='rcpt_cate'
                        value={values.rcpt_cate}
                        disabled={true}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
                {/* 수량 */}
                <Grid item xs={6}>
                  {/* <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">수량:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormText 
                            id='rcpt_cnt'
                            value={values.rcpt_cnt}
                            disabled={true}
                            required={true}
                            blur={handleBlur}
                            change={handleChange} />
                    </Grid>
                  </Grid> */}
                </Grid>
                {/* 수거요청일 */}
                <Grid item xs={6}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">수거요청일:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormText
                        id='rcpt_dtime'
                        value={values.rcpt_dtime}
                        disabled={true}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}></Grid>
                {/* 수거요청사항 */}
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">수거요청사항:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <FormText
                        id='rcpt_cont'
                        value={values.rcpt_cont}
                        disabled={true}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
                {/* 결재비용 */}
                {/* <Grid item xs={6}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">결재비용:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormText
                        id='rcpt_pay'
                        value={_setAddComma(values.rcpt_pay)}
                        disabled={true}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}></Grid> */}
                {/* 접수상태 */}
                <Grid item xs={6}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">접수상태:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormText
                        id='rcpt_stat'
                        value={values.rcpt_stat}
                        disabled={true}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
                {/* 결재상태 */}
                <Grid item xs={6}>
                  {/* <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">결재상태:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormText
                        id='pay_stat'
                        value={values.pay_stat}
                        disabled={true}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Box>
            {/* 버튼 */}
            <Box
              ml={1} mr={1}
              display="flex"
              justifyContent="flex-end"
              className={classes.box}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="submit"
                className={classes.button}
                startIcon={<DoubleArrow />}
              >
                접수이전
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default KaKaoRcptView;
