/* @material import */
import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Toolbar
} from "@material-ui/core";
import {
  Build,
  Category,
  Chat,
  Dashboard,
  ExitToApp,
  Home,
  ImageSearch,
  Payment,
  PersonAdd,
  Phone,
  PhotoLibrary,
  Receipt,
  Search,
  Send
} from "@material-ui/icons";
/* React import */
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { _accessCheck, _clearSession } from "./../../utils/Common";
import { getDashboard } from "./../../utils/redux/actions/ComAction";

/* navigator data */
const navigator = [
  {
    index: 0,
    textColor: "inherit",
    label: "HOME",
    icon: <Home />,
    link: "/main",
  },
  {
    index: 1,
    textColor: "inherit",
    label: "상담",
    icon: <Phone />,
    link: "/consult",
  },
  {
    index: 2,
    textColor: "inherit",
    label: "접수",
    icon: <Receipt />,
    link: "/receipt",
  },
  {
    index: 3,
    textColor: "inherit",
    label: "이미지",
    icon: <PhotoLibrary />,
    link: "/gallery",
  },
  {
    index: 4,
    textColor: "inherit",
    label: "수선품",
    icon: <ImageSearch />,
    link: "/product",
  },
  {
    index: 5,
    textColor: "inherit",
    label: "작업",
    icon: <Build />,
    link: "/repair",
  },  
  {
    index: 6,
    textColor: "inherit",
    label: "출고",
    icon: <Send />,
    link: "/delivery",
  },
  {
    index: 7,
    textColor: "inherit",
    label: "정산",
    icon: <Payment />,
    link: "/payment",
  },
  {
    index: 8,
    textColor: "inherit",
    label: "카테고리",
    icon: <Category />,
    link: "/category",
  },  
  {
    index: 9,
    textColor: "inherit",
    label: "챗봇",
    icon: <Chat />,
    link: "/chatbot",
  },      
  {
    index: 10,
    textColor: "inherit",
    label: "사용자",
    icon: <PersonAdd />,
    link: "/users",
  },
];

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
    secondaryBar: {
      color: '#fff',
      backgroundColor: '#000000',
      zIndex: 0,
    },
    iconButtonAvatar: {
      width: 130,
      minWidth: 130
    },
    link: {
      textDecoration: "none",
      color: "rgba(255, 255, 255, 0.7)",
      "&:hover": {
        color: "white",
      },
    }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const Navigator = (props:any) => {
  const classes = useStyles();            // 스타일
  const dispatch = useDispatch();         // dispatch hook
  const navigate = useNavigate();         // URL Redirect

  const common = useSelector((state:any) => state.common);      // common

  // [Events] ######################################################################  
  //@ 메뉴 클릭
  const handleChange = (evt:any, val:any) => {
    dispatch({type:'COM_TABS_LOCATION', payload: val }); 
    move(val);       // 페이지 이동
  };

  //@ 페이지 이동
  const move = (index:any) => {
    const navi = navigator.find(navi => navi.index === index);
    if(navi !== undefined) {
      if(_accessCheck(navi.link)) {
        navigate(navi.link, { replace: true });
      } else {
        alert('해당 페이지에 접근 권한이 없습니다.');
      }
    } else {
      alert('해당 페이지에 접근 권한이 없습니다.');      
    }
  };  
  //@ 로그아웃
  const logout = () => {
    _clearSession();
    navigate('/login', { replace: true });
  }; 

  //@ 고객검색 레이어
  const handleSearch = useCallback(() => {
    common.isSearch = true;        
    dispatch({type:'COM_SEARCH'});   
  },[common, dispatch]);

  //@ 대시보드 레이어
  const handleDashboard = useCallback(() => {
    common.isDashboard = true;       
    getDashboard().then((v:any) => dispatch(v));
  },[common, dispatch]);

  // [init] ######################################################################
  //@ 최초 실행
  useEffect(() => { 
  }, []);
    
  // [Render] ######################################################################    
  return (
    <React.Fragment>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        // color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid 
            container                            
            justify="center"
            alignItems="center" 
            >
            <Grid item xs={10}>
              {/* Navigator Area */}
              <Tabs
                value={common.tabLocation}
                onChange={handleChange}
                textColor="inherit"
                centered
              >
                {navigator.map((props, index) => (
                  <Tab
                    className={classes.iconButtonAvatar}
                    textColor={props.textColor}
                    icon={props.icon}
                    label={props.label}
                    key={index}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid>
              {window.sessionStorage.getItem('user_name')}
            </Grid>
            <Grid item xs={1}>
              <Grid 
                container                            
                justify="flex-end"
                alignItems="center"
                >
                {/* 사용자검색 */}
                <Grid item xs={4}>
                  <IconButton 
                    className={classes.link}
                    onClick={handleSearch}
                  >
                    <Search
                      color="inherit"
                    />
                  </IconButton>
                </Grid>            
                {/* 대시보드 */}
                <Grid item xs={4}>
                  <IconButton 
                    className={classes.link}
                    onClick={handleDashboard}
                  >
                    <Dashboard
                      color="inherit"
                    />
                  </IconButton>
                </Grid>
                {/* 로그아웃 */}
                <Grid item xs={4}>
                  <IconButton 
                    className={classes.link}
                    onClick={logout}
                  >
                    <ExitToApp
                      color="inherit"
                    />
                  </IconButton >
                </Grid> 
              </Grid>
            </Grid>           
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

export default Navigator;
