/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Comn Dashboard
**/
/* @material import */
import {
  Box,
  Drawer,
  Grid,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Done, List } from '@material-ui/icons';
/* react import */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { _isEmpty, _setAddComma } from "./../../utils/Common";
/* ######################################################################
 * style
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
  },
  grid: {
    height: 150,
    width: '100%',
    maxHeight: 150,
    minWidth: '100%',    
    backgroundColor: '#e6ecf2'
  },
  box1: {
    height: '100%',
    width: '100%',
    maxHeight: '90%',
    minWidth: '100%'
  },
  box2: {
    height: '100%',
    width: '100%',
    maxHeight: '90%',
    backgroundColor: '#1976d2'
  },
  box3: {
    height: 15,
    width: '100%',
    maxHeight: '90%',
    backgroundColor: '#1976d2'
  },
  title: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  text1: {
    minHeight: 30,
    color: '#fff'
  },
  paper: {
    width: '90%',
    height: 130,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper    
  },  
  toolbar: {
    minHeight: 25,
    marginBottom: theme.spacing(1),
    color: '#fff',
    backgroundColor: '#7986cb'
  },
  grid2: {
   marginTop: theme.spacing(1)
  },    
}));

/* ######################################################################
 * component
 * ###################################################################### */
const ComnSearch = () => {
  const classes = useStyles();
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const common = useSelector((state:any) => state.common);

  // [Events] ######################################################################
  const handleClose = useCallback(() => {
    common.isDashboard = false;
    dispatch({ type: 'COM_DASHBOARD' });
  },[common, dispatch]);
    
  // [Render] ######################################################################  
  return (
    <Drawer anchor='top' open={common.isDashboard} onClose={handleClose}>
      <Box
       mb={1}       
       justifyContent="center"
       alignItems="center"
       className={classes.box1}
      >
        <Box className={classes.box3}></Box>
        <Box
          justifyContent="center"
          alignItems="center"
          className={classes.box2}
        >
          <Typography component="div" variant="h2" className={classes.title} align="center">
            <Box
             fontWeight="fontWeightMedium" 
             justifyContent="center"
             alignItems="center">                           
              금일 현황
            </Box>
          </Typography>
        </Box>
        <Grid
         container 
         className={classes.grid}         
        >
          <Grid item xs={1} className={classes.grid2}></Grid>
          {/* 상담 */}
          <Grid item xs={2} className={classes.grid2}>
            <Paper className={classes.paper} elevation={4}>
              <Toolbar className={classes.toolbar}>
                <List /><Typography variant="h6">상담</Typography>
              </Toolbar>
              <Grid 
                container
                justify="center"
                alignItems="center"
                >
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">방문</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].cnslt_01)}건</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">온라인</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].cnslt_02)}건</Typography>
                </Grid>                              
                <Grid item xs={1}></Grid>
              </Grid>
            </Paper>            
          </Grid> 
          {/* 접수 */}
          <Grid item xs={2} className={classes.grid2}>
            <Paper className={classes.paper} elevation={4}>
              <Toolbar className={classes.toolbar}>
                <List /><Typography variant="h6">접수</Typography>
              </Toolbar>
              <Grid 
                container
                justify="center"
                alignItems="center"
                >
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">대기</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].rcpt_01)}건</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">완료</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].rcpt_02)}건</Typography>
                </Grid>                              
                <Grid item xs={1}></Grid>
              </Grid>
            </Paper>             
          </Grid> 
          {/* 수선 */}
          <Grid item xs={2} className={classes.grid2}>
            <Paper className={classes.paper} elevation={4}>
              <Toolbar className={classes.toolbar}>
                <List /><Typography variant="h6">수선</Typography>
              </Toolbar>
              <Grid 
                container
                justify="center"
                alignItems="center"
                >
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">대기</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].repr_01)}건</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">진행</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].repr_02)}건</Typography>
                </Grid>                              
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">완료</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].repr_03)}건</Typography>
                </Grid>                              
                <Grid item xs={1}></Grid>                
              </Grid>
            </Paper>             
          </Grid> 
          {/* 검수 */}
          <Grid item xs={2} className={classes.grid2}>
            <Paper className={classes.paper} elevation={4}>
              <Toolbar className={classes.toolbar}>
                <List /><Typography variant="h6">검수</Typography>
              </Toolbar>
              <Grid 
                container
                justify="center"
                alignItems="center"
                >
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">대기</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].chk_01)}건</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">진행</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].chk_02)}건</Typography>
                </Grid>                              
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">완료</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].chk_03)}건</Typography>
                </Grid>                              
                <Grid item xs={1}></Grid>                
              </Grid>
            </Paper>             
          </Grid> 
          {/* 출고 */}
          <Grid item xs={2} className={classes.grid2}>
           <Paper className={classes.paper} elevation={4}>
              <Toolbar className={classes.toolbar}>
                <List /><Typography variant="h6">출고</Typography>
              </Toolbar>
              <Grid 
                container
                justify="center"
                alignItems="center"
                >
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">대기</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].out_01)}건</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">완료</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].out_02)}건</Typography>
                </Grid>                              
                <Grid item xs={1}></Grid>                
              </Grid>
            </Paper>            
          </Grid> 
          <Grid item xs={1} className={classes.grid2}></Grid>
          {/* 정산 */}
          {/* <Grid item xs={2} className={classes.grid2}>
           <Paper className={classes.paper} elevation={4}>
              <Toolbar className={classes.toolbar}>
                <List /><Typography variant="h6">정산</Typography>
              </Toolbar>
              <Grid 
                container
                justify="center"
                alignItems="center"
                >
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">총수선금</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].pay_01)}원</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                
                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">납입금</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].pay_02)}원</Typography>
                </Grid>                              
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={2}><Done /></Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">미수금</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">{_isEmpty(common.dashboard)? '0' : _setAddComma(common.dashboard[0].pay_03)}원</Typography>
                </Grid>                              
                <Grid item xs={1}></Grid>                
              </Grid>
            </Paper>          
          </Grid>                                                    */}
          {/* <Grid item xs={1}>
          </Grid>
          <Grid item xs={2}>
            <Typography component="div" variant="h4">
              <Box fontWeight="fontWeightMedium" m={4}>
                접수 :  100 건
            </Box>
            </Typography>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2}>
            <Typography component="div" variant="h4">
              <Box fontWeight="fontWeightMedium" m={4}>
                수선대기 :  40 건
            </Box>
            </Typography>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2}>
            <Typography component="div" variant="h4">
              <Box fontWeight="fontWeightMedium" m={4}>
                수선 중 :  50 건
            </Box>
            </Typography>
          </Grid>         
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2}>
            <Typography component="div" variant="h4">
              <Box fontWeight="fontWeightMedium" m={4}>
                출고 대기 :  30 건
            </Box>
            </Typography>
          </Grid>                    */}
        </Grid>        
      </Box>           
    </Drawer>
  );
};

export default ComnSearch;
