/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Common Consult page
**/
/* @material import */
import {
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  DataGrid
} from '@material-ui/data-grid';
import {
  Close
} from '@material-ui/icons';
/* react import */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  _isEmpty,
  _setFormatCategory,
  _setFormatCode,
  _setFormatComma,
  _setFormatDate
} from "./../../utils/Common";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      width: 1100,
      height: 600
    }
  },
  grid: {
    height: '100%', 
    width: '98%',
    maxHeight: '90%',
    minWidth: '98%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#9fa8da',
      color: '#ffffff'
    },    
  },
  // 데이터 그리드 테이블 해더
  search: {
    margin: theme.spacing(1)
  },  
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  button: {
    margin: theme.spacing(1),
  },  
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ComnConsult = () => {
  const classes = useStyles();
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const common = useSelector((state:any) => state.common);    // common
  const consult = useSelector((state:any) => state.consult);  // consult
  // const product = useSelector((state:any) => state.product);  // product

  // [Events] ######################################################################
  //@ 초기화
  // const handleinit = useCallback((v) => {
  //   let body = {
  //     cust_no: v
  //   }
  //   // 상담 내역 검색
  //   getCnsltSrch(body).then((d) => dispatch(d))
  //               .catch((e) => console.log(e));
  // },[dispatch]);

  //@ 팝업 닫기
  const handleClose = useCallback(() => {
    common.isConsult = false;
    dispatch({ type: 'COM_CONSULT' });
  },[common, dispatch]);

  // [init] ######################################################################
  //@ 최초 실행
  // useEffect(() => {
  //   handleinit(product.info.cust_no);
  // }, [handleinit]);

  // 콤마 타입 설정
  const _setComma = _setFormatComma();
  // 데이트 타입 설정
  const _setDate = _setFormatDate();
  // 카테고리명 조회
  const _setCateName = _setFormatCategory(common.category);
  // 상담경로명 조회
  const _setCodeName = _setFormatCode(common.code, 'G024');

  //@ 초기 데이터 (Table 컬럼)
  const columns = [
    { field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
    { field: 'cnslt_no', headerAlign: 'center', headerName: '상담번호', width: 130 },
    { field: 'cust_no', headerAlign: 'center', headerName: '고객번호', hide: true },
    { field: 'cust_name', headerAlign: 'center', headerName: '상담자', width: 80 },
    { field: 'cnslt_dtime', headerAlign: 'center', headerName: '상담일', width: 120, ..._setDate },
    { field: 'rcpt_chnl', headerAlign: 'center', headerName: '상담경로', ..._setCodeName },
    { field: 'brand_cd', headerAlign: 'center', headerName: '브랜드', width: 150, ..._setCateName },
    { field: 'item_cd', headerAlign: 'center', headerName: '품목', width: 100, ..._setCateName },
    { field: 'repr_pay', headerAlign: 'center', headerName: '수선비', ..._setComma },    
    { field: 'galy_url', headerAlign: 'center', headerName: '이미지', hide: true },
    { field: 'qst_cont', headerAlign: 'center', headerName: '문의', width: 300 },
    { field: 'ans_cont', headerAlign: 'center', headerName: '답변', hide: true },
    { field: 'cnslt_stat', headerAlign: 'center', headerName: '상태', hide: true }
  ];

  // [Grid] ######################################################################    
  let datagrid;

  if (_isEmpty(consult.rows)) {
    datagrid =  <Typography 
                  variant="h6"
                  align="center"
                  >
                    데이터가 존재하지 않습니다.
                </Typography>;
  } else {
    datagrid =  <DataGrid
                  headerHeight={40}
                  rowHeight={30}
                  columns={columns} 
                  rows={consult.rows}
                  pageSize={10}
                  />;
  }

  // [Render] ######################################################################  
  return (
    <Dialog 
      className={classes.root}
      open={common.isConsult} 
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <Toolbar className={classes.toolbar}>
        <Grid 
        container
        justify="center"
        alignItems="center"
        >          
          <Grid item xs={11}>
            <Typography variant="h6">상담이력</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <Close />
            </IconButton>            
          </Grid>           
        </Grid>
      </Toolbar>         
      <div 
        className={classes.grid}
      >   
      {datagrid}
      </div>
    </Dialog>    
  );
};

export default ComnConsult;
