/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Common Alert
**/
/* @material import */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
/* react import */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";

/* ######################################################################
 * component
 * ###################################################################### */
const ComnAlert = () => {

  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const common = useSelector((state:any) => state.common);  // category

  // [Events] ######################################################################
  const handleClose = useCallback(() => {
    common.isMsg = false;
    common.msg = '';
    dispatch({ type: 'COM_ALERT' });
  },[common, dispatch]);

  // [Render] ######################################################################  
  return (
    <Dialog open={common.isMsg} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogContent>
          <DialogContentText>{common.msg}</DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose} color="primary">
          확인
          </Button>
      </DialogActions>
    </Dialog>    
  );
};

export default ComnAlert;
