/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Product View Page
**/
/* @material import */
import {
  Box,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Formik } from 'formik';
/* react import */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import {
  _getPayDiff,
  _isEmpty,
  _setAddComma,
  _setCateCodeFilter,
  _setCodeFilter,
  _setDateYYYYMMDD,
  _setProdCate,
  _setRemoveComma,
  _setReqDateYYYYMMDD,
  _setUTCDate,
  _setCateCodeFilter2
} from "./../../utils/Common";
import FormDate from "./../../utils/form/FormDate";
import FormOption from "./../../utils/form/FormOption";
import FormText from "./../../utils/form/FormText";
import { addPay, updPay } from "./../../utils/redux/actions/PayAction";
import { addProd, getProd, updProd } from "./../../utils/redux/actions/ProdAction";
import { addProdCate, updProdCate } from "./../../utils/redux/actions/ProdCateAction";
import { getRcpt } from "./../../utils/redux/actions/RcptAction";
import { addRepr, delRepr, updRepr } from "./../../utils/redux/actions/ReprAction";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
    height: '100%',
    width: '100%',
    maxHeight: '90%',
    minWidth: '90%',
    overflow: 'auto',
    margin: theme.spacing(2)
  },
  grid: {
    marginTop: theme.spacing(1)
  },
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ProductView = forwardRef((props, ref) => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook
  const formRef = useRef<any>(null);

  // 부모 컴포넌트 사용
  useImperativeHandle(ref, () => formRef.current);

  // [state] ######################################################################  
  const product = useSelector((state: any) => state.product);            // product
  const productCate = useSelector((state: any) => state.productCate);    // productCate
  const payment = useSelector((state: any) => state.payment);            // payment
  const repair = useSelector((state: any) => state.repair);              // repair
  const receipt = useSelector((state: any) => state.receipt);            // receipt
  const common = useSelector((state: any) => state.common);              // common

  // [Events] ######################################################################

  function sleep(ms: number) {
    const wakeUpTime = Date.now() + ms;
    while (Date.now() < wakeUpTime) { }
  }
  //@ 초기화
  const handleinit = useCallback(() => {
    //if(product.isEdit) {
    // if(!_isEmpty(product.info)) {    // 수정일 경우
    //   let body = {
    //     prod_no: product.info.prod_no
    //   }
    //   // 수선품 정산 조회
    //   getPayInfo(body).then((res) => dispatch(res))
    //                   .catch((e) => console.log(e));
    //   // 수선품 카테고리 정보 조회
    //   getProdCate(body).then((res) => dispatch(res))
    //                   .catch((e) => console.log(e));   
    // } else {                        // 신규일 경우
    //   dispatch({ type: 'PAY_GET_INFO', payload: [] });
    //   dispatch({ type: 'PROD_CATE_GET', payload: [] });
    // }
  }, []);

  //@ 신규등록
  const handleAdd = useCallback((v) => {

    addProd(v).then(async (res) => {         // 수선품 저장
      v.prod_no = res.payload.prod_no; // 수선품번호
      // 정산 저장
      addPay(v).catch((e) => console.log(e));

      // 수선품 카테고리 저장
      let rows = _setProdCate(v, '');
      rows.forEach((v: any) => addProdCate(v).catch((e) => console.log(e)));

      // 작업지시서 저장
      for (let f of repair.rows) {
        if (_isEmpty(f.prod_no)) {
          f.prod_no = v.prod_no;  // 수선품 번호 매핑
          await addRepr(f).catch((e) => console.log(e));
        }
      }
      // repair.rows.forEach((f: any) => {
      //   if (_isEmpty(f.prod_no)) {
      //     f.prod_no = v.prod_no;  // 수선품 번호 매핑
      //     addRepr(f).catch((e) => console.log(e));
      //   }
      // });

      // 접수 목록 초기화
      let body: any = {};
      // if (!_isEmpty(receipt.srch.st_dt)) body["st_dt"] = _setUTCDate(receipt.srch.st_dt);
      // if (!_isEmpty(receipt.srch.ed_dt)) body["ed_dt"] = _setUTCDate(receipt.srch.ed_dt);
      // if (!_isEmpty(receipt.srch.rcpt_stat)) body["rcpt_stat"] = receipt.srch.rcpt_stat;
      // if (!_isEmpty(receipt.srch.rcpt_chnl)) body["rcpt_chnl"] = receipt.srch.rcpt_chnl;
      // if (!_isEmpty(receipt.srch.srch_val)) body[receipt.srch.srch_key.toString()] = receipt.srch.srch_val;
      // if (!_isEmpty(receipt.srch.limit)) body["limit"] = receipt.srch.limit;
      body['limit'] = '1000';

      getRcpt(body)
        .then((d) => {
          dispatch(d);
        })
        .catch((e) => console.log(e));
      // getRcpt(receipt.search).then((d) => dispatch(d));
      // 수선품 목록 초기화
      let bodys = {
        rcpt_no: v.rcpt_no
      }
      getProd(bodys).then((d) => {
        dispatch(d);
        common.msg = '저장에 성공 하였습니다.';
        common.isMsg = true;
        dispatch({ type: 'COM_ALERT' });       // 안내메시지                
        dispatch({ type: 'PROD_ADD' });
      });

    }).catch((e) => {
      console.log(e);
      common.msg = '저장에 실패 하였습니다.';
      common.isMsg = true;
      return dispatch({ type: 'COM_ALERT' });       // 안내메시지 
    });

    // addProd(v).then((prod) => dispatch(prod))  // 수선품 저장
    //           .then((p) => {  // 정산 저장
    //             console.log(' ### addPay insert ###');
    //             console.log(p);
    //             v.prod_no = p.payload.prod_no;
    //             addPay(v);
    //             return;
    //           }).catch((e) => console.log(e)) 
    //           .then(() => {  // 수선품 카테고리 저장
    //             console.log(' ### addProdCate insert ###');
    //             console.log(v); 
    //             let rows = _setProdCate(v, '');            
    //             console.log(rows);    
    //             rows.forEach((v:any) => addProdCate(v));
    //             return;
    //           }).catch((e) => console.log(e))
    //           .then(() => {  // 작업지시서 저장
    //             let rows = repair.rows;
    //             console.log(' ### addRepr insert ###');
    //             console.log(v);
    //             rows.forEach((f:any) => {
    //                 if(_isEmpty(f.prod_no)) {
    //                   f.prod_no = v.prod_no;  // 수선품 번호 매핑
    //                   addRepr(f);
    //                 }
    //             });
    //             return;
    //           }).catch((e) => console.log(e))
    //           .then(() => {  // 수선품 목록 변경
    //             let body = {
    //               rcpt_no:v.rcpt_no
    //             }
    //             dispatch({ type: 'PROD_GET', payload: [] });
    //             return getProd(body).then((d) => dispatch(d));
    //           }).catch((e) => console.log(e))
    //           .then(() => {  // 접수 목록 변경
    //             dispatch({ type: 'RCPT_GET', payload: [] });
    //             return getRcpt(common.search).then((d) => dispatch(d));
    //           }).catch((e) => console.log(e))
    //           .then(() => {
    //             common.msg = '저장에 성공 하였습니다.';
    //             common.isMsg = true;        
    //             return dispatch({type:'COM_ALERT'});       // 안내메시지
    //           }).catch((e) => {
    //             console.log(e);
    //             common.msg = '저장에 실패 하였습니다.';
    //             common.isMsg = true;        
    //             return dispatch({type:'COM_ALERT'});       // 안내메시지 
    //           });
  }, [receipt, repair, common, dispatch]);

  //@ 수정
  const handleUpdate = useCallback((v) => {
    // 21.08.17 추가 (요청사항 : 수선품작성 페이지 한정으로 '수정'시 '최초작성자'변경되게끔 수정)
    let user_name = window.sessionStorage.getItem('user_name');
    v['reg_user'] = user_name
    // 21.08.17

    // 수선품 업데이트
    updProd(v).then(async (res) => {
      v.prod_no = res.payload.prod_no; // 수선품번호
      // 정산 업데이트
      if (_isEmpty(payment.info)) {
        addPay(v).catch((e) => console.log(e));
      } else {
        updPay(v).catch((e) => console.log(e));
      }
      // 수선품 카테고리 수정
      if (_isEmpty(productCate.rows)) {
        let rows = _setProdCate(v, '');
        rows.forEach((c: any) => addProdCate(c));
      } else {
        let rows = _setProdCate(v, productCate.rows);
        rows.forEach((c: any) => updProdCate(c));
      }
      // 작업지시서 삭제
      let remove = repair.remove;
      for (let id of remove) {
        let body = {
          repr_no: id
        }
        delRepr(body).then((d) => {
          dispatch(d)
        }).catch((e) => console.log(e));
      }
      // remove.forEach((id: any) => {
      //   let body = {
      //     repr_no: id
      //   }
      //   delRepr(body).catch((e) => console.log(e));
      // });
      // 작업지시서 업데이트
      let rows = repair.rows;
      for (let f of rows) {
        if (_isEmpty(f.repr_no)) {   // 신규
          if (_isEmpty(f.prod_no)) {
            f.prod_no = v.prod_no;  // 수선품 번호 매핑
            await addRepr(f).catch((e) => console.log(e));
          }
        } else {                    // 수정
          await updRepr(f).catch((e) => console.log(e));
        }
        sleep(5);
      }
      // rows.forEach((f: any) => {
      //   if (_isEmpty(f.repr_no)) {   // 신규
      //     if (_isEmpty(f.prod_no)) {
      //       f.prod_no = v.prod_no;  // 수선품 번호 매핑
      //       addRepr(f).catch((e) => console.log(e));
      //     }
      //   } else {                    // 수정
      //     updRepr(f).catch((e) => console.log(e));
      //   }
      //   sleep(5);
      // });

      // 접수 목록 초기화
      let body: any = {};
      // if (!_isEmpty(receipt.srch.st_dt)) body["st_dt"] = _setUTCDate(receipt.srch.st_dt);
      // if (!_isEmpty(receipt.srch.ed_dt)) body["ed_dt"] = _setUTCDate(receipt.srch.ed_dt);
      // if (!_isEmpty(receipt.srch.rcpt_stat)) body["rcpt_stat"] = receipt.srch.rcpt_stat;
      // if (!_isEmpty(receipt.srch.rcpt_chnl)) body["rcpt_chnl"] = receipt.srch.rcpt_chnl;
      // if (!_isEmpty(receipt.srch.srch_val)) body[receipt.srch.srch_key.toString()] = receipt.srch.srch_val;
      // if (!_isEmpty(receipt.srch.limit)) body["limit"] = receipt.srch.limit;
      body['limit'] = '1000';

      getRcpt(body)
        .then((d) => {
          dispatch(d);
        })
        .catch((e) => console.log(e));
      // getRcpt(receipt.search).then((d) => dispatch(d));
      // 수선품 목록 초기화
      let bodys = {
        rcpt_no: v.rcpt_no
      }
      getProd(bodys).then((d) => {
        dispatch(d);
        common.msg = '수정에 성공 하였습니다.';
        common.isMsg = true;
        dispatch({ type: 'COM_ALERT' });       // 안내메시지                
        dispatch({ type: 'PROD_ADD' });
      });

    }).catch(() => {
      common.msg = '수정에 실패 하였습니다.';
      common.isMsg = true;
      return dispatch({ type: 'COM_ALERT' });       // 안내메시지
    });

    // updProd(v).then((d) => dispatch(d))        // 수선품 수정
    //           .then((p) => {                   // 정산 수정
    //             console.log(' ### addPay update ###');
    //             console.log(p);
    //             v.prod_no = p.payload.prod_no;

    //             if(_isEmpty(payment.info)) {
    //               addPay(v);
    //             } else {
    //               updPay(v);
    //             }
    //             return;
    //           }).catch((e) => console.log(e))
    //           .then(() => {                   // 수선품 카테고리 수정
    //             console.log(' ### addProdCate update ###');
    //             console.log(productCate);
    //             console.log(' ###// addProdCate update ###');
    //             if(_isEmpty(productCate.rows)) {
    //               let rows = _setProdCate(v, '');
    //               rows.forEach((v:any) => addProdCate(v));
    //             } else {
    //               let rows = _setProdCate(v, productCate.rows);     
    //               rows.forEach((c:any) => updProdCate(c));
    //             }
    //             return;
    //           }).catch((e) => console.log(e))
    //           .then(() => {                   // 작업지시서 수정
    //             let rows = repair.rows;
    //             console.log(' ### addRepr update ###');
    //             console.log(v);

    //             rows.forEach((f:any) => {
    //               if(_isEmpty(f.repr_no)) {   // 신규
    //                 if(_isEmpty(f.prod_no)) {
    //                   f.prod_no = v.prod_no;  // 수선품 번호 매핑
    //                   addRepr(f);
    //                 }
    //               } else {                    // 수정
    //                 updRepr(f);
    //               }
    //             });
    //             return;
    //           }).catch((e) => console.log(e))
    //           .then(() => {  // 수선품 목록 변경
    //             let body = {
    //               rcpt_no:v.rcpt_no
    //             }
    //             dispatch({ type: 'PROD_GET', payload: [] });
    //             return getProd(body).then((d) => dispatch(d));
    //           }).catch((e) => console.log(e))
    //           .then(() => {  // 접수 목록 변경
    //             dispatch({ type: 'RCPT_GET', payload: [] });
    //             return getRcpt(common.search).then((d) => dispatch(d));
    //           }).catch((e) => console.log(e))              
    //           .then(() => {
    //             common.msg = '수정에 성공 하였습니다.';
    //             common.isMsg = true;        
    //             return dispatch({type:'COM_ALERT'});       // 안내메시지
    //           }).catch(() => {
    //             common.msg = '수정에 실패 하였습니다.';
    //             common.isMsg = true;        
    //             return dispatch({type:'COM_ALERT'});       // 안내메시지
    //           });
  }, [receipt, payment, productCate, repair, common, dispatch]);

  // [init] ######################################################################
  //@ 최초 실행
  useEffect(() => {
    handleinit();
  }, [handleinit]);

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          //# 수선품
          prod_no: _isEmpty(product.info) ? '' : product.info.prod_no,             // 수선품번호 
          rcpt_no: _isEmpty(product.info) ? '' : product.info.rcpt_no,             // 접수번호
          brand_cd: _isEmpty(product.info) ? '' : product.info.brand_cd,           // 브랜드
          cate_cd: _isEmpty(product.info) ? '' : product.info.cate_cd,             // 품목
          style_cd: _isEmpty(product.info) ? '' : product.info.style_cd,           // 모델
          org_no: _isEmpty(product.info) ? '01' : product.info.org_no,             // 지점번호
          rcpt_name: _isEmpty(product.info) ? '' : product.info.rcpt_name,         // 접수자명
          cust_no: _isEmpty(product.info) ? '' : product.info.cust_no,             // 고객번호
          repr_st_dt: _isEmpty(product.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(product.info.repr_st_dt, '-'),     // 입고일
          repr_req_dt: _isEmpty(product.info) ? _setReqDateYYYYMMDD('', '-') : _setDateYYYYMMDD(product.info.repr_req_dt, '-'),  // 출고요청일
          // check_ed_dt: _isEmpty(product.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(product.info.check_ed_dt, '-'),  // 검수완료일 (검수자 자동)
          // repr_ed_dt: _isEmpty(product.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(product.info.repr_ed_dt, '-'),     // 출고예정일 (출고자 자동)
          rcpt_chnl: _isEmpty(product.info) ? '' : product.info.rcpt_chnl,         // 접수경로 (G006)
          is_dust_bag: _isEmpty(product.info) ? false : product.info.is_dust_bag,  // 더스트백여부
          send_typ: _isEmpty(product.info) ? '01' : product.info.send_typ,           // 발송방식 (G007)
          send_no: _isEmpty(product.info) ? '' : product.info.send_no,             // 송장번호
          // etc_cont: _isEmpty(product.info) ? '' : product.info.etc_cont,           // 기타사항   HSH 2020-12-23 투엘 요청사항
          req_cont: _isEmpty(product.info) ? '' : product.info.req_cont,           // 고객요청사항
          prod_stat: _isEmpty(product.info) ? '01' : product.info.prod_stat,       // 처리상태 (G008)

          // 카테고리
          cate_01: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_01, // 브랜드 (0100000000)
          cate_02: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_02, // 품목 (0200000000)
          cate_03: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_03, // 품목(중분류) (0201000000)
          cate_04: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_04, // 품목(소분류) (0201010000)
          cate_05: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_05, // 스타일 (0300000000)
          cate_06: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_06, // 스타일2 (0300000000)
          cate_07: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_07, // 소재 (0400000000)
          cate_08: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_08, // 소재(중분류) (0401000000)
          cate_09: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_09, // 소재2 (0400000000)
          cate_10: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_10, // 소재2(중분류) (0401000000)
          cate_11: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_11, // 소재3 (0400000000)
          cate_12: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_12, // 소재3(중분류) (0401000000)
          cate_13: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_13, // 색상 (0500000000)
          cate_14: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_14, // 색상2 (0500000000)
          cate_15: _isEmpty(productCate.categorys) ? '' : productCate.categorys.cate_15, // 모델 (1000000000)      

          //# 정산관리
          calc_no: _isEmpty(payment.info) ? '' : payment.info.calc_no,                   // 정산번호
          calc_typ: _isEmpty(payment.info) ? '01' : payment.info.calc_typ,               // 납입방식 (G011)
          calc_acc: _isEmpty(payment.info) ? '01' : payment.info.calc_acc,               // 납입계좌 (G012)
          tot_pay: _isEmpty(payment.info) ? '0' : payment.info.tot_pay,                       // 수선총비용
          frst_pay: _isEmpty(payment.info) ? '0' : payment.info.frst_pay,                    // 선금비용
          frst_pay_dtime: _isEmpty(payment.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(payment.info.frst_pay_dtime, '-'),  // 선금납입일
          last_pay: _isEmpty(payment.info) ? _setAddComma('0') : _setAddComma(payment.info.last_pay),                    // 잔금비용
          last_pay_dtime: _isEmpty(payment.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(payment.info.last_pay_dtime, '-'),  // 잔금납입일
          calc_stat: _isEmpty(payment.info) ? '01' : payment.info.calc_stat              // 처리상태 (G013) 
        }}
        validationSchema={Yup.object().shape({
          org_no: Yup.string().required('지점을 선택해주세요'),
          rcpt_chnl: Yup.string().required('접수경로를 선택해주세요'),
          repr_st_dt: Yup.string().required('입고일을 선택해주세요'),
          cate_01: Yup.string().required('브랜드를 선택해주세요'),
          cate_cd: Yup.string().required('수선종류를 선택해주세요'),
          cate_02: Yup.string().required('품목을 선택해주세요'),
          calc_typ: Yup.string().required('납입방식을 선택해주세요'),
          calc_acc: Yup.string().required('납입계좌를 선택해주세요'),
          tot_pay: Yup.number().required('수선금을 입력해주세요').typeError('숫자로 입력해 주세요'),
          frst_pay: Yup.number().required('납부액을 입력해주세요').typeError('숫자로 입력해 주세요')
        })}
        onSubmit={(v) => {
          // console.log(v);
          v.rcpt_no = receipt.info.rcpt_no;      // 접수번호
          v.rcpt_name = receipt.info.cust_name;  // 고객명
          v.cust_no = receipt.info.cust_no;      // 고객번호
          v.brand_cd = v.cate_01;                // 브랜드
          // v.cate_cd = v.cate_02;                 // 품목

          // 콤마 데이터 포멧 변경
          v.tot_pay = _setRemoveComma(v.tot_pay);
          v.frst_pay = _setRemoveComma(v.frst_pay);
          v.last_pay = _setRemoveComma(v.last_pay);

          if (Number(v.frst_pay) === 0) {                    // 미납
            v.calc_stat = '01'
          } else {
            if (Number(v.frst_pay) === Number(v.tot_pay)) {  // 완납
              v.calc_stat = '04'
            } else {                                        // 선납
              v.calc_stat = '02'
            }
          }


          // UTC 포멧 변경
          v.repr_st_dt = _setUTCDate(v.repr_st_dt);          // 입고일
          v.repr_req_dt = _setUTCDate(v.repr_req_dt);        // 출고요청일
          v.frst_pay_dtime = _setUTCDate(v.frst_pay_dtime);  // 선금납입일
          v.last_pay_dtime = _setUTCDate(v.last_pay_dtime);  // 잔금납입일

          if (product.isEdit) {  // 수정
            handleUpdate(v);
          } else {              // 등록
            handleAdd(v);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
          <form onSubmit={handleSubmit}>
            {/*############################## 수선품 ##############################*/}
            <Box ml={3} mr={3} mb={1} mt={1} borderTop={2} borderColor="primary.main" justifyContent="center">
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}>
                {/* 지점 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='org_no'
                    label='지점'
                    value={values.org_no}
                    item={_setCodeFilter(common.code, 'G001')}
                    errors={errors.org_no}
                    touched={touched.org_no}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 접수경로 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='rcpt_chnl'
                    label='접수경로'
                    value={values.rcpt_chnl}
                    item={_setCodeFilter(common.code, 'G006')}
                    errors={errors.rcpt_chnl}
                    touched={touched.rcpt_chnl}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 입고일 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormDate
                    id='repr_st_dt'
                    label='입고일'
                    value={values.repr_st_dt}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 출고요청일 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormDate
                    id='repr_req_dt'
                    label='출고요청일'
                    value={values.repr_req_dt}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 발송방식 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='send_typ'
                    label='발송방식'
                    value={values.send_typ}
                    item={_setCodeFilter(common.code, 'G007')}
                    errors={errors.send_typ}
                    touched={touched.send_typ}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 더스트백여부 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormOption
                    id='is_dust_bag'
                    label='더스트백여부'
                    value={values.is_dust_bag}
                    item={[{ label: '아니오', value: false }, { label: '예', value: true }]}
                    errors={errors.send_typ}
                    touched={touched.send_typ}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 기타사항 HSH 2020-12-23 투엘 요청사항 */}
                {/* <Grid item xs={12}>
                      <FormText 
                        id='etc_cont' 
                        label='기타사항' 
                        value={values.etc_cont}
                        required={false}                   
                        blur={handleBlur} 
                        change={handleChange} />   
                    </Grid> */}
                {/* 고객요청사항 -> 고객안내사항 20201223-HSH -> 내역 20210608 */}
                <Grid item xs={12}>
                  <FormText
                    id='req_cont'
                    label='내역'
                    value={values.req_cont}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
              </Grid>
            </Box>
            {/*############################## 카테고리 ##############################*/}
            <Box ml={3} mr={3} mb={1} mt={1} borderTop={2} borderColor="primary.main" justifyContent="center">
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}>
                {/* 브랜드 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='cate_01'
                    label='브랜드'
                    value={values.cate_01}
                    item={_setCateCodeFilter2(common.category, '0100000000')}
                    errors={errors.cate_01}
                    touched={touched.cate_01}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                <Grid item xs={6}>
                  <FormSelect
                    id='cate_cd'
                    label='수선종류'
                    value={values.cate_cd}
                    item={_setCodeFilter(common.code, 'G025')}
                    errors={errors.cate_01}
                    touched={touched.cate_01}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 품목 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='cate_02'
                    label='품목'
                    value={values.cate_02}
                    item={_setCateCodeFilter(common.category, '0200000000')}
                    errors={errors.cate_02}
                    touched={touched.cate_02}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 품목(중분류) */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='cate_03'
                    label='품목(중분류)'
                    value={values.cate_03}
                    item={_setCateCodeFilter(common.category, values.cate_02)}
                    firstDefault={true}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 품목(소분류) */}
                {/* <Grid item xs={4} className={classes.grid}>
                      <FormSelect
                        id='cate_04' 
                        label='품목(소분류)' 
                        value={values.cate_04}
                        item={_setCateCodeFilter(common.category, values.cate_03)}
                        firstDefault={true}
                        required={false}                   
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid> */}
                {/* 스타일 */}
                {/* <Grid item xs={6} className={classes.grid}>
                      <FormSelect
                        id='cate_05' 
                        label='스타일' 
                        value={values.cate_05}
                        item={_setCateCodeFilter(common.category, '0300000000')}
                        firstDefault={true}
                        required={false}                   
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid> */}
                {/* 스타일2 */}
                {/* <Grid item xs={6} className={classes.grid}>
                      <FormSelect
                        id='cate_06' 
                        label='스타일2' 
                        value={values.cate_06}
                        item={_setCateCodeFilter(common.category, '0300000000')}
                        firstDefault={true}
                        required={false}                   
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid> */}
                {/* 소재 */}
                <Grid item xs={6} className={classes.grid}>     
                  <FormSelect
                    id='cate_07'
                    label='소재'
                    value={values.cate_07}
                    item={_setCateCodeFilter(common.category, '0400000000')}
                    firstDefault={true}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 소재(중분류) */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='cate_08'
                    label='소재(중분류)'
                    value={values.cate_08}
                    item={_setCateCodeFilter(common.category, values.cate_07)}
                    firstDefault={true}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 모델명 */}     
                  <Grid item xs={6} className={classes.grid}>  
                  <FormSelect
                    id='style_cd'
                    label='모델'
                    value={values.style_cd}
                    item={_setCateCodeFilter2(common.category, '1000000000')}
                    firstDefault={true}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                  </Grid> 
                {/* 소재2 */}
                {/* <Grid item xs={6} className={classes.grid}>
                      <FormSelect
                        id='cate_09' 
                        label='소재2' 
                        value={values.cate_09}
                        item={_setCateCodeFilter(common.category, '0400000000')}
                        firstDefault={true}
                        required={false}                   
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid> */}
                {/* 소재2(중분류) */}
                {/* <Grid item xs={6} className={classes.grid}>
                      <FormSelect
                        id='cate_10' 
                        label='소재2(중분류)' 
                        value={values.cate_10}
                        item={_setCateCodeFilter(common.category, values.cate_09)}
                        firstDefault={true}
                        required={false}                   
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid> */}
                {/* 소재3 */}
                {/* <Grid item xs={6} className={classes.grid}>
                      <FormSelect
                        id='cate_11' 
                        label='소재3' 
                        value={values.cate_11}
                        item={_setCateCodeFilter(common.category, '0400000000')}
                        firstDefault={true}
                        required={false}                   
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid> */}
                {/* 소재3(중분류) */}
                {/* <Grid item xs={6} className={classes.grid}>
                      <FormSelect
                        id='cate_12' 
                        label='소재3(중분류)' 
                        value={values.cate_12}
                        item={_setCateCodeFilter(common.category, values.cate_11)}
                        firstDefault={true}
                        required={false}                   
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid> */}

                {/* 색상 */}
                <Grid item xs={6} className={classes.grid}>       
                  <FormSelect
                    id='cate_13'
                    label='색상'
                    value={values.cate_13}
                    item={_setCateCodeFilter(common.category, '0500000000')}
                    firstDefault={true}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 색상2 */}
                {/* <Grid item xs={6} className={classes.grid}>
                      <FormSelect
                        id='cate_14' 
                        label='색상2' 
                        value={values.cate_14}
                        item={_setCateCodeFilter(common.category, '0500000000')}
                        firstDefault={true}
                        required={false}                   
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid> */}
              </Grid>
            </Box>
            {/*############################## 정산 ##############################*/}
            <Box ml={3} mr={3} mb={1} mt={1} borderTop={2} borderBottom={2} borderColor="primary.main" justifyContent="center">
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}>
                {/* 납입방식 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='calc_typ'
                    label='납입방식'
                    value={values.calc_typ}
                    item={_setCodeFilter(common.code, 'G011')}
                    errors={errors.calc_typ}
                    touched={touched.calc_typ}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 납입계좌 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='calc_acc'
                    label='납입계좌'
                    value={values.calc_acc}
                    item={_setCodeFilter(common.code, 'G012')}
                    errors={errors.calc_acc}
                    touched={touched.calc_acc}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 수선금 */}
                <Grid item xs={6}>
                  <FormText
                    id='tot_pay'
                    label='수선금'
                    value={values.tot_pay}
                    errors={errors.tot_pay}
                    touched={touched.tot_pay}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 미수금 */}
                <Grid item xs={6}>
                  <FormText
                    id='last_pay'
                    label='미수금'
                    value={_getPayDiff(values.tot_pay, values.frst_pay)}
                    disabled={true}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 납부액 */}
                <Grid item xs={6}>
                  <FormText
                    id='frst_pay'
                    label='납부액'
                    value={values.frst_pay}
                    errors={errors.frst_pay}
                    touched={touched.frst_pay}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 납입일 */}
                <Grid item xs={6}>
                  <FormDate
                    id='frst_pay_dtime'
                    label='납입일'
                    value={values.frst_pay_dtime}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 잔금납입일 */}
                {/* <Grid item xs={6}>
                        <FormDate 
                          id='last_pay_dtime' 
                          label='잔금납입일' 
                          value={values.last_pay_dtime}
                          required={false}                
                          blur={handleBlur} 
                          change={handleChange} />
                    </Grid> */}
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
});

export default ProductView;
