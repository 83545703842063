
/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : login layout page
**/
/* @material import */
import { makeStyles } from '@material-ui/core';
/* react import */
import React from 'react';
import { Outlet } from 'react-router-dom';
/* service import */
import Footer from './LoginFooter';
import Header from './LoginHeader';

/* styles */
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'hidden'
  }
}));

/* component */
const LoginLayout = () => {
  const classes = useStyles();  // 스타일
  return (
    <div className={classes.root}>       
      <div className={classes.wrapper}>          
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Header /> 
            <Outlet />                
            <Footer />
          </div>
        </div>                                
      </div>
    </div>
  );
};

export default LoginLayout;
