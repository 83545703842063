/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux store combined
**/
import { combineReducers } from "redux";
import botGaly from "./BotGalyReducer";
import botProd from "./BotProdReducer";
import botRcpt from "./BotRcptReducer";
import botSrch from "./BotSrchReducer";
import category from "./CateReducer";
import common from "./ComReducer";
import consult from "./ConsultReducer";
import customer from "./CustReducer";
import gallery from "./GallyReducer";
import image from "./ImgReducer";
import inspector from "./InsptReducer";
import mender from "./MndrReducer";
import payment from "./PayReducer";
import productCate from "./ProdCateReducer";
import product from "./ProdReducer";
import receipt from "./RcptReducer";
import repairCate from "./ReprCateReducer";
import repair from "./ReprReducer";
import user from "./UserReducer";
import users from "./UsersReducer";

const rootReducer = combineReducers({
  common,      // 공통
  user,        // 사용자
  category,    // 카테고리
  consult,     // 상담
  product,     // 수선품
  gallery,     // 사진
  receipt,     // 접수
  customer,    // 고객
  payment,     // 정산
  productCate, // 수선품 카테고리
  repair,      // 수선
  repairCate,  // 수선카테고리
  mender,      // 작업자
  inspector,   // 검수자
  users,       // 사용자 관리
  botProd,     // 챗봇상품
  botGaly,     // 챗봇사진
  botSrch,     // 챗봇수선품검색 로그
  botRcpt,     // 챗봇수거접수 로그
  image        // 이미지
});

export default rootReducer;