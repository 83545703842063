/* @material import */
import {
  Link, makeStyles,

  Typography
} from '@material-ui/core';
/* react import */
import React from 'react';

/* styles */
const useStyles = makeStyles(({
  root: {},    
  footer: {
    // padding: theme.spacing(2),
    background: "#eaeff1",
  },
}));

/* component */
const LoginFooter = () => {
  const classes = useStyles();
  return (
      <footer className={classes.footer}>
          <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="https://material-ui.com/">
              Your Tuel
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
          </Typography>
      </footer>
  );
};

export default LoginFooter;