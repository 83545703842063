/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux types
**/
// 인증
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

// 카테고리
export const CATE_GET = "CATE_GET";
export const CATE_ADD = "CATE_ADD";
export const CATE_ADD_STAT = "CATE_ADD_STAT";
export const CATE_ADD_GET = "CATE_ADD_GET";
export const CATE_DEL = "CATE_DEL";
export const CATE_FAIL = "CATE_FAIL";
export const CATE_DEL_RESET = "CATE_DEL_RESET";

// 고객관리
export const CUST_GET = "CUST_GET";
export const CUST_GET_INFO = "CUST_GET_INFO";
export const CUST_ADD = "CUST_ADD";
export const CUST_UPD = "CUST_UPD";
export const CUST_SEND = "CUST_SEND";
export const CUST_UPD_STAT = "CUST_UPD_STAT";
export const CUST_FAIL = "CUST_FAIL";

// 상담관리
export const CNSLT_GET = "CNSLT_GET";
export const CNSLT_GET_INFO = "CNSLT_GET_INFO";
export const CNSLT_ADD = "CNSLT_ADD";
export const CNSLT_UPD = "CNSLT_UPD";
export const CNSLT_FAIL = "CNSLT_FAIL";

// 접수관리
export const RCPT_GET = "RCPT_GET";
export const RCPT_GET_INFO = "RCPT_GET_INFO";
export const RCPT_ADD = "RCPT_ADD";
export const RCPT_UPD = "RCPT_UPD";
export const RCPT_SEND = "RCPT_SEND";
export const RCPT_UPD_STAT = "RCPT_UPD_STAT";
export const RCPT_FAIL = "RCPT_FAIL";

// 수선품관리
export const PROD_GET = "PROD_GET";
export const PROD_DEL = "PROD_DEL";
export const PROD_GET_INFO = "PROD_GET_INFO";
export const PROD_GET_SRCH = "PROD_GET_SRCH";
export const PROD_GET_CHK = "PROD_GET_CHK";
export const PROD_ADD = "PROD_ADD";
export const PROD_DASHBOARD = "PROD_DASHBOARD";
export const PROD_UPD = "PROD_UPD";
export const PROD_SEND = "PROD_SEND";
export const PROD_FAIL = "PROD_FAIL";

// 정상관리
export const PAY_GET = "PAY_GET";
export const PAY_DEL = "PAY_DEL";
export const PAY_GET_INFO = "PAY_GET_INFO";
export const PAY_ADD = "PAY_ADD";
export const PAY_UPD = "PAY_UPD";
export const PAY_FAIL = "PAY_FAIL";

// 수선품 카테고리
export const PROD_CATE_GET = "PROD_CATE_GET";
export const PROD_CATE_DEL = "PROD_CATE_DEL";
export const PROD_CATE_GET_INFO = "PROD_CATE_GET_INFO";
export const PROD_CATE_ADD = "PROD_CATE_ADD";
export const PROD_CATE_UPD = "PROD_CATE_UPD";
export const PROD_CATE_FAIL = "PROD_CATE_FAIL";

// 수선
export const REPR_GET = "REPR_GET";
export const REPR_DEL = "REPR_DEL";
export const REPR_GET_INFO = "REPR_GET_INFO";
export const REPR_GET_TEMP = "REPR_GET_TEMP";
// export const REPR_GET_SRCH = "REPR_GET_SRCH";
export const REPR_ADD = "REPR_ADD";
export const REPR_UPD = "REPR_UPD";
export const REPR_FAIL = "REPR_FAIL";

// 이미지
export const IMG_GET = "IMG_GET";
export const IMG_DEL = "IMG_DEL";
export const IMG_GET_INFO = "IMG_GET_INFO";
export const IMG_GET_URL = "IMG_GET_URL";
export const IMG_ADD = "IMG_ADD";
export const IMG_UPD = "IMG_UPD";
export const IMG_FAIL = "IMG_FAIL";

// 수선 카테고리
export const REPR_CATE_GET = "REPR_CATE_GET";
export const REPR_CATE_GET_INFO = "REPR_CATE_GET_INFO";
export const REPR_CATE_ADD = "REPR_CATE_ADD";
export const REPR_CATE_UPD = "REPR_CATE_UPD";
export const REPR_CATE_FAIL = "REPR_CATE_FAIL";

// 수선 작업자
export const MNDR_GET = "MNDR_GET";
export const MNDR_GET_INFO = "MNDR_GET_INFO";
export const MNDR_ADD = "MNDR_ADD";
export const MNDR_UPD = "MNDR_UPD";
export const MNDR_FAIL = "MNDR_FAIL";

// 수선 검수자
export const INSPT_GET = "INSPT_GET";
export const INSPT_GET_INFO = "INSPT_GET_INFO";
export const INSPT_ADD = "INSPT_ADD";
export const INSPT_UPD = "INSPT_UPD";
export const INSPT_FAIL = "INSPT_FAIL";

// 사진관리
export const GALLY_GET = "GALLY_GET";
export const GALLY_GET_INFO = "GALLY_GET_INFO";
export const GALLY_GET_TAG = "GALLY_GET_TAG";
export const GALLY_ADD = "GALLY_ADD";
export const GALLY_UPD = "GALLY_UPD";
export const GALLY_DEL = "GALLY_DEL";
export const GALLY_FAIL = "GALLY_FAIL";

// 챗봇-상품관리
export const BOT_PROD_GET = "BOT_PROD_GET";
export const BOT_PROD_DEL = "BOT_PROD_DEL";
export const BOT_PROD_GET_INFO = "BOT_PROD_GET_INFO";
export const BOT_PROD_ADD = "BOT_PROD_ADD";
export const BOT_PROD_UPD = "BOT_PROD_UPD";
export const BOT_PROD_FAIL = "BOT_PROD_FAIL";

// 챗봇-사진관리
export const BOT_GALY_GET = "BOT_GALY_GET";
export const BOT_GALY_DEL = "BOT_GALY_DEL";
export const BOT_GALY_GET_INFO = "BOT_GALY_GET_INFO";
export const BOT_GALY_ADD = "BOT_GALY_ADD";
export const BOT_GALY_UPD = "BOT_GALY_UPD";
export const BOT_GALY_FAIL = "BOT_GALY_FAIL";

// 챗봇-수선품검색 로그
export const BOT_SRCH_GET = "BOT_SRCH_GET";
export const BOT_SRCH_GET_INFO = "BOT_SRCH_GET_INFO";
export const BOT_SRCH_FAIL = "BOT_SRCH_FAIL";

// 챗봇-수거접수 로그
export const BOT_RCPT_GET = "BOT_RCPT_GET";
export const BOT_RCPT_GET_INFO = "BOT_RCPT_GET_INFO";
export const BOT_RCPT_FAIL = "BOT_RCPT_FAIL";

// 공통
export const COM_ALERT = "COM_ALERT";
export const COM_CONFIRM = "COM_CONFIRM";
export const COM_CATAGORY = "COM_CATAGORY";
export const COM_SEARCH = "COM_SEARCH";
export const COM_DASHBOARD = "COM_DASHBOARD";
export const COM_CUST_SEARCH = "COM_CUST_SEARCH";
export const COM_CODE = "COM_CODE";
export const COM_CONSULT = "COM_CONSULT";
export const COM_IMAGE = "COM_IMAGE";
export const COM_STORE_CLEAR = "COM_STORE_CLEAR";
export const COM_TABS_LOCATION = "COM_TABS_LOCATION";
export const COM_FAIL = "COM_FAIL";

// 사용자
export const USERS_LST = "USERS_LST";
export const USERS_GET = "USERS_GET";
export const USERS_GET_INFO = "USERS_GET_INFO";
export const USERS_ADD = "USERS_ADD";
export const USERS_DEL = "USERS_DEL";
export const USERS_UDP = "USERS_UDP";
export const USERS_FAIL = "USERS_FAIL";
export const USERS_SEARCH = "USERS_SEARCH";

// 엑셀 다운로드
export const DOWNLOAD_FAIL = "DOWNLOAD_FAIL";

// 카카오 전송
export const KAKAO_SEND = "KAKAO_SEND";
export const KAKAO_FAIL = "KAKAO_FAIL"