/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Form Date
**/
/* @material import */
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
/* React import */
import React from "react";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
    height: '90%',
    width: '90%',
    maxHeight: '90%',
    minWidth: '90%',
    fontSize: '0.1em',
    size: 'small'
  },
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const FormDate = (props: any) => {
  const classes = useStyles();     // CSS

  // [Render] ######################################################################  
  return (
    <TextField
      className={classes.root}
      id={props.id}
      name={props.id}
      label={props.label}
      type="date"
      required={props.required}
      disabled={props.disabled}
      value={props.value || ''}
      InputLabelProps={{
        shrink: true,
      }}
      error={Boolean(props.touched && props.errors)}
      helperText={props.touched && props.errors}
      onBlur={props.blur}
      onChange={props.change}
    />

  );
};

export default FormDate;