/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux chatbot search products log Action
**/
import { _ajaxPostJsonAsync } from "../../axios/axios";
import {
  BOT_SRCH_FAIL,
  BOT_SRCH_GET,
  BOT_SRCH_GET_INFO
} from "../types";

/* 챗봇 수선품 검색 로그 경로 정보 */
const API_PATH = "/api/v1/botSearches";

/* 챗봇 수선품 검색 로그 전체 조회 */
export const getBotSrch = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, BOT_SRCH_GET, BOT_SRCH_FAIL);
}

/* 챗봇 수선품 검색 로그 개별 조회 */
export const getBotSrchInfo = async (body:any) => {
  // ajax 통신
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, BOT_SRCH_GET_INFO, BOT_SRCH_FAIL);
}
