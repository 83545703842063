/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux chatbot gallery Action
**/
import { _ajaxPostJsonAsync } from "../../axios/axios";
import {
  BOT_GALY_ADD,
  BOT_GALY_DEL,
  BOT_GALY_FAIL,
  BOT_GALY_GET,
  BOT_GALY_GET_INFO,
  BOT_GALY_UPD
} from "../types";

/* 챗봇 상품사진 경로 정보 */
const API_PATH = "/api/v1/botGalleries";

/* 챗봇 상품사진 전체 조회 */
export const getBotGaly = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, BOT_GALY_GET, BOT_GALY_FAIL);
}

/* 챗봇 상품사진 개별 조회 */
export const getBotGalyInfo = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, BOT_GALY_GET_INFO, BOT_GALY_FAIL);
}

/* 챗봇 상품사진 등록 */
export const addBotGaly = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['reg_user'] = user_name;       // 최초작성자
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("post", API_PATH+"/", d, BOT_GALY_ADD, BOT_GALY_FAIL);
}

/* 챗봇 상품사진 수정 */
export const updBotGaly = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH+"/"+d.bot_galy_no, d, BOT_GALY_UPD, BOT_GALY_FAIL);
}

/* 챗봇 상품사진 삭제 */
export const delBotGaly = async (d:any) => {
  return await _ajaxPostJsonAsync("delete", API_PATH+"/"+d.bot_galy_no, d, BOT_GALY_DEL, BOT_GALY_FAIL);
}