/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux chatbot receipt log Action
**/
import { _ajaxPostJsonAsync } from "../../axios/axios";
import {
  BOT_RCPT_FAIL,
  BOT_RCPT_GET,
  BOT_RCPT_GET_INFO
} from "../types";

/* 챗봇 수거 접수 경로 정보 */
const API_PATH = "/api/v1/botReceipts";

/* 챗봇 수거 접수 전체 조회 */
export const getBotRcpt = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, BOT_RCPT_GET, BOT_RCPT_FAIL);
}

/* 챗봇 수거 접수 개별 조회 */
export const getBotRcptInfo = async (body:any) => {
  // ajax 통신
  body['limit'] = '1';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, BOT_RCPT_GET_INFO, BOT_RCPT_FAIL);
}
