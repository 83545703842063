/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Common Images page
**/
/* @material import */
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  DataGrid
} from '@material-ui/data-grid';
import { Close, DoubleArrow, Search } from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { _isEmpty, _setFormatDate, _setFormatDateandTime } from "../../utils/Common";
import { getImg } from "../../utils/redux/actions/ImgAction";
// import { XGrid, useGridApiRef } from '@material-ui/x-grid';

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
  },
  toolbar: {
    width: 1856,
    minHeight: 35,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  button: {
    margin: theme.spacing(1),
  },
  box: {
    width: 1800,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  box2: {
    width: 1800,
    height: 800,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  card: {
    width: 430,
    height: 430,
    margin: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper
  },
  CardMedia: {
    width: 430,
    height: 430,
  },
  datagrid: {
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    minWidth: '100%'
  },
  datagrid2: {
    height: '100%',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#9fa8da',
      color: '#ffffff'
    },
  },
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ComnImage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const image = useSelector((state: any) => state.image);      // image
  const common = useSelector((state: any) => state.common);    // common
  const botGaly = useSelector((state: any) => state.botGaly);  // botGaly
  const botProd = useSelector((state: any) => state.botProd);  // botGaly
  const consult = useSelector((state: any) => state.consult);  // consult
  const gallery = useSelector((state: any) => state.gallery);  // gallery
  // let GridApiRef = useGridApiRef();

  // [Events] ######################################################################
  //@ 고객검색
  const hendleSearch = useCallback((v) => {
    let body = {}; // param 구성
    image.row = {};
    if (!_isEmpty(v.search_text)) {
      body = { query: v.search_text };
    }
    getImg(body).then((v: any) => dispatch(v))
      .then((d) => {
        if (d.payload.length < 1) {
          common.msg = '검색 결과가 존재하지 않습니다.';
          common.isMsg = true;
          return dispatch({ type: 'COM_ALERT' });       // 안내메시지
        }
        else {
          // GridApiRef.current.setPage(1);
        }
      });

  }, [common, dispatch]);

  // //@ 초기화
  // const handleinit = useCallback(() => {
  //   // image.info = {};
  //   // image.rows = [];
  //   dispatch({ type: 'COM_IMAGE', payload: {} });
  //   getImg({}).then((v:any) => dispatch(v));  
  // },[dispatch]);

  //@ 챗봇 사진 선택
  const handleChange = useCallback((e) => {
    dispatch({ type: 'COM_IMAGE', payload: e.data });
  }, [dispatch]);

  //@ 팝업 닫기
  const handleClose = useCallback(() => {
    common.isImage = false;
    dispatch({
      type: 'COM_IMAGE',
      payload: common.imageInfo
    });
  }, [common, dispatch]);

  //@ 페이지 이동
  const handleAdd = useCallback(() => {

    if (_isEmpty(common.imageInfo)) {
      common.msg = '이미지를 선택해주세요';
      common.isMsg = true;
      dispatch({ type: 'COM_ALERT' });          // 안내메시지
    } else {
      if (common.imagePage === '01') {         // 상담
        consult.info.galy_url = common.imageInfo.image;
        dispatch({ type: 'CNSLT_GET_INFO', payload: consult.info });
      } else if (common.imagePage === '02') {  // 챗봇
        let tID = botProd.info.target_id;
        // let tVAL = botProd.info['image_'+tID+'_url'];
        let tNO = botProd.info['image_' + tID + '_no'];
        // 21.08.31 추가 : 이미지 초기 저장시 오류방지(botgaly_no가 없는데 수정을 할 경우)
        if (_isEmpty(tNO) && _isEmpty(botProd.info['image_' + tID])) {
          let body = {
            bot_galy_id: common.imageInfo.image_id,
            bot_galy_url: common.imageInfo.image
          }
          botGaly.rows.push(body);
          dispatch({ type: 'BOT_GALY_GET', payload: botGaly.rows });
        } else if (_isEmpty(tNO) && !_isEmpty(botProd.info['image_' + tID])) {
          for (let v of botGaly.rows) {
            if (v.bot_galy_id === botProd.info['image_' + tID]) {
              v.bot_galy_id = common.imageInfo.image_id
              v.bot_galy_url = common.imageInfo.image
            }
          }
          dispatch({ type: 'BOT_GALY_GET', payload: botGaly.rows });
          // 21.08.31
        } else {
          let body: any = [];
          botGaly.rows.forEach((v: any, i: number) => {
            if (v.bot_galy_no === tNO) {
              v['bot_galy_id'] = common.imageInfo.image_id;
              v['bot_galy_url'] = common.imageInfo.image;
            }
            body.push(v);
          });
          dispatch({ type: 'BOT_GALY_GET', payload: body });
        }
      } else if (common.imagePage === '03') {  // 이미지
        let body = {
          galy_id: common.imageInfo.image_id,
          galy_url: common.imageInfo.image
        }
        gallery.rows.push(body);
        dispatch({ type: 'GALLY_GET', payload: gallery.rows });
      }

      //# 고객검색창 닫기
      common.isImage = false;
      dispatch({
        type: 'COM_IMAGE',
        payload: common.imageInfo
      });
    }

  }, [consult, gallery, botProd, botGaly, common, dispatch]);

  // [init] ######################################################################
  //@ 최초 실행
  // useEffect(() => {   
  //   handleinit();
  // }, [handleinit]);

  // 데이트 타입 설정
  const _setDate = _setFormatDateandTime();

  //@ 초기 데이터 (Table 컬럼)
  const columns = [
    { field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
    { field: 'image_id', headerAlign: 'center', headerName: 'ID', width: 120, hide: true },
    { field: 'image', headerAlign: 'center', headerName: '이미지', width: 120, hide: true },
    { field: 'brand', headerAlign: 'center', headerName: '브랜드명', width: 180 },
    { field: 'customerName', headerAlign: 'center', headerName: '고객명', width: 90 },
    { field: 'category', headerAlign: 'center', headerName: '품목', width: 90 },
    { field: 'imgPosition', headerAlign: 'center', headerName: '촬영부위', width: 140 },
    { field: 'otherTag', headerAlign: 'center', headerName: '기타 태그', width: 300 },
    { field: 'repair1', headerAlign: 'center', headerName: '수선(대)', width: 120, hide: true },
    { field: 'repair2', headerAlign: 'center', headerName: '수선(중)', width: 120 },
    { field: 'repair3', headerAlign: 'center', headerName: '수선(소)', width: 200 },
    { field: 'isAltered', headerAlign: 'center', headerName: '유형', width: 90 },
    { field: 'altPosition', headerAlign: 'center', headerName: '수선위치', width: 140 },
    { field: 'dtime', headerAlign: 'center', headerName: '사진등록일', width: 160, ..._setDate },
    { field: 'check_ed_dt', headerAlign: 'center', headerName: '비용', width: 120, hide: true },
    { field: 'url', headerAlign: 'center', headerName: 'URL', width: 120, hide: true }
  ]
  // [Render] ######################################################################  
  return (
    <Dialog
      className={classes.root}
      open={common.isImage}
      onClose={handleClose}
      fullWidth
      maxWidth='xl'
      aria-labelledby="form-dialog-title">
      {/* 타이틀 */}
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={11}>
            <Typography variant="h6">이미지검색</Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid
              container
              justify="center"
              alignItems="center"
            >
              <Grid item xs={8}></Grid>
              <Grid item xs={4}>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      {/* 검색 */}
      <Box
        mt={1}
        display="flex"
        justifyContent="flex-end"
        className={classes.box}
      >
        <Formik
          enableReinitialize
          initialValues={{
            search_text: ''
          }}
          // validationSchema={}                  
          onSubmit={(v) => {
            hendleSearch(v);  // 검색
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                justify="center"
              >
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <InputBase
                      className={classes.input}
                      placeholder="검색어를 입력해주세요"
                      id='search_text'
                      name='search_text'
                      value={values.search_text}
                      type='text'
                      // error={Boolean(touched.search_text && errors.search_text)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      aria-label="search">
                      <Search />
                    </IconButton>
                  </Paper>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
      {/* 검색결과 */}
      <Box
        mt={1}
        display="flex"
        justifyContent="center"
        className={classes.box2}
      >
        <div
          className={classes.datagrid}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.datagrid}
          >
            {/* 이미지뷰 */}
            <Grid
              item xs={3}
              className={classes.datagrid2}
            >
              <Card
                className={classes.card}
              >
                {!_isEmpty(common.imageInfo) ?
                  <CardActionArea
                    onClick={() => window.open(common.imageInfo.image, '_blank')}
                  >
                    <CardMedia
                      className={classes.CardMedia}
                      image={common.imageInfo.image}
                      title="수선품이미지2"
                    />
                  </CardActionArea>
                  :
                  <CardActions>
                  </CardActions>
                }
              </Card>
            </Grid>
            {/* 데이터 */}
            <Grid
              item xs={9}
              className={classes.datagrid2}
            >
              {_isEmpty(image.rows) ?
                <Typography
                  variant="h6"
                  align="center"
                >
                  데이터가 존재하지 않습니다.
                </Typography>
                :
                <DataGrid
                  headerHeight={40}
                  rowHeight={30}
                  columns={columns}
                  rows={image.rows}
                  pageSize={13}
                  onRowClick={handleChange}
                  autoPageSize={true}
                // page={1}
                />
                // <XGrid
                //   headerHeight={40}
                //   rowHeight={30}
                //   columns={columns}
                //   rows={image.rows}
                //   pageSize={13}
                //   onRowClick={handleChange}
                //   autoPageSize={true}
                //   apiRef={GridApiRef}
                // // page={1}
                // />
              }
            </Grid>
          </Grid>
        </div>
      </Box>
      {/* 버튼 */}
      <Box
        ml={1} mr={1}
        display="flex"
        justifyContent="flex-end"
        className={classes.box}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<DoubleArrow />}
          onClick={handleAdd}
        >
          추가
        </Button>
      </Box>
    </Dialog>
  );
};

export default ComnImage;
