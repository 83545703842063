/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Receipt page
**/
/* @material import */
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';
/* react import */
import React from 'react';
import ProductList from '../product/ProductList';
import ProductListView from '../product/ProductListView';
import Page from './../Page';
import ReceiptList from './ReceiptList';
import ReceiptView from './ReceiptView';
import ReceiptEdit from './ReceiptEdit';

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '90%',
    overflow: 'hidden',
    marginTop: 0,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3)
  },
  paper: {
    width: '100%',
    height: 720,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  paper2: {
    width: '100%',
    height: 250,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  paper3: {
    width: '100%',
    height: 460,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },

  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const Receipt = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="수선품접수"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="flex-start"
      >
        <Container maxWidth="xl">
          <Box mb={1} justifyContent="center">
            <Grid
              container
              justify="center"
              spacing={1}
            >
              <Grid item xs={6}>
                {/* 접수이력 */}
                <Paper className={classes.paper} elevation={4}>
                  <ReceiptList />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                {/* 접수등록 */}
                <Paper className={classes.paper2} elevation={4}>
                  {
                    (window.sessionStorage.getItem('user_grd') === '09') ?
                      <ReceiptView />
                      :
                      <ReceiptEdit />
                  }
                </Paper>
                {/* 수선품 */}
                <Paper className={classes.paper3} elevation={4}>
                  {
                    (window.sessionStorage.getItem('user_grd') === '09') ?
                      <ProductListView />
                      :
                      <ProductList />
                  }
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default Receipt;
