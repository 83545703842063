/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux products category store
**/
// TYPES
import {
  PROD_CATE_ADD,
  PROD_CATE_DEL,
  PROD_CATE_FAIL,
  PROD_CATE_GET,
  PROD_CATE_GET_INFO,
  PROD_CATE_UPD
} from "../types";
import { _dataAddID, _isEmpty, _setProdCateFormat } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows:[],
  info:[],
  categorys:{},
  isEdit: false
};

// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case PROD_CATE_GET:         // 수선품 카테고리 전체 조회     
      return { 
        ...state,
        categorys: _setProdCateFormat(action.payload), 
        rows: _dataAddID(action.payload)
       };
    case PROD_CATE_GET_INFO:    // 수선품 카테고리 개별 조회    
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
       };       
    case PROD_CATE_ADD:         // 수선품 카테고리 저장
      return { 
        ...state,
        info: action.payload,
        isOpen: false,
        isEdit: _isEmpty(action.payload) ? false : true
       };    
    case PROD_CATE_UPD:         // 수선품 카테고리 수정
      return { 
        ...state,
        info: action.payload,
        isOpen: false,
        isEdit: _isEmpty(action.payload) ? false : true
       }; 
    case PROD_CATE_DEL:         // 수선품 삭제
      return { 
        ...state
      };              
    case PROD_CATE_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}