/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Comn Search
**/
/* @material import */
import {
  Drawer,
  makeStyles
} from '@material-ui/core';
/* react import */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Customer from './../customer/Customer';

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%', 
    height: '100%',
    overflow: 'hidden'
  }
}));

/* ######################################################################
 * component
 * ###################################################################### */
const ComnSearch = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const common = useSelector((state:any) => state.common);

  // [Events] ######################################################################
  const handleClose = useCallback(() => {
    common.isSearch = false;
    dispatch({ type: 'COM_SEARCH' });
  },[common, dispatch]);

  // [Render] ######################################################################  
  return (
    <Drawer 
      anchor='left' 
      open={common.isSearch} 
      onClose={handleClose}
      className={classes.root}
      >
      <Customer />
    </Drawer>
  );
};

export default ComnSearch;
