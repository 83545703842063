/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Base layout page
**/
/* @material import */
import { makeStyles } from '@material-ui/core';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from 'react-router-dom';
import { _removeSession } from "./../../utils/Common";
import { getCate } from "./../../utils/redux/actions/CateAction";
import { getComCode } from "./../../utils/redux/actions/ComAction";
import Navigator from './Navigator';

/* styles */
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    // paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

/* component */
const BaseLayout = () => {
  const classes = useStyles();      // css
  const dispatch = useDispatch();   // dispatch hook  
  const navigate = useNavigate();   // link

  // [Events] ######################################################################
  //@ 최초 실행
  const handleInit = useCallback(() => {
    _removeSession('COM_CATAGORY');
    _removeSession('COM_CODE');
    //1. 카테고리 데이터 조회
    getCate({}).then((d) => dispatch({ type : 'COM_CATAGORY', payload : d.payload}));
    //2. 공통코드 데이터 조회
    getComCode().then((d) => dispatch({ type : 'COM_CODE', payload : d.payload }));

  },[dispatch]);

  //@ 최초 실행
  useEffect(() => {
    // access_token 없을시, 로그인 화면 전환
    const access_token = window.sessionStorage.getItem('access_token');
    if(access_token === null || access_token === '') {
      navigate('/login', { replace: true });
    }

    handleInit();

  }, [navigate, handleInit]);

  return (
    <div className={classes.root}>      
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Navigator className={{}} />
            {/* 라우터 (router v6) */}
            <Outlet />                        
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseLayout;
