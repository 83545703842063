/* @material import */
import {
  AppBar,
  makeStyles,
  Toolbar
} from '@material-ui/core';
import PropTypes from 'prop-types';
/* react import */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import imgfile from './logo.png'

/* styles */
const useStyles = makeStyles(({
  root: {
    color: '#fff',
    backgroundColor: '#000000',    
  },
  toolbar: {
    height: 64
  }
}));

/*
 * component
 */
const LoginHeader = () => {
  const classes = useStyles();

  return (
    <AppBar
      className={classes.root}
      elevation={0}
    >
      <Toolbar className={classes.toolbar}>
        {/* <RouterLink to="/"> */}
        <a href='https://kr.object.iwinv.kr/settingfile/app-luxclinic-crm-20210527.apk'>
            <img
              alt="Logo"
              src={imgfile} />
          </a>
        {/* </RouterLink> */}
      </Toolbar>
    </AppBar>
  );
};

LoginHeader.propTypes = {
  className: PropTypes.string
};

export default LoginHeader;