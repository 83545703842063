/* @Material import */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Print } from '@material-ui/icons';
import { Formik } from 'formik';
import QRCode from "qrcode.react";
/* React import */
import React, { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from 'react-to-print';
import {
  _dust_bag_convert,
  _getCategoryName,
  _getCodeName,
  _getProdCate,
  _isEmpty,
  _setDateYYYYMMDD
} from "./../../utils/Common";


// [<----------------------------- (Style) ------------------------------>]
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#9fa8da",
      color: theme.palette.common.white,
      fontWeight: "bold",
      fontSize: 13
    },
    body: {
      fontSize: 12
    }
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
      }
    }
  })
)(TableRow);

// function createData(
//   name: string,
//   calories: string,
//   fat: string,
//   carbs: string,
//   protein: string
// ) {
//   return { name, calories, fat, carbs, protein };
// }

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
    textAlign: "center",
  },
  box: {
    padding: "7px",
  },
  button: {
    margin: theme.spacing(1),
  },
  container: {
    border: 1,
    color: "black",
  },
  col_grid: {
    alignSelf: "center",
    width: "10px",
  },
  col_grid2: {
    alignSelf: "center",
    width: "100px",
  },
  qrcode_area: {
    textAlign: "center",
  },
  qrcode_title: {
    textAlign: "right",
  },
  rcpt_list_title: {
    fontWeight: "bold",
    fontSize: 17,
    textAlign: "center",
  },
  resize: {
    fontSize: 15,
    color: "black"
  },
  rcpt_title: {
    fontWeight: "bold",
    fontSize: 20,
  },
  rcpt_sub_title: {
    fontWeight: "bold",
    fontSize: 15,
  },
  table: {
    minWidth: 700
  },
  typography: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center",
  },
  typography2: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
  },
  textfield: {
    textAlign: "center",
  },
}));

// [<--------------------------- (Component) ---------------------------->]
const WorkOrder = () => {
  const classes = useStyles();         // css
  const dispatch = useDispatch();      // dispatch hook
  const componentRef = useRef(null);   // react-to-print component

  // ###################################### [State] ######################################
  // const common = useSelector((state: any) => state.common);             // common
  const product = useSelector((state: any) => state.product);           // product
  const receipt = useSelector((state: any) => state.receipt);           // receipt
  const repair = useSelector((state: any) => state.repair);             // repair
  const productCate = useSelector((state: any) => state.productCate);   // productCate

  // ###################################### [Events] ######################################
  //@ 최초 실행
  //   const handleInit = useCallback(() => {
  //     // 접수이력 정보
  //     console.log(" ### 접수이력 정보 ### ");
  //     console.log(receipt.info);
  //     // 수선품 정보
  //     console.log(" ### 수선품 정보 ### ");
  //     console.log(product.info);
  //     // 리스트 정보
  //     console.log(" ### 수선품 카테고리 정보 ### ");
  //     console.log(productCate.rows);
  //     // 작업지시서 목록 정보
  //     console.log(" ### 작업지시서 목록 정보 ### ");
  //     console.log(repair.rows);
  //   },[product, productCate, receipt, repair, dispatch]);

  //@ 작업지시서 팝업창 닫기
  const handleClose = useCallback(() => {
    product.isView = false;
    dispatch({ type: 'PROD_GET_INFO', payload: product.info });
  }, [product, dispatch]);
  const [renderBox, setRenderBox] = useState(false);
  const handleInit = useCallback(() => { setRenderBox(product.isView) }, [product]);

  // ####################################### [init] #######################################
  //@ 최초 실행
  //   useEffect(() => {
  //     handleInit();
  //   }, [handleInit]);
  useEffect(() => {
    handleInit()
  }, [handleInit]);

  // ###################################### [Render] ######################################
  if (renderBox) {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            prod_no: _isEmpty(product.info) ? '' : product.info.prod_no,                     // 수선품번호
            brand_cd: _isEmpty(product.info) ? '' : _getCategoryName(product.info.brand_cd), // 브랜드코드
            cate_cd: _isEmpty(product.info) ? '' : _getCategoryName(product.info.cate_cd),   // 카테고리코드
            repr_st_dt: _isEmpty(product.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(product.info.repr_st_dt, '-'),
            repr_req_dt: _isEmpty(product.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(product.info.repr_req_dt, '-'),
            reg_user: _isEmpty(product.info) ? '' : product.info.reg_user,                     // 상담자 HSH 2020-12-29
            req_cont: _isEmpty(product.info) ? '' : product.info.req_cont,
            // 수선품 카테고리
            cate_02: _isEmpty(_getProdCate(productCate.rows, '02')) ? '' : _getProdCate(productCate.rows, '02'), // 품목 (0200000000)
            cate_03: _isEmpty(_getProdCate(productCate.rows, '03')) ? '' : _getProdCate(productCate.rows, '03'), // 품목(중분류)
            cate_04: _isEmpty(_getProdCate(productCate.rows, '04')) ? '' : _getProdCate(productCate.rows, '04'), // 품목(소분류) (0201010000)
            cate_07: _isEmpty(_getProdCate(productCate.rows, '07')) ? '' : _getProdCate(productCate.rows, '07'), // 소재 (0400000000)
            cate_13: _isEmpty(_getProdCate(productCate.rows, '13')) ? '' : _getProdCate(productCate.rows, '13'), // 색상 (0500000000)
            is_dust_bag: _isEmpty(product.info) ? '' : product.info.is_dust_bag,
            send_typ: _isEmpty(product.info) ? '' : product.info.send_typ,
          }}
          onSubmit={(v) => {
          }}
        >
          {({ values }) => (
            <Dialog
              open={product.isView}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div ref={componentRef}>
                <DialogTitle id="alert-dialog-title" classes={{ root: classes.root }}>
                  <Typography className={classes.rcpt_title}>작업지시서</Typography>
                </DialogTitle>
                <DialogContent>
                  <Box border={1} p={2} marginLeft={1} marginRight={1} marginTop={0} marginBottom={0}>
                    <Box className={classes.qrcode_area}>
                      <Grid container spacing={2}>
                        <Grid item xs={9}>
                          <Grid container>
                            <Grid item xs={9} className={classes.qrcode_title}>
                              <Grid container>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                  <Typography variant="subtitle1">QR Code</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={3}></Grid>
                          </Grid>
                        </Grid>
                        {/* HSH 상담자 영역 새로 추가 */}
                        <Grid item xs={3}>
                          <Grid
                            container
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <Typography className={classes.typography}>상담자</Typography>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={7}>
                              <TextField
                                id="reg_user"
                                disabled
                                label=""
                                placeholder=""
                                value={values.reg_user}
                                variant="standard"
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Box component="span">
                            <QRCode
                              value={values.prod_no}
                              size={64}
                              bgColor={"#ffffff"}
                              fgColor={"#000000"}
                              includeMargin={false} />
                          </Box>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>수선품 ID</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="prod_no"
                            disabled
                            fullWidth
                            label=""
                            placeholder=""
                            variant="standard"
                            value={values.prod_no}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}></Grid>
                      </Grid>
                    </Box>
                    <Typography variant="subtitle1" className={classes.rcpt_sub_title}>고객 정보</Typography>
                    <Box border={1} mb={1} className={classes.box}>
                      <Grid container spacing={1} className={classes.container}>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>접수 번호</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="cust_no"
                            disabled
                            fullWidth
                            label=""
                            placeholder=""
                            variant="standard"
                            value={receipt.info.rcpt_no}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>이름</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            id="cust_name"
                            disabled
                            fullWidth
                            label=""
                            placeholder=""
                            variant="standard"
                            value={receipt.info.cust_name}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>연락처</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="phone"
                            disabled
                            label=""
                            placeholder=""
                            variant="standard"
                            value={receipt.info.phone}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Typography variant="subtitle1" className={classes.rcpt_sub_title}>수선품</Typography>
                    <Box border={1} mb={1} p={2} className={classes.box}>
                      <Grid container spacing={1} className={classes.container}>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>브랜드</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="brand_cd"
                            disabled
                            label=""
                            placeholder=""
                            variant="standard"
                            value={values.brand_cd}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>품목</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="cate_02"
                            disabled
                            label=""
                            placeholder=""
                            variant="standard"
                            value={_getCategoryName(values.cate_02)}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>품목<br />(중분류)</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="cate_03"
                            label=""
                            placeholder=""
                            disabled
                            value={_getCategoryName(values.cate_03)}
                            // item={_setCateCodeFilter(common.category, values.cate_03)}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>소재</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="cate_07"
                            label=""
                            placeholder=""
                            disabled
                            variant="standard"
                            value={_getCategoryName(values.cate_07)}
                            // item={_setCateCodeFilter(common.category, '0400000000')}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>색상</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="cate_13"
                            label=""
                            placeholder=""
                            disabled
                            variant="standard"
                            value={_getCategoryName(values.cate_13)}
                            // item={_setCateCodeFilter(common.category, '0500000000')}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        {/* HSH 추가 */}
                        <Grid item xs={12} >
                          <Grid container>
                            <Grid item xs={1} className={classes.col_grid2}>
                              <Typography className={classes.typography2}>내역</Typography>
                            </Grid>
                            <Grid item xs={11}>
                              <TextField
                                id="req_cont"
                                label=""
                                placeholder=""
                                disabled
                                variant="standard"
                                fullWidth
                                value={values.req_cont || ''}
                                // item={_setCateCodeFilter(common.category, '0500000000')}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Grid item xs={3} className={classes.col_grid}>
                        <Typography className={classes.typography}>고객요청사항</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <TextField
                          id="cate_13"
                          label=""
                          placeholder=""
                          disabled
                          variant="standard"
                          value={_getCategoryName(values.cate_13)}
                          // item={_setCateCodeFilter(common.category, '0500000000')}
                          InputProps={{
                            classes: {
                              input: classes.resize
                            }
                          }}
                        />
                      </Grid> */}
                      </Grid>
                    </Box>
                    <Box mb={1} className={classes.box}>
                      <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>입고일</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="repr_st_dt"
                            disabled
                            label=""
                            placeholder=""
                            variant="standard"
                            value={values.repr_st_dt}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>출고<br />요청일</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="repr_req_dt"
                            disabled
                            label=""
                            placeholder=""
                            value={values.repr_req_dt}
                            variant="standard"
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography className={classes.typography}>더스트백</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <TextField
                            id="is_dust_bag"
                            disabled
                            label=""
                            placeholder=""
                            value={_dust_bag_convert(values.is_dust_bag)}

                            variant="standard"
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography className={classes.typography}>발송방식</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <TextField
                            id="send_typ"
                            disabled
                            label=""
                            placeholder=""
                            value={_getCodeName(values.send_typ, 'G007')}
                            variant="standard"
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box paddingBottom={1}>
                      <Typography className={classes.rcpt_list_title}>작업지시 리스트</Typography>
                    </Box>
                    <Box>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">순서</StyledTableCell>
                              <StyledTableCell align="center">수선(대)</StyledTableCell>
                              <StyledTableCell align="center">수선(중)</StyledTableCell>
                              <StyledTableCell align="center">수선(소)</StyledTableCell>
                              <StyledTableCell align="center">위치</StyledTableCell>
                              <StyledTableCell align="center">수량</StyledTableCell>{/* 요청사항 부품제작수량 -> 수량 210625 */}
                              <StyledTableCell align="center">세부요청사항</StyledTableCell>
                              <StyledTableCell align="center">체크리스트</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {repair.rows.map((v: any, i: number) => (
                              <StyledTableRow key={i + 1}>
                                <StyledTableCell align="center" component="th" scope="row">
                                  {i + 1}
                                </StyledTableCell>
                                <StyledTableCell align="center">{_getCategoryName(v.cate_02)}</StyledTableCell>
                                <StyledTableCell align="center">{_getCategoryName(v.cate_03)}</StyledTableCell>
                                <StyledTableCell align="center">{_getCategoryName(v.cate_04)}</StyledTableCell>
                                <StyledTableCell align="center">{_getCategoryName(v.repr_loc)}</StyledTableCell>
                                <StyledTableCell align="center">{v.repr_cnt}</StyledTableCell>
                                <StyledTableCell align="center">{v.req_cont}</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </DialogContent>
              </div>
              <DialogActions>
                <ReactToPrint
                  trigger={() => <Button onClick={handleClose} variant="contained" color="primary" startIcon={<Print />}>출력</Button>}
                  content={() => componentRef.current}
                />
                <Button onClick={handleClose} variant="contained" color="primary" autoFocus>닫기</Button>
              </DialogActions>
            </Dialog>
          )}
        </Formik>
      </div>
    );
  } else {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            prod_no: _isEmpty(product.info) ? '' : product.info.prod_no,                     // 수선품번호
            brand_cd: _isEmpty(product.info) ? '' : _getCategoryName(product.info.brand_cd), // 브랜드코드
            cate_cd: _isEmpty(product.info) ? '' : _getCategoryName(product.info.cate_cd),   // 카테고리코드
            repr_st_dt: _isEmpty(product.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(product.info.repr_st_dt, '-'),
            repr_req_dt: _isEmpty(product.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(product.info.repr_req_dt, '-'),
            reg_user: _isEmpty(product.info) ? '' : product.info.reg_user,                     // 상담자 HSH 2020-12-29
            req_cont: _isEmpty(product.info) ? '' : product.info.req_cont,
            // 수선품 카테고리
            cate_02: _isEmpty(_getProdCate(productCate.rows, '02')) ? '' : _getProdCate(productCate.rows, '02'), // 품목 (0200000000)
            cate_03: _isEmpty(_getProdCate(productCate.rows, '03')) ? '' : _getProdCate(productCate.rows, '03'), // 품목(중분류)
            cate_04: _isEmpty(_getProdCate(productCate.rows, '04')) ? '' : _getProdCate(productCate.rows, '04'), // 품목(소분류) (0201010000)
            cate_07: _isEmpty(_getProdCate(productCate.rows, '07')) ? '' : _getProdCate(productCate.rows, '07'), // 소재 (0400000000)
            cate_13: _isEmpty(_getProdCate(productCate.rows, '13')) ? '' : _getProdCate(productCate.rows, '13'), // 색상 (0500000000)
          }}
          onSubmit={(v) => {
          }}
        >
          {({ values }) => (
            <Dialog
              open={product.isView}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div ref={componentRef}>
                <DialogTitle id="alert-dialog-title" classes={{ root: classes.root }}>
                  <Typography className={classes.rcpt_title}>작업지시서</Typography>
                </DialogTitle>
                <DialogContent>
                  <Box border={1} p={2} marginLeft={1} marginRight={1} marginTop={0} marginBottom={0}>
                    <Box className={classes.qrcode_area}>
                      <Grid container spacing={2}>
                        <Grid item xs={9}>
                          <Grid container>
                            <Grid item xs={9} className={classes.qrcode_title}>
                              <Grid container>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                  <Typography variant="subtitle1">QR Code</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={3}></Grid>
                          </Grid>
                        </Grid>
                        {/* HSH 상담자 영역 새로 추가 */}
                        <Grid item xs={3}>
                          <Grid
                            container
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <Typography className={classes.typography}>상담자</Typography>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={7}>
                              <TextField
                                id="reg_user"
                                disabled
                                label=""
                                placeholder=""
                                value={values.reg_user}
                                variant="standard"
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Box component="span">
                            <QRCode
                              value={values.prod_no}
                              size={64}
                              bgColor={"#ffffff"}
                              fgColor={"#000000"}
                              includeMargin={false} />
                          </Box>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>수선품 ID</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="prod_no"
                            disabled
                            label=""
                            placeholder=""
                            variant="standard"
                            value={values.prod_no}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}></Grid>
                      </Grid>
                    </Box>
                    <Typography variant="subtitle1" className={classes.rcpt_sub_title}>고객 정보</Typography>
                    <Box border={1} mb={1} className={classes.box}>
                      <Grid container spacing={1} className={classes.container}>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>접수 번호</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="cust_no"
                            disabled
                            label=""
                            placeholder=""
                            variant="standard"
                            value={receipt.info.rcpt_no}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>이름</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="cust_name"
                            disabled
                            label=""
                            placeholder=""
                            variant="standard"
                            value={receipt.info.cust_name}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>연락처</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="phone"
                            disabled
                            label=""
                            placeholder=""
                            variant="standard"
                            value={receipt.info.phone}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Typography variant="subtitle1" className={classes.rcpt_sub_title}>수선품</Typography>
                    <Box border={1} mb={1} p={2} className={classes.box}>
                      <Grid container spacing={1} className={classes.container}>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>브랜드</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="brand_cd"
                            disabled
                            label=""
                            placeholder=""
                            variant="standard"
                            value={values.brand_cd}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>품목</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="cate_02"
                            disabled
                            label=""
                            placeholder=""
                            variant="standard"
                            value={_getCategoryName(values.cate_02)}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>품목<br />(중분류)</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="cate_03"
                            label=""
                            placeholder=""
                            disabled
                            value={_getCategoryName(values.cate_03)}
                            // item={_setCateCodeFilter(common.category, values.cate_03)}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>소재</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="cate_07"
                            label=""
                            placeholder=""
                            disabled
                            variant="standard"
                            value={_getCategoryName(values.cate_07)}
                            // item={_setCateCodeFilter(common.category, '0400000000')}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>색상</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="cate_13"
                            label=""
                            placeholder=""
                            disabled
                            variant="standard"
                            value={_getCategoryName(values.cate_13)}
                            // item={_setCateCodeFilter(common.category, '0500000000')}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        {/* HSH 추가 */}
                        <Grid item xs={12} >
                          <Grid container>
                            <Grid item xs={1} className={classes.col_grid2}>
                              <Typography className={classes.typography2}>내역</Typography>
                            </Grid>
                            <Grid item xs={11}>
                              <TextField
                                id="req_cont"
                                label=""
                                placeholder=""
                                disabled
                                variant="standard"
                                fullWidth
                                value={values.req_cont || ''}
                                // item={_setCateCodeFilter(common.category, '0500000000')}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Grid item xs={3} className={classes.col_grid}>
                        <Typography className={classes.typography}>고객요청사항</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <TextField
                          id="cate_13"
                          label=""
                          placeholder=""
                          disabled
                          variant="standard"
                          value={_getCategoryName(values.cate_13)}
                          // item={_setCateCodeFilter(common.category, '0500000000')}
                          InputProps={{
                            classes: {
                              input: classes.resize
                            }
                          }}
                        />
                      </Grid> */}
                      </Grid>
                    </Box>
                    <Box mb={1} className={classes.box}>
                      <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>입고일</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="repr_st_dt"
                            disabled
                            label=""
                            placeholder=""
                            variant="standard"
                            value={values.repr_st_dt}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>출고<br />요청일</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="repr_req_dt"
                            disabled
                            label=""
                            placeholder=""
                            value={values.repr_req_dt}
                            variant="standard"
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                    </Box>
                    <Box paddingBottom={1}>
                      <Typography className={classes.rcpt_list_title}>작업지시 리스트</Typography>
                    </Box>
                    <Box>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">순서</StyledTableCell>
                              <StyledTableCell align="center">수선<br />(대분류)</StyledTableCell>
                              <StyledTableCell align="center">수선<br />(중분류)</StyledTableCell>
                              <StyledTableCell align="center">수선<br />(소분류)</StyledTableCell>
                              <StyledTableCell align="center">위치</StyledTableCell>
                              <StyledTableCell align="center">수량</StyledTableCell>{/* 요청사항 부품제작수량 -> 수량 210625 */}
                              <StyledTableCell align="center">세부<br />요청사항</StyledTableCell>
                              <StyledTableCell align="center">체크<br />리스트</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* {repair.rows.map((v: any, i: number) => (
                              <StyledTableRow key={i + 1}>
                                <StyledTableCell align="center" component="th" scope="row">
                                  {i + 1}
                                </StyledTableCell>
                                <StyledTableCell align="center">{_getCategoryName(v.cate_02)}</StyledTableCell>
                                <StyledTableCell align="center">{_getCategoryName(v.cate_03)}</StyledTableCell>
                                <StyledTableCell align="center">{_getCategoryName(v.cate_04)}</StyledTableCell>
                                <StyledTableCell align="center">{_getCategoryName(v.repr_loc)}</StyledTableCell>
                                <StyledTableCell align="center">{v.repr_cnt}</StyledTableCell>
                                <StyledTableCell align="center">{v.req_cont}</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                              </StyledTableRow>
                            ))} */}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </DialogContent>
              </div>
              <DialogActions>
                <ReactToPrint
                  trigger={() => <Button onClick={handleClose} variant="contained" color="primary" startIcon={<Print />}>출력</Button>}
                  content={() => componentRef.current}
                />
                <Button onClick={handleClose} variant="contained" color="primary" autoFocus>닫기</Button>
              </DialogActions>
            </Dialog>
          )}
        </Formik>
      </div>
    );
  }
};

export default WorkOrder;