/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : FORM FILE
**/
/* @material import */
import {
  CardMedia,
  makeStyles
} from '@material-ui/core';
/* React import */
import React from "react";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
      size: 'small',
      position: 'absolute',
      width: 1,
      height: 1,
      padding: 0,
      margin: -1,
      overflow: 'hidden',
      clip:'rect(0,0,0,0)',
      border: 0       
  },  
  iconButton: {
    padding: 10,
  }, 
  CardMedia: {
    height: 60
  },     
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const FormFile = (props:any) => {
  const classes = useStyles();     // CSS

  // [Render] ######################################################################  
  return (
    <div>
      <label 
        htmlFor={props.id}>
          <CardMedia
            className={classes.CardMedia}
            image={props.value}
            title="정면"
          /> 
      </label>                
      <input
        className={classes.root}
        accept="image/*"
        required={props.required}
        disabled={props.onOff!=='01'}
        id={props.id}
        name={props.id}
        placeholder={props.label+' 첨부해주세요'}
        type='file'
        // onBlur={props.blur}
        onChange={props.change}
        // onClick={props.click}
        // value={props.value}
        style={{ display: "none" }}
      />      
    </div>
  );
};

export default FormFile;