/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Repair List Page
**/
/* @material import */
import {
  Grid,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  DataGrid
} from '@material-ui/data-grid';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  getReprWork
} from "../../utils/redux/actions/ReprAction";
import {
  _isEmpty,
  _setFormatCategory,
  _setFormatCode,
  _setFormatDate
} from "./../../utils/Common";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
  },
  grid: {
    height: '100%', 
    width: '96%',
    maxHeight: '92%',
    minWidth: '96%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#9fa8da',
      color: '#ffffff'
    },    
  },
  // 데이터 그리드 테이블 해더
  search: {
    margin: theme.spacing(1)
  },  
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },      
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const RepairList = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const repair = useSelector((state:any) => state.repair);      // repair
  const common = useSelector((state:any) => state.common);      // common
  const product = useSelector((state:any) => state.product);    // product

  // [Events] ######################################################################
  //@ 최초 실행
  const handleInit = useCallback(() => {
    
    // 수선 목록 조회
    if(!_isEmpty(product.info)) {
      let body = {}
      body = {
        prod_no: product.info.prod_no
      }
      getReprWork(body).then((d) => dispatch(d))
                  .catch((e) => console.log(e));
    } else {
      // 작업지시서 목록 초기화
      dispatch({ type: 'REPR_GET', payload: [] })   
    }

  },[product, dispatch]);
  
  // [init] ######################################################################
  // 데이트 타입 설정
  const _setDate = _setFormatDate();  
  // 카테고리명 조회
  const _setCateName = _setFormatCategory(common.category);
  // 접수상태명 조회
  const _setStateName = _setFormatCode(common.code, 'G016');

  //@ 초기 데이터 (Table 컬럼)
  const columns:any = [
    { field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
    { field: 'repr_no', headerAlign: 'center', headerName: '수선번호', width: 130 },
    { field: 'cate_02', headerAlign: 'center', headerName: '유형', width: 100, ..._setCateName },
    { field: 'repr_loc', headerAlign: 'center', headerName: '수선위치', width: 110, ..._setCateName },
    { field: 'repr_user_name', headerAlign: 'center', headerName: '수선자', width: 80 },    
    { field: 'repr_st_dtime', headerAlign: 'center', headerName: '수선시작', width: 120, ..._setDate },
    // { field: 'repr_ed_dtime', headerAlign: 'center', headerName: '수선종료', width: 120, ..._setDate },    
    { field: 'repr_stat', headerAlign: 'center', headerName: '수선상태', width: 90, ..._setStateName },
    { field: 'reg_user', headerAlign: 'center', headerName: '작성자', width: 80 },
    { field: 'last_mod_user', headerAlign: 'center', headerName: '수정자', width: 80 }    
  ];

  //@ 최초 실행
  useEffect(() => {
    //1. 상담 조회
    handleInit();
  }, [handleInit]);

  // [Grid] ######################################################################    
  let datagrid;

  if (_isEmpty(repair.rows)) {
    datagrid =  <Typography 
                  variant="h6"
                  align="center"
                  >
                    수선품을 선택해주세요.
                </Typography>;
  } else {
    datagrid =  <DataGrid
                  headerHeight={40}
                  rowHeight={30}
                  columns={columns} 
                  rows={repair.rows}
                  pageSize={10}
                  />;
  }

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid 
        container
        justify="center"
        alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h6">수선</Typography>
          </Grid>        
        </Grid>
      </Toolbar>      
      <div 
        className={classes.grid}
      >   
      {datagrid}
      </div>
    </React.Fragment>    
  );
};

export default RepairList;
