/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Consult Edit Page
**/
/* @material import */
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  Add,
  Save
} from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import {
  _isEmpty,
  _setAddComma,
  _setCateCodeFilter,
  _setCateCodeFilter2,
  _setCodeFilter,
  _setDateYYYYMMDD,
  _setRemoveComma,
  _setUTCDate
} from "../../utils/Common";
import FormDate from "../../utils/form/FormDate";
import FormSelect from "../../utils/form/FormSelect";
import FormText from "../../utils/form/FormText";
import { addCnslt, getCnsltSrch, updCnslt } from "../../utils/redux/actions/CnsltAction";
import { getImg } from "../../utils/redux/actions/ImgAction";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
    height: '100%', 
    width: '100%',
    maxHeight: '90%',
    minWidth: '90%',
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  button2: {
    marginTop: 16,
  },  
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 10,
  }  
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ConsultEdit = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################  
  const consult = useSelector((state:any) => state.consult);    // consult
  const common = useSelector((state:any) => state.common);      // common

  // [Events] ######################################################################
  //@ 저장
  const handleAdd = useCallback((v) => {
    let body = {};
    addCnslt(v).then((res) => dispatch(res))
              .then((res) => {                
                console.log(res);
                common.msg = '저장에 성공 하였습니다.';
                common.isMsg = true;        
                return dispatch({type:'COM_ALERT'});       // 안내메시지
              })
              // .then(() => getCnslt().then((d) => dispatch(d)));
              .then(() => getCnsltSrch(body).then((d) => dispatch(d)));
  },[common, dispatch]);
  
  //@ 수정
  const handleUpdate = useCallback((v) => {
    let body = {};
    updCnslt(v).then((res) => dispatch(res))
              .then(() => {                
                common.msg = '수정에 성공 하였습니다.';
                common.isMsg = true;        
                return dispatch({type:'COM_ALERT'});       // 안내메시지
              })
              // .then(() => getCnslt().then((d) => dispatch(d)));
              .then(() => getCnsltSrch(body).then((d) => dispatch(d)));
  },[common, dispatch]);

  //@ 초기화
  const handleinit = useCallback(() => {
    common.search = {};
    dispatch({ type:'CNSLT_GET_INFO', payload: {} });
  },[common, dispatch]);

  const handleCustSrch = useCallback((e) => {
    // 고객검색레이어 오픈
    common.isCustAdd = true;
    common.custPage = '01';
    dispatch({ type: 'COM_CUST_SEARCH', payload: common });
    // 고객검색정보 초기화
    dispatch({ type:'CUST_GET', payload: {} });
    dispatch({ type:'CUST_GET_INFO', payload: {} });      
  },[common, dispatch]);
  
  //@ 이미지 데이터 가져오기
  const getImageItem = useCallback((e) => {
    // 상담 입력 상세 정보 등록
    dispatch({ type:'CNSLT_GET_INFO', payload: e });
    // 이미지 검색 레이어 오픈
    common.isImage = true;
    common.imagePage = '01';
    getImg({}).then((v:any) => dispatch(v));
  },[common, dispatch]); 

  // [init] ######################################################################
  //@ 최초 실행
  useEffect(() => {   
    // handleinit();
  }, []);

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid 
        container
        justify="center"
        alignItems="center"
        >
          <Grid item xs={6}>
            <Typography variant="h6">상담등록</Typography>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Grid 
              container
              justify="center"
              alignItems="center"
              >
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                {/* 초기화 */}
                <IconButton 
                  className={classes.iconButton} 
                  aria-label="초기화"
                  color="inherit"
                  onClick={handleinit}
                  >
                  <Add /><Typography variant="h6">초기화</Typography>
                </IconButton>              
              </Grid>
            </Grid>            
          </Grid>          
        </Grid>
      </Toolbar>

      <Formik
          enableReinitialize
          initialValues={{
              cnslt_no: _isEmpty(consult.info.cnslt_no) ? '' : consult.info.cnslt_no,      // 상담번호
              cnslt_dtime: _isEmpty(consult.info.cnslt_dtime) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(consult.info.cnslt_dtime, '-'),    // 상담일
              // cust_no: _isEmpty(consult.info.cust_no) ? '' : consult.info.cust_no,          // 고객번호
              cust_no: _isEmpty(consult.info.cust_no) ? 
                          (_isEmpty(common.search.cust_no) ? '' : common.search.cust_no) : consult.info.cust_no,   // 고객번호              
              cust_name: _isEmpty(consult.info.cust_name) ? 
                          (_isEmpty(common.search.cust_name) ? '' : common.search.cust_name) : consult.info.cust_name,   // 고객명
              // phone: _isEmpty(consult.info.phone) ? 
              //             (_isEmpty(common.search.phone) ? '' : common.search.phone) : consult.info.phone,   //  전화번호 2020-12-30      
              phone: _isEmpty(consult.info.phone) ?
                          (_isEmpty(common.search.phone) ? '' : common.search.phone) : consult.info.phone,   // phone  
              rcpt_chnl: _isEmpty(consult.info.rcpt_chnl) ? '' : consult.info.rcpt_chnl,   // 상담경로              
              brand_cd: _isEmpty(consult.info.brand_cd) ? '' : consult.info.brand_cd,      // 브랜드
              item_cd: _isEmpty(consult.info.item_cd) ? '' : consult.info.item_cd,         // 품목
              repr_pay: _isEmpty(consult.info.repr_pay) ? '' : _setAddComma(consult.info.repr_pay),             // 수선비
              galy_url: _isEmpty(consult.info.galy_url) ? '' : consult.info.galy_url,      // 이미지
              qst_cont: _isEmpty(consult.info.qst_cont) ? '' : consult.info.qst_cont,      // 문의
              ans_cont: _isEmpty(consult.info.ans_cont) ? '' : consult.info.ans_cont,      // 답변 
              cnslt_stat: _isEmpty(consult.info.cnslt_stat) ? '' : consult.info.cnslt_stat // 답변 
          }}
          validationSchema={Yup.object().shape({
              cust_name: Yup.string()
                            .min(2, '2자 이상 입력해주세요')
                            .required('고객명을 입력해주세요'),
              rcpt_chnl: Yup.string().required('상담경로를 선택해주세요'),
              brand_cd: Yup.string().required('브랜드를 선택해주세요'),
              item_cd: Yup.string().required('품목을 선택해주세요')         
          })}
          onSubmit={(v) => {
            // 상담완료(01:접수, 02:진행중, 03:완료)
            v.cnslt_stat = '03';
            // UTC 포멧 변경
            v.cnslt_dtime = _setUTCDate(v.cnslt_dtime);
            // 콤마 데이터 포멧 변경
            v.repr_pay = _setRemoveComma(v.repr_pay);

            if(v.cnslt_no) {  // 수정
              handleUpdate(v);
            } else {              // 등록
              handleAdd(v);
            } 
          }}
          >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (   
              <form onSubmit={handleSubmit}>      
                <Box ml={3} mb={1} mt={1} justifyContent="center">
                  <Grid 
                      container
                      justify="center"
                      alignItems="center" 
                      className={classes.root}>
                    {/* 상담일 */}
                    <Grid item xs={4}>
                        <FormDate 
                          id='cnslt_dtime' 
                          label='상담일' 
                          value={values.cnslt_dtime}
                          // errors={errors.cnslt_dtime}
                          // touched={touched.cnslt_dtime}
                          required={true}                     
                          blur={handleBlur} 
                          change={handleChange} />
                    </Grid>
                    {/* 고객명 */}
                    <Grid item xs={4}>
                        <FormText 
                          id='cust_name' 
                          label='고객명' 
                          value={values.cust_name}
                          errors={errors.cust_name}
                          touched={touched.cust_name}
                          disabled={true}
                          required={true}
                          blur={handleBlur}
                          click={consult.isEdit ? null : handleCustSrch}
                          change={handleChange} />
                    </Grid>
                    {/* 고객연락처 */}
                    <Grid item xs={4}>
                        <FormText 
                          id='phone' 
                          label='연락처' 
                          value={values.phone}
                          errors={errors.phone}
                          touched={touched.phone}
                          disabled={true}
                          required={true}
                          blur={handleBlur}                          
                          change={handleChange} />
                    </Grid>
                    {/* 상담경로 */}
                    <Grid item xs={3}>
                      <FormSelect
                        id='rcpt_chnl' 
                        label='상담경로' 
                        value={values.rcpt_chnl}
                        item={_setCodeFilter(common.code, 'G024')}
                        errors={errors.rcpt_chnl}
                        touched={touched.rcpt_chnl}
                        required={true}                                              
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid>
                    {/* 브랜드 */}
                    <Grid item xs={3}>
                      <FormSelect 
                        id='brand_cd' 
                        label='브랜드' 
                        value={values.brand_cd}
                        item={_setCateCodeFilter2(common.category, '0100000000')}
                        errors={errors.brand_cd}
                        touched={touched.brand_cd}                      
                        required={true}                        
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid>
                    {/* 수선종류 */}
                    <Grid item xs={3}>
                      <FormSelect 
                        id='item_cd' 
                        label='수선종류' 
                        value={values.item_cd}
                        item={_setCodeFilter(common.code, 'G025')}
                        errors={errors.item_cd}
                        touched={touched.item_cd}    
                        required={true}                                          
                        blur={handleBlur} 
                        change={handleChange} />                   
                    </Grid>
                    {/* 수선비 */}
                    <Grid item xs={3}>
                        <FormText 
                          id='repr_pay' 
                          label='수선비' 
                          value={values.repr_pay}
                          // errors={errors.repr_pay}
                          // touched={touched.repr_pay}                      
                          blur={handleBlur} 
                          change={handleChange} /> 
                    </Grid>        
                    {/* 제공이미지 */}
                    <Grid item xs={9}>
                      <FormText 
                        id='galy_url' 
                        label='제공이미지'
                        value={values.galy_url}
                        disabled={true}                     
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        className={classes.button2}
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Add />}
                        onClick={() => getImageItem(values)}
                      >
                        검색
                      </Button>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    {/* 문의사항 */}
                    <Grid item xs={12}>
                      <FormText 
                        id='qst_cont' 
                        label='문의사항' 
                        value={values.qst_cont}
                        // 밸리데이션 체크가 필요 없을시 빼시오.
                        // errors={errors.qst_cont}
                        // touched={touched.qst_cont}                      
                        blur={handleBlur} 
                        change={handleChange} />   
                    </Grid>
                    {/* 답변사항 */}
                    <Grid item xs={12}>
                      <FormText 
                        id='ans_cont' 
                        label='답변사항' 
                        value={values.ans_cont}
                        // errors={errors.ans_cont}
                        // touched={touched.ans_cont}                      
                        blur={handleBlur} 
                        change={handleChange} /> 
                    </Grid>                              
                  </Grid>
                </Box>
                {/* 버튼영역 */}
                <Box
                  ml={1} mr={1} mb={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  {/* 수정,저장 */}
                  <Button
                    type="submit"           
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<Save />}
                  >
                    {consult.isEdit ? '수정' : '저장'}
                  </Button>
                </Box>    
              </form>          
          )}
      </Formik>
    </React.Fragment>
  );
};

export default ConsultEdit;
