/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Receipt List Page
**/
/* @material import */
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputBase,
	makeStyles,
	Paper,
	Toolbar,
	Typography
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { List, Publish, Print, Search } from '@material-ui/icons';
import { Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import XLSX from "xlsx";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import FormText from '../../utils/form/FormText';
import { addPay } from "./../../utils/redux/actions/PayAction";
import { getProd, addProd } from "../../utils/redux/actions/ProdAction";
import { addRcpt, getRcpt } from "../../utils/redux/actions/RcptAction";
import {
	addProdCate,
} from "./../../utils/redux/actions/ProdCateAction";
import {
	getCust,
	addCust,
} from "../../utils/redux/actions/CustAction";
import {
	_isEmpty,
	_setCodeFilter,
	_setDateYYYYMMDD,
	_setFormatCode,
	_setFormatComma,
	_setFormatCategory,
	_setFormatDate,
	_setUTCDate,
	_setProdCate,
	_getNowDate,
	_setCateCodeExist,
	_setCodeExist,
	_setKakaoState,
	_setFormatTime,
	_setFormatPhone
} from "./../../utils/Common";
import FormDate from "./../../utils/form/FormDate";
import Receipts from "./Receipts";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
	root: {
		padding: "5px",
		textAlign: "center",
	},
	container: {
		border: 1,
		color: "black",
	},
	grid: {
		height: "100%",
		width: "98%",
		maxHeight: "78%",
		minWidth: "98%",
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
		"& .MuiDataGrid-columnsContainer": {
			backgroundColor: "#9fa8da",
			color: "#ffffff",
		},
	},
	// 데이터 그리드 테이블 해더
	search: {
		margin: theme.spacing(1),
	},
	toolbar: {
		minHeight: 40,
		color: "#fff",
		backgroundColor: "#1976d2",
	},
	iconButton: {
		padding: 10,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	paper: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "100%",
		height: 30,
		marginTop: 13,
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	button: {
		margin: theme.spacing(1),
	},
	box: {
		padding: 10,
	},
	typography: {
		fontSize: 13,
		fontWeight: "bold",
		textAlign: "center",
	},
	searchTypography: {
		marginTop: 12,
	},
	//style for font size
	resize: {
		fontSize: 13,
	},
	col_grid: {
		alignSelf: "center",
		width: "2px",
	},
	rcpt_title: {
		fontWeight: "bold",
		fontSize: 20,
	},
	rcpt_sub_title: {
		fontWeight: "bold",
		fontSize: 15,
	},
	formLink: {
		textDecoration: 'none',
		color: "#fff",
		fontSize: 10
	},
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ReceiptList = () => {
	const classes = useStyles(); // CSS
	const dispatch = useDispatch(); // dispatch hook

	// [state] #######################################################################
	const common = useSelector((state: any) => state.common, shallowEqual); // common
	const receipt = useSelector((state: any) => state.receipt, shallowEqual); // receipt
	const search = useSelector((state: any) => state.common.search, shallowEqual); // common
	const product = useSelector((state: any) => state.product, shallowEqual); // product
	const repair = useSelector((state: any) => state.repair, shallowEqual)
	const navigate = useNavigate(); // link

	const [open, setOpen] = React.useState(false);

	// let upload_result_list: any = [];
	// let upload_excel_length = 0;
	// [Events] ######################################################################
	//@ 최초 실행
	const handleInit = useCallback(() => {
		product.rows = [];
		repair.rows = [];
		let body: any = {};
		if (!_isEmpty(search)) body = search;
		body['limit'] = '100'
		getRcpt(body)
			.then((d) => {
				dispatch(d);
				dispatch({ type: "RCPT_GET_INFO", payload: {} });
				dispatch({ type: "PROD_GET", payload: [] });
			})
			.catch((e) => console.log(e));
		receipt.srch['limit'] = '100'
	}, [search, dispatch]);

	const handleToggle = () => {
		setOpen(!open);
	};

	//@ 선택
	const handleChange = useCallback(
		(e) => {
			let body = {
				rcpt_no: e.data.rcpt_no,
			};
			dispatch({ type: "PROD_GET", payload: [] });
			getProd(body)
				.then((d) => {
					dispatch(d);
					dispatch({ type: "RCPT_GET_INFO", payload: e.data });
				})
				.catch((e) => console.log(e));
		},
		[dispatch]
	);
	// const handleChange = useCallback((e) => {
	// 		console.log(e.data);
	// 		dispatch({ type: "PROD_GET", payload: [] });
	// 		dispatch({ type: "RCPT_GET_INFO", payload: e.data });
	// },[dispatch]);

	//@ 접수증 출력
	const handleClickOpen = useCallback(async () => {
		if (_isEmpty(receipt.info)) {
			// 접수증이 선택되지 않은 경우
			common.msg = "접수정보를 선택해 주세요.";
			common.isMsg = true;
			return dispatch({ type: "COM_ALERT" }); // 안내메시지
		} else {
			receipt.isOpen = true;
			dispatch({ type: "RCPT_GET_INFO", payload: receipt.info });
		}
	}, [common, receipt, dispatch]);

	//@ 검색
	const handleSearch = useCallback(
		(v) => {
			receipt.srch = v;

			let body: any = {};
			if (!_isEmpty(v.st_dt)) body["st_dt"] = _setUTCDate(v.st_dt);
			if (!_isEmpty(v.ed_dt)) body["ed_dt"] = _setUTCDate(v.ed_dt);
			if (!_isEmpty(v.rcpt_stat)) body["rcpt_stat"] = v.rcpt_stat;
			if (!_isEmpty(v.rcpt_chnl)) body["rcpt_chnl"] = v.rcpt_chnl;
			if (!_isEmpty(v.srch_val)) body[v.srch_key.toString()] = v.srch_val;
			if (!_isEmpty(v.limit)) body["limit"] = v.limit;

			getRcpt(body)
				.then((d) => {
					dispatch(d);
					dispatch({ type: "PROD_GET", payload: [] });
				})
				.catch((e) => console.log(e));
		},
		[receipt, dispatch]
	);

	const addProduct = async (
		rcpt_info: any,
		cust_info: any,
		prod_info: any,
		excel_length: number,
		idx: number
	) => {
		let result_flag = false;
		// 접수 등록
		addRcpt(rcpt_info)
			.then((result: any) => {
				prod_info.rcpt_no = result.payload.rcpt_no;
				prod_info.cust_no = result.payload.cust_no;
				addProd(prod_info)
					.then((res: any) => {
						prod_info.prod_no = res.payload.prod_no;
						// 정산 등록
						addPay(prod_info).catch((e) => console.log(e));
						let rows = _setProdCate(prod_info, "");
						console.log(rows);
						rows.forEach((v: any, index_cont: number) =>
							addProdCate(v)
								.then((v) => {
									if (index_cont === 13 && excel_length === idx) {
										result_flag = true;
										getRcpt(receipt.search)
											.then((d) => dispatch(d))
											.catch((e) => console.log(e));
										setOpen(false);
										return v;
									} else if (index_cont === 13) {
										result_flag = true;
										return v;
									}
								})
								.catch((e) => {
									console.log(e);
								})
						);
					})
					.catch((e) => console.log(e));
			})
			.catch((err: any) => {
				console.log(err);
			});
	};

	const custExcelUpload = useCallback(async (e) => {
		try {
			let _f = e.target.files[0]; // 파일
			let reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;
			let cust_List: any = [];
			let valid_flag = true; // 엑셀 검사 Flag
			reader.onload = (event: any) => {
				handleToggle();
				let count = 0;
				let result_list: any = [];
				// @엑셀 읽기
				let bstr = event.target.result;
				let wb = XLSX.read(bstr, {
					type: rABS ? "binary" : "array",
					bookVBA: true,
				});
				let wsname = wb.SheetNames[0];
				let ws = wb.Sheets[wsname];
				let excel_array = XLSX.utils.sheet_to_json(ws);

				excel_array.forEach((elements: any, idx: number) => {
					if (idx >= 0) {
						console.log(Object.keys(elements).length);
						if (Object.keys(elements).length === 18) {
							let code_result = _setCodeExist(common.code, 'G023', elements["가입경로"]);
							console.log("---- code list ----");
							console.log(code_result);

							let cust_json = {
								// 고객정보
								email: _isEmpty(elements["이메일"]) ? "" : elements["이메일"].toString(),
								cust_name: _isEmpty(elements["고객명"]) ? (valid_flag = false) : elements["고객명"].toString(),
								phone: _isEmpty(elements["연락처"]) ? (valid_flag = false) : elements["연락처"].toString(),
								addr: _isEmpty(elements["주소"]) ? (valid_flag = false) : elements["주소"].toString(),
								join_dtime: _getNowDate(),
								join_chnl: _isEmpty(elements["가입경로"]) && !_setCodeExist(common.code, 'G023', elements["가입경로"]) ? "" : elements["가입경로"].toString(),
								cust_stat: "01",
							};
							// 수선품 정보
							let product_json = {
								// 고객정보
								prod_no: "",
								rcpt_no: "",
								org_no: "01",
								cust_no: "",
								rcpt_name: _isEmpty(elements["고객명"]) ? (valid_flag = false) : elements["고객명"].toString(),
								// 수선품
								rcpt_chnl: _isEmpty(elements["접수경로"]) || !_setCodeExist(common.code, 'G007', elements["접수경로"]) ? "01" : elements["접수경로"].toString(),
								repr_st_dt: _isEmpty(elements["입고일"]) ? _getNowDate() : _setUTCDate(elements["입고일"].toString()),
								repr_req_dt: _isEmpty(elements["출고요청일"]) ? _getNowDate() : _setUTCDate(elements["출고요청일"].toString()),
								send_typ: _isEmpty(elements["발송방식"]) || !_setCodeExist(common.code, 'G007', elements["발송방식"]) ? "01" : elements["발송방식"].toString(),
								is_dust_bag: elements["더스트백여부"] === "Y" ? true : false,
								req_cont: _isEmpty(elements["고객 요청사항"]) ? "" : elements["고객 요청사항"].toString(),
								brand_cd: _isEmpty(elements["브랜드"]) || !_setCateCodeExist(common.category, '0100000000', elements["브랜드"]) ? (valid_flag = false) : elements["브랜드"].toString(),
								cate_cd: _isEmpty(elements["수선종류"]) || !_setCodeExist(common.code, 'G025', elements["수선종류"]) ? (valid_flag = false) : elements["수선종류"].toString(),
								// 정산
								calc_typ: _isEmpty(elements["납입방식"]) || !_setCodeExist(common.code, 'G011', elements["납입방식"]) ? (valid_flag = false) : elements["납입방식"].toString(),
								tot_pay: _isEmpty(elements["수선금"]) ? "0" : elements["수선금"].toString(),
								frst_pay: _isEmpty(elements["선입금"]) ? "0" : elements["선입금"].toString(),
								last_pay: "0",
								prod_stat: "01",
								calc_stat: "01",
								calc_acc: "01",
								frst_pay_dtime: _getNowDate(),
								last_pay_dtime: _getNowDate(),
								calc_no: "",
								// category
								cate_01: _isEmpty(elements["브랜드"]) || !_setCateCodeExist(common.category, '0100000000', elements["브랜드"]) ? (valid_flag = false) : elements["브랜드"].toString(), // 브랜드 (0100000000)
								cate_02: _isEmpty(elements["품목"]) || !_setCateCodeExist(common.category, '0200000000', elements["품목"]) ? "" : elements["품목"].toString(),		// 0200000000 품목
								cate_03: "",
								cate_04: "",
								cate_05: "",
								cate_06: "",
								cate_07: "",
								cate_08: "",
								cate_09: "",
								cate_10: "",
								cate_11: "",
								cate_12: "",
								cate_13: "",
								cate_14: "",
							};
							if (valid_flag) {
								addCust(cust_json)
									.then((v: any) => {
										if (v.type === "CUST_FAIL" && v.status === 400) {
											// sh.heo  === status 형변환이 이루어지지 않아 오동작 수정 2021-02-25
											//기존고객 Process
											let cust_info = {
												cust_name: elements["고객명"],
												phone: elements["연락처"],
												limit: "1",
											};
											// 고객 정보 불러오기
											getCust(cust_info).then((cust: any) => {
												let rcpt_info = {
													rcpt_no: "",
													cust_no: cust.payload[0].cust_no,
													cust_name: cust_info.cust_name,
													cnslt_no: "",
													rcpt_chnl: product_json.rcpt_chnl,
													rcpt_dtime: _getNowDate(),
													rcpt_stat: "01",
													rcpt_name: cust_info.cust_name,
												};
												// 수선품 등록 영역
												console.log("**_addExcleValue**");
												let reValue = addProduct(
													rcpt_info,
													cust_json,
													product_json,
													excel_array.length,
													idx + 1
												); // method 호출
												result_list.push(reValue);
												console.log(reValue);
											});
											cust_List.push(cust_json.cust_name);
										} else {
											// 신규 고객															
											let rcpt_info = {
												rcpt_no: "",
												cust_no: v.payload.cust_no,
												cust_name: v.payload.cust_name,
												cnslt_no: "",
												rcpt_chnl: product_json.rcpt_chnl,
												rcpt_dtime: _getNowDate(),
												rcpt_stat: "01",
												rcpt_name: v.payload.cust_no,
											};
											// 수선품 등록 영역
											console.log("**_addExcleValue**");
											let reValue = addProduct(
												rcpt_info,
												cust_json,
												product_json,
												excel_array.length,
												idx + 1
											); // method 호출
											result_list.push(reValue);
											console.log(reValue);
										}
										count = count + 1;
									})
									.then(() => {
										// 비동기식 통신 때문에 최종 호출 판단 목적
										if (count === excel_array.length) {
											// setOpen(false);
											if (cust_List.length > 0) {
												common.msg =
													cust_List.join() + "  정보가 등록되었습니다.";
												common.isMsg = true;
												return dispatch({ type: "COM_ALERT" }); // 안내메시지
											} else {
												common.msg = " 등록되었습니다.";
												common.isMsg = true;
												return dispatch({ type: "COM_ALERT" }); // 안내메시지
											}
										}
									})
									.then(() => {
										getRcpt(receipt.search).then((d) => dispatch(d));
									})
									.catch((e) => {
										console.log(e);
										setOpen(false);
									});
							} else {
								setOpen(false);
								common.msg = "엑셀 데이터를 확인해주세요.";
								common.isMsg = true;
								getRcpt(receipt.search)
									.then((d) => dispatch(d))
									.catch((e) => console.log(e));
								return dispatch({ type: "COM_ALERT" }); // 안내메시지
							}
						} else {
							setOpen(false);
							common.msg = (idx + 1).toString() + " 행을 확인해주세요.";
							common.isMsg = true;
							getRcpt(receipt.search)
								.then((d) => dispatch(d))
								.catch((e) => console.log(e));
							return dispatch({ type: "COM_ALERT" }); // 안내메시지
						}
					} else {
						setOpen(false);
					}
				});
			};
			reader.readAsBinaryString(_f);
		} catch (_e) {
			//예외처리
			setOpen(false);
			let err = _e;
			console.log(err);
			navigate("/main", { replace: true });
			common.msg = "페이지를 새로고침합니다.";
			common.isMsg = true;
			return dispatch({ type: "COM_ALERT" }); // 안내메시지
		}
	}, [product, common, navigate, dispatch]);
	
	// [init] ######################################################################
	// 콤마 타입 설정
	const _setComma = _setFormatComma();
	// 데이트 타입 설정
	const _setDate = _setFormatDate();
	// 접수경로명 조회
	const _setChnlName = _setFormatCode(common.code, "G002");
	// 접수상태명 조회
	const _setStateName = _setFormatCode(common.code, "G018");
	// 카카오톡 조회
	const _setGridKakaoStatus = _setKakaoState();
	// 카테고리명 조회
	const _setCateName = _setFormatCategory(common.category);
	// 수선종류 조회
	const _setItemName = _setFormatCode(common.code, 'G025');

	const _setTime = _setFormatTime();

	//연락처 조회
	const _setGridPhone = _setFormatPhone();

	//@ 초기 데이터 (Table 컬럼)
	const columns = [
		{ field: "id", headerAlign: "center", headerName: "번호", hide: true, },
		{ field: "rcpt_no", headerAlign: "center", headerName: "접수번호", width: 130, },
		{ field: "cust_no", headerAlign: "center", headerName: "고객번호", hide: true, },
		{ field: "cust_name", headerAlign: "center", headerName: "고객명", width: 140, },
		{ field: 'phone', headerAlign: 'center', headerName: '연락처', width: 130, ..._setGridPhone }, //# 전화번호 추가 2021-11-17
		{ field: "req_cont", headerAlign: "center", headerName: "내역", width: 270, },
		{ field: "rcpt_dtime", headerAlign: "center", headerName: "접수일", width: 120, ..._setDate, hide: true },
		{ field: "reg_dtime", headerAlign: "center", headerName: "접수일", width: 180, ..._setTime},
		{ field: "rcpt_chnl", headerAlign: "center", headerName: "접수경로", width: 100, ..._setChnlName, hide: true, },
		{ field: "brand_cd", headerAlign: "center", headerName: "브랜드", width: 150, ..._setCateName, hide: true },
		{ field: "brand_name", headerAlign: "center", headerName: "브랜드", width: 150, },
		{ field: "cate_cd", headerAlign: "center", headerName: "수선종류", width: 120, ..._setItemName, hide: true },
		{ field: "cate_name", headerAlign: "center", headerName: "수선종류", width: 120, },
		{ field: "tot_pay", headerAlign: "center", headerName: "수선비", width: 120, ..._setComma, },
		{ field: "frst_pay", headerAlign: "center", headerName: "선금 ", width: 120, ..._setComma, },
		{ field: "rcpt_stat", headerAlign: "center", headerName: "접수상태", ..._setStateName, },
		{ field: "chk_kakao_rcpt", headerAlign: "center", headerName: "알림톡", ..._setGridKakaoStatus, },
		{ field: "reg_user", headerAlign: "center", headerName: "작성자", width: 80, },
		{ field: "last_mod_user", headerAlign: "center", headerName: "수정자", width: 80, },
	];

 
	//@ 최초 실행
	useEffect(() => {
		handleInit();
	}, [handleInit]);

	// [Grid] ########################################################################
	let datagrid;

	if (_isEmpty(receipt.rows)) {
		datagrid = (
			<Typography variant="h6" align="center">
				데이터가 존재하지 않습니다.
			</Typography>
		);
	} else {
		datagrid = <DataGrid
			headerHeight={40}
			rowHeight={30}
			columns={columns}
			rows={receipt.rows}
			pageSize={14}
			onRowClick={handleChange}
		/>;
	}
	// [Render] ######################################################################
	return (
		<React.Fragment>
			<Toolbar className={classes.toolbar}>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={2}>
						<Typography variant="h6">접수이력</Typography>
					</Grid>
					<Grid item xs={6}>
						<a href='https://kr.object.iwinv.kr/exceluploadfrom/%EC%A0%91%EC%88%98%20%EB%93%B1%EB%A1%9D_20210204.xlsx' download className={classes.formLink}>
							엑셀양식
						</a>
					</Grid>
					<Grid item xs={4}>
						<Grid container justify="flex-end" alignItems="center">
							{/* <Grid item xs={3}></Grid> */}
							<Grid item xs={6}>
								{/* 엑셀업로드 */}
								{/* <ReceiptsExcelUpload /> */}
								<label htmlFor="raised-button-file">
									<Button
										component="span"
										className={classes.iconButton}
										color="inherit"
									// onClick={handleToggle}
									>
										<Publish />
										<Typography variant="h6">엑셀접수</Typography>
									</Button>
									<Backdrop className={classes.backdrop} open={open}>
										<CircularProgress color="inherit" />
									</Backdrop>
								</label>
								<input
									style={{ display: "none" }}
									id="raised-button-file"
									name="raised-button-file"
									placeholder={"파일을 첨부해주세요"}
									type="file"
									required={false}
									onChange={(e: any) => custExcelUpload(e)}
								/>
							</Grid>
							<Grid item xs={3}>
								{/* 전체목록 */}
								<IconButton
									className={classes.iconButton}
									aria-label="전체목록"
									color="inherit"
									onClick={() => {
										common.search = {};
										handleInit();
									}}
								>
									<List />
									<Typography variant="h6">전체</Typography>
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
			{/* 검색기능 */}
			<div className={classes.search}>
				<Formik
					enableReinitialize
					initialValues={{
						// 기본정보
						st_dt: _isEmpty(receipt.srch)
							? ""
							: _isEmpty(receipt.srch.st_dt)
								? ""
								: _setDateYYYYMMDD(receipt.srch.st_dt, "-"), // 검색시작일
						ed_dt: _isEmpty(receipt.srch)
							? _setDateYYYYMMDD("", "-")
							: _isEmpty(receipt.srch.ed_dt)
								? _setDateYYYYMMDD("", "-")
								: _setDateYYYYMMDD(receipt.srch.ed_dt, "-"), // 검색종료일

						rcpt_stat: _isEmpty(receipt.srch)
							? ""
							: _isEmpty(receipt.srch.rcpt_stat)
								? ""
								: receipt.srch.rcpt_stat, // 접수상태
						rcpt_chnl: _isEmpty(receipt.srch)
							? ""
							: _isEmpty(receipt.srch.rcpt_chnl)
								? ""
								: receipt.srch.rcpt_chnl, // 접수채널

						srch_key: _isEmpty(receipt.srch)
							? "cust_name"
							: _isEmpty(receipt.srch.srch_key)
								? "cust_name"
								: receipt.srch.srch_key, // 검색키
						srch_val: _isEmpty(receipt.srch)
							? ""
							: _isEmpty(receipt.srch.srch_val)
								? ""
								: receipt.srch.srch_val, // 검색어
						chk_kakao_rcpt: _isEmpty(receipt.srch)
							? ""
							: _isEmpty(receipt.srch.chk_kakao_rcpt)
								? ""
								: receipt.srch.chk_kakao_rcpt,
						limit: '100' // 노출 데이터 수
					}}
					validationSchema={Yup.object().shape({
						// 밸리데이션 불필요
					})}
					onSubmit={(v) => {
						handleSearch(v);
					}}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						touched,
						values,
					}) => (
						<form onSubmit={handleSubmit}>
							{/* 그리드 */}
							<Grid container justify="center" alignItems="center">
								{/* 기간 */}
								<Grid item xs={4}>
									<Grid container justify="center">
										<Grid item xs={5}>
											<FormDate
												id="st_dt"
												label="접수일(시작)"
												value={values.st_dt}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={1}>
											<Typography
												variant="h3"
												className={classes.searchTypography}
												align="center"
											>
												~
											</Typography>
										</Grid>
										<Grid item xs={5}>
											<FormDate
												id="ed_dt"
												label="접수일(종료)"
												value={values.ed_dt}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={1}></Grid>
									</Grid>
								</Grid>
								{/* 구분 */}
								<Grid item xs={2}>
									<Grid container justify="center">
										{/* <Grid item xs={1}></Grid> */}
										<Grid item xs={10}>
											{/* 접수상태 */}
											<FormSelect
												id="rcpt_stat"
												label="접수상태"
												value={values.rcpt_stat}
												item={_setCodeFilter(common.code, "G018")}
												firstDefault={true}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={2}></Grid>
									</Grid>
								</Grid>
								{/* 검색어 */}
								<Grid item xs={6}>
									{/* 구분 */}
									<Grid
										container
										justify="center"
										alignItems="center">
										{/* <Grid item xs={1}></Grid> */}
										<Grid item xs={2}>
											<FormText
												id='limit'
												label='데이터 수'
												value={values.limit}
												// disabled={true}
												required={true}
												blur={handleBlur}
												change={handleChange} />
										</Grid>
										<Grid item xs={3}>
											<FormSelect
												id="srch_key"
												label="검색구분"
												firstDefault={false}
												value={values.srch_key}
												item={[
													{ code: "cust_name", name: "고객명" },
													{ code: "rcpt_no", name: "접수번호" },
													{ code: "phone", name: "연락처" }
												]}
												required={true}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={7}>
											<Paper className={classes.paper}>
												<InputBase
													className={classes.input}
													placeholder="검색어를 입력해주세요"
													id="srch_val"
													name="srch_val"
													value={values.srch_val}
													type="text"
													error={Boolean(
														touched.srch_val && errors.srch_val
													)}
													onBlur={handleBlur}
													onChange={handleChange}
												/>
												<IconButton
													type="submit"
													className={classes.iconButton}
													aria-label="search"
												>
													<Search />
												</IconButton>
											</Paper>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			</div>
			<div className={classes.grid}>{datagrid}</div>
			{/* 버튼영역 */}
			<Box
				ml={1} mr={1} mb={1}
				display="flex"
				justifyContent="flex-end">
				{/* 접수증 */}
				<Button
					variant="contained"
					color="primary"
					size="small"
					className={classes.button}
					startIcon={<Print />}
					onClick={handleClickOpen}
				>
					접수증
				</Button>
			</Box>
			{/* // 접수증 컴포넌트 */}
			<Receipts />
		</React.Fragment>
	);
};

export default ReceiptList;
