/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Customer page
**/
/* @material import */
import {
  Box,
  makeStyles
} from '@material-ui/core';
/* react import */
import React from 'react';
import CustomerEdit from "./CustomerEdit";
import CustomerView from "./CustomerView";
import CustomerSch from "./CustomerSch";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: 340, 
    height: '100%',
    paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3)   
  },
  toolbar: {
    width: '100%',
    minHeight: 35,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  }, 
  button: {
    margin: theme.spacing(1),
  },
  box: {
    margin: theme.spacing(1)
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const Customer = () => {
  const classes = useStyles();
  
  return (
    <div
      className={classes.root}
    >
      {/* 고객검색 */}
      <Box 
        mb={1}
        justifyContent="center"
        >   
        <CustomerSch />
      </Box>
      {/* 고객정보 */}
      <Box 
        mb={1}
        justifyContent="center" 
        >
        {
          (window.sessionStorage.getItem('user_grd') === '09') ?
            <CustomerView />                                  
            :
            <CustomerEdit />                                  
        }
      </Box> 
    </div>
  );
};

export default Customer;
