/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Consult View Page
**/
/* @material import */
import {
  Box,

  Grid,

  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
/* react import */
import React from 'react';
import { useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import {
  _isEmpty,
  _setAddComma,
  _setCateCodeFilter,
  _setCodeFilter,
  _setDateYYYYMMDD
} from "./../../utils/Common";
import FormDate from "./../../utils/form/FormDate";
import FormText from "./../../utils/form/FormText";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
    height: '100%', 
    width: '100%',
    maxHeight: '90%',
    minWidth: '90%',
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  button2: {
    marginTop: 16,
  },  
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 10,
  }  
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ConsultView = () => {
  const classes = useStyles();     // CSS
  // const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################  
  const consult = useSelector((state:any) => state.consult);    // consult
  const common = useSelector((state:any) => state.common);      // common

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid 
        container
        justify="center"
        alignItems="center"
        >
          <Grid item xs={6}>
            <Typography variant="h6">상담등록</Typography>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>          
          </Grid>          
        </Grid>
      </Toolbar>

      <Formik
          enableReinitialize
          initialValues={{
              cnslt_no: _isEmpty(consult.info.cnslt_no) ? '' : consult.info.cnslt_no,      // 상담번호
              cnslt_dtime: _isEmpty(consult.info.cnslt_dtime) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(consult.info.cnslt_dtime, '-'),    // 상담일
              // cust_no: _isEmpty(consult.info.cust_no) ? '' : consult.info.cust_no,          // 고객번호
              cust_no: _isEmpty(consult.info.cust_no) ? 
                          (_isEmpty(common.search.cust_no) ? '' : common.search.cust_no) : consult.info.cust_no,   // 고객번호              
              cust_name: _isEmpty(consult.info.cust_name) ? 
                          (_isEmpty(common.search.cust_name) ? '' : common.search.cust_name) : consult.info.cust_name,   // 고객명
              phone: _isEmpty(consult.info.phone) ?
                          (_isEmpty(common.search.phone) ? '' : common.search.phone) : consult.info.phone,   // phone  
              rcpt_chnl: _isEmpty(consult.info.rcpt_chnl) ? '' : consult.info.rcpt_chnl,   // 상담경로              
              brand_cd: _isEmpty(consult.info.brand_cd) ? '' : consult.info.brand_cd,      // 브랜드
              item_cd: _isEmpty(consult.info.item_cd) ? '' : consult.info.item_cd,         // 품목
              repr_pay: _isEmpty(consult.info.repr_pay) ? '' : _setAddComma(consult.info.repr_pay),             // 수선비
              galy_url: _isEmpty(consult.info.galy_url) ? '' : consult.info.galy_url,      // 이미지
              qst_cont: _isEmpty(consult.info.qst_cont) ? '' : consult.info.qst_cont,      // 문의
              ans_cont: _isEmpty(consult.info.ans_cont) ? '' : consult.info.ans_cont,      // 답변 
              cnslt_stat: _isEmpty(consult.info.cnslt_stat) ? '' : consult.info.cnslt_stat // 답변 
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={(v) => {}}
          >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (   
              <form onSubmit={handleSubmit}>      
                <Box ml={3} mb={1} mt={1} justifyContent="center">
                  <Grid 
                      container
                      justify="center"
                      alignItems="center" 
                      className={classes.root}>
                    {/* 상담일 */}
                    <Grid item xs={4}>
                        <FormDate 
                          id='cnslt_dtime' 
                          label='상담일' 
                          value={values.cnslt_dtime}
                          disabled={true}
                          required={true}                   
                          blur={handleBlur} 
                          change={handleChange} />
                    </Grid>
                    {/* 고객명 */}
                    <Grid item xs={4}>
                        <FormText 
                          id='cust_name' 
                          label='고객명' 
                          value={values.cust_name}
                          disabled={true}
                          required={true}
                          blur={handleBlur}
                          change={handleChange} />
                    </Grid>
                    {/* 상담경로 */}
                    <Grid item xs={4}>
                      <FormSelect
                        id='rcpt_chnl' 
                        label='상담경로' 
                        value={values.rcpt_chnl}
                        item={_setCodeFilter(common.code, 'G024')}
                        disabled={true}
                        required={true}                                        
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid>
                    {/* 브랜드 */}
                    <Grid item xs={4}>
                      <FormSelect 
                        id='brand_cd' 
                        label='브랜드' 
                        value={values.brand_cd}
                        item={_setCateCodeFilter(common.category, '0100000000')}
                        disabled={true}
                        required={true}                     
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid>
                    {/* 수선종류 */}
                    <Grid item xs={4}>
                      <FormSelect 
                        id='item_cd' 
                        label='수선종류' 
                        value={values.item_cd}
                        item={_setCodeFilter(common.code, 'G025')}
                        disabled={true}
                        required={true}                                        
                        blur={handleBlur} 
                        change={handleChange} />                   
                    </Grid>
                    {/* 수선비 */}
                    <Grid item xs={4}>
                        <FormText 
                          id='repr_pay' 
                          label='수선비' 
                          value={values.repr_pay}
                          disabled={true}
                          required={true}                   
                          blur={handleBlur} 
                          change={handleChange} /> 
                    </Grid>        
                    {/* 제공이미지 */}
                    <Grid item xs={9}>
                      <FormText 
                        id='galy_url' 
                        label='제공이미지'
                        value={values.galy_url}
                        disabled={true}
                        required={true}                   
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    {/* 문의사항 */}
                    <Grid item xs={12}>
                      <FormText 
                        id='qst_cont' 
                        label='문의사항' 
                        value={values.qst_cont}
                        disabled={true}
                        required={true}                   
                        blur={handleBlur} 
                        change={handleChange} />   
                    </Grid>
                    {/* 답변사항 */}
                    <Grid item xs={12}>
                      <FormText 
                        id='ans_cont' 
                        label='답변사항' 
                        value={values.ans_cont}
                        disabled={true}
                        required={true}                  
                        blur={handleBlur} 
                        change={handleChange} /> 
                    </Grid>                              
                  </Grid>
                </Box>
                {/* 버튼영역 */}
                <Box
                  ml={1} mr={1} mb={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                </Box>    
              </form>          
          )}
      </Formik>
    </React.Fragment>
  );
};

export default ConsultView;
