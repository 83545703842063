/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : common ajax network
**/
import axios from "axios"; // 네트워크 통신

/* config */
// const DOMAIN = "http://localhost";
const DOMAIN = "http://"+window.location.hostname;

/* axios config */
// header
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.post['accept'] = 'application/json';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Authorization'] = 'Basic Og==';
// etc
axios.defaults.withCredentials = true; // 쿠키 데이터를 전송받기 위해
axios.defaults.responseType = 'json';
// 인터셉터 토큰 변경
axios.interceptors.request.use((res) => {
  const access_token = window.sessionStorage.getItem('access_token');
  res.headers.Authorization =  access_token ? `Bearer ${access_token}` : '';
  return res;
});

/* component */
//@ from data default
export const _ajaxForm = (_method:any, _url:any, _data:any, _success:any, _fail:any) => {
  // ajax 통신
  return axios({
    method: _method,
    url: DOMAIN + _url,
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: _data
  });
  // .catch((err) => {
  //   if (err.response) {
  //     // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
  //     console.log(err.response.data);
  //     console.log(err.response.status);
  //     console.log(err.response.headers);
  //   }
  //   else if (err.request) {
  //     // 요청이 이루어 졌으나 응답을 받지 못했습니다.
  //     // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
  //     // Node.js의 http.ClientRequest 인스턴스입니다.
  //     console.log(err.request);
  //   }
  //   else {
  //     // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
  //     console.log('Error', err.message);
  //   }
  //   console.log(err.config);
  // });
};

//@ from data async
export const _ajaxFormAsync = async (_method:any, _url:any, _data:any, _success:any, _fail:any) => {
  // ajax 통신
  return axios({
    method: _method,
    url: DOMAIN + _url,
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: _data
  })
  .then((res) => ({
      type: _success,
      payload: res.data
    }))
  .catch((err) => ({
      type: _fail,
      payload: err.response.data
    }));
};

//@ get params default
export const _ajaxGetJson = (_method:any, _url:any, _data:any, _success:any, _fail:any) => {
  // ajax 통신
  return axios({
    method: _method,
    url: DOMAIN + _url,
    headers: { 
      'Content-Type': 'applicapplication/json'
    },
    params: _data
  });
};

//@ get params async
export const _ajaxGetJsonAsync = async (_method:any, _url:any, _data:any, _success:any, _fail:any) => {
  // ajax 통신
  return axios({
    method: _method,
    url: DOMAIN + _url,
    headers: { 
      'Content-Type': 'applicapplication/json'
    },
    params: _data
  })
  .then((res) => ({
      type: _success,
      payload: res.data
    }))
  .catch((err) => ({
      type: _fail,
      payload: err.response.data
    }));
};

//@ post data default
export const _ajaxPostJson = (_method:any, _url:any, _data:any, _success:any, _fail:any) => {
  // ajax 통신
  return axios({
    method: _method,
    url: DOMAIN + _url,
    headers: { 
      'Content-Type': 'applicapplication/json'
    },
    data: _data
  });
};

//@ post data async
export const _ajaxPostJsonAsync = async (_method:any, _url:any, _data:any, _success:any, _fail:any) => {
  // ajax 통신
  return axios({
    method: _method,
    url: DOMAIN + _url,
    headers: { 
      'Content-Type': 'applicapplication/json'
    },
    data: _data
  })
  .then((res) => ({
      type: _success,
      payload: res.data
    }))
  .catch((err) => ({
      type: _fail,
      payload: err.response.data,      
      status: err.response.status      // error status HSH
    }));
};

//@ post data async
export const _ajaxPostFormDataAsync = async (_method:any, _url:any, _data:any, _success:any, _fail:any) => {
  // ajax 통신
  return axios({
    method: _method,
    url: DOMAIN + _url,
    headers: { 
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    },
    maxContentLength: 100 * 1024 * 1024,
    maxBodyLength: 100 * 1024 * 1024,    
    data: _data
  })
  .then((res) => ({
      type: _success,
      payload: res.data
    }))
  .catch((err) => ({
      type: _fail,
      payload: err.response.data,
      status: err.response.status      // error status HSH
    }));
};

//@ post data async
// Excel File Download Popup Activate 
export const _ajaxPostExcelDataAsync = async (_method:any, _url:any, _data:any, _fail:any) => {
  // ajax 통신
  return axios({
		method: _method,
		url: DOMAIN + _url,
		headers: {
			"Content-Type": "application/octet-stream",
      "Content-Disposition": "attachment;filename=excel_download.xlsx"      
		},
    data: _data,
    responseType: 'blob'
	})
		.then((res) => {      
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "excel_download.xlsx");
      document.body.appendChild(link);
      link.click();			
		})
		.catch((err) => ({
			type: _fail,
			payload: err.response.data,
    }));
};