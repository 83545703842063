/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Customer Search page
**/
/* @material import */
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import { DoubleArrow, Search } from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import * as Yup from 'yup';
import { getProd } from '../../utils/redux/actions/ProdAction';
import {
  _isEmpty,
  _setPhone,
  _setSumLast
} from "./../../utils/Common";
import FormSelect from "./../../utils/form/FormSelect";
import { getCnsltSrch } from "./../../utils/redux/actions/CnsltAction";
import { getCust, getCustInfo } from "./../../utils/redux/actions/CustAction";
import { getRcpt } from "./../../utils/redux/actions/RcptAction";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    width: '100%',
    minHeight: 35,
    color: '#fff',
    backgroundColor: '#1976d2',
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 30
  },
  paperList: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },  
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  }, 
  button: {
    margin: theme.spacing(1),
  },
  box: {
    margin: theme.spacing(1)
  },
  ListItemText: {
    fontSize: 14
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const CustomerSch = () => {
  const classes = useStyles();
  const dispatch = useDispatch();  // dispatch hook
  const navigate = useNavigate();   // link

  // [state] ######################################################################
  const customer = useSelector((state:any) => state.customer);    // customer
  const common = useSelector((state:any) => state.common);        // common
  
  // [Events] ######################################################################
  //@ 고객검색
  const hendleSearch = useCallback((v) => {

    let body = {}; // param 구성

    if(v.search_key === '01') {  // 고객명
      body = {
        cust_name: v.search_text
      };
    } else {                     // 연락처
      body = {
        phone: _setPhone(v.search_text)
      };
    }
    // 초기화
    customer.list = [];
    customer.info = [];
    customer.isEdit = false;
    
    // 고객목록
    getCust(body).then((res:any) => dispatch(res))
              .then((res) => {
                if(res.payload.length < 1) {
                  common.msg = '검색 결과가 존재하지 않습니다.';
                  common.isMsg = true;        
                  return dispatch({type:'COM_ALERT'});       // 안내메시지
                }
              });

  },[customer, common, dispatch]);
  
  //@ 고객정보선택
  const handleCustInfo = useCallback((v) => {
    // 고객 정보 선택
    getCustInfo(v).then((res:any) => {
      let body = { cust_no: v };
      getProd(body).then((prod:any) => {
        let rows = prod.payload;
        let pay = '0';
        let count = '0';
        if(!_isEmpty(rows)) {
          count = rows.length;
          pay = _setSumLast(rows);
        }
        res.payload[0]['cust_pay'] = pay;      // 미수금
        res.payload[0]['cust_count'] = count.toString();  // 수선수
        console.log(res);
        dispatch(res);
      }).catch((e) => console.log(e));
    }).catch((e) => console.log(e));
  },[dispatch]);

  //@ 상담 이동
  const goConsult = useCallback((e) => {

    if(_isEmpty(customer.info.cust_no)) {
      common.msg = '고객을 선택해주세요';
      common.isMsg = true;        
      dispatch({type:'COM_ALERT'});       // 안내메시지
    } else {
      let body = {
        cust_no: customer.info.cust_no,
        cust_name: customer.info.cust_name,
        phone: customer.info.phone
      }
      common.isSearch = false;
      common.search = body
      dispatch({ type: 'COM_SEARCH' });      
      //dispatch({ type: 'CNSLT_GET_INFO', payload: body });       
      getCnsltSrch(body).then((d) => dispatch(d))
                        .catch((e) => console.log(e));               
      navigate('/consult', { replace: true });
      dispatch({type:'COM_TABS_LOCATION', payload: 1 });   
    }

  },[customer, common, navigate, dispatch]);  
  
  //@ 접수 이동
  const goReceipt = useCallback((e) => {

    if(_isEmpty(customer.info.cust_no)) {
      common.msg = '고객을 선택해주세요';
      common.isMsg = true;        
      dispatch({type:'COM_ALERT'});       // 안내메시지
    } else {
      let body = {
        cust_no: customer.info.cust_no,
        cust_name: customer.info.cust_name
      }
      common.isSearch = false;
      common.search = body
      dispatch({ type: 'COM_SEARCH' });      
      // dispatch({ type: 'RCPT_GET_INFO', payload: body }); 
      getRcpt(body).then((d) => dispatch(d))
                  .catch((e) => console.log(e));
      navigate('/receipt', { replace: true });
      dispatch({type:'COM_TABS_LOCATION', payload: 2 });                    
    }

  },[customer, common, navigate, dispatch]);   

  // [Method] ######################################################################  
  // 카테고리 목록 조회
  const getCustList = (props:any) => {

    const { data, index, style } = props;

    return (
      <ListItem 
        button 
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "9px",
          background: customer.info.cust_no === data[index].cust_no ? "#9ac0e6" : "white",
          cursor: "pointer"
        }} 
        key={index}
        onClick={() => {
          handleCustInfo(data[index].cust_no);
        }}
        >
        <ListItemText 
          primary={
            <Typography variant="subtitle2">
              {data[index].cust_name+', '+data[index].phone}
            </Typography>       
          } 
        />
      </ListItem>
    );
  };

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      {/* 타이틀 */}
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6">고객검색</Typography>
      </Toolbar>          
      {/* 검색 */}
      <Box
        mt={1}
        display="flex"
        justifyContent="center"
        className={classes.box}         
      >
          <Formik
                enableReinitialize
                initialValues={{
                  search_key  : '01',
                  search_text : ''
                }}
                validationSchema={Yup.object().shape({
                    search_text: Yup.string()
                                  .min(2, '2자 이상 입력해주세요')
                                  .required('검색어를 입력해주세요'),
                    search_key: Yup.string().required('검색 유형을 선택해주세요'),                    
                })}                  
                onSubmit={(v) => {
                  hendleSearch(v);  // 검색
                }}
                >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (   
                    <form onSubmit={handleSubmit}>
                        <Grid
                          container
                          justify="center"
                        >  
                          <Grid item xs={4}>
                            <FormSelect
                              id='search_key' 
                              firstDefault={false}
                              value={values.search_key}
                              item={[
                                {code:'01', name:'고객명'},
                                {code:'02', name:'연락처'}
                              ]}
                              errors={errors.search_key}
                              touched={touched.search_key}                      
                              blur={handleBlur} 
                              change={handleChange} />  
                          </Grid>
                          <Grid item xs={8}>
                            <Paper className={classes.paper}>
                              <InputBase
                                className={classes.input}
                                placeholder="검색어를 입력해주세요"
                                id='search_text'
                                name='search_text'
                                value={values.search_text}
                                type='text'
                                error={Boolean(touched.search_text && errors.search_text)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <IconButton 
                                type="submit" 
                                className={classes.iconButton} 
                                aria-label="search">
                                <Search />
                              </IconButton>
                            </Paper>
                          </Grid>
                        </Grid>                                                                  
                    </form>          
                )}
            </Formik>                                                    
      </Box>
      {/* 검색결과 */}
      {customer.list.length > 0 && 
        <Box
          mt={1}
          display="flex"
          justifyContent="center" 
          className={classes.box}     
        >
          <Paper className={classes.paperList} elevation={8}>
            <FixedSizeList 
              height={customer.list.length*35} 
              width={330}
              itemSize={35}
              itemCount={customer.list.length} 
              itemData={customer.list}
              >                       
              {getCustList}
            </FixedSizeList>
          </Paper>
        </Box>
      }        
      {/* 버튼 */}
      <Box
        ml={1} mr={1}
        display="flex"
        justifyContent="flex-end"
        className={classes.box}      
        >
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<DoubleArrow />}
          onClick={goConsult}
        >
          상담
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<DoubleArrow />}
          onClick={goReceipt}
        >
          접수
        </Button>            
      </Box>
    </React.Fragment>      
  );
};

export default CustomerSch;
