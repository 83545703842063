/**
 * date         : 2020.10.30
 * creater      : nuriggum
 * description  : Users List Page
**/
/* @material import */
import {
	Box,
	Grid,
	IconButton,
	InputBase,
	makeStyles,
	Paper,
	Tooltip,
	Typography
} from '@material-ui/core';
import {
	DataGrid
} from '@material-ui/data-grid';
import {
	Search
} from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import {
	getUsers, getUsersInfo, getUsersSrch
} from "../../utils/redux/actions/UsersAction";
import { _clearSession, _isEmpty, _setFormatCode, _setFormatPhone } from "./../../utils/Common";
import UsersEdit from "./UserEdit";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
		width: "100%",
		maxHeight: "90%",
		minWidth: "90%",
		margin: theme.spacing(1),
	},
	grid: {
		height: "100%",
		width: "98%",
		maxHeight: "70%",
		minWidth: "98%",
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
		"& .MuiDataGrid-columnsContainer": {
			backgroundColor: "#9fa8da",
			color: "#ffffff",
		},
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	button: {
		margin: theme.spacing(1),
	},
	toolbar: {
		minHeight: 40,
		color: "#fff",
		backgroundColor: "#1976d2",
	},
	iconButton: {
		padding: 10,
	},
	paper: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "100%",
		height: 30,
		marginTop: 13,
	},
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const UsersList = () => {
	const classes = useStyles(); // CSS
	const dispatch = useDispatch(); // dispatch hook
	const navigate = useNavigate(); // link

	// [state] ######################################################################
	const users = useSelector((state: any) => state.users); // Users
	const common = useSelector((state: any) => state.common); // common

	// [Events] ######################################################################
	//@ 최초 실행
	const handleInit = useCallback(() => {
		// 사용자 조회
		getUsers().then((d) => dispatch(d));
	}, [dispatch]);

	//@ 사용자 검색
	const hendleSearch = useCallback((v) => {
		let body = {};
		if (_isEmpty(v.search_text)) {
			getUsers().then((d) => dispatch(d));
		} else {
			body = {
				user_name: v.search_text,
			};
			getUsersSrch(body)
				.then((res: any) => dispatch(res))
				.then((v: any) => {
					if (v.type === "USERS_FAIL") {
						if (v.status === "401") {
							common.msg = "세션이 만료되어 로그 아웃됩니다.";
							common.isMsg = true;
							_clearSession();
							navigate("/login", { replace: true });
							return dispatch({ type: "COM_ALERT" }); // 안내메시지
						} else {
							common.msg = v.status.toString();
							common.isMsg = true;
							return dispatch({ type: "COM_ALERT" }); // 안내메시지
						}
					}
				});
		}
	}, [navigate, common, dispatch]);

	//@ 사용자 선택
	const handleChange = useCallback((e) => {
		// 사용자 개별 조회
		// common.msg = e
		let body = {};
		body = {
			user_id: e.data.user_id,
		};
		getUsersInfo(body)
			.then((res: any) => dispatch(res))
			.then((res: any) => {
				if (res.status === "401") {
					common.msg = "세션이 만료되어 로그 아웃됩니다.";
					common.isMsg = true;
					_clearSession();
					navigate("/login", { replace: true });
					return dispatch({ type: "COM_ALERT" }); // 안내메시지
				} else {
					return dispatch({
						type: "USERS_GET_INFO",
						payload: e.data,
					});
				}
			});
	}, [common, navigate, dispatch]);

	const _setStateName = _setFormatCode(common.code, "G022");
	const _setGridPhone = _setFormatPhone();

	// [init] ######################################################################
	//@ 초기 데이터 (Table 컬럼)
	// sh.heo 2021-02-25 tooltip table cell의 값 mouse hover 시 표시 기능 추가.
	const columns = [
		{ field: "id", headerName: "번호", hide: true, width: 100 },
		{ field: "org_no", headerName: "지점번호", hide: true },
		{ field: "email", headerName: "Email", width: 300 },
		{ field: "user_id", headerName: "사용자ID", hide: true, width: 150 },
		{ field: "user_name", headerName: "이름", width: 200 },
		{ field: "phone", headerName: "전화번호", width: 300 },
		{ field: "is_active", headerName: "사용여부", width: 200 },
		{ field: "user_grd", headerName: "권한 등급", width: 200, ..._setStateName },
		{ field: 'reg_user', headerName: '작성자', width: 200 },
		{ field: 'last_mod_user', headerName: '수정자', width: 200 }
	];
	//@ 최초 실행
	useEffect(() => {
		//1. 상담 조회
		handleInit();
	}, [handleInit]);

	// [Grid] ######################################################################
	let datagrid;

	// Data Binding 영역
	if (_isEmpty(users.rows)) {
		datagrid = (
			<Typography variant="h6" align="center">
				데이터가 존재하지 않습니다.
			</Typography>
		);
	} else {
		datagrid = (
			<DataGrid
				headerHeight={40}
				rowHeight={30}
				columns={columns}
				rows={users.rows}
				pageSize={10}
				onRowClick={handleChange}
			/>
		);
	}
	// [Render] ######################################################################
	return (
		<React.Fragment>
			<Grid container justify="center" alignItems="center">
				<Grid item xs={12}>
					<Grid container justify="flex-end">
						<div>
							<Formik
								enableReinitialize
								initialValues={{
									search_text: "",
								}}
								validationSchema={Yup.object().shape({
									search_text: Yup.string()
								})}
								onSubmit={(v) => {
									hendleSearch(v); // 검색
								}}
							>
								{({
									errors,
									handleBlur,
									handleChange,
									handleSubmit,
									touched,
									values,
								}) => (
									<form onSubmit={handleSubmit}>
										<Grid item xs={10}>
											<Paper className={classes.paper}>
												<InputBase
													className={classes.input}
													placeholder="이름을 입력해주세요"
													id="search_text"
													name="search_text"
													value={values.search_text}
													type="text"
													error={Boolean(
														touched.search_text && errors.search_text
													)}
													onBlur={handleBlur}
													onChange={handleChange}
												/>
												<IconButton
													type="submit"
													className={classes.iconButton}
													aria-label="search"
												>
													<Search />
												</IconButton>
											</Paper>
										</Grid>
										<Grid item xs={2}></Grid>
									</form>
								)}
							</Formik>
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Box
				display="flex"
				flexDirection="column"
				height="100%"
				alignItems="flex-start"
			>
				<div className={classes.grid}>{datagrid}</div>
				<Grid container justify="center">
					<Grid item xs={10}></Grid>
					<Grid item xs={2}>
						<Grid container justify="flex-end">
							<Grid item>
								<UsersEdit />
							</Grid>
							<Grid item xs={1}></Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</React.Fragment>
	);
};
export default UsersList;