/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : KaKao Search List Page
**/
/* @material import */
import {
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  DataGrid
} from '@material-ui/data-grid';
import {
  List,
  Search
} from '@material-ui/icons';
import { Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import {
  _isEmpty,
  _setCodeFilter,
  _setDateYYYYMMDD,
  _setFormatCategory,
  _setFormatCode,
  _setFormatDate,
  _setUTCDate
} from "../../utils/Common";
import FormDate from "../../utils/form/FormDate";
import FormSelect from "../../utils/form/FormSelect";
import { getBotSrch } from "../../utils/redux/actions/BotSrchAction";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
  },
  grid: {
    height: '100%',
    width: '98%',
    maxHeight: '70%',
    minWidth: '98%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#9fa8da',
      color: '#ffffff'
    },
  },
  // 데이터 그리드 테이블 해더
  search: {
    margin: theme.spacing(1)
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    height: 30,
    marginTop: 13
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  searchTypography: {
    marginTop: 12
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const KaKaoSrchList = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const botSrch = useSelector((state: any) => state.botSrch);    // botSrch
  const common = useSelector((state: any) => state.common);      // common

  // [Events] ######################################################################
  //@ 최초 실행
  const handleInit = useCallback(() => {
    let body = {};
    getBotSrch(body).then((d) => dispatch(d))
      .catch((e) => console.log(e));
  }, [dispatch]);

  //@ 검색
  const handleSearch = useCallback((v) => {
    let body: any = {};
    if (!_isEmpty(v.st_dt)) body['st_dt'] = _setUTCDate(v.st_dt);
    if (!_isEmpty(v.ed_dt)) body['ed_dt'] = _setUTCDate(v.ed_dt);
    if (!_isEmpty(v.bot_stat)) body['bot_stat'] = v.bot_stat;
    if (!_isEmpty(v.srch_val)) body[v.srch_key.toString()] = v.srch_val;

    getBotSrch(body).then((d) => dispatch(d))
      .catch((e) => console.log(e));

  }, [dispatch]);

  // [init] ######################################################################
  // 데이트 타입 설정
  const _setDate = _setFormatDate();
  // 카테고리명 조회
  const _setCateName = _setFormatCategory(common.category);
  // 상담경로명 조회
  const _setCodeName = _setFormatCode(common.code, 'G019');

  //@ 초기 데이터 (Table 컬럼)
  const columns = [
    { field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
    { field: 'bot_search_no', headerAlign: 'center', headerName: '검색번호', hide: true },
    { field: 'kakao_id', headerAlign: 'center', headerName: '카카오ID', width: 150 },
    { field: 'kakao_name', headerAlign: 'center', headerName: '닉네임', hide: true, width: 80 },
    { field: 'access_dtime', headerAlign: 'center', headerName: '접근일시', width: 120, ..._setDate },
    { field: 'brand_name', headerAlign: 'center', headerName: '브랜드', width: 140 },
    { field: 'bot_cate_1', headerAlign: 'center', headerName: '브랜드', width: 140, ..._setCateName, hide: true },
    { field: 'bot_cate_2', headerAlign: 'center', headerName: '품목', width: 100, ..._setCateName },
    { field: 'bot_cate_3', headerAlign: 'center', headerName: '수선', width: 110, ..._setCateName },
    { field: 'bot_prod_no', headerAlign: 'center', headerName: '상품번호', width: 120 },
    { field: 'bot_stat', headerAlign: 'center', headerName: '상태', width: 70, ..._setCodeName }
  ];

  //@ 최초 실행
  useEffect(() => {
    //1. 상담 조회
    handleInit();
  }, [handleInit]);

  // [Grid] ######################################################################    
  let datagrid;

  if (_isEmpty(botSrch.rows)) {
    datagrid = <Typography
      variant="h6"
      align="center"
    >
      데이터가 존재하지 않습니다.
    </Typography>;
  } else {
    datagrid = <DataGrid
      headerHeight={40}
      rowHeight={30}
      columns={columns}
      rows={botSrch.rows}
      pageSize={5}
    // onRowClick={handleChange}
    />;
  }

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography variant="h6">카카오 수선품 검색</Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <Grid
              container
              justify="center"
              alignItems="center"
            >
              <Grid item xs={5}></Grid>
              <Grid item xs={7}>
                {/* 전체목록 */}
                <IconButton
                  className={classes.iconButton}
                  aria-label="전체목록"
                  color="inherit"
                  onClick={() => {
                    handleInit();
                  }}
                >
                  <List /><Typography variant="h6">전체</Typography>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      {/* 검색기능 */}
      <div
        className={classes.search}
      >
        <Formik
          enableReinitialize
          initialValues={{
            // 기본정보
            st_dt: _isEmpty(botSrch.srch) ? '' :
              _isEmpty(botSrch.srch.st_dt) ? '' : _setDateYYYYMMDD(botSrch.srch.st_dt, '-'), // 검색시작일
            ed_dt: _isEmpty(botSrch.srch) ? _setDateYYYYMMDD('', '-') :
              _isEmpty(botSrch.srch.ed_dt) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(botSrch.srch.ed_dt, '-'), // 검색종료일

            bot_stat: _isEmpty(botSrch.srch) ? '' :
              _isEmpty(botSrch.srch.bot_stat) ? '' : botSrch.srch.bot_stat,              // 검색상태

            srch_key: _isEmpty(botSrch.srch) ? 'kakao_id' :
              _isEmpty(botSrch.srch.srch_key) ? 'kakao_id' : botSrch.srch.srch_key,           // 검색키
            srch_val: _isEmpty(botSrch.srch) ? '' :
              _isEmpty(botSrch.srch.srch_val) ? '' : botSrch.srch.srch_val                   // 검색어
          }}
          validationSchema={Yup.object().shape({
            // 밸리데이션 불필요
          })}
          onSubmit={(v) => {
            handleSearch(v);
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
            <form onSubmit={handleSubmit}>
              {/* 그리드 */}
              <Grid
                container
                justify="center"
                alignItems="center"
              >
                {/* 기간 */}
                <Grid item xs={5}>
                  <Grid
                    container
                    justify="center"
                  >
                    <Grid item xs={5}>
                      <FormDate
                        id='st_dt'
                        label='접근일(시작)'
                        value={values.st_dt}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        variant="h3"
                        className={classes.searchTypography}
                        align="center">~ </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <FormDate
                        id='ed_dt'
                        label='접근일(종료)'
                        value={values.ed_dt}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Grid>
                {/* 구분 */}
                <Grid item xs={2}>
                  <Grid
                    container
                    justify="center"
                  >
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <FormSelect
                        id='bot_stat'
                        label='검색상태'
                        value={values.bot_stat}
                        item={_setCodeFilter(common.code, 'G019')}
                        firstDefault={true}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Grid>
                {/* 검색어 */}
                <Grid item xs={5}>
                  {/* 구분 */}
                  <Grid
                    container
                    justify="center"
                  >
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}>
                      <FormSelect
                        id='srch_key'
                        label='검색구분'
                        firstDefault={false}
                        value={values.srch_key}
                        item={[
                          { code: 'kakao_id', name: '카카오ID' }
                        ]}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={7}>
                      <Paper className={classes.paper}>
                        <InputBase
                          className={classes.input}
                          placeholder="검색어를 입력해주세요"
                          id='srch_val'
                          name='srch_val'
                          value={values.srch_val}
                          type='text'
                          error={Boolean(touched.srch_val && errors.srch_val)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <IconButton
                          type="submit"
                          className={classes.iconButton}
                          aria-label="search">
                          <Search />
                        </IconButton>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
      <div
        className={classes.grid}
      >
        {datagrid}
      </div>
    </React.Fragment>
  );
};

export default KaKaoSrchList;
