/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Product page
**/
/* @material import */
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';
/* react import */
import React from 'react';
import Page from './../Page';
import ProductList2 from './ProductList2';
import RepairList from './RepairList';

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '90%',
    overflow: 'hidden',  
    marginTop: 0,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3)      
  },
  paper: {
    width: '100%',
    height: 700,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper    
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const Product = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="수선품"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="flex-start"
      >
        <Container maxWidth="xl">
          <Box mb={1} justifyContent="center">
            <Grid
              container
              justify="center" 
              spacing={1}
            >         
              <Grid item xs={7}>
                {/* 수선품 */}
                <Paper className={classes.paper} elevation={4}>  
                  <ProductList2 />         
                </Paper>              
              </Grid>
              <Grid item xs={5}>
                {/* 수선 */}
                <Paper className={classes.paper} elevation={4}>  
                  <RepairList />         
                </Paper>              
              </Grid>              
            </Grid>
          </Box>
        </Container>
      </Box> 
    </Page>   
  );
};

export default Product;
