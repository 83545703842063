/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : router config page
**/
/* react import */
import React from 'react';
import { Navigate } from 'react-router-dom';
/* service import */
import BaseLayout from './taurus/layout/base';
import LoginLayout from './taurus/layout/login';
import Login from './taurus/pages/auth/Login';
import Category from './taurus/pages/category/Category';
import Chatbot from './taurus/pages/chatbot/Chatbot';
import Consult from './taurus/pages/consult/Consult';
import Delivery from './taurus/pages/delivery/Delivery';
import Gallery from './taurus/pages/gallery/Gallery';
import Main from './taurus/pages/main/Main';
import NotPage from './taurus/pages/NotPage';
import Payment from './taurus/pages/payment/Payment';
import Product from './taurus/pages/product/Product';
import Receipt from './taurus/pages/receipt/Receipt';
import Repair from './taurus/pages/repair/Repair';
import Users from './taurus/pages/users/Users';

/* router url info */
const RouteConfig = [
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      { path: '/', element: <Navigate to="/main" /> },
      { path: 'main', element: <Main /> },
      { path: 'consult', element: <Consult /> },
      { path: 'receipt', element: <Receipt /> },
      { path: 'gallery', element: <Gallery /> },
      { path: 'product', element: <Product /> },
      { path: 'repair', element: <Repair /> },
      { path: 'delivery', element: <Delivery /> },
      { path: 'payment', element: <Payment /> },
      { path: 'category', element: <Category /> },
      { path: 'chatbot', element: <Chatbot /> },
      { path: 'users', element: <Users /> },
      { path: '*', element: <Navigate to="/error" /> },
    ]
  },
  {
    path: '/login',
    element: <LoginLayout />,
    children: [
      { path: '/', element: <Login /> },
      { path: '*', element: <Navigate to="/error" /> }
    ]
  },
  {
    path: '/error',
    element: <LoginLayout />,
    children: [
      { path: '/', element: <NotPage /> },
      { path: '*', element: <Navigate to="/error" /> }      
    ]
  }  
];

export default RouteConfig;
