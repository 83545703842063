/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux products Action
 **/
import {
  _ajaxPostExcelDataAsync
} from "../../axios/axios";
import { DOWNLOAD_FAIL } from "../types";


/* 수선품 엑셀 다운로드 */
export const getExcel = async (body: any) => {  
  return await _ajaxPostExcelDataAsync("post", "/api/v1/excels/json/", body, DOWNLOAD_FAIL);
}