/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux payments Action
**/
import { _ajaxPostJsonAsync } from "../../axios/axios";
import {
  PAY_ADD,
  PAY_DEL,
  PAY_FAIL,
  PAY_GET,
  PAY_GET_INFO,
  PAY_UPD
} from "../types";

/* 정산 경로 정보 */
const API_PATH = "/api/v1/calculates";

/* 정산 전체 조회 */
export const getPay = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, PAY_GET, PAY_FAIL);
}

/* 정산 개별 조회 */
export const getPayInfo = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, PAY_GET_INFO, PAY_FAIL);
}

/* 정산 등록 */
export const addPay = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['reg_user'] = user_name;       // 최초작성자
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("post", API_PATH+"/", d, PAY_ADD, PAY_FAIL);
}

/* 정산 수정 */
export const updPay = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH+"/"+d.calc_no, d, PAY_UPD, PAY_FAIL);
}

/* 정산 삭제 */
export const delPay = async (d:any) => {
  return await _ajaxPostJsonAsync("delete", API_PATH+"/"+d.calc_no, d, PAY_DEL, PAY_FAIL);
}