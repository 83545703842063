/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux user store
**/
// TYPES
import { SIGNUP_FAIL, SIGNUP_SUCCESS } from "./../types";

// state 초기 데이터
const initialState = {
  data: {}
};

// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case SIGNUP_SUCCESS:  // 인증 성공
      //return { ...state, ...action.payload }; // ...은 변화된 상태만을 받음
      return { 
        ...state, 
        ...action.payload 
      }; // ...은 변화된 상태만을 받음
    case SIGNUP_FAIL:     // 인증 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}