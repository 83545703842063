/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux products Action
**/
import { _ajaxGetJsonAsync, _ajaxPostJsonAsync } from "../../axios/axios";
import {
  KAKAO_FAIL,
  KAKAO_SEND,
  PROD_SEND,
  PROD_ADD,
  PROD_DASHBOARD,
  PROD_DEL,
  PROD_FAIL,
  PROD_GET,
  PROD_GET_INFO,
  PROD_GET_SRCH,
  PROD_UPD
} from "../types";

/* 수선품 경로 정보 */
const API_PATH = "/api/v1/products";

/* 수선품 전체 조회 */
export const getProd = async (body: any) => {
  // IN Data 구성
  // body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH + "/search/", body, PROD_GET, PROD_FAIL);
}

export const getProdImg = async (body: any) => {
  // IN Data 구성
  // body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH + "/search_imgpage/", body, PROD_GET, PROD_FAIL);
}


/* 수선품 전체 조회 */
export const getDashboardProd = async (body: any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH + "/search/", body, PROD_DASHBOARD, PROD_FAIL);
}

/* 수선품 개별 조회 */
export const getProdInfo = async (d: any) => {
  // ajax 통신
  return await _ajaxGetJsonAsync("get", API_PATH + "/" + d, {}, PROD_GET_INFO, PROD_FAIL);
}

/* 출고 조회 */
export const getProdSrch = async (body: any) => {
  // IN Data 구성
  body['limit'] = '1';
  return await _ajaxPostJsonAsync("post", API_PATH + "/search/", body, PROD_GET_SRCH, PROD_FAIL);
}

/* 수선품 등록 */
export const addProd = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['reg_user'] = user_name;       // 최초작성자
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("post", API_PATH + "/", d, PROD_ADD, PROD_FAIL);
}

/* 수선품 수정 */
export const updProd = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH + "/" + d.prod_no, d, PROD_UPD, PROD_FAIL);
}

/* 수선품 삭제 */
export const delProd = async (d: any) => {
  return await _ajaxPostJsonAsync("delete", API_PATH + "/" + d.prod_no, d, PROD_DEL, PROD_FAIL);
}

/* 출고 카카오톡 전송 */
export const sendKakaoDliv = async (d: any) => {
  return await _ajaxPostJsonAsync("post", API_PATH + "/send_kakao_dliv/", d, PROD_SEND, PROD_FAIL);
}

/* 출고 카카오톡 상태변경 */
export const updKakaoDliv = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH + "/update_kakao_dliv/" + d.prod_no, d, PROD_UPD, PROD_FAIL);
}