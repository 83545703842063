/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux payments Action
**/
import { _ajaxGetJsonAsync, _ajaxPostFormDataAsync, _ajaxPostJsonAsync } from "../../axios/axios";
import {
  IMG_ADD,
  IMG_DEL,
  IMG_FAIL,
  IMG_GET,
  IMG_GET_INFO,
  IMG_GET_URL,
  IMG_UPD
} from "../types";

/* 이미지 경로 정보 */
const API_PATH = "/api/v1/images";

/* 이미지 전체 조회 */
export const getImg = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxGetJsonAsync("get", API_PATH+"/search", body, IMG_GET, IMG_FAIL);
}

/* 이미지 개별 조회 */
export const getImgInfo = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1';
  return await _ajaxGetJsonAsync("get", API_PATH+"/"+body.imageid, {}, IMG_GET_INFO, IMG_FAIL);
}

/* 이미지 등록 */
export const addImg = async (body:any) => {
  return await _ajaxPostFormDataAsync("post", API_PATH+"/", body, IMG_ADD, IMG_FAIL);
}

/* 이미지 수정 */
export const updImg = async (d:any, id:string) => {
  return await _ajaxPostFormDataAsync("put", API_PATH+"/"+id, d, IMG_UPD, IMG_FAIL);
}

/* 이미지 삭제 */
export const delImg = async (d:any, id:string) => {
  return await _ajaxPostJsonAsync("delete", API_PATH+"/"+id, d, IMG_DEL, IMG_FAIL);
}

/* 이미지 URL 정보 */
export const getImgUrl = async (d:any) => {
  return await _ajaxPostJsonAsync("post", API_PATH+"/urls", d, IMG_GET_URL, IMG_FAIL);
}


