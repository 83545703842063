/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Repair View Page
**/
/* @material import */
import {
  Box,
  Button,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  Add,
  Delete
} from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import {
  _isEmpty,
  _setCateCodeFilter,
  _setJsonFilter
} from "./../../utils/Common";
import FormText from "./../../utils/form/FormText";
import RepairList from "./RepairList";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
    height: '100%',
    width: '100%',
    maxHeight: '90%',
    minWidth: '90%',
    overflow: 'auto',
    margin: theme.spacing(2)
  },
  grid: {
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const RepairView = forwardRef((props, ref) => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook
  const formRef = useRef<any>(null);

  // 부모 컴포넌트 사용
  useImperativeHandle(ref, () => formRef.current);

  // [state] ######################################################################  
  const repair = useSelector((state: any) => state.repair);              // repair
  const common = useSelector((state: any) => state.common);              // common

  // [Events] ######################################################################
  //@ 초기화
  const handleinit = useCallback(() => {
  }, []);

  //@ 임시 저장
  const handleTempAdd = useCallback((v) => {
    // 임시 수선 데이터 추가 적재
    repair.rows.push(v);
    dispatch({ type: 'REPR_GET', payload: repair.rows });
    formRef.current.resetForm();
  }, [repair, dispatch]);

  //@ 임시 수정
  const handleTempUpd = useCallback((v) => {
    if (_isEmpty(repair.temp.id)) {          // 수선작업이 선택 되지 않은 경우
      common.msg = '수정하실 수선 작업을 선택해 주세요.';
      common.isMsg = true;
      return dispatch({ type: 'COM_ALERT' });  // 안내메시지      
    } else {
      // 임시 수선 데이터 삭제
      dispatch({
        type: 'REPR_GET',
        payload: _setJsonFilter(repair.rows, repair.temp.id)
      });
    }
    repair.rows.push(v);
    dispatch({ type: 'REPR_GET', payload: repair.rows });
    formRef.current.resetForm();
    dispatch({ type: 'REPR_GET_TEMP', payload: {} })
  }, [repair, common, dispatch]);

  //@ 임시 삭제
  const handleTempDel = useCallback(() => {
    if (!_isEmpty(repair.temp.repr_no)) {
      repair.remove.push(repair.temp.repr_no);
    }
    if (_isEmpty(repair.temp.id)) {          // 수선작업이 선택 되지 않은 경우
      common.msg = '삭제하실 수선 작업을 선택해 주세요.';
      common.isMsg = true;
      return dispatch({ type: 'COM_ALERT' });  // 안내메시지      
    } else {
      // 임시 수선 데이터 삭제
      dispatch({
        type: 'REPR_GET',
        payload: _setJsonFilter(repair.rows, repair.temp.id)
      });
    }
    dispatch({ type: 'REPR_GET_TEMP', payload: {} })
  }, [repair, common, dispatch]);


  // [init] ######################################################################
  //@ 최초 실행
  useEffect(() => {
    handleinit();
  }, [handleinit]);

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          //# 수선품
          repr_no: _isEmpty(repair.temp) ? '' : repair.temp.repr_no,           // 수선번호
          prod_no: _isEmpty(repair.temp) ? '' : repair.temp.prod_no,           // 수선품번호
          cate_01: _isEmpty(repair.temp) ? '' : repair.temp.cate_01,           // 수선유형
          cate_02: _isEmpty(repair.temp) ? '' : repair.temp.cate_02,           // 수선(대분류)
          cate_03: _isEmpty(repair.temp) ? '' : repair.temp.cate_03,           // 수선(중분류)
          cate_04: _isEmpty(repair.temp) ? '' : repair.temp.cate_04,           // 수선(소분류)
          galy_id: _isEmpty(repair.temp) ? '' : repair.temp.galy_id,           // 사진ID
          is_plated: _isEmpty(repair.temp) ? false : repair.temp.is_plated,  // 장식도금여부
          repr_cnt: _isEmpty(repair.temp) ? '0' : repair.temp.repr_cnt,       // 부품제작수량
          repr_loc_typ: _isEmpty(repair.temp) ? '' : repair.temp.repr_loc_typ,        // 수선위치유형
          repr_loc: _isEmpty(repair.temp) ? '' : repair.temp.repr_loc,        // 수선위치
          req_cont: _isEmpty(repair.temp) ? '' : repair.temp.req_cont,        // 기타문의사항
          repr_qr_cd: _isEmpty(repair.temp) ? '' : repair.temp.repr_qr_cd,  // QR 코드
          repr_stat: _isEmpty(repair.temp) ? '01' : repair.temp.repr_stat,    // 수선상태 (G016)
          repr_pay: _isEmpty(repair.temp) ? '0' : repair.temp.repr_pay,      //수선비용
          reg_user: _isEmpty(repair.temp) ? '0' : repair.temp.reg_user,      //작성자
          reg_dtime: _isEmpty(repair.temp) ? '0' : repair.temp.reg_dtime,      //작성시간


        }}
        validationSchema={Yup.object().shape({
          cate_01: Yup.string().required('수선유형을 선택해주세요'),
          cate_02: Yup.string().required('수선(대분류)를 선택해주세요'),
          repr_loc_typ: Yup.string().required('수선위치유형을 선택해주세요'),
          repr_loc: Yup.string().required('수선위치를 선택해주세요'),
          repr_pay: Yup.number().required('작업비용을 입력해주세요').typeError('숫자로 입력해주세요')
        })}
        onSubmit={(v) => {
          // UTC 포멧 변경
          // v.rcpt_dtime = _setUTCDate(v.rcpt_dtime);

          // if(repair.isEdit) {  // 수정
          // } else {              // 등록
          if (_isEmpty(repair.temp)) {
            handleTempAdd(v);
          }
          else {
            handleTempUpd(v);
          }
          // }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
          <form onSubmit={handleSubmit}>
            {/*############################## 목록 ##############################*/}
            <Box ml={3} mr={3} mb={1} mt={1} borderTop={2} borderBottom={2} borderColor="primary.main" justifyContent="center">
              <RepairList />
            </Box>
            {/*############################## 수선 ##############################*/}
            <Box ml={3} mr={3} mb={1} mt={1} borderTop={2} borderColor="primary.main" justifyContent="center">
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}>
                {/* 수선유형 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='cate_01'
                    label='수선유형'
                    value={values.cate_01}
                    item={[
                      { code: '0600000000', name: '가방,지갑,기타' },
                      { code: '0700000000', name: '벨트' },
                      { code: '0800000000', name: '신발' }
                    ]}
                    errors={errors.cate_01}
                    touched={touched.cate_01}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                <Grid item xs={6}></Grid>
                {/* 수선(대분류) */}
                <Grid item xs={4}>
                  <FormSelect
                    id='cate_02'
                    label='수선(대분류)'
                    value={values.cate_02}
                    item={_setCateCodeFilter(common.category, values.cate_01)}
                    errors={errors.cate_02}
                    touched={touched.cate_02}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 수선(중분류) */}
                <Grid item xs={4} className={classes.grid}>
                  <FormSelect
                    id='cate_03'
                    label='수선(중분류)'
                    value={values.cate_03}
                    item={_setCateCodeFilter(common.category, values.cate_02)}
                    firstDefault={true}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 수선(소분류) */}
                <Grid item xs={4} className={classes.grid}>
                  <FormSelect
                    id='cate_04'
                    label='수선(소분류)'
                    value={values.cate_04}
                    item={_setCateCodeFilter(common.category, values.cate_03)}
                    firstDefault={true}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 부속품제작수량 */}
                <Grid item xs={6}>
                  <FormText
                    id='repr_cnt'
                    label='수량'
                    value={values.repr_cnt}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                <Grid item xs={6}>
                  <FormText
                    id='repr_pay'
                    label='작업비용'
                    value={values.repr_pay}
                    errors={errors.repr_pay}
                    touched={touched.repr_pay}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 장식도금 */}
                {/* <Grid item xs={6} className={classes.grid}>
                      <FormOption
                        id='is_plated' 
                        label='장식도금'
                        value={values.is_plated}
                        item={[{ label: '아니오', value: false },{ label: '예', value: true }]}
                        errors={errors.is_plated}
                        touched={touched.is_plated}
                        required={false}                                               
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={6}></Grid> */}
                {/* 수선위치유형 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='repr_loc_typ'
                    label='수선위치유형'
                    value={values.repr_loc_typ}
                    item={_setCateCodeFilter(common.category, '0900000000')}
                    errors={errors.repr_loc_typ}
                    touched={touched.repr_loc_typ}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 수선위치 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='repr_loc'
                    label='수선위치'
                    value={values.repr_loc}
                    item={_setCateCodeFilter(common.category, values.repr_loc_typ)}
                    errors={errors.repr_loc}
                    touched={touched.repr_loc}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 기타요청사항 -> 세부요청사항 20201223 HSH */}
                <Grid item xs={12}>
                  <FormText
                    id='req_cont'
                    label='세부요청사항'
                    value={values.req_cont}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
              </Grid>
            </Box>
            {/* 버튼영역 */}
            <Box
              ml={1} mr={1} mb={1}
              display="flex"
              justifyContent="flex-end"
            >
              {/* 등록 */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<Add />}
              >
                {_isEmpty(repair.temp) ? '등록' : '수정'}
              </Button>
              {/* 삭제 */}
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="small"
                className={classes.button}
                startIcon={<Delete />}
                onClick={handleTempDel}
              >
                삭제
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
});

export default RepairView;
