/* @material import */
import {
  Box,
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Message, Print } from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from 'react-to-print';
import {
  _getCategoryName,
  _getCodeName,
  _getPayDiff,
  _isEmpty,
  _setAddComma,
  _setDateYYYYMMDD
} from "./../../utils/Common";
import { getRcpt, sendKakaoRcpt, updRcptStat } from "./../../utils/redux/actions/RcptAction";

// [<--------------------------- Style ---------------------------->]
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px",
    textAlign: "center"
  },
  container: {
    border: 1,
    color: "black"
  },
  grid: {
    height: '100%',
    width: '98%',
    maxHeight: '78%',
    minWidth: '98%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#9fa8da',
      color: '#ffffff'
    },
  },
  // 데이터 그리드 테이블 해더
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  box: {
    padding: 10
  },
  typography: {
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  //style for font size
  resize: {
    fontSize: 13,
    color: 'black'
  },
  col_grid: {
    alignSelf: 'center',
    width: '2px'
  },
  rcpt_title: {
    fontWeight: 'bold',
    fontSize: 20
  },
  rcpt_sub_title: {
    fontWeight: 'bold',
    fontSize: 15
  }
}));

// [<--------------------------- Component ---------------------------->]
const Receipts = () => {
  const classes = useStyles();         // CSS
  const dispatch = useDispatch();  // dispatch hook
  const componentRef = useRef(null);   // react-to-print component

  // ###################################### State ######################################
  const product = useSelector((state: any) => state.product);    // product
  const receipt = useSelector((state: any) => state.receipt);    // receipt
  const common = useSelector((state: any) => state.common);      // common

  // ###################################### Events ######################################
  //@ 접수증 팝업 닫기
  const handleClose = useCallback(() => {
    receipt.isOpen = false;
    dispatch({ type: 'RCPT_GET_INFO', payload: receipt.info });
  }, [receipt, dispatch]);

  //@ 카카오 알림톡 전송(접수증)
  const sendKakao = useCallback(() => {
    let body = {
      phone: receipt.info.phone,                               // 연락처
      cust_name: receipt.info.cust_name,                       // 고객명
      rcpt_no: receipt.info.rcpt_no,                           // 접수 번호
      repr_st_dt: receipt.info.repr_st_dt,                     // 입고일
      repr_req_dt: receipt.info.repr_req_dt,                   // 출고 요청일
      send_typ: _getCodeName(receipt.info.send_typ, 'G007'),   // 수령 방식
      prod_cnt: receipt.info.prod_cnt,                          // 수량(수선품 개수)
      tot_pay: receipt.info.tot_pay                             // 수선비
    }

    sendKakaoRcpt(body).then(() => dispatch({ type: 'RCPT_SEND', payload: body })).then(() => {
      common.msg = '접수증이 전송되었습니다.';
      common.isMsg = true;
      return dispatch({ type: 'COM_ALERT' });       // 안내메시지
    });
    let body2 = {
      rcpt_no: receipt.info.rcpt_no
    }
    updRcptStat(body2).then((res: any) => dispatch(res)).then(() => getRcpt(receipt.srch)).then((d) => dispatch(d));
  }, [common, receipt, dispatch]);
  const [renderBox, setRenderBox] = useState(false);
  const handleInit = useCallback(() => { setRenderBox(receipt.isOpen) }, [receipt]);

  // //@ 최초실행
  // const handleInit = useCallback(() => {
  //   //1. 접수 선택 정보
  //   console.log(' ### 접수 선택 정보 ### ');
  //   console.log(receipt.info);
  //   //2. 수선품 목록
  //   console.log(' ### 수선품 목록 ### ');
  //   console.log(product.rows);

  // },[product, receipt, dispatch]);

  // [init] ######################################################################
  // //@ 최초 실행
  // useEffect(() => {
  //   handleInit();
  // }, [handleInit]);
  useEffect(() => {
    handleInit()
  }, [handleInit]);

  // ###################################### Render ######################################
  if (renderBox) {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            // 접수
            cust_no: _isEmpty(receipt.info) ? '' : receipt.info.cust_no,                                                           // 고객 번호
            rcpt_dtime: _isEmpty(receipt.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(receipt.info.rcpt_dtime, '-'),       // 접수일
            cust_name: _isEmpty(receipt.info) ? '' : receipt.info.cust_name,                                                       // 고객명
            phone: _isEmpty(receipt.info) ? '' : receipt.info.phone,                                                               // 연락처
            addr: _isEmpty(receipt.info) ? '' : receipt.info.addr,                                                                 // 주소
            tot_pay: _isEmpty(receipt.info) ? _setAddComma('0') : _setAddComma(receipt.info.tot_pay),                              // 총 수선비
            frst_pay: _isEmpty(receipt.info) ? _setAddComma('0') : _setAddComma(receipt.info.frst_pay),                            // 선금
            last_pay: _isEmpty(receipt.info) ? _getPayDiff('0', '0') : _getPayDiff(receipt.info.tot_pay, receipt.info.frst_pay),   // 잔금
            chk_kakao_rcpt: _isEmpty(receipt.info) ? '' : receipt.info.chk_kakao_rcpt                                              // 알림톡 전송 상태
          }}
          onSubmit={(v) => {
          }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Dialog
                open={receipt.isOpen}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <div ref={componentRef}>
                  <DialogTitle id="form-dialog-title" classes={{ root: classes.root }}>
                    <Typography variant="subtitle1" className={classes.rcpt_title}>접수증</Typography>
                  </DialogTitle>
                  <Box border={1} m={2} p={2} marginTop={1} marginBottom={1}>
                    <Typography variant="subtitle1" className={classes.rcpt_sub_title}>고객 정보</Typography>
                    <Box border={1} mb={1} className={classes.box}>
                      <Grid container spacing={1} className={classes.container}>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>이름</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="cust_name"
                            disabled
                            variant="standard"
                            value={values.cust_name || ''}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>연락처</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="phone"
                            disabled
                            variant="standard"
                            value={values.phone || ''}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>접수일</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="rcpt_dtime"
                            disabled
                            variant="standard"
                            value={values.rcpt_dtime || ''}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>주소</Typography>
                        </Grid>
                        <Grid item xs={11}>
                          <TextField
                            id="addr"
                            fullWidth
                            disabled
                            variant="standard"
                            value={values.addr || ''}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {product.rows.map((v: any, i: number) => (
                      <Box key={i + 1}>
                        <Typography variant="subtitle1" className={classes.rcpt_sub_title}>수선품 {i + 1}</Typography>
                        <Box border={1} mb={1} className={classes.box}>
                          <Grid container spacing={1} className={classes.container}>
                            <Grid item xs={1} className={classes.col_grid}>
                              <Typography className={classes.typography}>브랜드</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                id="brand_cd"
                                disabled
                                variant="standard"
                                value={_getCategoryName(v.brand_cd) || ''}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={classes.col_grid}>
                              <Typography className={classes.typography}>수선 종류</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                id="cate_cd"
                                disabled
                                variant="standard"
                                value={_getCodeName(v.cate_cd, "G025") || ''}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={1} className={classes.col_grid}>
                              <Typography className={classes.typography}>입고일</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                id="repr_st_dt"
                                disabled
                                variant="standard"
                                value={_isEmpty(v.repr_st_dt) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(v.repr_st_dt, '-')}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={classes.col_grid}>
                              <Typography className={classes.typography}>출고 요청일</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                id="repr_req_dt"
                                disabled
                                variant="standard"
                                value={_isEmpty(v.repr_req_dt) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(v.repr_req_dt, '-')}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={classes.col_grid}>
                              <Typography className={classes.typography}>수령 방식</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                id="send_typ"
                                disabled
                                variant="standard"
                                value={_getCodeName(v.send_typ, "G007") || ''}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={classes.col_grid}>
                              <Typography className={classes.typography}>내역</Typography>
                            </Grid>
                            <Grid item xs={10}>
                              <TextField
                                id="req_cont"
                                fullWidth
                                disabled
                                variant="standard"
                                value={v.req_cont || ''}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    ))}
                    <Box mb={1} className={classes.box}>
                      <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>총 수선비</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="tot_pay"
                            disabled
                            variant="standard"
                            value={values.tot_pay || ''}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>선금</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="frst_pay"
                            disabled
                            variant="standard"
                            value={values.frst_pay || ''}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>잔금</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="last_pay"
                            disabled
                            variant="standard"
                            value={values.last_pay || ''}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </div>
                <DialogActions>
                  {
                    (window.sessionStorage.getItem('user_grd') === '09') ?
                      <></>
                      :
                      <Button
                        onClick={sendKakao}
                        disabled={!(receipt.info.chk_kakao_rcpt === "01")}
                        variant="contained" color="primary" startIcon={<Message />}>알림톡</Button>
                  }
                  <ReactToPrint
                    trigger={() => <Button variant="contained" color="primary" startIcon={<Print />}>출력</Button>}
                    content={() => componentRef.current}
                  />
                  <Button onClick={handleClose} variant="contained" color="primary">닫기</Button>
                </DialogActions>
              </Dialog>
            </form>
          )}
        </Formik>
      </div>
    );
  } else {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            // 접수
            cust_no: _isEmpty(receipt.info) ? '' : receipt.info.cust_no,                                                           // 고객 번호
            rcpt_dtime: _isEmpty(receipt.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(receipt.info.rcpt_dtime, '-'),       // 접수일
            cust_name: _isEmpty(receipt.info) ? '' : receipt.info.cust_name,                                                       // 고객명
            phone: _isEmpty(receipt.info) ? '' : receipt.info.phone,                                                               // 연락처
            addr: _isEmpty(receipt.info) ? '' : receipt.info.addr,                                                                 // 주소
            tot_pay: _isEmpty(receipt.info) ? _setAddComma('0') : _setAddComma(receipt.info.tot_pay),                              // 총 수선비
            frst_pay: _isEmpty(receipt.info) ? _setAddComma('0') : _setAddComma(receipt.info.frst_pay),                            // 선금
            last_pay: _isEmpty(receipt.info) ? _getPayDiff('0', '0') : _getPayDiff(receipt.info.tot_pay, receipt.info.frst_pay),   // 잔금
            chk_kakao_rcpt: _isEmpty(receipt.info) ? '' : receipt.info.chk_kakao_rcpt                                              // 알림톡 전송 상태
          }}
          onSubmit={(v) => {
          }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Dialog
                open={receipt.isOpen}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <div ref={componentRef}>
                  <DialogTitle id="form-dialog-title" classes={{ root: classes.root }}>
                    <Typography variant="subtitle1" className={classes.rcpt_title}>접수증</Typography>
                  </DialogTitle>
                  <Box border={1} m={2} p={2} marginTop={1} marginBottom={1}>
                    <Typography variant="subtitle1" className={classes.rcpt_sub_title}>고객 정보</Typography>
                    <Box border={1} mb={1} className={classes.box}>
                      <Grid container spacing={1} className={classes.container}>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>이름</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="cust_name"
                            disabled
                            variant="standard"
                            value={values.cust_name}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>연락처</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="phone"
                            disabled
                            variant="standard"
                            value={values.phone}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>접수일</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="rcpt_dtime"
                            disabled
                            variant="standard"
                            value={values.rcpt_dtime}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className={classes.col_grid}>
                          <Typography className={classes.typography}>주소</Typography>
                        </Grid>
                        <Grid item xs={11}>
                          <TextField
                            id="addr"
                            fullWidth
                            disabled
                            variant="standard"
                            value={values.addr}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {/* {product.rows.map((v: any, i: number) => (
                      <Box key={i + 1}>
                        <Typography variant="subtitle1" className={classes.rcpt_sub_title}>수선품 {i + 1}</Typography>
                        <Box border={1} mb={1} className={classes.box}>
                          <Grid container spacing={1} className={classes.container}>
                            <Grid item xs={1} className={classes.col_grid}>
                              <Typography className={classes.typography}>브랜드</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                id="brand_cd"
                                disabled
                                variant="standard"
                                value={_getCategoryName(v.brand_cd)}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={classes.col_grid}>
                              <Typography className={classes.typography}>수선 종류</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                id="cate_cd"
                                disabled
                                variant="standard"
                                value={_getCodeName(v.cate_cd, "G025")}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={1} className={classes.col_grid}>
                              <Typography className={classes.typography}>입고일</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                id="repr_st_dt"
                                disabled
                                variant="standard"
                                value={_isEmpty(v.repr_st_dt) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(v.repr_st_dt, '-')}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={classes.col_grid}>
                              <Typography className={classes.typography}>출고 요청일</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                id="repr_req_dt"
                                disabled
                                variant="standard"
                                value={_isEmpty(v.repr_req_dt) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(v.repr_req_dt, '-')}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={classes.col_grid}>
                              <Typography className={classes.typography}>수령 방식</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                id="send_typ"
                                disabled
                                variant="standard"
                                value={_getCodeName(v.send_typ, "G007")}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={classes.col_grid}>
                              <Typography className={classes.typography}>고객 안내사항</Typography>
                            </Grid>
                            <Grid item xs={10}>
                              <TextField
                                id="req_cont"
                                fullWidth
                                disabled
                                variant="standard"
                                value={v.req_cont}
                                InputProps={{
                                  classes: {
                                    input: classes.resize
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    ))} */}
                    <Box mb={1} className={classes.box}>
                      <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>총 수선비</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="tot_pay"
                            disabled
                            variant="standard"
                            value={values.tot_pay}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>선금</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="frst_pay"
                            disabled
                            variant="standard"
                            value={values.frst_pay}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.col_grid}>
                          <Typography className={classes.typography}>잔금</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="last_pay"
                            disabled
                            variant="standard"
                            value={values.last_pay}
                            InputProps={{
                              classes: {
                                input: classes.resize
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </div>
                <DialogActions>
                  {
                    (window.sessionStorage.getItem('user_grd') === '09') ?
                      <></>
                      :
                      <Button
                        onClick={sendKakao}
                        disabled={!(receipt.info.chk_kakao_rcpt === "01")}
                        variant="contained" color="primary" startIcon={<Message />}>알림톡</Button>
                  }
                  <ReactToPrint
                    trigger={() => <Button variant="contained" color="primary" startIcon={<Print />}>출력</Button>}
                    content={() => componentRef.current}
                  />
                  <Button onClick={handleClose} variant="contained" color="primary">닫기</Button>
                </DialogActions>
              </Dialog>
            </form>
          )}
        </Formik>
      </div>
    );
  }
};

export default Receipts;