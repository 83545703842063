/* meterial import */
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
/* react import */
import React from 'react';
import { useRoutes } from 'react-router-dom';
/* service import */
import RouteConfig from './RouteConfig';
import theme from './taurus/theme';

/* component */
const App = () => {
  const routing = useRoutes(RouteConfig);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <GlobalStyles /> */}
      {routing}
    </ThemeProvider>
  );
};

export default App;
