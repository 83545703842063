/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Payment List Page
**/
/* @material import */
import {
	Grid,
	IconButton,
	InputBase,
	makeStyles,
	Paper,
	Toolbar,
	Typography
} from '@material-ui/core';
import {
	DataGrid
} from '@material-ui/data-grid';
import {
	GetApp,
	Payment,
	Search
} from '@material-ui/icons';
/* react import */
import { Formik } from 'formik';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import FormText from '../../utils/form/FormText';
import { getExcel } from "../../utils/redux/actions/ExcelDownloadAction";
import { getPayInfo } from "../../utils/redux/actions/PayAction";
import { getProd } from "../../utils/redux/actions/ProdAction";
import {
	_getCategoryName,
	_getCategoryName2,
	_getCodeName,
	_getCodeName2,
	_getSession,
	_isEmpty,
	_setCodeFilter,
	_setDateYYYYMMDD,
	_setDayYYYYMMDD,
	_setFormatCategory,
	_setFormatCode,
	_setFormatComma,
	_setFormatDate,
	_setFormatPhone,
	_setPhone,
	_setSumFrst,
	_setSumLast,
	_setSumTotal,
	_setUTCDate
} from "./../../utils/Common";
import FormDate from "./../../utils/form/FormDate";
 
/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
	root: {
	},
	grid: {
		height: '100%',
		width: '98%',
		maxHeight: '76%',
		minWidth: '98%',
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
		'& .MuiDataGrid-columnsContainer': {
			backgroundColor: '#9fa8da',
			color: '#ffffff'
		},
	},
	// 데이터 그리드 테이블 해더
	search: {
		margin: theme.spacing(1)
	},
	toolbar: {
		minHeight: 40,
		color: '#fff',
		backgroundColor: '#1976d2'
	},
	iconButton: {
		padding: 10,
	},
	paper: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: 30,
		marginTop: 13
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	searchTypography: {
		marginTop: 12
	}
}));

/* ######################################################################
 * component 
 * ###################################################################### */
// let excel_info: any = [];
const PaymentList = () => {
	const classes = useStyles();     // CSS
	const dispatch = useDispatch();  // dispatch hook

	// [state] ######################################################################
	const common = useSelector((state: any) => state.common);          // common
	const product = useSelector((state: any) => state.product);        // product

	// [Events] ######################################################################
	//@ 최초 실행
	const handleInit = useCallback(() => {
		let body: any = {};
		body['limit'] = '100'
		getProd(body).then((d) => dispatch(d))
			// .then((d) => {
			// 	excel_info = [];
			// 	excel_info = d.payload;
			// })
			.catch((e) => console.log(e));
		product.srch['limit'] = '100'
	}, [dispatch]);

	//@ 정산이력 선택
	const handleChange = useCallback((e) => {
		// 정산이력 상세 조회
		let body = {};
		body = {
			prod_no: e.data.prod_no
		};
		getPayInfo(body).then((res: any) => dispatch(res))
			.catch((e) => console.log("pay: " + e));
	}, [dispatch]);

	//@ 검색
	const handleSearch = useCallback((v) => {

		product.srch = v;
		// excel_info = [];
		let body: any = {};
		if (!_isEmpty(v.date_key)) body['date_key'] = v.date_key;
		if (!_isEmpty(v.st_dt)) body['st_dt'] = _setUTCDate(v.st_dt);
		if (!_isEmpty(v.ed_dt)) body['ed_dt'] = _setUTCDate(v.ed_dt);
		if (!_isEmpty(v.calc_stat)) body['calc_stat'] = v.calc_stat;
		if (!_isEmpty(v.rcpt_chnl)) body['rcpt_chnl'] = v.rcpt_chnl;
		if (!_isEmpty(v.srch_val)) {
			if (v.srch_key.toString() === 'phone') {
				v.srch_val = _setPhone(v.srch_val);
			}
			body[v.srch_key.toString()] = v.srch_val;
		}
		if (!_isEmpty(v.limit)) body["limit"] = v.limit;

		getProd(body).then((d) => dispatch(d))
			// .then((d) => {
			// 	excel_info = d.payload;
			// })
			.catch((e) => console.log(e));

	}, [product, dispatch]);

	//@ Excel downlaod
	const excelDownload = useCallback(() => {
		let excel_table: any = []
		let _code_data = _getSession('CODE_DATA');
		_code_data = _code_data.payload;
		let _category_data = _getSession('CATEGOORY_DATA');
		_category_data = _category_data.payload;
		let excel_info = product.excel;
		excel_info.forEach((excel: any) => {
			excel_table.push({
				수선품번호: excel.prod_no,
				고객명: excel.cust_name,
				연락처: excel.phone,
				접수경로: _getCodeName2(_code_data, excel.rcpt_chnl, "G006"),
				브랜드: _getCategoryName2(_category_data, excel.brand_cd),
				수선종류: _getCodeName2(_code_data, excel.cate_cd, "G025"),
				내역: _isEmpty(excel.req_cont) ? "" : excel.req_cont,
				접수일: _setDayYYYYMMDD(excel.rcpt_dtime, "-"),
				출고요청일: _setDayYYYYMMDD(excel.repr_req_dt, '-'),
				출고일: _setDayYYYYMMDD(excel.prod_ed_dt, '-'),
				수선비: _isEmpty(excel.tot_pay) ? "" : excel.tot_pay.toString(),
				결제액: _isEmpty(excel.frst_pay) ? "" : excel.frst_pay.toString(),
				미수금: _isEmpty(excel.last_pay) ? "" : excel.last_pay.toString(),
				결재상태: _getCodeName2(_code_data, excel.calc_stat, "G013"),
			});
		});
		if (_isEmpty(excel_table)) {
			alert('데이터를 선택해주세요')
		} else {
			getExcel(excel_table);
		}
	}, [product]);

	// [init] ######################################################################
	useEffect(() => {
		handleInit();
	}, [handleInit]);

	// 콤마 타입 설정
	const _setComma = _setFormatComma();
	// 데이트 타입 설정
	const _setDate = _setFormatDate();
	// 카테고리명 조회
	const _setCateName = _setFormatCategory(common.category);
	// 결재상태명 조회
	const _setStateName = _setFormatCode(common.code, 'G013');
	// 수선종류 조회
	const _setItemName = _setFormatCode(common.code, 'G025');
	// 연락처 조회
	const _setGridPhone = _setFormatPhone();
	// 접수경로 조회
	const _setRcptChnl = _setFormatCode(common.code, 'G006');

	//@ 초기 데이터 (Table 컬럼)
	const columns = [
		{ field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
		{ field: 'prod_no', headerAlign: 'center', headerName: '수선품번호', width: 130 },
		{ field: 'cust_name', headerAlign: 'center', headerName: '고객명', width: 140 },
		{ field: 'phone', headerAlign: 'center', headerName: '연락처', width: 130, ..._setGridPhone },
		{ field: 'rcpt_chnl', headerAlign: 'center', headerName: '접수경로', width: 100, ..._setRcptChnl },
		{ field: 'brand_cd', headerAlign: 'center', headerName: '브랜드', width: 150, ..._setCateName, hide: true },
		{ field: 'brand_name', headerAlign: 'center', headerName: '브랜드', width: 150 },
		{ field: 'cate_cd', headerAlign: 'center', headerName: '수선종류', width: 120, ..._setItemName, hide: true },
		{ field: 'cate_name', headerAlign: 'center', headerName: '수선종류', width: 120},
		{ field: 'req_cont', headerAlign: 'center', headerName: '내역', width: 300 },
		{ field: 'rcpt_dtime', headerAlign: 'center', headerName: '접수일', width: 110, ..._setDate },
		{ field: 'repr_req_dt', headerAlign: 'center', headerName: '출고요청일', width: 110, ..._setDate },
		{ field: 'prod_ed_dt', headerAlign: 'center', headerName: '출고일', width: 110, ..._setDate },
		{ field: 'tot_pay', headerAlign: 'center', headerName: '수선비', width: 100, ..._setComma },
		{ field: 'frst_pay', headerAlign: 'center', headerName: '결제액', width: 100, ..._setComma },
		{ field: 'last_pay', headerAlign: 'center', headerName: '미수금', width: 100, ..._setComma },
		{ field: 'calc_stat', headerAlign: 'center', headerName: '결재상태', width: 100, ..._setStateName },
		{ field: 'reg_user', headerAlign: 'center', headerName: '작성자', width: 80 },
		{ field: 'last_mod_user_calc', headerAlign: 'center', headerName: '수정자', width: 80 }
	];

	// [Grid] ######################################################################    
	let datagrid;

	if (_isEmpty(product.rows)) {
		datagrid = <Typography
			variant="h6"
			align="center"
		>
			데이터가 존재하지 않습니다.
		</Typography>;
	} else {
		datagrid = <DataGrid
			headerHeight={40}
			rowHeight={30}
			columns={columns}
			rows={product.rows}
			pageSize={14}
			onRowClick={handleChange}
		/>;
	}

	// [Render] ######################################################################  
	return (
		<React.Fragment>
			<Toolbar className={classes.toolbar}>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={10}>
						<Typography variant="h6">정산</Typography>
					</Grid>
					<Grid item xs={2}>
						{/* 엑셀 다운로드 */}
						<IconButton
							className={classes.iconButton}
							aria-label="엑셀 다운로드"
							color="inherit"
							onClick={excelDownload}
						>
							<GetApp />
							<Typography variant="h6">엑셀 다운로드</Typography>
						</IconButton>
					</Grid>
				</Grid>
			</Toolbar>
			{/* 검색기능 */}
			<div className={classes.search}>
				<Formik
					enableReinitialize
					initialValues={{
						// 기본정보
						date_key: _isEmpty(product.srch)
							? "repr_st_dt"
							: _isEmpty(product.srch.date_key)
								? "repr_st_dt"
								: product.srch.date_key, // 검색날짜키
						st_dt: _isEmpty(product.srch)
							? ""
							: _isEmpty(product.srch.st_dt)
								? ""
								: _setDateYYYYMMDD(product.srch.st_dt, "-"), // 검색시작일
						ed_dt: _isEmpty(product.srch)
							? _setDateYYYYMMDD("", "-")
							: _isEmpty(product.srch.ed_dt)
								? _setDateYYYYMMDD("", "-")
								: _setDateYYYYMMDD(product.srch.ed_dt, "-"), // 검색종료일

						calc_stat: _isEmpty(product.srch)
							? ""
							: _isEmpty(product.srch.calc_stat)
								? ""
								: product.srch.calc_stat, // 수선품상태

						rcpt_chnl: _isEmpty(product.srch)
							? ""
							: _isEmpty(product.srch.rcpt_chnl)
								? ""
								: product.srch.rcpt_chnl, // 접수경로

						srch_key: _isEmpty(product.srch)
							? "prod_no"
							: _isEmpty(product.srch.srch_key)
								? "prod_no"
								: product.srch.srch_key, // 검색키
						srch_val: _isEmpty(product.srch)
							? ""
							: _isEmpty(product.srch.srch_val)
								? ""
								: product.srch.srch_val, // 검색어
						limit: '100' // 노출 데이터 수
					}}
					validationSchema={Yup.object().shape({
						// 밸리데이션 불필요
					})}
					onSubmit={(v) => {
						handleSearch(v);
					}}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						touched,
						values,
					}) => (
						<form onSubmit={handleSubmit}>
							{/* 그리드 */}
							<Grid container justify="center" alignItems="center">
								{/* 기간 */}
								<Grid item xs={5}>
									<Grid container justify="center">
										<Grid item xs={1}></Grid>
										<Grid item xs={2}>
											<FormSelect
												id="date_key"
												label="구분"
												firstDefault={false}
												value={values.date_key}
												item={[
													{ code: "repr_st_dt", name: "접수일" },
													{ code: "prod_ed_dt", name: "출고일" },
													{ code: "repr_req_dt", name: "출고요청일" },
													{ code: "frst_pay_dtime", name: "결제일" },
												]}
												required={true}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={4}>
											<FormDate
												id="st_dt"
												label="시작일"
												value={values.st_dt}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={1}>
											<Typography
												className={classes.searchTypography}
												variant="h3" align="center">
												~{" "}
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<FormDate
												id="ed_dt"
												label="종료일"
												value={values.ed_dt}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
									</Grid>
								</Grid>
								{/* 구분 */}
								<Grid item xs={3}>
									<Grid container justify="center">
										<Grid item xs={2}></Grid>
										<Grid item xs={4}>
											{/* 결재상태 */}
											<FormSelect
												id="calc_stat"
												label="결재상태"
												value={values.calc_stat}
												item={_setCodeFilter(common.code, "G013")}
												firstDefault={true}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={4}>
											{/* 접수경로 */}
											<FormSelect
												id="rcpt_chnl"
												label="접수경로"
												value={values.rcpt_chnl}
												item={_setCodeFilter(common.code, "G006")}
												firstDefault={true}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={2}></Grid>
									</Grid>
								</Grid>
								{/* 검색어 */}
								<Grid item xs={4}>
									{/* 구분 */}
									<Grid
										container
										justify="center"
										alignItems="center">
										<Grid item xs={2}>
											<FormText
												id='limit'
												label='데이터 수'
												value={values.limit}
												// disabled={true}
												required={true}
												blur={handleBlur}
												change={handleChange} />
										</Grid>
										<Grid item xs={3}>
											<FormSelect
												id="srch_key"
												label="구분"
												firstDefault={false}
												value={values.srch_key}
												item={[
													{ code: "prod_no", name: "수선품번호" },
													{ code: "rcpt_name", name: "고객명" },
													{ code: "phone", name: "연락처" }
												]}
												required={true}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper}>
												<InputBase
													className={classes.input}
													placeholder="검색어를 입력해주세요"
													id="srch_val"
													name="srch_val"
													value={values.srch_val}
													type="text"
													error={Boolean(touched.srch_val && errors.srch_val)}
													onBlur={handleBlur}
													onChange={handleChange}
												/>
												<IconButton
													type="submit"
													className={classes.iconButton}
													aria-label="search"
												>
													<Search />
												</IconButton>
											</Paper>
										</Grid>
										<Grid item xs={1}></Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			</div>
			<div className={classes.grid}>{datagrid}</div>
			<div>
				<Grid container justify="center" alignItems="center">
					{/* 기간 */}
					<Grid item xs={4}></Grid>
					<Grid item xs={1}>
						<Grid container justify="center" alignItems="center">
							<Grid item xs={6}>
								<Payment />
							</Grid>
							<Grid item xs={6}>
								<Typography variant="h6">합계</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={6}>
						<Grid container justify="center" alignItems="center">
							{/* 수선비 */}
							<Grid item xs={2}>
								<Typography variant="h6">수선비:</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography variant="h6">
									{_setSumTotal(product.rows)}원
								</Typography>
							</Grid>
							{/* 결재액 */}
							<Grid item xs={2}>
								<Typography variant="h6">결재액:</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography variant="h6">
									{_setSumFrst(product.rows)}원
								</Typography>
							</Grid>
							{/* 미수금 */}
							<Grid item xs={2}>
								<Typography variant="h6">미수금:</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography variant="h6">
									{_setSumLast(product.rows)}원
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</React.Fragment>
	);
};

export default PaymentList;
