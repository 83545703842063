/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Category page
**/
/* @material import */
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  Delete,
  Save
} from '@material-ui/icons';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList } from 'react-window';
/* service import */
import {
  getCate,
  getCateInfo
} from "./../../utils/redux/actions/CateAction";
import Page from './../Page';
import CategoryAdd from './CategoryAdd';
import CategoryRemove from './CategoryRemove';

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',  
    marginTop: 0,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)      
  },
  paper: {
    width: '100%',
    height: 400,
    maxWidth: 250,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper    
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  itemlist: {
    width: '100%',
    height: 360,
    maxWidth: 250,
  },
  button: {
    margin: theme.spacing(1),
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
// 카테고리 기본 구성
const Category = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const category = useSelector((state:any) => state.category);  // category
  const common = useSelector((state:any) => state.common);      // common

  // [Events] ######################################################################
  //@ 카테고리 최초 조회
  const handleInit = useCallback(() => {
    // data setting
    // category.cateTyp = '01';
    // category.initCate = '0000000000';
    // category.cateNode = 0;
    let body = {
      cateTyp: '01',
      initCate: '0000000000',
      cateNode: 0      
    }
    // 카테고리 조회
    getCate(body).then((d) => dispatch(d)); 

  },[dispatch]);
  
  //@ 카테고리 변경시
  const handleChange = useCallback((type, node, code) => {
    // state 변경
    if(type !== '') category.cateTyp = type;
    if(code !== '') category.initCate = code;
    if(node !== '') {
      let _n = Number(node); 
      // data 초기화
      if(_n < 4) category.cData4 = [];
      if(_n < 3) category.cData3 = [];
      if(_n < 2) category.cData2 = [];

      category.cateNode = _n;
    }
    // 카테고리 조회
    getCate(category).then((d) => dispatch(d));

  },[category, dispatch]);
    
  //@ 카테고리 등록
  const addDialogOpen = useCallback(() => {

    if(category.initCate === '0000000000') {
      // 공통 알럿 실행
      common.msg = '카테고리 그룹을 선택해 주세요.';
      common.isMsg = true;
      dispatch({type:'COM_ALERT'});
    } else if(category.cateNode > 3) {  // 마지막 카테고리 단계일 경우
      // 공통 알럿 실행
      common.msg = '카테고리를 생성 할 수 없는 그룹입니다. 다시 선택해 주시기 바랍니다.';
      common.isMsg = true;
      dispatch({type:'COM_ALERT'});
    } else {
      category.isAdd = true;
      getCateInfo(category).then((d) => dispatch(d));    
    }
  },[common, category, dispatch]);
  
  //@ 카테고리 삭제
  const removeDialogOpen = useCallback(() => {
    if(category.initCate === '0000000000') {
      // 공통 알럿 실행
      common.msg = '카테고리 그룹을 선택해 주세요.';
      common.isMsg = true;
      dispatch({type:'COM_ALERT'});
    } else {
      category.isRemove = true;
      getCateInfo(category).then((d) => dispatch(d));   
    }
  },[common, category, dispatch]);

  // [init] ######################################################################
  useEffect(() => {
    handleInit();  // 카테고리 최초 조회
  }, [handleInit]);
    
  // [Method] ######################################################################  
  // 카테고리 목록 조회
  const getCategory = (props:any) => {

    const { data, index, style } = props;

    // 정렬순서
    data.sort((a:any, b:any) => {
      if(a.cate_name > b.cate_name) return 1;
      if(a.cate_name < b.cate_name) return -1;
      return 0;
    });
    
    return (
      <ListItem 
        button 
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: category.initCate === data[index].cate_cd ? "#9ac0e6" : "white",
          cursor: "pointer"
        }} 
        key={index}
        // onClick={props.handleChange('', data[index].cate_grp_node, data[index].cate_cd)}
        onClick={() => {
          handleChange('', data[index].cate_grp_node, data[index].cate_cd);
        }}
        >
        <ListItemText primary={data[index].cate_name} />
      </ListItem>
    );
  };
    
  // [Render] ######################################################################  
  return (
    <Page
      className={classes.root}
      title="카테고리관리"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="flex-start"
      >
        <Container maxWidth="lg">
          {/* 타이틀 */}
          {/* <Box 
            display="flex"
            mb={3}
            justifyContent="flex-start"
          >
            <Typography
                variant="h4"
              >
                카테고리관리
            </Typography> 
          </Box> */}
          {/* 유형선택 */}
          <Box 
            display="flex"
            mb={3} 
            justifyContent="center"
          >
            <ButtonGroup disableElevation variant="contained" color="primary">
              <Button 
                color={category.cateTyp === '01' ? "primary" : "default"}
                onClick={() => {
                  handleChange('01', '0', '0000000000');
                }}
                >수선품</Button>
              <Button 
                color={category.cateTyp === '02' ? "primary" : "default"}
                onClick={() => {
                  handleChange('02', '0', '0000000000');
                }}                
                >수선유형</Button>
              <Button 
                color={category.cateTyp === '03' ? "primary" : "default"}
                onClick={() => {
                  handleChange('03', '0', '0000000000');
                }}                
                >수선위치</Button>                
            </ButtonGroup>        
          </Box>
          {/* 아이템 */}
          <Box mb={3} justifyContent="center">
            <Grid
              container
              justify="center" 
              spacing={1}
            >
              {/* 1단계 */}
              <Paper className={classes.paper} elevation={4}>  
                <Toolbar className={classes.toolbar}>
                  <Typography variant="h6">1 단계</Typography>
                </Toolbar>                    
                <FixedSizeList 
                  height={360} 
                  width={250} 
                  itemSize={45} 
                  itemCount={category.cData1.length} 
                  itemData={category.cData1}
                  >                        
                  {getCategory}
                </FixedSizeList>
              </Paper>
              {/* 2단계 */}
              <Paper className={classes.paper} elevation={4}>  
                <Toolbar className={classes.toolbar}>
                  <Typography variant="h6">2 단계</Typography>
                </Toolbar>                    
                <FixedSizeList 
                  height={360} 
                  width={250} 
                  itemSize={45} 
                  itemCount={category.cData2.length} 
                  itemData={category.cData2}
                  >                        
                  {getCategory}
                </FixedSizeList>
              </Paper>            
              {/* 3단계 */}
              <Paper className={classes.paper} elevation={4}>  
                <Toolbar className={classes.toolbar}>
                  <Typography variant="h6">3 단계</Typography>
                </Toolbar>                    
                <FixedSizeList 
                  height={360} 
                  width={250} 
                  itemSize={45} 
                  itemCount={category.cData3.length} 
                  itemData={category.cData3}
                  >                        
                  {getCategory}
                </FixedSizeList>
              </Paper>          
              {/* 4단계 */}
              <Paper className={classes.paper} elevation={4}>  
                <Toolbar className={classes.toolbar}>
                  <Typography variant="h6">4 단계</Typography>
                </Toolbar>                    
                <FixedSizeList 
                  height={360} 
                  width={250} 
                  itemSize={45} 
                  itemCount={category.cData4.length} 
                  itemData={category.cData4}
                  >                        
                  {getCategory}
                </FixedSizeList>
              </Paper>                                                      
            </Grid>
          </Box>
          {/* 버튼영역 */}
          <Box 
            display="flex"
            justifyContent="flex-end"
          >
            {/* 삭제 */}
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<Delete />}
              onClick={removeDialogOpen}
            >
              삭제
            </Button>    
            {/* 추가 */}
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<Save />}
              onClick={addDialogOpen}              
            >
              추가
            </Button>            
          </Box>          
        </Container>
      </Box> 
      {/* // 카테고리 추가 컴포넌트 */}
      <CategoryAdd />
      {/* // 카테고리 삭제 컴포넌트 */}
      <CategoryRemove />
    </Page>
  );
};

export default Category;
