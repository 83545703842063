/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux inspectors store
**/
// TYPES
import {
  INSPT_ADD,
  INSPT_FAIL,
  INSPT_GET,
  INSPT_GET_INFO,
  INSPT_UPD
} from "../types";
import { _dataAddID, _isEmpty } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows:[],
  info:[],
  isEdit: false
};

// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case INSPT_GET:         // 수선 검수자 전체 조회     
      return { 
        ...state,
        rows: _dataAddID(action.payload)
       };
    case INSPT_GET_INFO:    // 수선 검수자 개별 조회    
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
       };       
    case INSPT_ADD:         // 수선 검수자 저장
      return { 
        ...state,
        info: action.payload,
        isOpen: false,
        isEdit: _isEmpty(action.payload) ? false : true
       };    
    case INSPT_UPD:         // 수선 검수자 수정
      return { 
        ...state,
        info: action.payload,
        isOpen: false,
        isEdit: _isEmpty(action.payload) ? false : true
       };      
    case INSPT_FAIL:        // 수선 검수자 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}