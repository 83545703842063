/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Payment page
**/
/* @material import */
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';
/* react import */
import React from 'react';
import Page from './../Page';
import PaymentList from './PaymentList';
import PaymentView from './PaymentView';

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '90%',
    overflow: 'hidden',  
    marginTop: 0,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3)      
  },
  paper: {
    width: '100%',
    height: 700,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper    
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const Payment = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="정산"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="flex-start"
      >
        <Container maxWidth="xl">
          <Box mb={1} justifyContent="center">
            <Grid
              container
              justify="center" 
              spacing={1}
            >            
              <Grid item xs={8}>
                {/* 정산이력 */}
                <Paper className={classes.paper} elevation={4}>  
                  <PaymentList />         
                </Paper>                      
              </Grid>         
              <Grid item xs={4}>
                {/* 정산수정 */}
                <Paper className={classes.paper} elevation={4}>  
                  <PaymentView />         
                </Paper>                              
              </Grid>                            
            </Grid>
          </Box>
        </Container>
      </Box> 
    </Page>   
  );
};

export default Payment;
