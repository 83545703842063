/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux consult store
**/
// TYPES
import {
    USERS_ADD,
    USERS_FAIL,
    USERS_GET,
    USERS_GET_INFO,

    USERS_SEARCH, USERS_UDP
} from "../types";
import { _dataAddID } from "./../../Common";

// state 초기 데이터
const initialState = {
    // org_no: "01",           
    // Is_active: true,
    rows: [],
    info: []
    // isEdit: false
};

// action type 별 상태 관리
export default (state = initialState, action: any) => {
    switch (action.type) {
        case USERS_GET:         // 사용자 전체 조회     
            return {
            ...state,
            rows: _dataAddID(action.payload)
            };
        case USERS_GET_INFO:    // 사용자 개별 조회    
            return {
                ...state,
                info: action.payload,
                // isEdit: _isEmpty(action.payload) ? false : true
            };
        case USERS_ADD:         // 사용자 저장
            return {
                ...state,
                info: action.payload
                // isEdit: _isEmpty(action.payload) ? false : true
            };
        case USERS_UDP:         // 사용자 수정
            return {
                ...state,
                info: action.payload
                // isEdit: _isEmpty(action.payload) ? false : true
            };
        case USERS_SEARCH:
            return {
                ...state,
                // info: action.payload,
                rows: action.payload               
                // rows: _dataAddID(action.payload)
            };
        case USERS_FAIL:        // 결과 실패
            return { ...state, ...action.payload };
        default:
            return state;
    }
}