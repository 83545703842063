/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : FORM Select
**/
/* @material import */
import {
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select
} from '@material-ui/core';
/* React import */
import React from "react";
import {
    _isEmpty
} from "./../../utils/Common";
/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
    /* select style */
    root: {
        height: '90%',
        width: '90%',
        maxHeight: '90%',
        minWidth: '90%',
        fontSize: '0.1em',
        size: 'small',
        verticalAlign: 'bottom'
    },
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const FormSelect = (props: any) => {
    const classes = useStyles();     // CSS

    // [Grid] ######################################################################    
    let datagrid;
    let labelgrid;

    if (props.firstDefault) {
        datagrid = <MenuItem key='0' value=''>선택안함</MenuItem>;
    }
    if (!_isEmpty(props.label)) {
        labelgrid = <InputLabel htmlFor={props.id}>{props.label}</InputLabel>;
    }
    // [Render] ######################################################################  
    return (
        <FormControl
            className={classes.root}
            required={props.required}
            disabled={props.disabled}
        >
            {labelgrid}
            <Select
                // autoWidth
                labelId={props.id}
                id={props.id}
                name={props.id}
                required={props.required}
                disabled={props.disabled}
                label={props.label + ' 선택해주세요'}
                onBlur={props.blur}
                onChange={props.change}
                value={props.value || ''}
                error={Boolean(props.touched && props.errors)}
            // helperText={props.touched && props.errors}              
            // defaultValue={props.value}
            >
                {datagrid}
                {props.item.map((v: any, i: number) =>
                    <MenuItem
                        key={i + 1}
                        value={v.code}>
                        {v.name}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default FormSelect;