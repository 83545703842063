/**
 * date         : 2020.10.30
 * creater      : nuriggum
 * description  : redux users actions
**/
import { _ajaxGetJsonAsync, _ajaxPostJsonAsync } from "../../axios/axios";
// import { _CodeConvert, _getSession, _isEmpty, _removeSession, _setSession } from "./../../Common";
import {
  USERS_ADD,
  USERS_DEL,
  USERS_FAIL,
  USERS_GET,
  USERS_GET_INFO,
  USERS_UDP
} from "../types";

/* 사용자 경로 정보 */
const API_PATH = "/api/v1/users";
// const key = "USERS_DATA";

/* 사용자 전체 조회 */
export const getUsers = async () => {
  // IN Data 구성
  let body = {
     limit: '1000',  // 개수
  };  
  return await _ajaxGetJsonAsync("get", API_PATH + "/", body, USERS_GET, USERS_FAIL);
}

/* 사용자 조회 */
export const getUsersSrch = async (body: any) => {
  // IN Data 구성
  // body['limit'] = '1000';        
  return await _ajaxPostJsonAsync("post", API_PATH + "/search/", body, USERS_GET, USERS_FAIL);
}

/* 사용자 개별 조회 */
export const getUsersInfo = async (d: any) => {
  // ajax 통신
  return await _ajaxPostJsonAsync("post", API_PATH + "/search/", d, USERS_GET_INFO, USERS_FAIL);
}

/* 사용자 등록 */
export const addUsers = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['reg_user'] = user_name;       // 최초작성자
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("post", API_PATH + "/", d, USERS_ADD, USERS_FAIL);
}

/* 사용자 업데이트 */
export const updUsers = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH + "/"+d.user_id, d, USERS_UDP, USERS_FAIL);
}

/* 사용자 삭제 */
export const delUsers = async (d: any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자    
  return await _ajaxGetJsonAsync("delete", API_PATH + "/" + d.user_id, d, USERS_DEL, USERS_FAIL);    
}