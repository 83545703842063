/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Repair List Page
**/
/* @material import */
import {
  makeStyles,
  Typography
} from '@material-ui/core';
import {
  DataGrid
} from '@material-ui/data-grid';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  getReprWork
} from "../../utils/redux/actions/ReprAction";
import { _isEmpty, _setFormatCategory } from "./../../utils/Common";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
  },
  grid: {
    height: 250,
    margin: theme.spacing(1),
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#9fa8da',
      color: '#ffffff'
    },
  },
  iconButton: {
    padding: 10,
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const RepairList = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const repair = useSelector((state: any) => state.repair);      // repair
  const common = useSelector((state: any) => state.common);      // common
  const product = useSelector((state: any) => state.product);    // product

  // [Events] ######################################################################
  //@ 최초 실행
  const handleInit = useCallback(() => {
    // repair.rows = [];
    // 수선 목록 조회
    if (product.isEdit) {
      let body = {}
      body = {
        prod_no: product.info.prod_no
      }
      getReprWork(body).then((d) => dispatch(d));
    } else {
      dispatch({ type: 'REPR_GET', payload: [] });
    }
  }, [product, dispatch]);

  //@ 목록 선택
  const handleChange = useCallback((e) => {
    // 임시 데이터 선택
    dispatch({
      type: 'REPR_GET_TEMP',
      payload: e.data
    });

  }, [dispatch]);

  // [init] ######################################################################
  // 카테고리명 조회
  const _setCateName = _setFormatCategory(common.category);

  //@ 초기 데이터 (Table 컬럼)
  const columns: any = [
    { field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
    { field: 'repr_no', headerAlign: 'center', headerName: '수선번호', width: 130 },
    { field: 'cate_01', headerAlign: 'center', headerName: '수선유형', width: 120, hide: true, ..._setCateName },
    { field: 'cate_02', headerAlign: 'center', headerName: '수선(대분류)', width: 120, ..._setCateName },
    { field: 'cate_03', headerAlign: 'center', headerName: '수선(중분류)', width: 120, ..._setCateName },
    { field: 'cate_04', headerAlign: 'center', headerName: '수선(소분류)', width: 120, ..._setCateName },
    { field: 'repr_loc', headerAlign: 'center', headerName: '수선위치', width: 140, ..._setCateName },
    { field: 'repr_cnt', headerAlign: 'center', headerName: '수량', width: 120 },
    { field: 'repr_pay', headerAlign: 'center', headerName: '작업비용', width: 100 },
    { field: 'reg_user', headerAlign: 'center', headerName: '작성자', width: 80 },
    { field: 'last_mod_user', headerAlign: 'center', headerName: '수정자', width: 80 },
  ];

  //@ 최초 실행
  useEffect(() => {
    //1. 상담 조회
    handleInit();
  }, [handleInit]);

  // [Grid] ######################################################################    
  let datagrid;

  if (_isEmpty(repair.rows)) {
    datagrid = <Typography
      variant="h6"
      align="center"
    >
      데이터가 존재하지 않습니다.
    </Typography>;
  } else {
    datagrid = <DataGrid
      headerHeight={40}
      rowHeight={30}
      columns={columns}
      rows={repair.rows}
      pageSize={5}
      onRowClick={handleChange}
    />;
  }

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <div
        className={classes.grid}
      >
        {datagrid}
      </div>
    </React.Fragment>
  );
};

export default RepairList;
