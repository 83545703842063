/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Repair List Page
**/
/* @material import */
import {
  Grid,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  DataGrid
} from '@material-ui/data-grid';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getGallyInfo } from "../../utils/redux/actions/GallyAction";
import { getReprInfo, getReprWork } from "../../utils/redux/actions/ReprAction";
import { _isEmpty, _setFormatCategory } from "./../../utils/Common";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
  },
  grid: {
    height: '100%',
    width: '98%',
    maxHeight: '74%',
    minWidth: '98%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#9fa8da',
      color: '#ffffff'
    },
  },
  // 데이터 그리드 테이블 해더
  search: {
    margin: theme.spacing(1)
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const RepairList = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const repair = useSelector((state: any) => state.repair);      // repair
  const common = useSelector((state: any) => state.common);      // common
  const product = useSelector((state: any) => state.product);    // product

  // [Events] ######################################################################
  //@ 최초 실행
  const handleInit = useCallback(() => {

    // 수선 목록 조회
    if (!_isEmpty(product.info)) {
      let body = {}
      body = {
        prod_no: product.info.prod_no
      }
      getReprWork(body).then((d) => dispatch(d))
        .catch((e) => console.log(e));
    } else {
      // 작업지시서 목록 초기화
      dispatch({ type: 'REPR_GET', payload: [] })
    }

    // 작업지시서 정보 초기화
    dispatch({ type: 'REPR_GET_INFO', payload: [] })

  }, [product, dispatch]);

  //@ 작업지시서 목록 변경
  const handleChange = useCallback((e) => {
    let body = {
      repr_no: e.data.repr_no
    }

    dispatch({ type: 'GALLY_GET', payload: [] });
    dispatch({ type: 'GALLY_GET_TAG', payload: [] });

    getReprInfo(body).then((d) => dispatch(d))
      .catch((e) => console.log(e));
    getGallyInfo(body).then((d) => dispatch(d))
      .catch((e) => console.log(e));

  }, [dispatch]);

  // [init] ######################################################################
  // 카테고리명 조회
  const _setCateName = _setFormatCategory(common.category);

  //@ 초기 데이터 (Table 컬럼)
  const columns: any = [
    { field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
    { field: 'repr_no', headerAlign: 'center', headerName: '수선번호', width: 130 },
    { field: 'cate_01', headerAlign: 'center', headerName: '분류', width: 120, ..._setCateName },
    { field: 'cate_02', headerAlign: 'center', headerName: '유형(대)', width: 110, ..._setCateName },
    { field: 'cate_03', headerAlign: 'center', headerName: '유형(중)', width: 110, ..._setCateName },
    { field: 'cate_04', headerAlign: 'center', headerName: '유형(소)', width: 110, ..._setCateName },
    { field: 'repr_loc', headerAlign: 'center', headerName: '수선위치', width: 120, ..._setCateName },
    { field: 'repr_cnt', headerAlign: 'center', headerName: '수량', width: 120 }
  ];

  //@ 최초 실행
  useEffect(() => {
    handleInit();
  }, [handleInit]);

  // [Grid] ######################################################################    
  let datagrid;

  if (_isEmpty(repair.rows)) {
    datagrid = <Typography
      variant="h6"
      align="center"
    >
      수선품을 선택해주세요.
    </Typography>;
  } else {
    datagrid = <DataGrid
      headerHeight={30}
      rowHeight={20}
      columns={columns}
      rows={repair.rows}
      pageSize={3}
      onRowClick={handleChange}
    />;
  }

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h6">작업지시서</Typography>
          </Grid>
        </Grid>
      </Toolbar>
      <div
        className={classes.grid}
      >
        {datagrid}
      </div>
    </React.Fragment>
  );
};

export default RepairList;
