/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux chatbot products store
**/
// TYPES
import {
  BOT_PROD_ADD,
  BOT_PROD_DEL,
  BOT_PROD_FAIL,
  BOT_PROD_GET,
  BOT_PROD_GET_INFO,
  BOT_PROD_UPD
} from "../types";
import { _dataAddID, _isEmpty, _BotdataAddID } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows: [],
  info: [],
  srch: {},  // 검색  
  isEdit: false,
  isOpen: false
};

// action type 별 상태 관리
export default (state = initialState, action: any) => {
  switch (action.type) {
    case BOT_PROD_GET:         // 챗봇 전체 조회     
      return {
        ...state,
        rows: _BotdataAddID(action.payload)
      };
    case BOT_PROD_GET_INFO:    // 챗봇 개별 조회    
      return {
        ...state,
        info: action.payload[0],
        isEdit: _isEmpty(action.payload) ? false : true
      };
    case BOT_PROD_ADD:         // 챗봇 저장
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
      };
    case BOT_PROD_UPD:         // 챗봇 수정
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
      };
    case BOT_PROD_DEL:         // 챗봇 삭제
      return {
        ...state
      };
    case BOT_PROD_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}