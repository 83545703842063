/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Product List Page
**/
/* @material import */
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  DataGrid
} from '@material-ui/data-grid';
import {
  List,
  Save,
  Search
} from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import FormText from '../../utils/form/FormText';
import { getProdImg, getProdInfo, updProd } from "../../utils/redux/actions/ProdAction";
import { getProdCate } from "../../utils/redux/actions/ProdCateAction";
import {
  _isEmpty,
  _setCodeFilter,
  _setDateYYYYMMDD,
  _setFormatCategory,
  _setFormatCode,
  _setFormatDate,
  _setFormatPhone,
  _setPhone,
  _setUTCDate
} from "./../../utils/Common";
import FormDate from "./../../utils/form/FormDate";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
  },
  grid: {
    height: '100%',
    width: '98%',
    maxHeight: '78%',
    minWidth: '98%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#9fa8da',
      color: '#ffffff'
    },
  },
  // 데이터 그리드 테이블 해더
  search: {
    margin: theme.spacing(1),
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    // alignItems: 'flex-end',
    alignItems: 'center',
    width: '100%',
    height: 30,
    marginTop: 13
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  searchTypography: {
    marginTop: 12
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ProductList = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const product = useSelector((state: any) => state.product);    // product
  const common = useSelector((state: any) => state.common);      // common

  // [Events] ######################################################################
  //@ 최초 실행
  const handleInit = useCallback(() => {

    // 접수상태 완료
    // let body = {
    //   rcpt_stat: '02'  // 01:접수대기, 02:접수완료
    // }
    let body: any = {};
    body['rcpt_stat'] = '02'  // 01:접수대기, 02:접수완료
    body['limit'] = '100'
    // 수선품 조회
    getProdImg(body).then((d) => {
      let rows = d.payload;
      // if (!_isEmpty(rows)) {
      //   d.payload = rows.filter((v: any) => v.prod_stat === '01' || v.prod_stat === '03');
      // }
      return dispatch(d);
    })
      .catch((e) => console.log(e));

    // 수선품 정보 초기화
    dispatch({ type: 'PROD_GET_INFO', payload: {} });

  }, [dispatch]);

  //@ 작업지시서 목록 변경
  const handleChange = useCallback((e) => {
    let body = {
      prod_no: e.data.prod_no
    }
    dispatch({ type: 'REPR_GET_INFO', payload: [] });
    dispatch({ type: 'REPR_GET', payload: [] });
    dispatch({ type: 'GALLY_GET_INFO', payload: [] });

    // 작업지시서 목록
    // getRepr(body).then((d) => dispatch(d))
    //              .catch((e) => console.log(e));
    // 수선품 정보
    getProdInfo(e.data.prod_no).then((d) => dispatch(d))
      .catch((e) => console.log(e));
    // 수선품 카테고리 목록                 
    getProdCate(body).then((d) => dispatch(d))
      .catch((e) => console.log(e));
  }, [dispatch]);

  //@ 체크박스 선택
  const handleSel = useCallback((e) => {
    dispatch({ type: 'PROD_GET_CHK', payload: e.rows });
  }, [dispatch]);

  //@ 체크박스 선택
  const handleUpdate = useCallback(() => {
    if (product.checked.length > 0) {  // 선택된 정보가 있을 경우
      product.checked.forEach((v: any) => {
        let prod_stat = '';     // 상태
        if (v.prod_stat === '01') {
          prod_stat = '02';
        } else if (v.prod_stat === '03') {
          prod_stat = '04';
        }
        let body = {
          prod_no: v.prod_no,   // 수선품번호
          prod_stat: prod_stat  // 상태
        }
        // 수선품 상태 업데이트
        updProd(body)
          .then((d) => {
            dispatch(d);
            let body: any = {};
            body['date_key'] = 'repr_st_dt';
            if (!_isEmpty(product.srch.st_dt)) body['st_dt'] = _setUTCDate(product.srch.st_dt);
            if (!_isEmpty(product.srch.ed_dt)) body['ed_dt'] = _setUTCDate(product.srch.ed_dt);
            if (!_isEmpty(product.srch.prod_stat)) body['prod_stat'] = product.srch.prod_stat;
            if (!_isEmpty(product.srch.srch_val)) {
              if (product.srch.srch_key.toString() === 'phone') {
                product.srch.srch_val = _setPhone(product.srch.srch_val);
              }
              body[product.srch.srch_key.toString()] = product.srch.srch_val;
            }
            if (!_isEmpty(product.srch.limit)) body["limit"] = product.srch.limit;
            body['rcpt_stat'] = '02';    // 01:접수대기, 02:접수완료

            // 수선품 조회
            getProdImg(body).then((d) => {
              return dispatch(d);
            })
              .catch((e) => console.log(e));
            // handleInit();
          })
          .catch((e) => console.log(e));
      });
    } else {
      common.msg = '선택된 정보가 없습니다.';
      common.isMsg = true;
      return dispatch({ type: 'COM_ALERT' });       // 안내메시지 
    }

  }, [product, common, dispatch, handleInit]);

  //@ 검색
  const handleSearch = useCallback((v) => {

    product.srch = v;

    let body: any = {};
    body['date_key'] = 'repr_st_dt';
    if (!_isEmpty(v.st_dt)) body['st_dt'] = _setUTCDate(v.st_dt);
    if (!_isEmpty(v.ed_dt)) body['ed_dt'] = _setUTCDate(v.ed_dt);
    if (!_isEmpty(v.prod_stat)) body['prod_stat'] = v.prod_stat;
    if (!_isEmpty(v.srch_val)) {
      if (v.srch_key.toString() === 'phone') {
        v.srch_val = _setPhone(v.srch_val);
      }
      body[v.srch_key.toString()] = v.srch_val;
    }
    if (!_isEmpty(v.limit)) body["limit"] = v.limit;
    body['rcpt_stat'] = '02';    // 01:접수대기, 02:접수완료

    // 수선품 조회
    getProdImg(body).then((d) => {
      let rows = d.payload;
      // if (!_isEmpty(rows)) {
      //   d.payload = rows.filter((v: any) => v.prod_stat === '01' || v.prod_stat === '03');
      // }
      return dispatch(d);
    })
      .catch((e) => console.log(e));

  }, [product, dispatch]);

  // [init] ######################################################################
  useEffect(() => {
    handleInit();
  }, [handleInit]);

  // 데이트 타입 설정
  const _setDate = _setFormatDate();
  // 카테고리명 조회
  const _setCateName = _setFormatCategory(common.category);
  // 접수상태명 조회
  const _setStateName = _setFormatCode(common.code, 'G008');
  // 수선종류 조회
  const _setItemName = _setFormatCode(common.code, 'G025');
  // 연락처 조회
  const _setGridPhone = _setFormatPhone();

  //@ 초기 데이터 (Table 컬럼) 
  const columns = [
    { field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
    { field: 'prod_no', headerAlign: 'center', headerName: '수선품번호', width: 130 },
    { field: 'cust_name', headerAlign: 'center', headerName: '고객명', width: 140 },
    { field: 'phone', headerAlign: 'center', headerName: '연락처', width: 130, ..._setGridPhone },
    { field: 'brand_cd', headerAlign: 'center', headerName: '브랜드', width: 150, ..._setCateName, hide: true },
    { field: 'brand_name', headerAlign: 'center', headerName: '브랜드', width: 150 },
    // { field: 'cate_cd', headerAlign: 'center', headerName: '품목', ..._setCateName },
    { field: 'cate_cd', headerAlign: 'center', headerName: '수선종류', width: 120, ..._setItemName, hide: true },
    { field: "cate_name", headerAlign: "center", headerName: "수선종류", width: 120, },
    { field: 'repr_st_dt', headerAlign: 'center', headerName: '입고일', width: 120, ..._setDate },
    { field: 'repr_req_dt', headerAlign: 'center', headerName: '출고요청일', width: 120, ..._setDate },
    { field: 'prod_stat', headerAlign: 'center', headerName: '상태', width: 100, ..._setStateName },
  ];
  // [Grid] ######################################################################    
  let datagrid;

  if (_isEmpty(product.rows)) {
    datagrid = <Typography
      variant="h6"
      align="center"
    >
      데이터가 존재하지 않습니다.
    </Typography>;
  } else {
    datagrid = <DataGrid
      headerHeight={40}
      rowHeight={30}
      columns={columns}
      rows={product.rows}
      pageSize={15}
      checkboxSelection={true}
      onSelectionChange={(e: any) => handleSel(e)}
      onRowClick={handleChange}
    />;
  }

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      {/* 목록영역 */}
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={10}>
            <Typography variant="h6">수선품</Typography>
          </Grid>
          <Grid item xs={2}>
            <Grid
              container
              justify="center"
              alignItems="center"
            >
              <Grid item xs={5}></Grid>
              <Grid item xs={7}>
                {/* 전체목록 */}
                <IconButton
                  className={classes.iconButton}
                  aria-label="전체목록"
                  color="inherit"
                  onClick={handleInit}
                >
                  <List /><Typography variant="h6">전체</Typography>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      {/* 검색기능 */}
      <div
        className={classes.search}
      >
        <Formik
          enableReinitialize
          initialValues={{
            // 기본정보
            st_dt: _isEmpty(product.srch) ? '' :
              _isEmpty(product.srch.st_dt) ? '' : _setDateYYYYMMDD(product.srch.st_dt, '-'), // 검색시작일
            ed_dt: _isEmpty(product.srch) ? _setDateYYYYMMDD('', '-') :
              _isEmpty(product.srch.ed_dt) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(product.srch.ed_dt, '-'), // 검색종료일

            prod_stat: _isEmpty(product.srch) ? '' :
              _isEmpty(product.srch.prod_stat) ? '' : product.srch.prod_stat,                // 수선품상태

            srch_key: _isEmpty(product.srch) ? 'prod_no' :
              _isEmpty(product.srch.srch_key) ? 'prod_no' : product.srch.srch_key,           // 검색키
            srch_val: _isEmpty(product.srch) ? '' :
              _isEmpty(product.srch.srch_val) ? '' : product.srch.srch_val,                   // 검색어
            limit: '100' // 노출 데이터 수
          }}
          validationSchema={Yup.object().shape({
            // 밸리데이션 불필요
          })}
          onSubmit={(v) => {
            handleSearch(v);
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
            <form onSubmit={handleSubmit}>
              {/* 그리드 */}
              <Grid
                container
                justify="center"
                alignItems="center"
              >
                {/* 기간 */}
                <Grid item xs={4}>
                  <Grid
                    container
                    justify="center"
                  >

                    <Grid item xs={5}>
                      <FormDate
                        id='st_dt'
                        label='접수일(시작)'
                        value={values.st_dt}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        variant="h3"
                        className={classes.searchTypography}
                        align="center">~ </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <FormDate
                        id='ed_dt'
                        label='접수일(종료)'
                        value={values.ed_dt}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Grid>
                {/* 구분 */}
                <Grid item xs={2}>
                  <Grid
                    container
                    justify="center"
                  >
                    {/* <Grid item xs={2}></Grid> */}
                    <Grid item xs={10}>
                      {/* 수선품상태 */}
                      <FormSelect
                        id='prod_stat'
                        label='수선품상태'
                        value={values.prod_stat}
                        item={_setCodeFilter(common.code, 'G008')}
                        firstDefault={true}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={2}></Grid>
                  </Grid>
                </Grid>
                {/* 검색어 */}
                <Grid item xs={6}>
                  {/* 구분 */}
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={2}>
                      <FormText
                        id='limit'
                        label='데이터 수'
                        value={values.limit}
                        // disabled={true}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={3}>
                      <FormSelect
                        id='srch_key'
                        label='검색구분'
                        firstDefault={false}
                        value={values.srch_key}
                        item={[
                          { code: 'prod_no', name: '수선품번호' },
                          { code: 'rcpt_name', name: '고객명' },
                          { code: 'phone', name: '연락처' }
                        ]}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={7}>
                      <Paper className={classes.paper}>
                        <InputBase
                          className={classes.input}
                          placeholder="검색어를 입력해주세요"
                          id='srch_val'
                          name='srch_val'
                          value={values.srch_val}
                          type='text'
                          error={Boolean(touched.srch_val && errors.srch_val)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <IconButton
                          type="submit"
                          className={classes.iconButton}
                          aria-label="search"
                        >
                          <Search />
                        </IconButton>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
      <div
        className={classes.grid}
      >
        {datagrid}
      </div>
      {/* 버튼영역 */}
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        {/* 작업완료 */}
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Save />}
          onClick={handleUpdate}
        >
          작업완료
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default ProductList;
