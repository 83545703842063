/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Category Add Layer Popup
**/
/* @material import */
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    TextField
} from '@material-ui/core';
import { Formik } from 'formik';
/* react import */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { _removeSession } from "./../../utils/Common";
import {
    addCate,
    getCate
} from "./../../utils/redux/actions/CateAction";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
    root: {
    },
    toolbar: {
        minHeight: 40,
        color: '#fff',
        backgroundColor: '#1976d2'
    },
}));

/* ######################################################################
 * component
 * ###################################################################### */
const CategoryAdd = () => {
    const classes = useStyles();     // CSS
    const dispatch = useDispatch();  // dispatch hook

    // [state] ######################################################################
    // state 상태 관리
    const common = useSelector((state: any) => state.common);      // common
    const category = useSelector((state: any) => state.category);  // category

    // [Events] ######################################################################
    //@ 카테고리 등록
    const handleAdd = useCallback((v) => {
        let body = {
            cateInfo: category.cateInfo,              // 그룹 카테고리 정보
            cate_name: v.catename                     // 카테고리 이름
        }
        // 카테고리 등록
        addCate(body).then((a) => {
            // 21.08.31 추가 : 이미 존재하는 카테고리 추가 시 알림메세지 출력
            if (a.payload.detail === "Category name already exists.") {
                common.msg = '이미 존재하는 카테고리입니다.';
                common.isMsg = true;
                return dispatch({ type: 'COM_ALERT' });       // 안내메시지
                // 21.08.31
            } else {
                category.cateNode = Number(category.cateInfo.cate_grp_node);
                let body2 = {
                    initCate: category.cateInfo.cate_cd,       // 카테고리 코드
                    cateTyp: category.cateInfo.cate_type
                }
                dispatch(a);  // 카테고리 등록 완료
                _removeSession('COM_CATAGORY');
                getCate({}).then((d) => dispatch({ type: 'COM_CATAGORY', payload: d.payload }));
                getCate(body2).then((b) => {
                    dispatch(b);  // 카테고리 조회
                }).catch((e) => console.log(e));
            }
        }).catch((e) => console.log(e));

    }, [category, dispatch]);

    //@ 팝업 닫기
    const handleClose = useCallback(() => {
        category.isAdd = false;
        dispatch({
            type: 'CATE_ADD_STAT'
        });
    }, [category, dispatch]);

    // [Render] ######################################################################  
    return (
        <Dialog open={category.isAdd} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className={classes.toolbar}>카테고리 추가</DialogTitle>
            <Formik
                initialValues={{
                    catename: ''
                }}
                validationSchema={Yup.object().shape({
                    catename: Yup.string().max(255).required('catename is required')
                })}
                onSubmit={(v) => {
                    handleAdd(v);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                name="catename"
                                label="카테고리 입력"
                                type="text"
                                fullWidth
                                error={Boolean(touched.catename && errors.catename)}
                                helperText={touched.catename && errors.catename}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.catename}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                            <Button
                                type="submit"
                                color="primary">
                                추가
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default CategoryAdd;
