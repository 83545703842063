/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Category Add Layer Popup
**/
/* @material import */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
/* react import */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { _removeSession } from "./../../utils/Common";
import {
  delCate,
  getCate
} from "./../../utils/redux/actions/CateAction";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
}));

/* ######################################################################
 * component
 * ###################################################################### */
const CategoryRemove = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  // state 상태 관리
  const category = useSelector((state: any) => state.category);  // category

  // [Events] ######################################################################
  //@ 카테고리 삭제
  const handleRemove = useCallback(() => {
    let body = {
      cateInfo: category.cateInfo,       // 카테고리 코드
    }

    // 카테고리 삭제
    delCate(body).then((a) => {
      // 카테고리 재조회
      category.cateNode = Number(category.cateInfo.cate_grp_node) - 1;
      let body2 = {
        initCate: category.cateInfo.cate_grp_cd,    // 카테고리 코드
        cateTyp: category.cateInfo.cate_type
      }
      dispatch(a);
      _removeSession('COM_CATAGORY');
      getCate({}).then((d) => dispatch({ type: 'COM_CATAGORY', payload: d.payload }));
      getCate(body2).then((b) => {                    // 카테고리 제거 완료
        dispatch(b);
        dispatch({ type: 'CATE_DEL_RESET' })             // 카테고리 제거 후 상태값 리셋(21.8.23추가)
        dispatch({ type: 'CATE_ADD_GET', payload: [] })
      }).catch((e) => console.log(e));
    }).catch((e) => console.log(e));

  }, [category, dispatch]);

  const handleClose = useCallback(() => {
    category.isRemove = false;
    dispatch({
      type: 'CATE_ADD_STAT'
    });
  }, [category, dispatch]);

  // [Render] ######################################################################  
  return (
    <Dialog open={category.isRemove} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.toolbar}>카테고리 삭제</DialogTitle>
      <DialogContent>
        <DialogContentText variant="h6">
          {category.cateInfo.cate_name} 카테고리를 삭제 진행 하시겠습니까?
          (하위 카테고리 모두 삭제 됩니다.)
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          취소
        </Button>
        <Button onClick={handleRemove} color="secondary">
          삭제
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryRemove;
