/**
 * date         : 2024.09.22
 * updater      : benterest
 * description  : FORM NUMERIC - Updated to allow numbers, spaces, minus signs, and commas with safe integer check
**/
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '90%',
    width: '90%',
    maxHeight: '90%',
    minWidth: '90%',
    fontSize: '0.1em',
    size: 'small'
  },
}));

const FormNumeric = (props: any) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    
    // Allow numbers, spaces, minus signs, and commas
    if (/^[-,\d\s]*$/.test(newValue)) {
      // Remove commas and spaces for number validation
      const cleanValue = newValue.replace(/[,\s]/g, '');
      
      // Check if it's empty or within safe integer range
      if (cleanValue === '' || cleanValue === '-' || isWithinSafeIntegerRange(cleanValue)) {
        props.change(event);
      }
    }
  };

  const isWithinSafeIntegerRange = (value: string): boolean => {
    const num = Number(value);
    return !isNaN(num) && num >= Number.MIN_SAFE_INTEGER && num <= Number.MAX_SAFE_INTEGER;
  };

  // [Render] ######################################################################  
  return (
    <TextField
      className={classes.root}
      margin="dense"
      required={props.required}
      disabled={props.disabled}
      id={props.id}
      name={props.id}
      label={props.label}
      placeholder={props.label + ' 입력해주세요'}
      InputProps={{ 
        style: { fontSize: 14 }
      }}
      inputProps={{
        inputMode: 'numeric'
      }}
      type='text'
      size='small'
      error={Boolean(props.touched && props.errors)}
      helperText={props.touched && props.errors}
      onBlur={props.blur}
      onChange={handleChange}
      onClick={props.click}
      value={props.value || ''}
    />
  );
};

export default FormNumeric;