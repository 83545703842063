/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux customer store
**/
// TYPES
import {
  CUST_ADD,
  CUST_FAIL,
  CUST_GET,
  CUST_GET_INFO,
  CUST_UPD,
  CUST_SEND,
  CUST_UPD_STAT
} from "../types";
import { _isEmpty } from "./../../Common";

// state 초기 데이터
const initialState = {
  list:[],
  info:[],
  cust_no:'',
  isEdit: false
};

// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case CUST_GET:         // 목록 조회
      return { 
        ...state,
        list: action.payload
       };
    case CUST_GET_INFO:    // 정보 조회
      return { 
        ...state,
        info: action.payload.length > 0 ? action.payload[0] : [],
        isEdit: _isEmpty(action.payload) ? false : true
       };       
    case CUST_ADD:         // 정보 저장
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
       };    
    case CUST_UPD:         // 정보 수정
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
       };      
    case CUST_SEND:
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload.cust_no) ? false : true
      };
    case CUST_UPD_STAT:
      return {
        ...state,
        info:action.payload,
        isEdit: _isEmpty(action.payload.cust_no) ? false : true
      };
    case CUST_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}