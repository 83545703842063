/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Chatbot Product List Page
**/
/* @material import */
import {
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  DataGrid
} from '@material-ui/data-grid';
import {
  List,
  Search
} from '@material-ui/icons';
/* react import */
import { Formik } from 'formik';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import { getBotGaly } from "../../utils/redux/actions/BotGalyAction";
import { getBotProd, getBotProdInfo } from "../../utils/redux/actions/BotProdAction";
import { getImgInfo } from "../../utils/redux/actions/ImgAction";
import {
  _isEmpty,
  _setCateCodeFilter,
  _setFormatCategory
} from "./../../utils/Common";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
  },
  grid: {
    height: '100%',
    width: '98%',
    maxHeight: '82%',
    minWidth: '98%',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#9fa8da',
      color: '#ffffff'
    },
  },
  // 데이터 그리드 테이블 해더
  search: {
    margin: theme.spacing(1)
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 1,
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    height: 30,
    marginTop: 13
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchTypography: {
    marginTop: 12
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ChatbotList = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const botProd = useSelector((state: any) => state.botProd);    // product
  const common = useSelector((state: any) => state.common);      // common

  // [Events] ######################################################################
  //@ 최초 실행
  const handleInit = useCallback(() => {
    let body = {};
    getBotProd(body).then((d) => dispatch(d))
      .catch((e) => console.log(e));
  }, [dispatch]);

  //@ 챗봇 수선품 선택
  const handleChange = useCallback((e) => {
    let data: any = [];
    let body = {
      bot_prod_no: e.data.bot_prod_no
    }
    // dispatch({ type: 'BOT_GALY_GET', payload: [] });
    getBotProdInfo(body).then((d) => {
      // 상품 이미지 정보 조회
      getBotGaly(body).then(async (d) => {
        let rows = d.payload;
        if (rows.length > 0) {
          for (let v of rows) {
            let body = { imageid: v.bot_galy_id };
            await getImgInfo(body).then((img: any) => {
              if (!_isEmpty(img.payload)) {
                let image_url = '';
                if (img.payload.isResized) {
                  image_url = img.payload.resizedImageUrl;
                } else {
                  image_url = img.payload.rawImageUrl;
                }
                v['bot_galy_url'] = _isEmpty(image_url) ? '' : image_url;

                data.push(v);
              }
            })
              .then(() => dispatch({ type: 'BOT_GALY_GET', payload: data }))
          }
          // rows.forEach((v: any, idx: number) => {
          //   setTimeout(() => {
          //     let body = { imageid: v.bot_galy_id };
          //     getImgInfo(body).then((img: any) => {
          //       if (!_isEmpty(img.payload)) {
          //         let image_url = '';
          //         if (img.payload.isResized) {
          //           image_url = img.payload.resizedImageUrl;
          //         } else {
          //           image_url = img.payload.rawImageUrl;
          //         }
          //         v['bot_galy_url'] = _isEmpty(image_url) ? '' : image_url;

          //         data.push(v);
          //       }
          //       if ((idx + 1) === rows.length) {
          //         dispatch({ type: 'BOT_GALY_GET', payload: data });
          //       }
          //     }).catch((e) => console.log(e));
          //   }, (idx * 100));
          // });
        } else {  // 이미지가 없을 경우
          return dispatch({ type: 'BOT_GALY_GET', payload: [] });
        }
      }).catch((e) => console.log(e));

      // 챗봇 상품 정보 업데이트
      dispatch(d);

    }).catch((e) => console.log(e));

  }, [dispatch]);

  //@ 검색
  const handleSearch = useCallback((v) => {

    botProd.srch = v;

    let body: any = {};
    if (!_isEmpty(v.bot_cate_1)) body['bot_cate_1'] = v.bot_cate_1;
    if (!_isEmpty(v.bot_cate_2)) body['bot_cate_2'] = v.bot_cate_2;
    if (!_isEmpty(v.bot_cate_3)) body['bot_cate_3'] = v.bot_cate_3;
    if (!_isEmpty(v.srch_val)) body[v.srch_key.toString()] = v.srch_val;

    getBotProd(body).then((d) => dispatch(d))
      .catch((e) => console.log(e));

  }, [botProd, dispatch]);

  // [init] ######################################################################
  useEffect(() => {
    handleInit();
  }, [handleInit]);

  // 카테고리명 조회
  const _setCateName = _setFormatCategory(common.category);

  //@ 초기 데이터 (Table 컬럼)
  const columns: any = [
    { field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
    { field: 'bot_prod_no', headerAlign: 'center', headerName: '상품번호', width: 130 },
    { field: 'brand_name', headerAlign: 'center', headerName: '브랜드', width: 180 },
    { field: 'bot_cate_1', headerAlign: 'center', headerName: '브랜드', width: 180, ..._setCateName, hide: true },
    { field: 'bot_cate_2', headerAlign: 'center', headerName: '품목', width: 120, ..._setCateName },
    { field: 'bot_cate_3', headerAlign: 'center', headerName: '수선종류', width: 150, ..._setCateName },
    { field: 'bot_cate_3_1', headerAlign: 'center', headerName: '수선(대분류)', width: 120, ..._setCateName },
    { field: 'bot_cate_3_2', headerAlign: 'center', headerName: '수선(중분류)', width: 120, ..._setCateName },
    // { field: 'bot_cate_3_3', headerAlign: 'center', headerName: '수선(소분류)', width: 130, ..._setCateName },
    { field: 'bot_pay_cont', headerAlign: 'center', headerName: '비용 및 기타', width: 200, hide: true },
    { field: 'reg_user', headerAlign: 'center', headerName: '작성자', width: 80 },
    { field: 'last_mod_user', headerAlign: 'center', headerName: '수정자', width: 80 }
  ];
  // [Grid] ######################################################################    
  let datagrid;

  if (_isEmpty(botProd.rows)) {
    datagrid = <Typography
      variant="h6"
      align="center"
    >
      데이터가 존재하지 않습니다.
    </Typography>;
  } else {
    datagrid = <DataGrid
      headerHeight={40}
      rowHeight={30}
      columns={columns}
      rows={botProd.rows}
      pageSize={15}
      onRowClick={handleChange}
    />;
  }

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={11}>
            <Typography variant="h6">챗봇상품</Typography>
          </Grid>
          <Grid item xs={1}>
            {/* 전체목록 */}
            <IconButton
              className={classes.iconButton}
              aria-label="전체목록"
              color="inherit"
              onClick={handleInit}
            >
              <List /><Typography variant="h6">전체</Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      {/* 검색기능 */}
      <div
        className={classes.search}
      >
        <Formik
          enableReinitialize
          initialValues={{
            // 기본정보
            bot_cate_1: _isEmpty(botProd.srch) ? '' :
              _isEmpty(botProd.srch.bot_cate_1) ? '' : botProd.srch.bot_cate_1,                // 브랜드
            bot_cate_2: _isEmpty(botProd.srch) ? '' :
              _isEmpty(botProd.srch.bot_cate_2) ? '' : botProd.srch.bot_cate_2,                // 브랜드
            bot_cate_3: _isEmpty(botProd.srch) ? '' :
              _isEmpty(botProd.srch.bot_cate_3) ? '' : botProd.srch.bot_cate_3,                // 수선종류

            srch_key: _isEmpty(botProd.srch) ? 'bot_prod_no' :
              _isEmpty(botProd.srch.srch_key) ? 'bot_prod_no' : botProd.srch.srch_key,         // 검색키
            srch_val: _isEmpty(botProd.srch) ? '' :
              _isEmpty(botProd.srch.srch_val) ? '' : botProd.srch.srch_val                     // 검색어
          }}
          validationSchema={Yup.object().shape({
            // 밸리데이션 불필요
          })}
          onSubmit={(v) => {
            handleSearch(v);
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
            <form onSubmit={handleSubmit}>
              {/* 그리드 */}
              <Grid
                container
                justify="center"
                alignItems="center"
              >
                {/* 기간 */}
                {/* <Grid item xs={4}></Grid> */}
                {/* 구분 */}
                <Grid item xs={8}>
                  <Grid
                    container
                    justify="center"
                  >
                    {/* <Grid item xs={2}></Grid> */}
                    <Grid item xs={5}>
                      <FormSelect
                        id='bot_cate_1'
                        label='브랜드'
                        value={values.bot_cate_1}
                        item={_setCateCodeFilter(common.category, '0100000000')}
                        firstDefault={true}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={3}>
                      <FormSelect
                        id='bot_cate_2'
                        label='품목'
                        value={values.bot_cate_2}
                        item={_setCateCodeFilter(common.category, '0200000000')}
                        firstDefault={true}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={3}>
                      <FormSelect
                        id='bot_cate_3'
                        label='수선종류'
                        value={values.bot_cate_3}
                        item={[
                          { code: '0600000000', name: '가방,지갑,기타' },
                          { code: '0700000000', name: '벨트' },
                          { code: '0800000000', name: '신발' }
                        ]}
                        firstDefault={true}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2}></Grid>
                  </Grid>
                </Grid>
                {/* 검색어 */}
                <Grid item xs={4}>
                  {/* 구분 */}
                  <Grid
                    container
                    justify="center"
                  >
                    <Grid item xs={4}>
                      <FormSelect
                        id='srch_key'
                        label='검색구분'
                        firstDefault={false}
                        value={values.srch_key}
                        item={[
                          { code: 'bot_prod_no', name: '상품번호' },
                          // {code:'bot_cate_1', name:'브랜드'},
                          // {code:'bot_cate_2', name:'품목'},
                          // {code:'bot_cate_3_1', name:'수선(대분류)'},
                          // {code:'bot_cate_3_2', name:'수선(중분류)'},
                        ]}
                        required={true}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={7}>
                      <Paper className={classes.paper}>
                        <InputBase
                          className={classes.input}
                          placeholder="검색어를 입력해주세요"
                          id='srch_val'
                          name='srch_val'
                          value={values.srch_val}
                          type='text'
                          error={Boolean(touched.srch_val && errors.srch_val)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <IconButton
                          type="submit"
                          className={classes.iconButton}
                          aria-label="search">
                          <Search />
                        </IconButton>
                      </Paper>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
      <div
        className={classes.grid}
      >
        {datagrid}
      </div>
    </React.Fragment>
  );
};

export default ChatbotList;
