/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Users page
**/
/* @material import */
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Save } from '@material-ui/icons';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
/* react import */
import { _clearSession, _isEmpty, _setCodeFilter } from "../../utils/Common";
import FormSelect from "../../utils/form/FormSelect";
import { getUsers, updUsers } from "../../utils/redux/actions/UsersAction";

/* styles */
const useStyles = makeStyles((theme) => ({
	/* select style */
	root: {
		height: "100%",
		width: "100%",
		maxHeight: "90%",
		minWidth: "90%",
		margin: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
	},
	toolbar: {
		minHeight: 40,
		width: 400,
		color: "#fff",
		backgroundColor: "#1976d2",
	},
	label: {
		fontSize: 20,
		color: "primary",
		marginTop: theme.spacing(2),
	},
}));

/* ######################################################################
 * component
 * ###################################################################### */
const UsersEdit = () => {
				const classes = useStyles(); // CSS
				const dispatch = useDispatch(); // dispatch hook
				const navigate = useNavigate(); // link

				// [state] ######################################################################
				// state 상태 관리

				const users = useSelector((state: any) => state.users); // users
				const common = useSelector((state: any) => state.common); // common

				// [Events] ######################################################################
				//@ 사용자 정보 수정
				const handleEdit = useCallback(
					(v) => {
						updUsers(v)
							.then((res: any) => dispatch(res))
							.then((v: any) => {
								if (v.type === "USERS_FAIL") {
									if (v.status == "401") {
										common.msg = "세션이 만료되어 로그 아웃됩니다.";
										common.isMsg = true;
										_clearSession();
										navigate("/login", { replace: true });
										return dispatch({ type: "COM_ALERT" }); // 안내메시지
									} else if (v.status == "400") {
										common.msg = "존재하는 email 입니다.";
										common.isMsg = true;
										return dispatch({ type: "COM_ALERT" }); // 안내메시지
									} else {
										common.msg = "error code: " + v.status.toString();
										common.isMsg = true;
										return dispatch({ type: "COM_ALERT" }); // 안내메시지
									}									
								} 
								else {
									common.msg = "수정에 성공 하였습니다.";
									common.isMsg = true;
									return dispatch({ type: "COM_ALERT" }); // 안내메시지
								}
							})
							.then(() => getUsers().then((d) => dispatch(d)));
						setOpen(false);
					},
					[common, navigate, dispatch]
				);

				//@ 팝업 속성 상태 선언
				const [open, setOpen] = React.useState(false);

				//@ 팝업 닫기
				const handleClose = useCallback(() => {
					setOpen(false);
				}, []);

				//@ 팝업 열기
				const handleClickOpen = () => {
					// 선택된 ID가 없는 경우 알람창 발생
					if (_isEmpty(users.info.user_id)) {
						common.msg = "사용자를 선택해주세요.";
						common.isMsg = true;
						return dispatch({ type: "COM_ALERT" }); // 안내메시지
					} else {
						setOpen(true);
					}
				};
				// [Render] ######################################################################
				return (
					<div>
						<Button
							variant="contained"
							color="primary"
							onClick={handleClickOpen}
							size="medium"
							className={classes.button}
							startIcon={<Save />}
						>							
							수정
						</Button>
						<Dialog
							open={open}
							onClose={handleClose}
							aria-labelledby="form-dialog-title"
						>
							<DialogTitle id="form-dialog-title" className={classes.toolbar}>
								사용자 정보 수정
							</DialogTitle>
							<Formik
								initialValues={{
									// org_no: _isEmpty(users.info.org_no) ? '' : users.info.org_no,      // 지점 코드
									// email: _isEmpty(users.info.email) ? '' : users.info.email,   // 이메일
									user_id: users.info.user_id, // 사용자 ID
									org_no: "01", // 지점번호
									email: users.info.email, // 이메일
									user_name: users.info.user_name, // 이름
									phone: _isEmpty(users.info.phone) ? "" : users.info.phone, // 전화번호
									// is_superuser: users.info.is_superuser, // 관리자 권한
									password: _isEmpty(users.info.password)
										? ""
										: users.info.password, // 비밀번호
									is_active: users.info.is_active, // 사용 여부
									user_grd: _isEmpty(users.info) ? "1" : users.info.user_grd,
								}}
								validationSchema={Yup.object().shape({
									user_name: Yup.string()
										.min(2, "2자 이상 입력해주세요")
										.required("사용자 이름을 입력해주세요"),
									email: Yup.string()
										.required("이메일을 입력해주세요")
										.email("이메일을 입력해주세요."),
									password: Yup.string()
										.min(4, "4자 이상 입력해주세요.")
										.required("비밀번호를 입력해주세요."),
									phone: Yup.string().min(4, "4자 이상 입력해주세요."),
								})}
								onSubmit={(v) => {
									// 등록
									handleEdit(v);
									handleClose();
								}}
							>
								{({
									errors,
									handleBlur,
									handleChange,
									handleSubmit,
									touched,
									values,
								}) => (
									<form onSubmit={handleSubmit}>
										<DialogContent>
											<Box ml={1} mb={1} mt={1} justifyContent="center">
												{/* email 입력 영역 */}
												<Box display="flex" justifyContent="center">
													<TextField
														className={classes.label}
														autoFocus
														margin="dense"
														name="email"
														label="email 입력"
														type="text"
														fullWidth
														error={Boolean(touched.email && errors.email)}
														helperText={touched.email && errors.email}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.email}
														disabled={true}
													/>
												</Box>
												{/* 비밀번호 입력 영역*/}
												<Box display="flex" justifyContent="center">
													<TextField
														className={classes.label}
														margin="dense"
														name="password"
														label="비밀번호 입력"
														type="password"
														fullWidth
														error={Boolean(touched.password && errors.password)}
														helperText={touched.password && errors.password}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.password}
													/>
												</Box>
												{/* 이름 입력 영역 */}
												<Box display="flex" justifyContent="center">
													<TextField
														className={classes.label}
														margin="dense"
														name="user_name"
														label="이름 입력"
														type="text"
														fullWidth
														error={Boolean(
															touched.user_name && errors.user_name
														)}
														helperText={touched.user_name && errors.user_name}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.user_name}
													/>
												</Box>
												{/* 전화번호 입력 영역 */}
												<Box display="flex" justifyContent="center">
													<TextField
														className={classes.label}
														margin="dense"
														name="phone"
														label="전화번호 입력"
														type="text"
														fullWidth
														// error={Boolean(touched.phone && errors.phone)}
														// helperText={touched.phone && errors.phone}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.phone}
													/>
												</Box>
												{/* 사용권한 입력 영역 */}
												<Box display="flex" justifyContent="left">
													<FormSelect
														mb={1}
														className={classes.label}
														id="user_grd"
														label="관리자 권한"
														value={values.user_grd}
														item={_setCodeFilter(common.code, "G022")}
														errors={errors.user_grd}
														blur={handleBlur}
														change={handleChange}
														touched={touched.user_grd}
													/>
												</Box>
												{/* <Box mb={1} display="flex" justifyContent="left">
													<FormSelect
														mb={1}
														className={classes.label}
														id="is_superuser"
														label="관리자 권한"
														value={values.is_superuser}
														item={_setCodeFilter(common.code, "G023")}
														errors={errors.is_superuser}
														blur={handleBlur}
														change={handleChange}
														touched={touched.is_superuser}
													/>
												</Box> */}
												<Box mb={1} display="flex" justifyContent="left">
													<FormSelect
														mb={1}
														className={classes.label}
														id="is_active"
														label="사용여부"
														value={values.is_active}
														item={[
															{ code: true, name: "사용" },
															{ code: false, name: "미사용" },
														]}
														errors={errors.is_active}
														blur={handleBlur}
														change={handleChange}
														touched={touched.is_active}
													/>
												</Box>
											</Box>
										</DialogContent>
										<DialogActions>
											{/* 취소버튼 */}
											<Button onClick={handleClose} color="primary">
												취소
											</Button>
											{/* 추가 버튼*/}
											<Button type="submit" variant="contained" color="primary">
												정보 수정
											</Button>
										</DialogActions>
									</form>
								)}
							</Formik>
						</Dialog>
					</div>
				);
			};
export default UsersEdit;
