/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Payment View Page
**/
/* @material import */
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import {
  _getPayDiff,
  _isEmpty,
  _setAddComma,
  _setCodeFilter,
  _setDateYYYYMMDD,
  _setRemoveComma,
  _setUTCDate,
  _setPhone
} from "./../../utils/Common";
import FormDate from "./../../utils/form/FormDate";
import FormText from "./../../utils/form/FormText";
import FormNumeric from '../../utils/form/FormNumeric';
import { updPay } from "./../../utils/redux/actions/PayAction";
import { getProd, updProd } from "./../../utils/redux/actions/ProdAction";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
    height: '100%',
    width: '100%',
    maxHeight: '90%',
    minWidth: '90%',
    overflow: 'auto',
    margin: theme.spacing(2)
  },
  grid: {
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const PaymentView = forwardRef((props, ref) => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook
  const formRef = useRef<any>(null);

  // 부모 컴포넌트 사용
  useImperativeHandle(ref, () => formRef.current);

  // [state] #######################################################################
  const payment = useSelector((state: any) => state.payment);     // payment
  const product = useSelector((state: any) => state.product);        // product
  const common = useSelector((state: any) => state.common);       // common

  // [Events] ######################################################################
  //@ 수정
  const handleUpdate = useCallback((v) => {
    updPay(v).then((res: any) => {
      dispatch(res);
      updProd(v).then(() => {
        // getProd({}).then((d) => dispatch(d));
        let body: any = {};
        if (!_isEmpty(product.srch.date_key)) body['date_key'] = product.srch.date_key;
        if (!_isEmpty(product.srch.st_dt)) body['st_dt'] = _setUTCDate(product.srch.st_dt);
        if (!_isEmpty(product.srch.ed_dt)) body['ed_dt'] = _setUTCDate(product.srch.ed_dt);
        if (!_isEmpty(product.srch.calc_stat)) body['calc_stat'] = product.srch.calc_stat;
        if (!_isEmpty(product.srch.rcpt_chnl)) body['rcpt_chnl'] = product.srch.rcpt_chnl;
        if (!_isEmpty(product.srch.srch_val)) {
          if (product.srch.srch_key.toString() === 'phone') {
            product.srch.srch_val = _setPhone(product.srch.srch_val);
          }
          body[product.srch.srch_key.toString()] = product.srch.srch_val;
        }
        if (!_isEmpty(product.srch.limit)) body["limit"] = product.srch.limit;

        getProd(body).then((d) => dispatch(d))
          .catch((e) => console.log(e));
      });
    }).then(() => {
      common.msg = '수정에 성공 하였습니다.';
      common.isMsg = true;
      return dispatch({ type: 'COM_ALERT' });       // 안내메시지
    });
  }, [product, common, dispatch]);


  // [init] ########################################################################
  //@ 최초 실행
  useEffect(() => {
    // handleinit();
  }, []);

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h6">정산 정보</Typography>
          </Grid>
        </Grid>
      </Toolbar>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          //# 정산
          calc_no: _isEmpty(payment.info) ? '' : payment.info.calc_no,                  // 수선품 번호
          prod_no: _isEmpty(payment.info) ? '' : payment.info.prod_no,                  // 수선품 번호
          calc_typ: _isEmpty(payment.info) ? '' : payment.info.calc_typ,                // 납입 방식
          calc_acc: _isEmpty(payment.info) ? '' : payment.info.calc_acc,                // 납입 계좌
          dpst_name: _isEmpty(payment.info) ? '' : payment.info.dpst_name,    // 입금자
          tot_pay: _isEmpty(payment.info) ? '' : _setAddComma(payment.info.tot_pay),    // 수선비
          frst_pay: _isEmpty(payment.info) ? '' : _setAddComma(payment.info.frst_pay),  // 결제 금액
          frst_pay_dtime: _isEmpty(payment.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(payment.info.frst_pay_dtime, '-'),   // 결제일
          last_pay: _isEmpty(payment.info) ? '' : _setRemoveComma(_getPayDiff(payment.info.tot_pay, payment.info.frst_pay)),         // 미수금
          last_pay_dtime: _isEmpty(payment.info) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(payment.info.last_pay_dtime, '-'),   // 미납 납입일
          calc_stat: _isEmpty(payment.info) ? '' : payment.info.calc_stat,              // 결제 상태
          // pay_etc_cont: _isEmpty(payment.info) ? '' : payment.info.pay_etc_cont,        // 결제관련 기타 내용
        }}

        validationSchema={Yup.object().shape({
          calc_typ: Yup.string().required('납입 방식을 선택해주세요'),
          calc_acc: Yup.string().required('납입 계좌를 선택해주세요'),
          // dpst_name: Yup.string().required('입금자명을 입력해주세요'),   // HSH 주석처리 210202
          frst_pay: Yup.string().required('결제 금액을 입력해주세요'),
          frst_pay_dtime: Yup.string().required('결제일을 선택해주세요'),
          calc_stat: Yup.string().required('결제 상태를 선택해주세요')
        })}

        onSubmit={(v) => {
          // UTC 포멧 변경
          v.tot_pay = _setRemoveComma(v.tot_pay);
          v.frst_pay = _setRemoveComma(v.frst_pay);
          v.last_pay = _setRemoveComma(v.last_pay);
          v.frst_pay_dtime = _setUTCDate(v.frst_pay_dtime);
          v.last_pay_dtime = _setUTCDate(v.last_pay_dtime);
          handleUpdate(v);
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
          <form onSubmit={handleSubmit}>
            {/*############################## 정산 ##############################*/}
            <Box ml={3} mr={3} mb={1} mt={1} borderTop={2} borderColor="primary.main" justifyContent="center">
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}>
                {/* 수선비 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormNumeric
                    id='tot_pay'
                    label='수선비'
                    value={values.tot_pay}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 미납 금액 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormText
                    id='last_pay'
                    label='미수금'
                    value={_getPayDiff(values.tot_pay, values.frst_pay)}
                    required={true}
                    disabled={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 결제 방식 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='calc_typ'
                    label='결제 방식'
                    value={values.calc_typ}
                    item={_setCodeFilter(common.code, 'G011')}
                    errors={errors.calc_typ}
                    touched={touched.calc_typ}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 납입 계좌 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='calc_acc'
                    label='납입 계좌'
                    value={values.calc_acc}
                    item={_setCodeFilter(common.code, 'G012')}
                    firstDefault={true}
                    // required={true}                   
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 결제액 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormNumeric
                    id='frst_pay'
                    label='결제액'
                    value={values.frst_pay}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 입금자 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormText
                    id='dpst_name'
                    label='입금자'
                    value={values.dpst_name}
                    // required={true}                   
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 결제일 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormDate
                    id='frst_pay_dtime'
                    label='결제일'
                    value={values.frst_pay_dtime}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                <Grid item xs={6} className={classes.grid}></Grid>
                {/* 결제 상태 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='calc_stat'
                    label='결제 상태'
                    value={values.calc_stat}
                    item={_setCodeFilter(common.code, 'G013')}
                    errors={errors.calc_stat}
                    touched={touched.calc_stat}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                <Grid item xs={6} className={classes.grid}></Grid>
                {/* 결제관련 기타 내용 */}
                {/* <Grid item xs={12} className={classes.grid}>
                      <FormText 
                        id='pay_etc_cont' 
                        label='결제관련 기타 내용' 
                        value={values.pay_etc_cont}                    
                        required={false} 
                        blur={handleBlur} 
                        change={handleChange} />   
                    </Grid> */}
              </Grid>
            </Box>
            {/* 버튼영역 */}
            <Box
              ml={1} mr={1} mb={1}
              display="flex"
              justifyContent="flex-end"
            >
              {/* 저장 */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<Save />}
              >
                저장
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
});

export default PaymentView;
