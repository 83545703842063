/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Chatbot View Page
**/
/* @material import */
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  Link
} from '@material-ui/core';
import {
  Add,
  Save
} from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import { addBotGaly, updBotGaly, delBotGaly, getBotGaly } from "../../utils/redux/actions/BotGalyAction";
import { addBotProd, getBotProd, updBotProd } from "../../utils/redux/actions/BotProdAction";
import { getImg, getImgInfo } from "../../utils/redux/actions/ImgAction";
import {
  _isEmpty,
  _setCateCodeFilter
} from "./../../utils/Common";
import FormTextarea from "./../../utils/form/FormTextarea";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
    height: '100%',
    width: '100%',
    maxHeight: '90%',
    minWidth: '90%',
    overflow: 'auto',
    margin: theme.spacing(2)
  },
  grid: {
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  label: {
    fontSize: 12,
    color: 'primary',
    marginTop: theme.spacing(2)
  },
  paper: {
    width: '80%',
    height: 120,
    margin: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper
  },
  CardMedia: {
    height: 120
  },
  iconButton: {
    padding: 10,
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ChatbotView = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook
  const formRef = useRef<any>(null);

  // [state] ######################################################################  
  const botProd = useSelector((state: any) => state.botProd);            // botProd
  const botGaly = useSelector((state: any) => state.botGaly);            // botGaly
  const common = useSelector((state: any) => state.common);              // common

  // [Events] ######################################################################
  //@ 초기화
  const handleinit = useCallback(() => {

    // 상품정보 초기화
    dispatch({ type: 'BOT_PROD_GET_INFO', payload: [] })
    // 이미지정보 초기화
    dispatch({ type: 'BOT_GALY_GET', payload: [] })

  }, [dispatch]);

  //@ 초기화
  // const handleinit = useCallback(() => { 
  //   if(!_isEmpty(botProd.info)) {
  //     let data:any = [];
  //     let body = {
  //       bot_prod_no: botProd.info.bot_prod_no
  //     }  
  //     console.log(body);       
  //     // 상품 이미지 정보 조회
  //     getBotGaly(body).then((d) => {
  //        console.log(d);     
  //       let rows = d.payload;
  //       if(rows.length > 0) {
  //         rows.forEach((v:any, idx:number) => {
  //           setTimeout(() => {
  //             let body = { imageid: v.bot_galy_id };
  //             getImgInfo(body).then((img:any) => {
  //               if(!_isEmpty(img.payload)) {
  //                 let image_url = '';
  //                 if(img.payload.isResized) {
  //                   image_url = img.payload.resizedImageUrl; 
  //                 } else {
  //                   image_url = img.payload.rawImageUrl; 
  //                 }                
  //                 v['bot_galy_url'] = _isEmpty(image_url)? '' : image_url;

  //                 data.push(v);
  //               }
  //               if((idx+1) === rows.length) {       
  //                 console.log(data);                 
  //                 dispatch({ type: 'BOT_GALY_GET', payload: data });
  //               }
  //             }).catch((e) => console.log(e));
  //           }, (idx * 100));
  //         });
  //       } else {  // 이미지가 없을 경우
  //         return dispatch({ type: 'BOT_GALY_GET', payload: [] }); 
  //       }
  //     }).catch((e) => console.log(e));

  //   } else {
  //     dispatch({ type: 'BOT_GALY_GET', payload: [] });
  //   }
  // },[botProd, dispatch]);

  //@ 저장
  const handleAdd = useCallback((v) => {
    addBotProd(v).then((d) => dispatch(d))
      .then(async (b) => {
        // 21.09.08 추가 : 이미 존재하는 품목 추가 시 알림메세지 출력
        if (b.payload.detail === "You are already a registered Bot Product.") {
          common.msg = '이미 존재하는 챗봇상품입니다.';
          common.isMsg = true;
          return dispatch({ type: 'COM_ALERT' });       // 안내메시지
          // 21.09.08
        } else {
          let rows = botGaly.rows;
          for (let s of rows) {
            if (_isEmpty(s.bot_galy_no) && _isEmpty(s.bot_galy_url)) {

            } else {
              let body = {
                bot_galy_no: '',
                bot_prod_no: b.payload.bot_prod_no,
                bot_galy_id: s.bot_galy_id,
                is_active: true
              }
              // v['bot_galy_no'] = '';
              // v['bot_prod_no'] = b.payload.bot_prod_no;
              // v['is_active'] = true;
              await addBotGaly(body).catch((e) => console.log(e));
            }
          }

          let body = {
            bot_prod_no: b.payload.bot_prod_no
          }
          getBotGaly(body).then(async (d) => {
            let data: any = [];
            let rows = d.payload;
            if (rows.length > 0) {
              for (let f of rows) {
                let body = { imageid: f.bot_galy_id };
                await getImgInfo(body).then((img: any) => {
                  if (!_isEmpty(img.payload)) {
                    let image_url = '';
                    if (img.payload.isResized) {
                      image_url = img.payload.resizedImageUrl;
                    } else {
                      image_url = img.payload.rawImageUrl;
                    }
                    f['bot_galy_url'] = _isEmpty(image_url) ? '' : image_url;

                    data.push(f);
                  }
                })
                  .then(() => dispatch({ type: 'BOT_GALY_GET', payload: data }))
              }
            } else {  // 이미지가 없을 경우
              return dispatch({ type: 'BOT_GALY_GET', payload: [] });
            }
          })
          // rows.forEach((v: any) => {
          //   let body = {
          //     bot_galy_no: '',
          //     bot_prod_no: b.payload.bot_prod_no,
          //     bot_galy_id: v.bot_galy_id,
          //     is_active: true
          //   }
          //   // v['bot_galy_no'] = '';
          //   // v['bot_prod_no'] = b.payload.bot_prod_no;
          //   // v['is_active'] = true;
          //   addBotGaly(body).catch((e) => console.log(e));
          // });
          return;
        }
      })
      // .then(() => formRef.current.resetForm())
      .then(() => {
        common.msg = '저장에 성공 하였습니다.';
        common.isMsg = true;
        return dispatch({ type: 'COM_ALERT' });       // 안내메시지
      })
      .then(() => getBotProd({}).then((d) => dispatch(d)))
      .catch((e) => console.log(e));
  }, [botGaly, common, dispatch]);

  //@ 수정
  const handleUpd = useCallback((v) => {
    updBotProd(v).then((d) => dispatch(d))
      .then(async (b) => {
        let rows = botGaly.rows;
        for (let s of rows) {
          if (_isEmpty(s.bot_galy_no) && !_isEmpty(s.bot_galy_url)) {
            let body = {
              bot_galy_no: '',
              bot_prod_no: b.payload.bot_prod_no,
              bot_galy_id: s.bot_galy_id,
              is_active: true
            }
            await addBotGaly(body).catch((e) => console.log(e));
          } else if (_isEmpty(s.bot_galy_no) && _isEmpty(s.bot_galy_url)) {

          } else if (!_isEmpty(s.bot_galy_no) && _isEmpty(s.bot_galy_url)) { // 기존에 있던 챗봇상품정보에서 사진 삭제할때
            let body = {
              bot_galy_no: s.bot_galy_no,
              bot_prod_no: s.bot_prod_no,
              bot_galy_id: s.bot_galy_id,
              is_active: true
            }
            await delBotGaly(body).catch((e) => console.log(e));
          } else {
            let body = {
              bot_galy_no: s.bot_galy_no,
              bot_prod_no: s.bot_prod_no,
              bot_galy_id: s.bot_galy_id,
              is_active: true
            }
            await updBotGaly(body).catch((e) => console.log(e));
          }
        }
        // rows.forEach((v: any) => {
        //   if (_isEmpty(v.bot_galy_no)) {
        //     let body = {
        //       bot_galy_no: '',
        //       bot_prod_no: b.payload.bot_prod_no,
        //       bot_galy_id: v.bot_galy_id,
        //       is_active: true
        //     }
        //     addBotGaly(body).catch((e) => console.log(e));
        //     // 기존에 있던 챗봇상품정보에서 사진 삭제할때
        //   } else if (!_isEmpty(v.bot_galy_no) && _isEmpty(v.bot_galy_url)) {
        //     let body = {
        //       bot_galy_no: v.bot_galy_no,
        //       bot_prod_no: v.bot_prod_no,
        //       bot_galy_id: v.bot_galy_id,
        //       is_active: true
        //     }
        //     delBotGaly(body).catch((e) => console.log(e));
        //   } else {
        //     let body = {
        //       bot_galy_no: v.bot_galy_no,
        //       bot_prod_no: v.bot_prod_no,
        //       bot_galy_id: v.bot_galy_id,
        //       is_active: true
        //     }
        //     updBotGaly(body).catch((e) => console.log(e));
        //   }
        // });
        return;
      })
      .then(() => formRef.current.resetForm())
      .then(() => {
        common.msg = '수정에 성공 하였습니다.';
        common.isMsg = true;
        return dispatch({ type: 'COM_ALERT' });       // 안내메시지
      })
      .then(() => getBotProd({}).then((d) => dispatch(d)))
      .then(() => {
        let body = {
          bot_prod_no: v.bot_prod_no
        }
        getBotGaly(body).then(async (d) => {
          let data: any = [];
          let rows = d.payload;
          if (rows.length > 0) {
            for (let f of rows) {
              let body = { imageid: f.bot_galy_id };
              await getImgInfo(body).then((img: any) => {
                if (!_isEmpty(img.payload)) {
                  let image_url = '';
                  if (img.payload.isResized) {
                    image_url = img.payload.resizedImageUrl;
                  } else {
                    image_url = img.payload.rawImageUrl;
                  }
                  f['bot_galy_url'] = _isEmpty(image_url) ? '' : image_url;

                  data.push(f);
                }
              })
                .then(() => dispatch({ type: 'BOT_GALY_GET', payload: data }))
            }
          } else {  // 이미지가 없을 경우
            return dispatch({ type: 'BOT_GALY_GET', payload: [] });
          }
        })
      })
      .catch((e) => console.log(e));
  }, [botGaly, common, dispatch]);

  //@ 이미지 데이터 가져오기
  const getImageItem = useCallback((v, key) => {
    // if(_isEmpty(id)) {
    //   dispatch({ type: 'BOT_GALY_GET_INFO', payload: {} })getImageItem;
    // } else {
    //   let body = {
    //     bot_galy_no: id
    //   }    
    //   getBotGalyInfo(body).then((d) => dispatch(d))
    //                 .catch((e) => console.log(e));
    // }
    v['target_id'] = key;
    let body: any = [];
    body.push(v);
    dispatch({ type: 'BOT_PROD_GET_INFO', payload: body });
    setTimeout(() => {
      // 이미지 검색 레이어 오픈
      common.isImage = true;
      common.imagePage = '02';
      // dispatch({ type: 'COM_IMAGE', payload: {} });
      getImg({}).then((v: any) => dispatch(v));
    }, (500));
  }, [common, dispatch]);

  //@ 이미지 데이터 삭제
  const delImageItem = useCallback((_cate) => {
    let tID = botGaly.images['image_' + _cate];

    for (let v of botGaly.rows) {
      if (v.bot_galy_id === tID) {
        v.bot_galy_url = ''
      }
    }
    dispatch({ type: 'BOT_GALY_GET', payload: botGaly.rows });

    // let payload = botGaly.rows.filter((v: any) => v.bot_galy_id === tID);
    // if (payload.length > 0) {        // 수정
    //   json = payload[0];
    //   botGaly.rows.splice(botGaly.rows.indexOf(payload[0]), 1);

    //   if (json.bot_galy_no) {
    //     // json['bot_galy_id'] = '';
    //     // json['bot_galy_no'] = '';
    //     json['bot_galy_url'] = '';
    //     botGaly.rows.push(json);
    //   }
    //   dispatch({ type: 'BOT_GALY_GET', payload: botGaly.rows });
    // }
  }, [botGaly, dispatch])


  // [init] ######################################################################
  //@ 최초 실행
  // useEffect(() => {
  //   // handleinit();
  // }, []);

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography variant="h6">챗봇상품등록</Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            {/* 초기화 */}
            <IconButton
              className={classes.iconButton}
              aria-label="초기화"
              color="inherit"
              onClick={handleinit}
            >
              <Add /><Typography variant="h6">초기화</Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          bot_prod_no: _isEmpty(botProd.info) ? '' : botProd.info.bot_prod_no,       // 상품번호
          bot_cate_1: _isEmpty(botProd.info) ? '' : botProd.info.bot_cate_1,         // 브랜드
          bot_cate_2: _isEmpty(botProd.info) ? '' : botProd.info.bot_cate_2,         // 품목
          bot_cate_3: _isEmpty(botProd.info) ? '' : botProd.info.bot_cate_3,         // 수선유형
          bot_cate_3_1: _isEmpty(botProd.info) ? '' : botProd.info.bot_cate_3_1,     // 수선(대분류)
          bot_cate_3_2: _isEmpty(botProd.info) ? '' : botProd.info.bot_cate_3_2,     // 수선(중분류)
          bot_cate_3_3: _isEmpty(botProd.info) ? '' : botProd.info.bot_cate_3_3,     // 수선(소분류)
          bot_pay_cont: _isEmpty(botProd.info) ? '' : botProd.info.bot_pay_cont,     // 비용정보
          is_active: _isEmpty(botProd.info) ? true : botProd.info.is_active,         // 사용여부

          image_01: _isEmpty(botGaly.images) ? '' : botGaly.images.image_01,        // 이미지 1
          image_01_url: _isEmpty(botGaly.images) ? '' : botGaly.images.image_01_url,   // 이미지 1
          image_01_no: _isEmpty(botGaly.images) ? '' : botGaly.images.image_01_no,   // 이미지 1
          image_02: _isEmpty(botGaly.images) ? '' : botGaly.images.image_02,        // 이미지 2
          image_02_url: _isEmpty(botGaly.images) ? '' : botGaly.images.image_02_url,   // 이미지 2
          image_02_no: _isEmpty(botGaly.images) ? '' : botGaly.images.image_02_no,   // 이미지 2
          image_03: _isEmpty(botGaly.images) ? '' : botGaly.images.image_03,        // 이미지 3
          image_03_url: _isEmpty(botGaly.images) ? '' : botGaly.images.image_03_url,   // 이미지 3
          image_03_no: _isEmpty(botGaly.images) ? '' : botGaly.images.image_03_no,   // 이미지 3

          //  HSH 2021.02.03 - 카카오 챗봇에서 제공하는 이미지가 최대 3개까지이기에 주석처리함
          // image_04: _isEmpty(botGaly.images) ? '' : botGaly.images.image_04,        // 이미지 4              
          // image_04_url: _isEmpty(botGaly.images) ? '' : botGaly.images.image_04_url,   // 이미지 4
          // image_04_no: _isEmpty(botGaly.images) ? '' : botGaly.images.image_04_no,   // 이미지 4


          // image_1: _isEmpty(botGaly.rows) ? '' : _getBotImgID(botGaly.rows, 0),      // 이미지 1
          // image_url_1: _isEmpty(botGaly.rows) ? '' : _getBotImgURL(botGaly.rows, 0), // 이미지 1
          // image_2: _isEmpty(botGaly.rows) ? '' : _getBotImgID(botGaly.rows, 1),      // 이미지 2
          // image_url_2: _isEmpty(botGaly.rows) ? '' : _getBotImgURL(botGaly.rows, 1), // 이미지 2
          // image_3: _isEmpty(botGaly.rows) ? '' : _getBotImgID(botGaly.rows, 2),      // 이미지 3
          // image_url_3: _isEmpty(botGaly.rows) ? '' : _getBotImgURL(botGaly.rows, 2), // 이미지 3
          // image_4: _isEmpty(botGaly.rows) ? '' : _getBotImgID(botGaly.rows, 3),      // 이미지 4
          // image_url_4: _isEmpty(botGaly.rows) ? '' : _getBotImgURL(botGaly.rows, 3), // 이미지 4
        }}
        validationSchema={Yup.object().shape({
          bot_cate_1: Yup.string().required('브랜드코드를 선택해주세요'),
          bot_cate_2: Yup.string().required('품목코드를 선택해주세요'),
          bot_cate_3: Yup.string().required('수선유형을 선택해주세요')
        })}
        onSubmit={(v) => {
          if (_isEmpty(v.bot_prod_no)) {  // 수정
            handleAdd(v);
          } else {              // 등록
            handleUpd(v);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
          <form onSubmit={handleSubmit}>
            {/*############################## 수선 ##############################*/}
            <Box ml={3} mr={3} mb={1} mt={1} borderTop={2} borderColor="primary.main" justifyContent="center">
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}>
                {/* 브랜드 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='bot_cate_1'
                    label='브랜드'
                    value={values.bot_cate_1}
                    item={_setCateCodeFilter(common.category, '0100000000')}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 품목 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='bot_cate_2'
                    label='품목'
                    value={values.bot_cate_2}
                    item={_setCateCodeFilter(common.category, '0200000000')}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>

                {/* 수선유형 */}
                <Grid item xs={6} className={classes.grid}>
                  <FormSelect
                    id='bot_cate_3'
                    label='수선유형'
                    value={values.bot_cate_3}
                    item={[
                      { code: '0600000000', name: '가방,지갑,기타' },
                      { code: '0700000000', name: '벨트' },
                      { code: '0800000000', name: '신발' }
                    ]}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                <Grid item xs={6} className={classes.grid}></Grid>
                {/* 수선(대분류) */}
                <Grid item xs={4} className={classes.grid}>
                  <FormSelect
                    id='bot_cate_3_1'
                    label='수선(대분류)'
                    value={values.bot_cate_3_1}
                    item={_setCateCodeFilter(common.category, values.bot_cate_3)}
                    firstDefault={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 수선(중분류) */}
                <Grid item xs={4} className={classes.grid}>
                  <FormSelect
                    id='bot_cate_3_2'
                    label='수선(중분류)'
                    value={values.bot_cate_3_2}
                    item={_setCateCodeFilter(common.category, values.bot_cate_3_1)}
                    firstDefault={true}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 수선(소분류) */}
                <Grid item xs={4} className={classes.grid}>
                  {/* <FormSelect
                    id='bot_cate_3_3'
                    label='수선(소분류)'
                    value={values.bot_cate_3_3}
                    item={_setCateCodeFilter(common.category, values.bot_cate_3_2)}
                    firstDefault={true}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} /> */}
                </Grid>
                {/* 수선품 이미지 */}
                <Grid item xs={12} className={classes.grid}>
                  <Typography
                    color="primary"
                    className={classes.label}
                    variant="subtitle2">수선품 이미지</Typography>
                  <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={3}>
                      <Card className={classes.paper}>
                        <CardHeader
                          title="이미지01"
                          action={_isEmpty(values.image_01_url) ? '' : <Link onClick={() => delImageItem('01')}>DEL</Link>}
                        />
                        {!_isEmpty(values.image_01_url) ?
                          <CardActionArea>
                            <CardMedia
                              className={classes.CardMedia}
                              image={values.image_01_url}
                              title="수선품이미지1"
                              onClick={() => window.open(values.image_01_url, '_blank')}
                            // onClick={() => getImageItem(values, '01')}
                            />
                          </CardActionArea>
                          :
                          <CardActions>
                            <IconButton
                              className={classes.iconButton}
                              aria-label="이미지추가"
                              color="inherit"
                              onClick={() => getImageItem(values, '01')}
                            >
                              <Add />
                            </IconButton>
                          </CardActions>
                        }
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card className={classes.paper}>
                        <CardHeader
                          title="이미지02"
                          action={_isEmpty(values.image_02_url) ? '' : <Link onClick={() => delImageItem('02')}>DEL</Link>}
                        />
                        {!_isEmpty(values.image_02_url) ?
                          <CardActionArea>
                            <CardMedia
                              className={classes.CardMedia}
                              image={values.image_02_url}
                              title="수선품이미지2"
                              onClick={() => window.open(values.image_02_url, '_blank')}
                            // onClick={() => getImageItem(values, '02')}
                            />
                          </CardActionArea>
                          :
                          <CardActions>
                            <IconButton
                              className={classes.iconButton}
                              aria-label="이미지추가"
                              color="inherit"
                              onClick={() => getImageItem(values, '02')}
                            >
                              <Add />
                            </IconButton>
                          </CardActions>
                        }
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card className={classes.paper}>
                        <CardHeader
                          title="이미지03"
                          action={_isEmpty(values.image_03_url) ? '' : <Link onClick={() => delImageItem('03')}>DEL</Link>}
                        />
                        {!_isEmpty(values.image_03_url) ?
                          <CardActionArea>
                            <CardMedia
                              className={classes.CardMedia}
                              image={values.image_03_url}
                              title="수선품이미지3"
                              onClick={() => window.open(values.image_03_url, '_blank')}
                            // onClick={() => getImageItem(values, '03')}
                            />
                          </CardActionArea>
                          :
                          <CardActions>
                            <IconButton
                              className={classes.iconButton}
                              aria-label="이미지추가"
                              color="inherit"
                              onClick={() => getImageItem(values, '03')}
                            >
                              <Add />
                            </IconButton>
                          </CardActions>
                        }
                      </Card>
                    </Grid>
                    {/* HSH 2021.02.03 - 카카오 챗봇에서 제공하는 이미지가 최대 3개이기에 4번째부터는 주석 처리함. */}
                    {/* <Grid item xs={3}>
                          <Card className={classes.paper}>
                              {!_isEmpty(values.image_04_url) ? 
                                <CardActionArea>
                                  <CardMedia
                                    className={classes.CardMedia}
                                    image={values.image_04_url}
                                    title="수선품이미지4"
                                    onClick={() => getImageItem(values, '04')}
                                  /> 
                                </CardActionArea>
                              : 
                                <CardActions>
                                  <IconButton 
                                    className={classes.iconButton} 
                                    aria-label="이미지추가"
                                    color="inherit"
                                    onClick={() => getImageItem(values, '04')}
                                    >
                                    <Add />
                                  </IconButton> 
                                </CardActions>
                              } 
                          </Card>  
                        </Grid>                                                                         */}
                  </Grid>
                </Grid>
                {/* 비용정보 */}
                {/* <Grid item xs={12}>
                  <Typography
                    color="primary"
                    className={classes.label}
                    variant="subtitle2">비용정보</Typography>
                  <FormTextarea
                    id='bot_pay_cont'
                    label='비용정보'
                    value={values.bot_pay_cont}
                    rows={10}
                    required={false}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid> */}
              </Grid>
            </Box>
            {/* 버튼영역 */}
            <Box
              ml={1} mr={1} mb={1}
              display="flex"
              justifyContent="flex-end"
            >
              {/* 수정,저장 */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<Save />}
              >
                {!_isEmpty(values.bot_prod_no) ? '수정' : '저장'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default ChatbotView;
