/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux consult actions
**/
import { _ajaxGetJsonAsync, _ajaxPostJsonAsync } from "../../axios/axios";
import {
  CNSLT_ADD,
  CNSLT_FAIL,
  CNSLT_GET,
  CNSLT_GET_INFO,
  CNSLT_UPD
} from "../types";

/* 상담 경로 정보 */
const API_PATH = "/api/v1/consults";

/* 상담 전체 조회 */
export const getCnslt = async () => {
  // IN Data 구성
  let body = {
     limit: '1000',  // 개수
  };  
  return await _ajaxGetJsonAsync("get", API_PATH+"/", body, CNSLT_GET, CNSLT_FAIL);
}

/* 상담 전체 조회 */
export const getCnsltSrch = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, CNSLT_GET, CNSLT_FAIL);
}

/* 상담 개별 조회 */
export const getCnsltInfo = async (d:any) => {
  // ajax 통신
  return await _ajaxGetJsonAsync("get", API_PATH+"/"+d, {}, CNSLT_GET_INFO, CNSLT_FAIL);
}

/* 상담 등록 */
export const addCnslt = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['reg_user'] = user_name;       // 최초작성자
  d['last_mod_user'] = user_name;  // 수정자
  return await _ajaxPostJsonAsync("post", API_PATH+"/", d, CNSLT_ADD, CNSLT_FAIL);
}

/* 상담 수정 */
export const updCnslt = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH+"/"+d.cnslt_no, d, CNSLT_UPD, CNSLT_FAIL);
}