/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux Gally Action
**/
import { _ajaxPostJsonAsync } from "../../axios/axios";
import {
  GALLY_ADD,
  GALLY_DEL,
  GALLY_FAIL,
  GALLY_GET,
  GALLY_GET_INFO,
  GALLY_UPD
} from "../types";

/* 이미지 경로 정보 */
const API_PATH = "/api/v1/galleries";

/* 이미지 전체 조회 */
export const getGally = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, GALLY_GET, GALLY_FAIL);
}

/* 이미지 개별 조회 */
export const getGallyInfo = async (body:any) => {
  // IN Data 구성
  body['limit'] = '1000';
  return await _ajaxPostJsonAsync("post", API_PATH+"/search/", body, GALLY_GET_INFO, GALLY_FAIL);
}

/* 이미지 등록 */
export const addGally = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['reg_user'] = user_name;       // 최초작성자
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("post", API_PATH+"/", d, GALLY_ADD, GALLY_FAIL);
}

/* 이미지 수정 */
export const updGally = async (d:any) => {
  let user_name = window.sessionStorage.getItem('user_name');
  d['last_mod_user'] = user_name;  // 수정자  
  return await _ajaxPostJsonAsync("put", API_PATH+"/"+d.galy_no, d, GALLY_UPD, GALLY_FAIL);
}

/* 이미지 삭제 */
export const delGally = async (d:any) => {
  return await _ajaxPostJsonAsync("delete", API_PATH+"/"+d.galy_no, d, GALLY_DEL, GALLY_FAIL);
}