/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Consult page
**/
/* @material import */
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';
/* react import */
import React from 'react';
import Page from './../Page';
import ConsultEdit from './ConsultEdit';
import ConsultList from './ConsultList';
import ConsultView from './ConsultView';
import KaKaoRcptList from './KaKaoRcptList';
import KaKaoSrchList from './KaKaoSrchList';
/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '90%',
    overflow: 'auto',  
    marginTop: 0,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3)     
  },
  paper: {
    width: '100%',
    height: 400,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper    
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const Consult = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="상담관리"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="flex-start"
      >
        <Container maxWidth="xl">
          <Box mb={1} justifyContent="center">
            <Grid
              container
              justify="center" 
              spacing={1}
            >
              <Grid item xs={6}> 
                {/* 상담 */}
                <Paper className={classes.paper} elevation={4}>  
                  <ConsultList />         
                </Paper>              
              </Grid>
              <Grid item xs={6}> 
                {/* 상담 등록 */}
                <Paper className={classes.paper} elevation={4}>  
                  {
                    (window.sessionStorage.getItem('user_grd') === '09') ?
                      <ConsultView />
                    :
                      <ConsultEdit />
                  }
                  
                </Paper>                
              </Grid>                                          
            </Grid>
          </Box> 
          {/* 접수 영역 */}
          <Box mb={1} justifyContent="center">
            <Grid
              container
              justify="center" 
              spacing={1}
            >
              <Grid item xs={6}>
                {/* 카카오 수선품 검색 */}
                <Paper className={classes.paper} elevation={4}>  
                  <KaKaoSrchList />
                </Paper>                
              </Grid>              
              <Grid item xs={6}>
                {/* 카카오 수거 접수 */}
                <Paper className={classes.paper} elevation={4}>  
                  <KaKaoRcptList />
                </Paper>                 
              </Grid>                                                                       
            </Grid>
          </Box> 
        </Container>
      </Box> 
    </Page>   
  );
};

export default Consult;
