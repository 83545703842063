/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Repair List Page
**/
/* @material import */
import {
	Grid,
	IconButton,
	InputBase,
	makeStyles,
	Paper,
	Toolbar,
	Typography
} from '@material-ui/core';
import {
	DataGrid
} from '@material-ui/data-grid';
import {
	GetApp,
	Search
} from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import FormText from '../../utils/form/FormText';
import { getExcel } from "../../utils/redux/actions/ExcelDownloadAction";
import {
	getRepr
} from "../../utils/redux/actions/ReprAction";
import {
	_getCategoryName,
	_getCategoryName2,
	_getCodeName,
	_getCodeName2,
	_getSession,
	_isEmpty,
	_setCodeFilter,
	_setDateYYYYMMDD,
	_setDayYYYYMMDD,
	_setFormatCategory,
	_setFormatCode,
	_setFormatComma,
	_setFormatDate,
	_setFormatPhone,
	_setUTCDate
} from "./../../utils/Common";
import FormDate from "./../../utils/form/FormDate";
/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
	root: {
	},
	grid: {
		height: '100%',
		width: '98%',
		maxHeight: '80%',
		minWidth: '98%',
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
		'& .MuiDataGrid-columnsContainer': {
			backgroundColor: '#9fa8da',
			color: '#ffffff'
		},
	},
	// 데이터 그리드 테이블 해더
	search: {
		margin: theme.spacing(1)
	},
	toolbar: {
		minHeight: 40,
		color: '#fff',
		backgroundColor: '#1976d2'
	},
	iconButton: {
		padding: 10,
	},
	paper: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: 30,
		marginTop: 13
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	searchTypography: {
		marginTop: 12
	}
}));

/* ######################################################################
 * component 
 * ###################################################################### */
// let excel_info: any = [];
const RepairList2 = () => {
	const classes = useStyles(); // CSS
	const dispatch = useDispatch(); // dispatch hook

	// [state] ######################################################################
	const repair = useSelector((state: any) => state.repair); // repair
	const common = useSelector((state: any) => state.common); // common

	// [Events] ######################################################################
	//@ 최초 실행
	const handleInit = useCallback(() => {
		let body: any = {};
		body["limit"] = '100'
		getRepr(body)
			.then((d) => dispatch(d))
			// .then((d) => {
			// 	excel_info = [];
			// 	excel_info = d.payload;
			// })
			.catch((e) => console.log(e));
	}, [dispatch]);

	//@ 검색
	const handleSearch = useCallback(
		(v) => {
			repair.srch = v;

			let body: any = {};
			if (!_isEmpty(v.date_key)) body["date_key"] = v.date_key;
			if (!_isEmpty(v.st_dt)) body["st_dt"] = _setUTCDate(v.st_dt);
			if (!_isEmpty(v.ed_dt)) body["ed_dt"] = _setUTCDate(v.ed_dt);
			if (!_isEmpty(v.chk_stat)) body["chk_stat"] = v.chk_stat;
			if (!_isEmpty(v.repr_stat)) body["repr_stat"] = v.repr_stat;
			if (!_isEmpty(v.srch_val)) body[v.srch_key.toString()] = v.srch_val;
			if (!_isEmpty(v.limit)) body["limit"] = v.limit;

			getRepr(body)
				.then((d) => dispatch(d))
				// .then((d) => {
				// 	excel_info = d.payload;
				// })
				.catch((e) => console.log(e));
		},
		[repair, dispatch]
	);

	//@ Excel downlaod
	const excelDownload = useCallback(() => {
		let excel_table: any = [];
		let _code_data = _getSession('CODE_DATA');
		_code_data = _code_data.payload;
		let _category_data = _getSession('CATEGOORY_DATA');
		_category_data = _category_data.payload;
		let excel_info = repair.excel

		excel_info.forEach((excel: any) => {
			excel_table.push({
				수선품번호: excel.prod_no,
				수선번호: excel.repr_no,
				고객명: excel.rcpt_name,
				연락처: excel.phone,
				수령방식: _getCodeName2(_code_data, excel.send_typ, "G007"),
				내역: _isEmpty(excel.req_cont_prod) ? "" : excel.req_cont_prod.toString(),
				세부요청사항: _isEmpty(excel.req_cont) ? "" : excel.req_cont.toString(),
				접수일: _setDayYYYYMMDD(excel.rcpt_dtime, "-"),
				출고요청일: _setDayYYYYMMDD(excel.repr_req_dt, "-"),
				브랜드: _getCategoryName2(_category_data, excel.brand_cd),
				"유형(대)": _getCategoryName2(_category_data, excel.cate_02),
				"유형(중)": _getCategoryName2(_category_data, excel.cate_03),
				"유형(소)": _getCategoryName2(_category_data, excel.cate_04),
				수선위치: _getCategoryName2(_category_data, excel.repr_loc),
				수선자: _isEmpty(excel.repr_user_name) ? "" : excel.repr_user_name.toString(),
				수선시작: _setDayYYYYMMDD(excel.repr_st_dtime, "-"),
				수선종료: _setDayYYYYMMDD(excel.repr_ed_dtime, "-"),
				작업비용: _isEmpty(excel.repr_pay) ? "" : excel.repr_pay.toString(),
				수선비: _isEmpty(excel.tot_pay) ? "" : excel.tot_pay.toString(),
				결재액: _isEmpty(excel.frst_pay) ? "" : excel.frst_pay.toString(),
				미수금: _isEmpty(excel.last_pay) ? "" : excel.last_pay.toString(),
				수선상태: _getCodeName2(_code_data, excel.repr_stat, "G016"),
				검수자: _isEmpty(excel.chk_user_name) ? "" : excel.chk_user_name.toString(),
				검수시작: _setDayYYYYMMDD(excel.chk_st_dtime, "-"),
				검수종료: _setDayYYYYMMDD(excel.chk_ed_dtime, "-"),
				검수상태: _getCodeName2(_code_data, excel.chk_stat, "G017"),
				검수의견: _isEmpty(excel.chk_user_cmnt) ? "" : excel.chk_user_cmnt.toString(),
			});
		});
		if (_isEmpty(excel_table)) {
			alert('데이터를 선택해주세요')
		} else {
			getExcel(excel_table);
		}
	}, [repair]);

	// [init] ######################################################################
	// 데이트 타입 설정
	const _setDate = _setFormatDate();
	// 카테고리명 조회
	const _setCateName = _setFormatCategory(common.category);
	// 수선상태명 조회
	const _setReprStateName = _setFormatCode(common.code, "G016");
	// 검수상태명 조회
	const _setChkStateName = _setFormatCode(common.code, "G017");
	// 콤마 타입 설정
	const _setComma = _setFormatComma();
	// 연락처 조회
	const _setGridPhone = _setFormatPhone();
	// 발송방식 조회
	const _setCodeName = _setFormatCode(common.code, 'G007');

	//@ 초기 데이터 (Table 컬럼)
	const columns: any = [
		{ field: "id", headerAlign: "center", headerName: "번호", hide: true },
		{ field: "prod_no", headerAlign: "center", headerName: "수선품번호", width: 130, },
		{ field: "repr_no", headerAlign: "center", headerName: "수선번호", width: 130, },
		{ field: "rcpt_name", headerAlign: "center", headerName: "고객명", width: 140, },
		{ field: "phone", headerAlign: "center", headerName: "연락처", width: 130, ..._setGridPhone },
		{ field: "send_typ", headerAlign: "center", headerName: "수령방식", width: 100, ..._setCodeName, },
		{ field: "req_cont_prod", headerAlign: "center", headerName: "내역", width: 270, },
		{ field: "req_cont", headerAlign: "center", headerName: "세부요청사항", width: 270, },
		{ field: "rcpt_dtime", headerAlign: "center", headerName: "접수일", width: 120, ..._setDate, },
		{ field: "repr_req_dt", headerAlign: "center", headerName: "출고요청일", width: 120, ..._setDate, },
		{ field: 'brand_cd', headerAlign: 'center', headerName: '브랜드', width: 150, ..._setCateName, hide: true },
		{ field: 'brand_name', headerAlign: 'center', headerName: '브랜드', width: 130 },
		{ field: "cate_02", headerAlign: "center", headerName: "유형(대)", width: 110, ..._setCateName, hide: true },
		{ field: "case2_name", headerAlign: "center", headerName: "유형(대)", width: 110 },
		{ field: "cate_03", headerAlign: "center", headerName: "유형(중)", width: 140, ..._setCateName, hide: true },
		{ field: "case3_name", headerAlign: "center", headerName: "유형(중)", width: 110 },
		{ field: "cate_04", headerAlign: "center", headerName: "유형(소)", width: 140, ..._setCateName, hide: true },
		{ field: "case4_name", headerAlign: "center", headerName: "유형(소)", width: 110 },
		{ field: "repr_loc", headerAlign: "center", headerName: "수선위치", width: 150, ..._setCateName, },
		{ field: "repr_user_name", headerAlign: "center", headerName: "수선자", width: 80, },
		{ field: "repr_st_dtime", headerAlign: "center", headerName: "수선시작", width: 120, ..._setDate, },
		{ field: "repr_ed_dtime", headerAlign: "center", headerName: "수선종료", width: 120, ..._setDate, },
		// {field: "repr_user_pay",headerAlign: "center",headerName: "작업비용",width: 100,..._setComma,},
		{ field: 'repr_pay', headerAlign: 'center', headerName: '작업비용', width: 100, ..._setComma },
		{ field: 'tot_pay', headerAlign: 'center', headerName: '수선비', width: 100, ..._setComma },
		{ field: 'frst_pay', headerAlign: 'center', headerName: '결재액', width: 100, ..._setComma },
		{ field: 'last_pay', headerAlign: 'center', headerName: '미수금', width: 100, ..._setComma },
		{ field: "repr_stat", headerAlign: "center", headerName: "수선상태", width: 90, ..._setReprStateName, },
		{ field: "chk_user_name", headerAlign: "center", headerName: "검수자", width: 160, },
		{ field: "chk_st_dtime", headerAlign: "center", headerName: "검수시작", width: 120, ..._setDate, },
		{ field: "chk_ed_dtime", headerAlign: "center", headerName: "검수종료", width: 120, ..._setDate, },
		{ field: "chk_stat", headerAlign: "center", headerName: "검수상태", width: 90, ..._setChkStateName, },
		{ field: 'reg_user', headerAlign: 'center', headerName: '작성자', width: 80 },
		{ field: 'last_mod_user', headerAlign: 'center', headerName: '수정자', width: 80 },
		{ field: 'chk_user_cmnt', headerAlign: 'center', headerName: '검수의견', width: 700 }
	];
 
	//@ 최초 실행
	useEffect(() => {
		//1. 상담 조회
		handleInit();
	}, [handleInit]);

	// [Grid] ######################################################################
	let datagrid;

	if (_isEmpty(repair.rows)) {
		datagrid = (
			<Typography variant="h6" align="center">
				데이터가 존재하지 않습니다.
			</Typography>
		);
	} else {
		datagrid = (
			<DataGrid
				headerHeight={40}
				rowHeight={30}
				columns={columns}
				rows={repair.rows}
				pageSize={15}
			/>
		);
	}

	// [Render] ######################################################################
	return (
		<React.Fragment>
			<Toolbar className={classes.toolbar}>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={11}>
						<Typography variant="h6">작업</Typography>
					</Grid>
					<Grid item xs={1}>
						{/* 엑셀다운로드 */}
						<IconButton
							className={classes.iconButton}
							aria-label="엑셀다운로드"
							color="inherit"
							onClick={excelDownload}
						>
							<GetApp />
							<Typography variant="h6">엑셀다운로드</Typography>
						</IconButton>
					</Grid>
				</Grid>
			</Toolbar>
			{/* 검색기능 */}
			<div className={classes.search}>
				<Formik
					enableReinitialize
					initialValues={{
						// 기본정보
						date_key: _isEmpty(repair.srch)
							? "rcpt_dtime"
							// "repr_st_dtime"
							: _isEmpty(repair.srch.date_key)
								? "rcpt_dtime"
								// "repr_st_dtime"
								: repair.srch.date_key, // 검색날짜키
						st_dt: _isEmpty(repair.srch)
							? ""
							: _isEmpty(repair.srch.st_dt)
								? ""
								: _setDateYYYYMMDD(repair.srch.st_dt, "-"), // 검색시작일
						ed_dt: _isEmpty(repair.srch)
							? ""
							: _isEmpty(repair.srch.ed_dt)
								? ""
								: _setDateYYYYMMDD(repair.srch.ed_dt, "-"), // 검색종료일
						repr_stat: _isEmpty(repair.srch)
							? ""
							: _isEmpty(repair.srch.repr_stat)
								? ""
								: repair.srch.repr_stat, // 접수상태
						chk_stat: _isEmpty(repair.srch)
							? ""
							: _isEmpty(repair.srch.chk_stat)
								? ""
								: repair.srch.chk_stat, // 검수상태
						srch_key: _isEmpty(repair.srch)
							? "repr_no"
							: _isEmpty(repair.srch.srch_key)
								? "repr_no"
								: repair.srch.srch_key, // 검색키
						srch_val: _isEmpty(repair.srch)
							? ""
							: _isEmpty(repair.srch.srch_val)
								? ""
								: repair.srch.srch_val, // 검색어
						limit: '100' // 노출 데이터 수
					}}
					validationSchema={Yup.object().shape({
						// 밸리데이션 불필요
					})}
					onSubmit={(v) => {
						handleSearch(v);
					}}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						touched,
						values,
					}) => (
						<form onSubmit={handleSubmit}>
							{/* 그리드 */}
							<Grid container justify="center" alignItems="center">
								{/* 기간 */}
								<Grid item xs={4}>
									<Grid container justify="center">
										<Grid item xs={1}></Grid>
										<Grid item xs={2}>
											<FormSelect
												id="date_key"
												label="구분"
												firstDefault={false}
												value={values.date_key}
												item={[
													{ code: "rcpt_dtime", name: "접수일" }, // 21.08.09 추가
													{ code: "repr_req_dt", name: "출고요청일" }, //21.08.12 추가
													{ code: "repr_st_dtime", name: "수선시작" },
													{ code: "repr_ed_dtime", name: "수선종료" },
													{ code: "chk_st_dtime", name: "검수시작" },
													{ code: "chk_ed_dtime", name: "검수종료" },
												]}
												required={true}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={3}>
											<FormDate
												id="st_dt"
												label="시작"
												value={values.st_dt}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={1}>
											<Typography
												className={classes.searchTypography}
												variant="h3" align="center">
												~{" "}
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<FormDate
												id="ed_dt"
												label="종료"
												value={values.ed_dt}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={2}></Grid>
									</Grid>
								</Grid>
								{/* 구분 */}
								<Grid item xs={4}>
									<Grid container justify="center">
										<Grid item xs={3}></Grid>
										<Grid item xs={3}>
											{/* 수선상태 */}
											<FormSelect
												id="repr_stat"
												label="수선상태"
												value={values.repr_stat}
												item={_setCodeFilter(common.code, "G016")}
												firstDefault={true}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={3}>
											{/* 검수상태 */}
											<FormSelect
												id="chk_stat"
												label="검수상태"
												value={values.chk_stat}
												item={_setCodeFilter(common.code, "G017")}
												firstDefault={true}
												required={false}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={3}></Grid>
									</Grid>
								</Grid>
								{/* 검색어 */}
								<Grid item xs={4}>
									{/* 구분 */}
									<Grid
										container
										justify="center"
										alignItems="center">
										<Grid item xs={2}>
											<FormText
												id='limit'
												label='데이터 수'
												value={values.limit}
												// disabled={true}
												required={true}
												blur={handleBlur}
												change={handleChange} />
										</Grid>
										<Grid item xs={3}>
											<FormSelect
												id="srch_key"
												label="구분"
												firstDefault={false}
												value={values.srch_key}
												item={[
													{ code: "prod_no", name: "수선품번호" },
													{ code: "repr_no", name: "수선번호" },
													{ code: "repr_user_name", name: "수선자" },
													{ code: "chk_user_name", name: "검수자" },
													{ code: "rcpt_name", name: "고객명" },
													{ code: "phone", name: "연락처" }
												]}
												required={true}
												blur={handleBlur}
												change={handleChange}
											/>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper}>
												<InputBase
													className={classes.input}
													placeholder="검색어를 입력해주세요"
													id="srch_val"
													name="srch_val"
													value={values.srch_val}
													type="text"
													error={Boolean(touched.srch_val && errors.srch_val)}
													onBlur={handleBlur}
													onChange={handleChange}
												/>
												<IconButton
													type="submit"
													className={classes.iconButton}
													aria-label="search"
												>
													<Search />
												</IconButton>
											</Paper>
										</Grid>
										<Grid item xs={1}></Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			</div>
			<div className={classes.grid}>{datagrid}</div>
		</React.Fragment>
	);
};

export default RepairList2;
