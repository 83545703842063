/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux common store
**/
// TYPES
import {
  COM_ALERT,
  COM_CATAGORY,
  COM_CODE,
  COM_CONFIRM,
  COM_CONSULT,
  COM_CUST_SEARCH,
  COM_DASHBOARD,
  COM_FAIL,
  COM_IMAGE,
  COM_SEARCH,
  COM_STORE_CLEAR,
  COM_TABS_LOCATION
} from "../types";

// state 초기 데이터
const initialState = {
  msg: '',             // 공통 팝업 메시지
  isMsg: false,        // 공통 팝업 레이어 활성화 여부 (활성:true, 비활성: false)
  isSearch: false,     // 검색 레이어 활성화 여부 (활성:true, 비활성: false)
  isDashboard: false,  // 대시보드 레이어 활성화 여부 (활성:true, 비활성: false) 
  dashboard: [],       // 대시보드 데이터
  category: [],        // 공통 카테고리 데이터
  code: [],            // 공통 코드 데이터
  isCustAdd: false,    // 고객명 검색 레이어 활성화 여부 (활성:true, 비활성: false)
  custPage: '',        // 고객명 검색 페이지 (01:상담, 02:접수)
  isImage: false,      // 이미지 검색 레이어 활성화 여부 (활성:true, 비활성: false)
  imageInfo: {},       // 이미지 검색 정보
  imagePage: '',       // 이미지 검색 페이지 (01:상담, 02:챗봇)
  search:{},           // 검색조건
  isConsult: false,    // 상담이력 레이어 활성화 여부 (활성:true, 비활성: false)
  tabLocation: 0       // 메뉴 탭 스타일 위치 변경
};

// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case COM_ALERT:             // 공통 ALERT
      return { ...state };
    case COM_CONFIRM:           // 공통 CONFIRM
      return { ...state };
    case COM_CATAGORY:          // 공통 카테고리 데이터
      return { 
        ...state, 
        category: action.payload 
      };
    case COM_CODE:              // 공통 코드 데이터
      return { 
        ...state, 
        code: action.payload 
      };
    case COM_SEARCH:            // 공통 검색 레이어
      return { ...state };
    case COM_DASHBOARD:         // 공통 대시보드 레이어
      return { 
        ...state, 
        dashboard: action.payload
      }; 
    case COM_CUST_SEARCH:       // 공통 고객 검색 레이어
      return { 
        ...state, 
        isCustAdd: action.payload.isCustAdd,
        custPage: action.payload.custPage
      };
    case COM_IMAGE:            // 공통 이미지 검색 레이어
      return { 
        ...state, 
        imageInfo: action.payload 
      };
    case COM_CONSULT:           // 공통 이미지 검색 레이어
      return { ...state };
    case COM_STORE_CLEAR:       // 상태관리 저장소 클리어
      return { state: undefined };            
    case COM_TABS_LOCATION:       // 탭 스타일 위치
      return { 
        ...state, 
        tabLocation: action.payload
      };          
    case COM_FAIL:              // 공통 코드 데이터
      return { ...state };      
    default:
      return state;
  }
}