/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : FORM Option
**/
/* @material import */
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup
} from '@material-ui/core';
/* React import */
import React from "react";
import { _isEmpty } from '../Common';

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
    /* select style */
    root: {
        height: '90%', 
        width: '90%',
        maxHeight: '90%',
        minWidth: '90%',
        fontSize: '0.1em',
        size: 'small', 
        verticalAlign: 'bottom'
    },  
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const FormOption = (props:any) => {
  const classes = useStyles();     // CSS

  let _data = [];

  if(_isEmpty(props.item)) {       // Default Setting
    _data = [
        { label: '아니오', value: false },
        { label: '예', value: true }
    ];
  } else {
    _data = props.item;
  }
  // [Render] ######################################################################  
  return (
    <FormControl 
      className={classes.root}
      required={props.required}
      disabled={props.disabled}    
      >
        <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
        <RadioGroup 
            row 
            aria-label="position"
            id={props.id}
            name={props.id}
            onBlur={props.blur}
            onChange={props.change}
            value={props.value.toString()}   
            >
            {_data.map((v:any, i:number) => 
                <FormControlLabel
                    key={i+1}
                    value={v.value.toString()}
                    control={<Radio color="primary" />}
                    label={v.label}
                    labelPlacement="end"
                />
            )}
        </RadioGroup>        
    </FormControl>
  );
};

export default FormOption;