/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : browsers header title area
**/
/* react import */
import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import ComnAlert from './../pages/common/ComnAlert';
import ComnCustAdd from './../pages/common/ComnCustAdd';
import ComnDashboard from './../pages/common/ComnDashboard';
import ComnImage from './../pages/common/ComnImage';
import ComnSearch from './../pages/common/ComnSearch';

/* 참조 공통 컴포넌트 */
const Page = forwardRef((props:any, ref:any) => {
  
  return (
    <div
      ref={ref}
      {...props}
    >
      <Helmet>
        <title>투엘다움({props.title})</title>
      </Helmet>
      {props.children}
      <ComnAlert />
      <ComnDashboard />
      <ComnSearch />
      <ComnCustAdd />
      <ComnImage />
    </div>  
  );
});

export default Page;
