/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Gallery Edit Page
**/
/* @material import */
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  Chip,
  Grid,
  Link,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  Add,
  Save
} from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import Resizer from 'react-image-file-resizer';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { addGally, delGally, getGally, updGally } from "../../utils/redux/actions/GallyAction";
import { addImg, delImg, getImgInfo, updImg } from "../../utils/redux/actions/ImgAction";
import {
  _getCodeName,
  _isEmpty,
  _setCateCodeFilter,
  _setCodeFilter,
  _setDataURIToBlob,
  _setImgJson
} from "./../../utils/Common";
import FormFile from "./../../utils/form/FormFile";
import FormFileAfter from "./../../utils/form/FormFileAfter";
import FormFileCard from "./../../utils/form/FormFileCard";
import FormFileCardAfter from "./../../utils/form/FormFileCardAfter";
import FormSelect from "./../../utils/form/FormSelect";
import FormText from "./../../utils/form/FormText";
import FormImgPosition from '../../utils/form/FormImgPosition';
import { getReprInfo } from "../../utils/redux/actions/ReprAction";



/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
    height: '100%',
    width: '100%',
    maxHeight: '90%',
    minWidth: '90%',
    overflow: 'auto',
    margin: theme.spacing(2)
  },
  grid: {
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  label: {
    fontSize: 12,
    color: 'primary',
    marginTop: theme.spacing(2)
  },
  paper: {
    width: '80%',
    height: 80,
    margin: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper
  },
  iconButton: {
    padding: 10,
  },
  chiproot: {
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  CardMedia: {
    height: 60
  },
  files: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: 0
  },
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const GalleryEdit = forwardRef((props, ref) => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook
  const formRef = useRef<any>(null);

  // 부모 컴포넌트 사용
  useImperativeHandle(ref, () => formRef.current);

  // [state] ######################################################################  
  const repair = useSelector((state: any) => state.repair);              // repair
  const common = useSelector((state: any) => state.common);              // common
  const gallery = useSelector((state: any) => state.gallery);            // gallery
  const product = useSelector((state: any) => state.product);            // product
  const productCate = useSelector((state: any) => state.productCate);    // productCate
  // console.log(gallery)
  // console.log(repair)

  // [Events] ######################################################################
  //@ 초기화
  const handleinit = useCallback(() => {

    if (!_isEmpty(repair.info)) {
      let data: any = [];
      let body = {
        repr_no: repair.info.repr_no
        , galy_typ: "02"
      }
      // 상품 이미지 정보 조회
      getGally(body).then(async (d) => {
        // console.log(d)
        let rows = d.payload;
        if (rows.length > 0) {
          for await (const v of rows) {
            // rows.forEach((v: any, idx: number) => {
            // setTimeout(() => {
            let body = { imageid: v.galy_id };
            // console.log(body)
            await getImgInfo(body).then((img: any) => {
              // 스토리지 이미지 조회
              if (!_isEmpty(img.payload)) {
                let image_url = '';
                let imgPosition = img.payload.imgPosition;
                if (img.payload.isResized) {
                  image_url = img.payload.resizedImageUrl;
                } else {
                  image_url = img.payload.rawImageUrl;
                }
                v['galy_url'] = _isEmpty(image_url) ? '' : image_url;
                v['galy_url_real'] = _isEmpty(image_url) ? '' : image_url
                v['imgPosition'] = _isEmpty(imgPosition) ? '' : imgPosition

                data.push(v);
              }

              // 스토리지 태그 조회
              let otherTag = img.payload.otherTag;
              // console.log(img)
              if ((rows.indexOf(v) + 1) === rows.length) {
                // console.log(rows.indexOf(v) + 1)
                // 스토리지 태그 조회
                if (!_isEmpty(otherTag)) {
                  let tag: any = [];
                  otherTag.forEach((t: any) => {
                    let json: any = {};
                    json['label'] = t;
                    tag.push(json);
                  });
                  dispatch({ type: 'GALLY_GET_TAG', payload: tag }); // gallery에 tag를 적재
                }
                // 이미지 데이터 조회
                dispatch({ type: 'GALLY_GET', payload: data }); // gallery에 이미지정보 적재
              }
            }
            ).catch((e) => console.log(e));
            // }, (idx * 100));
            // })
          }
        } else {  // 이미지가 없을 경우
          return dispatch({ type: 'GALLY_GET', payload: [] });
        }
      }).catch((e) => console.log(e));

    } else { // 
      dispatch({ type: 'GALLY_GET', payload: [] });
      dispatch({ type: 'GALLY_GET_TAG', payload: [] });
    }
  }, [repair, dispatch]);

  //@ 이미지 데이터 가져오기
  const getImageItem = useCallback(async (e) => {

    let _f = e.target.files[0];      // 파일
    let reader = new FileReader();
    let name = e.target.name;
    let cate = name.split('_')[1];

    // 2. 리사이즈 이미지
    let resize = await resizeFile(_f);

    reader.onload = (event: any) => {
      let gallyRows: any = gallery.rows;
      let json: any = {};
      let payload = gallyRows.filter((v: any) => v.galy_cate === cate);

      if (payload.length > 0) {        // 수정
        json = payload[0];
        gallyRows.splice(gallyRows.indexOf(payload[0]), 1);
      } else {                        // 신규
        // json['galy_id'] = name;
        json['galy_cate'] = cate;
      }

      let images = event.target.result;
      // 1. DB 적재 이미지
      json['galy_url_real'] = images;
      json['galy_url'] = resize;
      // 이미지 저장   
      gallyRows.push(json);
      dispatch({ type: 'GALLY_GET', payload: gallyRows });
    };
    reader.readAsDataURL(_f);

  }, [gallery, dispatch]);

  const resizeFile = (images: any) => new Promise(resolve => {
    Resizer.imageFileResizer(images, 300, 300, 'JPEG', 10, 0,
      uri => {
        resolve(uri);
      },
      'base64'
    );
  });

  //@ 이미지 데이터 삭제
  const delImageItem = useCallback((_cate) => {
    let json: any = {};
    let payload = gallery.rows.filter((v: any) => v.galy_cate === _cate);
    if (payload.length > 0) {        // 수정
      json = payload[0];
      gallery.rows.splice(gallery.rows.indexOf(payload[0]), 1);
      json['galy_url'] = '';
      json['galy_url_real'] = '';
      gallery.rows.push(json);
      dispatch({ type: 'GALLY_GET', payload: gallery.rows });
    }
  }, [gallery, dispatch]);


  //@ 태그 데이터 적재
  const handleAddTag = useCallback(() => {
    let tag = formRef.current.values.tag;
    if (!_isEmpty(tag)) {
      let json: any = {};
      json['label'] = tag;
      gallery.tag.push(json);
      dispatch({ type: 'GALLY_GET_TAG', payload: gallery.tag });
    }
  }, [gallery, dispatch]);

  //@ 태그 데이터 삭제
  const handleDelTag = useCallback((i) => {
    gallery.tag.splice(i, 1);
    dispatch({ type: 'GALLY_GET_TAG', payload: gallery.tag });
  }, [gallery, dispatch]);


  //@ 저장
  const handleAdd = useCallback((data, json) => {

    // 이미지(위치) 개수 만큼 적재
    let images = gallery.rows;
    if (!_isEmpty(images)) {
      // images.forEach((v: any) => {
      for (let v of images) {
        let repr_no = data.repr_no;      // 수선번호
        let galy_typ = data.galy_typ;    // 등록유형
        let galy_cate = v.galy_cate;     // 이미지 위치
        let galy_id = v.galy_id;         // 이미지 ID 
        // let galy_chk = v.galy_url;       // 이미지 URL 
        let galy_url = v.galy_url_real;  // 이미지 URL 
        let galy_no = v.galy_no;         // 이미지 URL 



        // json['imgPosition'] = _getCodeName(galy_cate, 'G010');  // 코드명변환
        if (galy_cate == '01') {
          if (data.imgPosition_01) {
            json.imgPosition = data.imgPosition_01
          }
          else {
            json.imgPosition = '수선전01'
          }
        } else if (galy_cate == '02') {
          if (data.imgPosition_02) {
            json.imgPosition = data.imgPosition_02
          }
          else {
            json.imgPosition = '수선전02'
          }
        } else if (galy_cate == '03') {
          if (data.imgPosition_03) {
            json.imgPosition = data.imgPosition_03
          }
          else {
            json.imgPosition = '수선전03'
          }
        } else if (galy_cate == '04') {
          if (data.imgPosition_04) {
            json.imgPosition = data.imgPosition_04
          }
          else {
            json.imgPosition = '수선전04'
          }
        } else if (galy_cate == '05') {
          if (data.imgPosition_05) {
            json.imgPosition = data.imgPosition_05
          }
          else {
            json.imgPosition = '수선후01'
          }
        } else if (galy_cate == '06') {
          if (data.imgPosition_06) {
            json.imgPosition = data.imgPosition_06
          }
          else {
            json.imgPosition = '수선후02'
          }
        } else if (galy_cate == '07') {
          if (data.imgPosition_07) {
            json.imgPosition = data.imgPosition_07
          }
          else {
            json.imgPosition = '수선후03'
          }
        } else if (galy_cate == '08') {
          if (data.imgPosition_08) {
            json.imgPosition = data.imgPosition_08
          }
          else {
            json.imgPosition = '수선후04'
          }
        }
        // json['imgPosition'] = '-';  // 코드명변환


        if (galy_cate == '01' || galy_cate == '02' || galy_cate == '03' || galy_cate == '04') {
          json['isAltered'] = '수선전';
          galy_typ = '02';
        }
        else if (galy_cate == '05' || galy_cate == '06' || galy_cate == '07' || galy_cate == '08') {
          json['isAltered'] = '수선후'
          galy_typ = '02';
        }

        if (_isEmpty(galy_no)) {  // 신규등록
          let body = new FormData();
          body.append('image_info', JSON.stringify(json));
          body.append('image_file', _setDataURIToBlob(galy_url), 'image.jpg');
          // console.log(body)
          // 이미지 스토리지 저장
          addImg(body).then((d: any) => {
            // console.log(d)
            let rows = d.payload;     // callback data
            if (!_isEmpty(rows.id)) {  // 이미지 정상 적재 후
              let id = rows.id;
              let sBody: any = {};
              sBody['galy_no'] = '';
              sBody['repr_no'] = repr_no;
              sBody['galy_typ'] = galy_typ;
              sBody['galy_cate'] = galy_cate;
              sBody['galy_id'] = id;
              sBody['galy_url'] = rows.rawImageUrl;
              sBody['is_active'] = true;
              // console.log(f'aaa {sBody}')
              let resetBody = { repr_no: repr_no }
              addGally(sBody).then(() => {
                common.msg = '저장에 성공 하였습니다.';
                common.isMsg = true;
                return dispatch({ type: 'COM_ALERT' });       // 안내메시지
              }).then(() => getReprInfo(resetBody).then((d) => dispatch(d)))
                // .then(() => handleinit())
                .catch((e) => {
                  console.log(e)
                  common.msg = '저장에 실패 하였습니다.';
                  common.isMsg = true;
                  return dispatch({ type: 'COM_ALERT' });       // 안내메시지                       
                });
            }
          }).catch((e) => {
            console.log(e)
            common.msg = '저장에 실패 하였습니다.';
            common.isMsg = true;
            return dispatch({ type: 'COM_ALERT' });       // 안내메시지                       
          })
        } else {                 // 수정등록
          let body = new FormData();
          body.append('imageid', galy_id);
          body.append('image_info', JSON.stringify(json));
          if (!_isEmpty(galy_url)) {
            if (galy_url.indexOf("data:image/jpeg;base64") > -1) {
              body.append('image_file', _setDataURIToBlob(galy_url), 'image.jpg');
            } else {
              body.append('image_file', '');
            }
            // 이미지 스토리지 저장
            updImg(body, galy_id).then((d: any) => {
              let sBody: any = {};
              sBody['galy_no'] = galy_no;
              sBody['repr_no'] = repr_no;
              sBody['galy_typ'] = galy_typ;
              sBody['galy_cate'] = galy_cate;
              let resetBody = { repr_no: repr_no }
              updGally(sBody)
                .then(() => {
                  common.msg = '수정에 성공 하였습니다.';
                  common.isMsg = true;
                  return dispatch({ type: 'COM_ALERT' });       // 안내메시지                    
                }).then(() => getReprInfo(resetBody).then((d) => dispatch(d)))
                // .then(() => handleinit())
                .catch((e) => {
                  console.log(e)
                  common.msg = '수정에 실패 하였습니다.';
                  common.isMsg = true;
                  return dispatch({ type: 'COM_ALERT' });       // 안내메시지                       
                });
            }).catch((e) => {
              console.log(e)
              common.msg = '수정에 실패 하였습니다.';
              common.isMsg = true;
              return dispatch({ type: 'COM_ALERT' });       // 안내메시지                       
            })
          } else {              // 삭제
            // 이미지 스토리지 저장
            delImg(body, galy_id).then((d: any) => {
              let sBody: any = {};
              sBody['galy_no'] = galy_no;
              let resetBody = { repr_no: repr_no }
              delGally(sBody).then(() => {
                common.msg = '수정에 성공 하였습니다.';
                common.isMsg = true;
                return dispatch({ type: 'COM_ALERT' });       // 안내메시지                    
              }).then(() => getReprInfo(resetBody).then((d) => dispatch(d)))
                // .then(() => handleinit())
                .catch((e) => {
                  console.log(e)
                  common.msg = '수정에 실패 하였습니다.';
                  common.isMsg = true;
                  return dispatch({ type: 'COM_ALERT' });       // 안내메시지                       
                });
            });
          }
        }
      }
      // });
    } else {
      common.msg = '수선품 이미지를 등록해주세요.';
      common.isMsg = true;
      dispatch({ type: 'COM_ALERT' });       // 안내메시지                             
    }

  }, [common, gallery, dispatch]);

  // [init] ######################################################################
  //@ 최초 실행
  useEffect(() => {
    handleinit();
  }, [handleinit]);

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h6">사진등록</Typography>
          </Grid>
        </Grid>
      </Toolbar>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          // 기본정보
          repr_no: _isEmpty(repair.info) ? '' : repair.info.repr_no,                   // 수선 번호
          cate_01: _isEmpty(repair.info) ? '' : repair.info.cate_01,                   // 수선 종류
          prod_no: _isEmpty(repair.info) ? '' : repair.info.prod_no,                   // 수선품 번호
          repr_loc_typ: _isEmpty(repair.info) ? '' : repair.info.repr_loc_typ,         // 수선 위치 유형
          repr_loc: _isEmpty(repair.info) ? '' : repair.info.repr_loc,                 // 수선 위치
          galy_typ: _isEmpty(gallery.info) ? '' : gallery.info.galy_typ,               // 등록 유형
          tag: _isEmpty(gallery.images) ? '' : gallery.images.tag,                     // 이미지 태그 정보
          // 이미지 정보
          image_01: _isEmpty(gallery.images) ? '' : gallery.images.image_01,           // 이미지 1
          image_01_url: _isEmpty(gallery.images) ? '' : gallery.images.image_01_url,   // 이미지 1
          imgPosition_01: _isEmpty(gallery.images) ? '' : gallery.images.imgPosition_01,
          image_02: _isEmpty(gallery.images) ? '' : gallery.images.image_02,           // 이미지 2
          image_02_url: _isEmpty(gallery.images) ? '' : gallery.images.image_02_url,   // 이미지 2
          imgPosition_02: _isEmpty(gallery.images) ? '' : gallery.images.imgPosition_02,
          image_03: _isEmpty(gallery.images) ? '' : gallery.images.image_03,           // 이미지 3
          image_03_url: _isEmpty(gallery.images) ? '' : gallery.images.image_03_url,   // 이미지 3
          imgPosition_03: _isEmpty(gallery.images) ? '' : gallery.images.imgPosition_03,
          image_04: _isEmpty(gallery.images) ? '' : gallery.images.image_04,           // 이미지 4
          image_04_url: _isEmpty(gallery.images) ? '' : gallery.images.image_04_url,   // 이미지 4
          imgPosition_04: _isEmpty(gallery.images) ? '' : gallery.images.imgPosition_04,
          image_05: _isEmpty(gallery.images) ? '' : gallery.images.image_05,           // 이미지 5
          image_05_url: _isEmpty(gallery.images) ? '' : gallery.images.image_05_url,   // 이미지 5
          imgPosition_05: _isEmpty(gallery.images) ? '' : gallery.images.imgPosition_05,
          image_06: _isEmpty(gallery.images) ? '' : gallery.images.image_06,           // 이미지 6
          image_06_url: _isEmpty(gallery.images) ? '' : gallery.images.image_06_url,   // 이미지 6
          imgPosition_06: _isEmpty(gallery.images) ? '' : gallery.images.imgPosition_06,
          image_07: _isEmpty(gallery.images) ? '' : gallery.images.image_07,           // 이미지 7
          image_07_url: _isEmpty(gallery.images) ? '' : gallery.images.image_07_url,   // 이미지 7
          imgPosition_07: _isEmpty(gallery.images) ? '' : gallery.images.imgPosition_07,
          image_08: _isEmpty(gallery.images) ? '' : gallery.images.image_08,           // 이미지 8
          image_08_url: _isEmpty(gallery.images) ? '' : gallery.images.image_08_url,   // 이미지 8
          imgPosition_08: _isEmpty(gallery.images) ? '' : gallery.images.imgPosition_08,
          prod_stat: _isEmpty(product.info) ? '' : product.info.prod_stat
        }}
        validationSchema={Yup.object().shape({

        })}
        onSubmit={(v) => {
          let json = _setImgJson(v, product.info, productCate.rows, repair.info, gallery.tag);
          handleAdd(v, json);
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            {/*############################## 수선 ##############################*/}
            <Box ml={3} mr={3} mb={1} justifyContent="center">
              <Grid
                container
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    className={classes.label}
                    variant="h6">기본 정보</Typography>
                </Grid>
                {/* 수선품번호 */}
                <Grid item xs={6}>
                  <FormText
                    id='repr_no'
                    label='수선번호'
                    value={values.repr_no}
                    disabled={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 수선위치 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='repr_loc'
                    label='수선위치'
                    value={values.repr_loc}
                    item={_setCateCodeFilter(common.category, values.repr_loc_typ)}
                    disabled={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 수선유형 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='cate_01'
                    label='수선유형'
                    value={values.cate_01}
                    item={[
                      { code: '0600000000', name: '가방,지갑,기타' },
                      { code: '0700000000', name: '벨트' },
                      { code: '0800000000', name: '신발' }
                    ]}
                    disabled={true}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 등록유형 */}
                <Grid item xs={6}>
                  {/* <FormSelect
                    id='galy_typ'
                    label='등록유형'
                    firstDefault={false}
                    value={values.galy_typ}
                    item={_setCodeFilter(common.code, 'G009')}
                    errors={errors.galy_typ}
                    touched={touched.galy_typ}
                    blur={handleBlur}
                    change={handleChange} /> */}
                </Grid>
                {/* 수선품 이미지 */}
                <Grid item xs={12} className={classes.grid}>
                  <Typography
                    color="primary"
                    className={classes.label}
                    variant="h6">수선품 이미지</Typography>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <Grid
                        container
                        justify="center"
                        alignItems="center">
                        {/* 수선 전(01) */}
                        <Grid item xs={3}>
                          <Card className={classes.paper}>
                            <CardHeader
                              // title="정면" 
                              title="수선 전(01)"
                              action={_isEmpty(values.image_01_url) || values.prod_stat == '03' ? '' : <Link onClick={() => delImageItem('01')}>DEL</Link>}
                            />
                            {!_isEmpty(values.image_01_url) ?
                              <CardActionArea>
                                <FormFileCard
                                  id='image_01'
                                  label='수선 전(01)'
                                  value={values.image_01_url}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActionArea>
                              :
                              <CardActions>
                                <FormFile
                                  id='image_01'
                                  label='수선 전(01)'
                                  value={_isEmpty(values.image_01_url) ? '' : values.image_01}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActions>
                            }
                          </Card>
                          <FormImgPosition
                            id='imgPosition_01'
                            label='촬영부위(1)'
                            value={values.imgPosition_01}
                            disabled={_isEmpty(values.image_01_url)}
                            required={false}
                            blur={handleBlur}
                            change={handleChange}
                          />
                        </Grid>
                        {/* 수선 전(02) */}
                        <Grid item xs={3}>
                          <Card className={classes.paper}>
                            <CardHeader
                              // title="윗면"
                              title="수선 전(02)"
                              action={_isEmpty(values.image_02_url) || values.prod_stat == '03' ? '' : <Link onClick={() => delImageItem('02')}>DEL</Link>}
                            />
                            {!_isEmpty(values.image_02_url) ?
                              <CardActionArea>
                                <FormFileCard
                                  id='image_02'
                                  label='수선 전(02)'
                                  value={values.image_02_url}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActionArea>
                              :
                              <CardActions>
                                <FormFile
                                  id='image_02'
                                  label='수선 전(02)'
                                  value={_isEmpty(values.image_02_url) ? '' : values.image_02}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActions>
                            }
                          </Card>
                          <FormImgPosition
                            id='imgPosition_02'
                            label='촬영부위(2)'
                            value={values.imgPosition_02}
                            disabled={_isEmpty(values.image_02_url)}
                            required={false}
                            blur={handleBlur}
                            change={handleChange}
                          />
                        </Grid>
                        {/* 수선 전(03) */}
                        <Grid item xs={3}>
                          <Card className={classes.paper}>
                            <CardHeader
                              // title={values.cate_01 == "0600000000" ? "우옆면" : "바깥쪽"}
                              title='수선 전(03)'
                              action={_isEmpty(values.image_03_url) || values.prod_stat == '03' ? '' : <Link onClick={() => delImageItem('03')}>DEL</Link>}
                            />
                            {!_isEmpty(values.image_03_url) ?
                              <CardActionArea>
                                <FormFileCard
                                  id='image_03'
                                  label='수선 전(03)'
                                  value={values.image_03_url}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActionArea>
                              :
                              <CardActions>
                                <FormFile
                                  id='image_03'
                                  label='수선 전(03)'
                                  value={_isEmpty(values.image_03_url) ? '' : values.image_03}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActions>
                            }
                          </Card>
                          <FormImgPosition
                            id='imgPosition_03'
                            label='촬영부위(3)'
                            value={values.imgPosition_03}
                            disabled={_isEmpty(values.image_03_url)}
                            required={false}
                            blur={handleBlur}
                            change={handleChange}
                          />
                        </Grid>
                        {/* 수선 전(04) */}
                        <Grid item xs={3}>
                          <Card className={classes.paper}>
                            <CardHeader
                              // title={values.cate_01 == "0600000000" ? "좌옆면" : "안쪽"}
                              title='수선 전(04)'
                              action={_isEmpty(values.image_04_url) || values.prod_stat == '03' ? '' : <Link onClick={() => delImageItem('04')}>DEL</Link>}
                            />
                            {!_isEmpty(values.image_04_url) ?
                              <CardActionArea>
                                <FormFileCard
                                  id='image_04'
                                  label='수선 전(04)'
                                  value={values.image_04_url}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActionArea>
                              :
                              <CardActions>
                                <FormFile
                                  id='image_04'
                                  label='수선 전(04)'
                                  value={_isEmpty(values.image_04_url) ? '' : values.image_04}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActions>
                            }
                          </Card>
                          <FormImgPosition
                            id='imgPosition_04'
                            label='촬영부위(4)'
                            value={values.imgPosition_04}
                            disabled={_isEmpty(values.image_04_url)}
                            required={false}
                            blur={handleBlur}
                            change={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        justify="center"
                        alignItems="center">
                        {/* 수선 후(01) */}
                        <Grid item xs={3}>
                          <Card className={classes.paper}>
                            <CardHeader
                              // title={values.cate_01 == "0600000000" ? "밑면" : "밑창"}
                              title='수선 후(01)'
                              action={_isEmpty(values.image_05_url) || values.prod_stat == '01' ? '' : <Link onClick={() => delImageItem('05')}>DEL</Link>}
                            />
                            {!_isEmpty(values.image_05_url) ?
                              <CardActionArea>
                                <FormFileCardAfter
                                  id='image_05'
                                  label='수선 후(01)'
                                  value={values.image_05_url}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActionArea>
                              :
                              <CardActions>
                                <FormFileAfter
                                  id='image_05'
                                  label='수선 후(01)'
                                  value={_isEmpty(values.image_05_url) ? '' : values.image_05}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActions>
                            }
                          </Card>
                          <FormImgPosition
                            id='imgPosition_05'
                            label='촬영부위(1)'
                            value={values.imgPosition_05}
                            disabled={_isEmpty(values.image_05_url)}
                            required={false}
                            blur={handleBlur}
                            change={handleChange}
                          />
                        </Grid>
                        {/* 수선 후(02) */}
                        <Grid item xs={3}>
                          <Card className={classes.paper}>
                            <CardHeader
                              // title="뒷면"
                              title='수선 후(02)'
                              action={_isEmpty(values.image_06_url) || values.prod_stat == '01' ? '' : <Link onClick={() => delImageItem('06')}>DEL</Link>}
                            />
                            {!_isEmpty(values.image_06_url) ?
                              <CardActionArea>
                                <FormFileCardAfter
                                  id='image_06'
                                  label='수선 후(02)'
                                  value={values.image_06_url}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActionArea>
                              :
                              <CardActions>
                                <FormFileAfter
                                  id='image_06'
                                  label='수선 후(02)'
                                  value={_isEmpty(values.image_06_url) ? '' : values.image_06}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActions>
                            }
                          </Card>
                          <FormImgPosition
                            id='imgPosition_06'
                            label='촬영부위(2)'
                            value={values.imgPosition_06}
                            disabled={_isEmpty(values.image_06_url)}
                            required={false}
                            blur={handleBlur}
                            change={handleChange}
                          />
                        </Grid>
                        {/* 수선 후(03) */}
                        <Grid item xs={3}>
                          <Card className={classes.paper}>
                            <CardHeader
                              // title={values.cate_01 == "0600000000" ? "끈" : "갑보"}
                              title='수선 후(03)'
                              action={_isEmpty(values.image_07_url) || values.prod_stat == '01' ? '' : <Link onClick={() => delImageItem('07')}>DEL</Link>}
                            />
                            {!_isEmpty(values.image_07_url) ?
                              <CardActionArea>
                                <FormFileCardAfter
                                  id='image_07'
                                  label='수선 후(03)'
                                  value={values.image_07_url}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActionArea>
                              :
                              <CardActions>
                                <FormFileAfter
                                  id='image_07'
                                  label='수선 후(03)'
                                  value={_isEmpty(values.image_07_url) ? '' : values.image_07}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActions>
                            }
                          </Card>
                          <FormImgPosition
                            id='imgPosition_07'
                            label='촬영부위(3)'
                            value={values.imgPosition_07}
                            disabled={_isEmpty(values.image_07_url)}
                            required={false}
                            blur={handleBlur}
                            change={handleChange}
                          />
                        </Grid>
                        {/* 수선 후(04) */}
                        <Grid item xs={3}>
                          <Card className={classes.paper}>
                            <CardHeader
                              // title={values.cate_01 == "0600000000" ? "기타" : "굽"}
                              title='수선 후(04)'
                              action={_isEmpty(values.image_08_url) || values.prod_stat == '01' ? '' : <Link onClick={() => delImageItem('08')}>DEL</Link>}
                            />
                            {!_isEmpty(values.image_08_url) ?
                              <CardActionArea>
                                <FormFileCardAfter
                                  id='image_08'
                                  label='수선 후(04)'
                                  value={values.image_08_url}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActionArea>
                              :
                              <CardActions>
                                <FormFileAfter
                                  id='image_08'
                                  label='수선 후(04)'
                                  value={_isEmpty(values.image_08_url) ? '' : values.image_08}
                                  change={(e: any) => getImageItem(e)}
                                  onOff={values.prod_stat} />
                              </CardActions>
                            }
                          </Card>
                          <FormImgPosition
                            id='imgPosition_08'
                            label='촬영부위(4)'
                            value={values.imgPosition_08}
                            disabled={_isEmpty(values.image_08_url)}
                            required={false}
                            blur={handleBlur}
                            change={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* 기타 검색 태그 */}
                <Grid item xs={12} className={classes.grid}>
                  <Typography
                    color="primary"
                    className={classes.label}
                    variant="subtitle2">기타 검색 태그</Typography>

                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                  >
                    {/* 태그 추가 */}
                    <Grid item xs={4}>
                      <FormText
                        id='tag'
                        label='태그추가'
                        value={values.tag}
                        disabled={false}
                        required={false}
                        blur={handleBlur}
                        change={handleChange} />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Add />}
                        onClick={handleAddTag}
                      >
                        태그추가
                      </Button>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12}>
                      <Paper component="ul" className={classes.chiproot}>
                        {(gallery.tag).map((v: any, i: number) =>
                          <li key={i + 1}>
                            <Chip
                              // icon={}
                              label={v.label}
                              onDelete={(e: number) => handleDelTag(i)}
                              className={classes.chip}
                            />
                          </li>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {/* 버튼영역 */}
            <Box
              ml={1} mr={1} mb={1}
              display="flex"
              justifyContent="flex-end"
            >
              {/* 저장 */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<Save />}
                disabled={isSubmitting}
              >
                {gallery.isEdit ? '저장' : '저장'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
});

export default GalleryEdit;
