/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : FormTex tarea
**/
/* @material import */
import { makeStyles, TextareaAutosize } from '@material-ui/core';
/* React import */
import React from "react";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
    /* select style */
    root: {
        height: '90%', 
        width: '90%',
        maxHeight: '90%',
        minWidth: '90%',
        fontSize: '12px',
        resize: 'none',
        size: 'small'
    },  
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const FormTextarea = (props:any) => {
  const classes = useStyles();     // CSS

  // [Render] ######################################################################  
  return (
    <TextareaAutosize
      className={classes.root}
      rows={props.rows}
      required={props.required}
      disabled={props.disabled}      
      id={props.id}
      name={props.id}
      aria-label={props.label}
      placeholder={props.label+' 입력해주세요'}
      value={props.value}
      onBlur={props.blur}
      onChange={props.change}
      onClick={props.click}      
    />

  );
};

export default FormTextarea;