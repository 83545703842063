/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Delivery page
**/
/* @material import */
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';
/* react import */
import React from 'react';
import Page from './../Page';
import DeliveryEdit from './DeliveryEdit';
import DeliveryList from './DeliveryList';
import DeliveryView from './DeliveryView';

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '90%',
    overflow: 'hidden',  
    marginTop: 0,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3)      
  },
  paper: {
    width: '100%',
    height: 700,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper    
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const Delivery = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="출고"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="flex-start"
      >
        <Container maxWidth="xl">
          <Box mb={1} justifyContent="center">
            <Grid
              container
              justify="center" 
              spacing={1}
            >           
              <Grid item xs={9}>
                {/* 출고 이력 */}
                <Paper className={classes.paper} elevation={4}>  
                  <DeliveryList />         
                </Paper>                              
              </Grid>
              <Grid item xs={3}> 
                {/* 출고 정보 */}
                <Paper className={classes.paper} elevation={4}>
                  {
                    (window.sessionStorage.getItem('user_grd') === '09') ?
                    <DeliveryView />
                    :
                    <DeliveryEdit />
                  }
                </Paper>                               
              </Grid>              
            </Grid>
          </Box>
        </Container>
      </Box> 
    </Page>   
  );
};

export default Delivery;
