/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : FORM TEXT
**/
/* @material import */
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
/* React import */
import React from "react";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
    height: '90%',
    width: '90%',
    maxHeight: '90%',
    minWidth: '90%',
    fontSize: '0.1em',
    size: 'small'
  },
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const FormText = (props: any) => {
  const classes = useStyles();     // CSS

  // [Render] ######################################################################  
  return (
    <TextField
      className={classes.root}
      // autoFocus
      margin="dense"
      required={props.required}
      disabled={props.disabled}
      id={props.id}
      name={props.id}
      label={props.label}
      placeholder={props.label + ' 입력해주세요'}
      InputProps={{ style: { fontSize: 14 } }}
      type='text'
      size='small'
      multiline //2021-11-18 한줄로 나온거를 여러줄로 표현
      // variant="outlined"
      error={Boolean(props.touched && props.errors)}
      helperText={props.touched && props.errors}
      onBlur={props.blur}
      onChange={props.change}
      onClick={props.click}
      value={props.value || ''}
    // defaultValue={props.value}
    />

  );
};

export default FormText;