/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux payments store
**/
// TYPES
import {
  PAY_ADD,
  PAY_DEL,
  PAY_FAIL,
  PAY_GET,
  PAY_GET_INFO,
  PAY_UPD
} from "../types";
import { _dataAddID, _isEmpty } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows:[],
  info:{},
  isEdit: false
};

// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case PAY_GET:         // 정산 전체 조회     
      return { 
        ...state,
        rows: _dataAddID(action.payload)
       };
    case PAY_GET_INFO:    // 정산 개별 조회    
      return { 
        ...state,
        info: action.payload[0],
        isEdit: _isEmpty(action.payload) ? false : true
       };       
    case PAY_ADD:         // 정산 저장
      return { 
        ...state,
        info: action.payload,
        isOpen: false,
        isEdit: _isEmpty(action.payload) ? false : true
       };    
    case PAY_UPD:         // 정산 수정
      return { 
        ...state,
        info: action.payload,
        isOpen: false,
        isEdit: _isEmpty(action.payload) ? false : true
       };   
    case PAY_DEL:         // 정산 삭제
      return { 
        ...state
      };            
    case PAY_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}