/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux categories actions
**/
import { _ajaxGetJsonAsync, _ajaxPostJsonAsync } from "../../axios/axios";
import { _CodeConvert, _getSession, _isEmpty, _removeSession, _setSession } from "./../../Common";
import {
  CATE_ADD,
  CATE_ADD_GET,
  CATE_DEL,
  CATE_FAIL,
  CATE_GET
} from "./../types";

/* 카테고리 API 경로 정보 */
const API_PATH = "/api/v1/categories";
const key = 'CATEGOORY_DATA';

/* 카테고리 전체 조회 */
export const getCate = async (d: any) => {

  // IN Data 구성
  let body = {
    limit: '1000',  // 개수
  };

  let data;

  data = _getSession(key);

  if (_isEmpty(data)) {  // 세션 스토리지 확인
    // Ajax 통신
    // payload = await _ajaxGetJsonAsync("get", API_PATH+"/type/", body, CATE_GET, CATE_FAIL);
    return await _ajaxGetJsonAsync("get", API_PATH + "/", body, CATE_GET, CATE_FAIL).then(async (res) => {

      // 세션 설정
      if (!_isEmpty(res)) {
        _setSession(key, res);
      }

      if (!_isEmpty(res.payload)) {
        if (!_isEmpty(d)) {
          res.payload = res.payload.filter((v: any) => v.cate_grp_cd === d.initCate && v.cate_type === d.cateTyp);
        }
      }

      return await res;
    });

  } else {

    if (!_isEmpty(data.payload)) {
      if (!_isEmpty(d)) {
        data.payload = data.payload.filter((v: any) => v.cate_grp_cd === d.initCate && v.cate_type === d.cateTyp);
      }
    }
    return await data;
  }
}

/* 카테고리 개별 조회 */
export const getCateInfo = async (d: any) => {
  // IN Data 구성
  let body = {};

  // ajax 통신
  return await _ajaxGetJsonAsync("get", API_PATH + "/" + d.initCate, body, CATE_ADD_GET, CATE_FAIL);
}

/* 카테고리 등록 */
export const addCate = async (d: any) => {

  let user_name = window.sessionStorage.getItem('user_name');

  // 마지막 카테고리 구성 정보 가져오기
  let body = {
    initCate: d.cateInfo.cate_cd,   // 카테고리 코드  
    cateTyp: d.cateInfo.cate_type,  // 카테고리 유형
  }

  return await getCate(body).then(async (a) => {

    let i = {};

    if (!_isEmpty(a.payload)) {        // 하위 그룹이 있을 경우
      let o = a.payload[a.payload.length - 1];

      // 정렬순서
      o.cate_ord = Number(o.cate_ord) + 1;
      o.cate_name = d.cate_name;
      o.cate_grp_node = Number(o.cate_grp_node);
      o.cate_cd = _CodeConvert(o.cate_cd, d.cateInfo.cate_grp_node);

      if (o.cate_ord.toString().length == 1) {

        i = {
          cate_type: o.cate_type,
          cate_cd: o.cate_cd,
          cate_name: o.cate_name,
          cate_grp_cd: o.cate_grp_cd,
          cate_grp_name: o.cate_grp_name,
          cate_grp_node: o.cate_grp_node + '',
          cate_ord: '0' + o.cate_ord,
          cate_cont: o.cate_cont,
          is_active: true,
          reg_user: user_name,
          last_mod_user: user_name
        };
      } else {

        i = {
          cate_type: o.cate_type,
          cate_cd: o.cate_cd,
          cate_name: o.cate_name,
          cate_grp_cd: o.cate_grp_cd,
          cate_grp_name: o.cate_grp_name,
          cate_grp_node: o.cate_grp_node + '',
          cate_ord: o.cate_ord + '',
          cate_cont: o.cate_cont,
          is_active: true,
          reg_user: user_name,
          last_mod_user: user_name

        };
      }

    } else {                     // 하위 그룹이 없을 경우

      i = {
        cate_type: d.cateInfo.cate_type,
        cate_cd: _CodeConvert(d.cateInfo.cate_cd, d.cateInfo.cate_grp_node),
        cate_name: d.cate_name,
        cate_grp_cd: d.cateInfo.cate_cd,
        cate_grp_name: d.cateInfo.cate_name,
        cate_grp_node: (Number(d.cateInfo.cate_grp_node) + 1) + '',
        cate_ord: '01',
        cate_cont: '',
        is_active: true,
        reg_user: user_name,
        last_mod_user: user_name
      };
    }

    return await _ajaxPostJsonAsync("post", API_PATH + "/", i, CATE_ADD, CATE_FAIL).then(async (b) => {
      _removeSession(key); // 세션 스토리지 삭제
      return await b;
    });
  });
}

/* 카테고리 삭제 */
export const delCate = async (d: any) => {

  return await _ajaxGetJsonAsync("delete", API_PATH + "/" + d.cateInfo.cate_cd, {}, CATE_DEL, CATE_FAIL).then(async (a) => {
    _removeSession(key); // 세션 스토리지 삭제  
    return await a;
  });
}