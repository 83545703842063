/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Product Edit page
**/
/* @material import */
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  Close,
  Phone,
  Save,
  Add
} from '@material-ui/icons';
/* react import */
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getCnsltSrch } from "../../utils/redux/actions/CnsltAction";
import ComnConsult from './../common/ComnConsult';
import RepairView from './../repair/RepairView';
import ProductView from './ProductView';

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    marginTop: 0,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3)
  },
  paper: {
    width: '100%',
    height: 700,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    width: '100%',
    minHeight: 40,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 10,
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

/* ######################################################################
 * component 
 * ###################################################################### */
const ProductEdit = () => {
  const classes = useStyles();
  const dispatch = useDispatch();       // dispatch hook
  const prodRef = useRef<any>(null);    // 자식 함수 사용 (수선품)
  const repairRef = useRef<any>(null);  // 자식 함수 사용 (작업지시서)

  // [state] ######################################################################
  const product = useSelector((state: any) => state.product);    // product
  const receipt = useSelector((state: any) => state.receipt);    // receipt
  const common = useSelector((state: any) => state.common);    // common  

  const [disabled, setDisabled] = useState(false);
  // [Events] ######################################################################
  //@ 팝업 닫기
  const handleClose = useCallback(() => {
    product.isOpen = false;
    dispatch({ type: 'PROD_GET_INFO', payload: product.info });
    dispatch({ type: 'REPR_GET_TEMP', payload: {} });
  }, [product, dispatch]);

  //@ 저장
  const handleSave = useCallback(() => {
    // 수선품 저장
    prodRef.current.handleSubmit();
    // 작업지지서 저장
    // repairRef.current.handleSubmit();
    dispatch({ type: 'REPR_GET_TEMP', payload: {} });
  }, []);
  //@ 초기화
  const handleinit = useCallback(() => {
    dispatch({ type: 'REPR_GET_TEMP', payload: {} });
  }, [common, product, receipt, dispatch]);

  //@ 상담내역
  const handleConsult = useCallback(() => {
    let body = {
      cust_no: receipt.info.cust_no
    }
    // 상담 내역 검색
    getCnsltSrch(body).then((d) => {
      dispatch(d);
      common.isConsult = true;
      dispatch({ type: 'COM_CONSULT' });
    }).catch((e) => console.log(e));

  }, [receipt, common, dispatch]);


  // [Render] ######################################################################   
  return (
    <Dialog fullScreen open={product.isOpen} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            수선품 작성
          </Typography>
          <Button autoFocus color="inherit" onClick={handleConsult}>
            <Phone />상담내역
          </Button>
          <Button autoFocus color="inherit" onClick={async () => {
            setDisabled(true)
            await handleSave()
            setTimeout(() => {
              setDisabled(false)
            }, 3000)
          }} disabled={disabled}>
            <Save />저장
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="flex-start"
      >
        <Container maxWidth="xl">
          <Box mb={1} justifyContent="center">
            <Grid
              container
              justify="center"
              spacing={1}
            >
              <Grid item xs={6}>
                <Toolbar className={classes.toolbar}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6">수선품</Typography>
                    </Grid>
                  </Grid>
                </Toolbar>
                {/* 수선품접수 */}
                <Paper className={classes.paper} elevation={4}>
                  <ProductView ref={prodRef} />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Toolbar className={classes.toolbar}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <Typography variant="h6">작업지시서</Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                          {/* 초기화 */}
                          <IconButton
                            className={classes.iconButton}
                            aria-label="초기화"
                            color="inherit"
                            onClick={handleinit}
                          >
                            <Add /><Typography variant="h6">초기화</Typography>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Toolbar>
                {/* 작업지시서 */}
                <Paper className={classes.paper} elevation={4}>
                  <RepairView ref={repairRef} />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <ComnConsult />
    </Dialog>
  );
};

export default ProductEdit;
