/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Customer View page
**/
/* @material import */
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Add, Save } from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import {
  _isEmpty,

  _setCodeFilter,
  _setPhone
} from "./../../utils/Common";
import FormSelect from "./../../utils/form/FormSelect";
import FormText from "./../../utils/form/FormText";
import FormTextarea from "./../../utils/form/FormTextarea";
import { addCust, updCust } from "./../../utils/redux/actions/CustAction";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: 340, 
    height: '100%',
    overflow: 'auto',
    paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3)   
  },
  toolbar: {
    width: '100%',
    minHeight: 35,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  }, 
  button: {
    margin: theme.spacing(1),
  },
  box: {
    overflow: 'auto',    
    margin: theme.spacing(1)
  },
  label: {
    fontSize: 12,
    color: 'primary',
    marginTop: theme.spacing(2)
  },
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const CustomerView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################  
  const customer = useSelector((state:any) => state.customer);    // customer
  const common = useSelector((state:any) => state.common);        // common

  // [Events] ######################################################################
  //@ 저장
  const handleAdd = useCallback((v) => {
    addCust(v).then((res:any) => dispatch(res))
              .then((v:any) => {
                if (v.type == "CUST_FAIL") {
                  if(v.status == "400"){
                    console.log(v);
										common.msg = "가입된 고객입니다.";  
                  }else if (v.status == "500"){
                    console.log(v);
										common.msg = "등록되어 있는 이메일입니다.";  
                  }else{
                    console.log(v);
										common.msg = "저장에 실패 하였습니다.";
                  }									
									common.isMsg = true;
									return dispatch({ type: "COM_ALERT" }); // 안내메시지
								} else {
                  console.log(v);
									common.msg = "저장에 성공 하였습니다.";
									common.isMsg = true;
									return dispatch({ type: "COM_ALERT" }); // 안내메시지
								}                
              });
  },[common, dispatch]);
  
  //@ 수정
  const handleUpdate = useCallback((v) => {
    updCust(v).then((res:any) => dispatch(res))
              .then((v:any) => {
                if (v.type == "CUST_FAIL") {
									if (v.status == "400") {
										console.log(v);
										common.msg = "가입된 고객입니다.";
									} else if (v.status == "500") {
										console.log(v);
										common.msg = "등록되어 있는 이메일입니다.";
									} else {
										console.log(v);
										common.msg = "수정에 실패 하였습니다.";
									}
									common.isMsg = true;
									return dispatch({ type: "COM_ALERT" }); // 안내메시지
								} else {
									console.log(v);
									common.msg = "수정에 성공 하였습니다.";
									common.isMsg = true;
									return dispatch({ type: "COM_ALERT" }); // 안내메시지
								}                                
              });
  },[common, dispatch]);

  //@ 초기화
  const handleinit = useCallback(() => {
    dispatch({ type:'CUST_GET_INFO', payload: {} });
  },[dispatch]);

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid 
        container
        justify="center"
        alignItems="center"
        >
          <Grid item xs={6}>
            <Typography variant="h6">고객정보</Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            {/* 수정,저장 */}
            <IconButton 
              className={classes.iconButton} 
              aria-label="초기화"
              color="inherit"
              onClick={handleinit}
              >
              <Add /><Typography variant="h6">초기화</Typography>
            </IconButton>
          </Grid>          
        </Grid>
      </Toolbar>       
      {/* 고객정보 */}
      <Box
        mt={1}
        display="flex"
        justifyContent="center"
        className={classes.box}
      >
        <Formik
          enableReinitialize
          initialValues={{
            cust_no: _isEmpty(customer.info) ? '' : customer.info.cust_no,        // 고객번호
            cust_name: _isEmpty(customer.info) ? '' : customer.info.cust_name,  // 고객명
            phone: _isEmpty(customer.info) ? '' : customer.info.phone,              // 고객전화번호
            phone2: _isEmpty(customer.info) ? '' : customer.info.phone2,           // 고객전화번호2
            email: _isEmpty(customer.info) ? '' : customer.info.email,              // 이메일
            join_chnl: _isEmpty(customer.info) ? '' : customer.info.join_chnl,  // 가입채널
            addr: _isEmpty(customer.info) ? '' : customer.info.addr,                 // 주소
            cust_grd: _isEmpty(customer.info) ? '' : customer.info.cust_grd,     // 고객등급
            cust_stat: _isEmpty(customer.info) ? '01' : customer.info.cust_stat,  // 고객상태
            cust_cont: _isEmpty(customer.info) ? '' : customer.info.cust_cont,  // 고객정보
            // repr_pay: _setAddComma('0'),      // 정산금액
            // repr_pay2: _setAddComma('0')      // 미수금액
            cust_pay: _isEmpty(customer.info) ? '0' : customer.info.cust_pay,                    // 미수금
            cust_count: _isEmpty(customer.info) ? '0' : customer.info.cust_count               // 수선수
          }}
          validationSchema={Yup.object().shape({
            cust_name: Yup.string().min(2, '두자리 이상 입력해주세요').required('고객명을 입력해주세요'),
            // email: Yup.string().email('이메일 형식이 올바르지 않습니다').max(255).required('이메일을 입력해주세요'),
            join_chnl: Yup.string().required('가입경로를 선택해주세요'),            
            phone: Yup.number(),
            phone2: Yup.number(),
          })}
          onSubmit={(v) => {
            // 콤마 데이터 포멧 변경
            // v.repr_pay = _setRemoveComma(v.repr_pay);
            // v.repr_pay2 = _setRemoveComma(v.repr_pay2);
            // 전화번호 입력시 '-' 제거 HSH
            v.phone = _setPhone(v.phone);
            v.phone2 = _setPhone(v.phone2);
            if(customer.isEdit) {  // 수정
              handleUpdate(v);
            } else {               // 등록
              handleAdd(v);
            }             
          }}
          >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (   
              <form onSubmit={handleSubmit}>      
                <Box ml={3} mb={1} mt={1} justifyContent="center">
                  <Grid 
                      container
                      justify="center"
                      alignItems="center"
                      >
                    {/* 고객상태 */}
                    <Grid item xs={12}>
                      <FormSelect
                        id='cust_stat' 
                        label='고객상태'
                        firstDefault={false}
                        value={values.cust_stat}                           
                        item={_setCodeFilter(common.code, 'G004')}
                        errors={errors.cust_stat}
                        touched={touched.cust_stat}                      
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid>                        
                    {/* 고객명 */}
                    <Grid item xs={12}>
                        <FormText 
                          id='cust_name' 
                          label='고객명'
                          value={values.cust_name}
                          errors={errors.cust_name}
                          touched={touched.cust_name}                      
                          blur={handleBlur} 
                          change={handleChange} />
                    </Grid>
                    {/* 연락처1 */}
                    <Grid item xs={12}>
                        <FormText 
                          id='phone' 
                          label='연락처'
                          value={values.phone}
                          errors={errors.phone}
                          touched={touched.phone}
                          blur={handleBlur} 
                          change={handleChange} />
                    </Grid>
                    {/* 연락처2 */}
                    <Grid item xs={12}>
                        <FormText 
                          id='phone2' 
                          label='연락처2'
                          value={values.phone2}
                          errors={errors.phone2}
                          touched={touched.phone2}            
                          blur={handleBlur} 
                          change={handleChange} />
                    </Grid>
                    {/* 이메일 */}
                    <Grid item xs={12}>
                        <FormText 
                          id='email' 
                          label='이메일'
                          value={values.email}
                          // errors={errors.email}
                          // touched={touched.email}                       
                          blur={handleBlur} 
                          change={handleChange} />
                    </Grid>
                    {/* 주소 */}
                    <Grid item xs={12}>
                        <FormText 
                          id='addr' 
                          label='주소'
                          value={values.addr}
                          errors={errors.addr}
                          touched={touched.addr}                      
                          blur={handleBlur} 
                          change={handleChange} />
                    </Grid>                      
                    {/* 가입경로 */}
                    <Grid item xs={12}>                    
                      <FormSelect
                        id='join_chnl' 
                        label='가입경로'
                        value={values.join_chnl}                           
                        item={_setCodeFilter(common.code, 'G023')}
                        errors={errors.join_chnl}
                        touched={touched.join_chnl}                      
                        blur={handleBlur} 
                        change={handleChange} />
                    </Grid>
                    {/* 고객기타정보 */}
                    <Grid item xs={12}>
                      <Typography 
                        color="primary"
                        className={classes.label} 
                        variant="subtitle2">고객기타정보</Typography>                        
                      <FormTextarea
                        id='cust_cont' 
                        label='고객기타정보' 
                        value={values.cust_cont}
                        rows={4}                   
                        required={false}
                        blur={handleBlur} 
                        change={handleChange} />   
                    </Grid>                    
                    {/* 수선수 */}
                    <Grid item xs={12}>
                        <FormText 
                          id='cust_count' 
                          label='수선수' 
                          value={values.cust_count}
                          disabled={true}
                          required={true}                
                          blur={handleBlur} 
                          change={handleChange} /> 
                    </Grid>  
                    {/* 미수금 */}
                    <Grid item xs={12}>
                        <FormText 
                          id='cust_pay' 
                          label='미수금' 
                          value={values.cust_pay}
                          disabled={true}
                          required={true}                    
                          blur={handleBlur} 
                          change={handleChange} /> 
                    </Grid>                                                                            
                  </Grid>
                </Box>
                {/* 버튼영역 */}
                <Box
                  ml={1} mr={1} mb={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  {/* 수정,저장 */}
                  {/* <Button
                    type="submit"           
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<Save />}
                  >
                    {customer.isEdit ? '수정' : '저장'}
                  </Button> */}
                </Box>    
              </form>          
            )}
        </Formik>
      </Box> 
    </React.Fragment>
  );
};

export default CustomerView;
