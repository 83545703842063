/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Receipt Edit Page
**/
/* @material import */
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  Add,
  Save
} from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import FormSelect from "../../utils/form/FormSelect";
import {
  _isEmpty,
  _setCodeFilter,
  _setDateYYYYMMDD,
  _setUTCDate
} from "./../../utils/Common";
import FormDate from "./../../utils/form/FormDate";
import FormText from "./../../utils/form/FormText";
import { addRcpt, getRcpt, updRcpt } from "./../../utils/redux/actions/RcptAction";
import { getProd } from "../../utils/redux/actions/ProdAction";

/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  /* select style */
  root: {
    height: '100%',
    width: '100%',
    maxHeight: '90%',
    minWidth: '90%',
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  toolbar: {
    minHeight: 40,
    color: '#fff',
    backgroundColor: '#1976d2'
  },
  iconButton: {
    padding: 10,
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ReceiptEdit = () => {
  const classes = useStyles();     // CSS
  const dispatch = useDispatch();  // dispatch hook

  // [state] #######################################################################
  const receipt = useSelector((state: any) => state.receipt);    // receipt
  const common = useSelector((state: any) => state.common);      // common

  // [Events] ######################################################################
  //@ 저장
  const handleAdd = useCallback((v) => {
    addRcpt(v).then((res) => dispatch(res))
      .then(() => {
        common.msg = '저장에 성공 하였습니다.';
        common.isMsg = true;
        return dispatch({ type: 'COM_ALERT' });       // 안내메시지
      })
      .then(() => {
        let body: any = {};
        // if (!_isEmpty(receipt.srch.st_dt)) body["st_dt"] = _setUTCDate(receipt.srch.st_dt);
        // if (!_isEmpty(receipt.srch.ed_dt)) body["ed_dt"] = _setUTCDate(receipt.srch.ed_dt);
        // if (!_isEmpty(receipt.srch.rcpt_stat)) body["rcpt_stat"] = receipt.srch.rcpt_stat;
        // if (!_isEmpty(receipt.srch.rcpt_chnl)) body["rcpt_chnl"] = receipt.srch.rcpt_chnl;
        // if (!_isEmpty(receipt.srch.srch_val)) body[receipt.srch.srch_key.toString()] = receipt.srch.srch_val;
        // if (!_isEmpty(receipt.srch.limit)) body["limit"] = receipt.srch.limit;
        body['limit'] = '1000';

        getRcpt(body)
          .then((d) => {
            dispatch(d);
            //수선품 목록 다시 불러오기
            let body = {
              rcpt_no: receipt.info.rcpt_no,
            };
            dispatch({ type: "PROD_GET", payload: [] });
            if (!_isEmpty(body.rcpt_no)) {
              getProd(body)
                .then((d) => dispatch(d))
                .catch((e) => console.log(e));
            }
          })
          .catch((e) => console.log(e));
      });
  }, [receipt, common, dispatch]);

  //@ 수정
  const handleUpdate = useCallback((v) => {
    updRcpt(v).then((res) => dispatch(res))
      .then(() => {
        common.msg = '수정에 성공 하였습니다.';
        common.isMsg = true;
        return dispatch({ type: 'COM_ALERT' });       // 안내메시지
      })
      .then(() => {
        let body: any = {};
        // if (!_isEmpty(receipt.srch.st_dt)) body["st_dt"] = _setUTCDate(receipt.srch.st_dt);
        // if (!_isEmpty(receipt.srch.ed_dt)) body["ed_dt"] = _setUTCDate(receipt.srch.ed_dt);
        // if (!_isEmpty(receipt.srch.rcpt_stat)) body["rcpt_stat"] = receipt.srch.rcpt_stat;
        // if (!_isEmpty(receipt.srch.rcpt_chnl)) body["rcpt_chnl"] = receipt.srch.rcpt_chnl;
        // if (!_isEmpty(receipt.srch.srch_val)) body[receipt.srch.srch_key.toString()] = receipt.srch.srch_val;
        // if (!_isEmpty(receipt.srch.limit)) body["limit"] = receipt.srch.limit;
        body['limit'] = '1000';

        getRcpt(body)
          .then((d) => {
            dispatch(d);
            //수선품 목록 다시 불러오기
            let body = {
              rcpt_no: receipt.info.rcpt_no,
            };
            dispatch({ type: "PROD_GET", payload: [] });
            getProd(body)
              .then((d) => dispatch(d))
              .catch((e) => console.log(e));
            // dispatch({ type: "PROD_GET", payload: [] });
          })
          .catch((e) => console.log(e));
      }
        // getRcpt({}).then((d) => dispatch(d))
      );
  }, [receipt, common, dispatch]);

  //@ 초기화
  const handleinit = useCallback(() => {
    common.search = {};
    dispatch({ type: 'RCPT_GET_INFO', payload: {} });
  }, [common, dispatch]);

  const handleCustSrch = useCallback((e) => {
    // 고객검색레이어 오픈
    common.isCustAdd = true;
    common.custPage = '02';
    dispatch({ type: 'COM_CUST_SEARCH', payload: common });
    // 고객검색정보 초기화
    dispatch({ type: 'CUST_GET', payload: {} });
    dispatch({ type: 'CUST_GET_INFO', payload: {} });
  }, [common, dispatch]);

  // [init] ######################################################################
  //@ 최초 실행
  useEffect(() => {
  }, []);

  // [Render] ######################################################################  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography variant="h6">접수등록</Typography>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Grid
              container
              justify="center"
              alignItems="center"
            >
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                {/* 초기화 */}
                <IconButton
                  className={classes.iconButton}
                  aria-label="초기화"
                  color="inherit"
                  onClick={handleinit}
                >
                  <Add /><Typography variant="h6">초기화</Typography>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Formik
        enableReinitialize
        initialValues={{
          rcpt_no: _isEmpty(receipt.info.rcpt_no) ? '' : receipt.info.rcpt_no,          // 접수번호
          // cust_no: _isEmpty(receipt.info.cust_no) ? '' : receipt.info.cust_no,          // 고객번호
          cust_no: _isEmpty(receipt.info.cust_no) ?
            (_isEmpty(common.search.cust_no) ? '' : common.search.cust_no) : receipt.info.cust_no,   // 고객번호                            
          // cust_name: _isEmpty(receipt.info.cust_name) ? '' : receipt.info.cust_name,    // 고객명
          cust_name: _isEmpty(receipt.info.cust_name) ?
            (_isEmpty(common.search.cust_name) ? '' : common.search.cust_name) : receipt.info.cust_name,   // 고객명              
          cnslt_no: _isEmpty(receipt.info.cnslt_no) ? '' : receipt.info.cnslt_no,       // 상담번호
          rcpt_chnl: _isEmpty(receipt.info.rcpt_chnl) ? '' : receipt.info.rcpt_chnl,    // 접수경로
          rcpt_dtime: _isEmpty(receipt.info.rcpt_dtime) ? _setDateYYYYMMDD('', '-') : _setDateYYYYMMDD(receipt.info.rcpt_dtime, '-'),    // 접수일자
          rcpt_stat: _isEmpty(receipt.info.rcpt_stat) ? '01' : receipt.info.rcpt_stat,  // 접수상태
        }}
        validationSchema={Yup.object().shape({
          cust_name: Yup.string()
            .min(2, '2자 이상 입력해주세요')
            .required('고객명을 입력해주세요'),
          rcpt_chnl: Yup.string().required('접수경로를 선택해주세요')
        })}
        onSubmit={(v) => {
          // UTC 포멧 변경
          v.rcpt_dtime = _setUTCDate(v.rcpt_dtime);

          if (v.rcpt_no) {  // 수정
            handleUpdate(v);
          } else {              // 등록
            handleAdd(v);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <Box ml={3} mb={1} mt={1} justifyContent="center">
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}>
                {/* 접수일자 */}
                <Grid item xs={6}>
                  <FormDate
                    id='rcpt_dtime'
                    label='접수일자'
                    value={values.rcpt_dtime}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 고객명 */}
                <Grid item xs={6}>
                  <FormText
                    id='cust_name'
                    label='고객명'
                    value={values.cust_name}
                    errors={errors.cust_name}
                    touched={touched.cust_name}
                    disabled={true}
                    required={true}
                    blur={handleBlur}
                    click={receipt.isEdit ? null : handleCustSrch}
                    change={handleChange} />
                </Grid>
                {/* 접수경로 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='rcpt_chnl'
                    label='접수경로'
                    value={values.rcpt_chnl}
                    item={_setCodeFilter(common.code, 'G002')}
                    errors={errors.rcpt_chnl}
                    touched={touched.rcpt_chnl}
                    required={true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
                {/* 접수상태 */}
                <Grid item xs={6}>
                  <FormSelect
                    id='rcpt_stat'
                    label='접수상태'
                    value={values.rcpt_stat}
                    item={_setCodeFilter(common.code, 'G018')}
                    errors={errors.rcpt_stat}
                    touched={touched.rcpt_stat}
                    disabled={receipt.isEdit ? false : true}
                    blur={handleBlur}
                    change={handleChange} />
                </Grid>
              </Grid>
            </Box>
            {/* 버튼영역 */}
            <Box
              ml={1} mr={1} mb={1}
              display="flex"
              justifyContent="flex-end"
            >
              {/* 수정,저장 */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<Save />}
              >
                {receipt.isEdit ? '수정' : '저장'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default ReceiptEdit;
