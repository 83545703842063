/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux chatbot search product log store
**/
// TYPES
import {
  BOT_SRCH_FAIL,
  BOT_SRCH_GET,
  BOT_SRCH_GET_INFO
} from "../types";
import { _dataAddID, _BotdataAddID, _isEmpty } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows: [],
  info: [],
  srch: {},  // 검색  
  isEdit: false
};

// action type 별 상태 관리
export default (state = initialState, action: any) => {
  switch (action.type) {
    case BOT_SRCH_GET:         // 챗봇 수선품 검색 로그 전체 조회     
      return {
        ...state,
        rows: _BotdataAddID(action.payload)
      };
    case BOT_SRCH_GET_INFO:    // 챗봇 수선품 검색 로그 개별 조회    
      return {
        ...state,
        info: action.payload[0],
        isEdit: _isEmpty(action.payload) ? false : true
      };
    case BOT_SRCH_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}