/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Users page
**/
/* @material import */
import {
    Grid,
    makeStyles,
    Toolbar,
    Typography
} from '@material-ui/core';
import {
    DataGrid
} from '@material-ui/data-grid';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getProd } from "../../utils/redux/actions/ProdAction";
import { _getNowDate, _isEmpty, _setFormatCategory, _setFormatCode, _setFormatComma, _setFormatDate } from "./../../utils/Common";


/* ######################################################################
 * style
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
    root: {
    },
    grid: {
        height: '100%',
        width: '98%',
        maxHeight: '84%',
        minWidth: '98%',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#9fa8da',
            color: '#ffffff'
        },
    },
    toolbar: {
        minHeight: 40,
        color: '#fff',
        backgroundColor: '#1976d2'
    },
    iconButton: {
        padding: 10,
    }
}));

/* ######################################################################
 * component
 * ###################################################################### */
const MainList = () => {
    const classes = useStyles();      // CSS
    const dispatch = useDispatch();  // dispatch hook   

    // [state] ######################################################################
    const product = useSelector((state: any) => state.product);    // product
    const common = useSelector((state: any) => state.common);      // common
    // [Events] ######################################################################
    const handleChange = useCallback((e) => {
        console.log(e);
        dispatch({
            type: 'PROD_GET_INFO',
            payload: e.data
        });
    }, [dispatch]);
    // [init] ######################################################################
    // 콤마 타입 설정
    const _setComma = _setFormatComma();
    // 데이트 타입 설정
    const _setDate = _setFormatDate();
    // 카테고리명 조회
    const _setCateName = _setFormatCategory(common.category);
    // 접수상태명 조회
    const _setStateName = _setFormatCode(common.code, 'G008');
    // 수선종류 조회
    const _setItemName = _setFormatCode(common.code, 'G025');

    //@ 초기 데이터 (Table 컬럼)
    const columns = [
        { field: 'id', headerAlign: 'center', headerName: '번호', hide: true },
        { field: 'prod_no', headerAlign: 'center', headerName: '수선품번호', width: 200 },
        { field: 'cust_name', headerAlign: 'center', headerName: '고객명', width: 140 },
        { field: 'brand_cd', headerAlign: 'center', headerName: '브랜드', width: 200, ..._setCateName, hide: true },
        { field: 'brand_name', headerAlign: 'center', headerName: '브랜드', width: 200 },
        // { field: 'cate_cd', headerAlign: 'center', headerName: '품목', ..._setCateName },
        { field: 'cate_cd', headerAlign: 'center', headerName: '수선종류', width: 120, ..._setItemName },
        { field: 'repr_st_dt', headerAlign: 'center', headerName: '입고일', width: 200, ..._setDate },
        { field: 'repr_req_dt', headerAlign: 'center', headerName: '출고요청일', width: 200, ..._setDate },
        { field: 'tot_pay', headerAlign: 'center', headerName: '수선비', width: 200, ..._setComma },
        { field: 'frst_pay', headerAlign: 'center', headerName: '선금', hide: true, ..._setComma },
        { field: 'prod_stat', headerAlign: 'center', headerName: '상태', width: 200, ..._setStateName },
    ];

    // [Grid] ######################################################################        
    const handleInit = useCallback(() => {
        let srch_date = _getNowDate();        //당일 날짜        
        let body = {
            repr_st_dt: srch_date              // 당일 날짜 검색
        }
        // 사용자 조회
        getProd(body).then((d) => dispatch(d));
    }, [dispatch]);

    let datagrid;

    //@ 최초 실행
    useEffect(() => {
        handleInit();
    }, [handleInit]);

    if (_isEmpty(product.rows)) {
        datagrid = <Typography
            variant="h6"
            align="center"
        >
            데이터가 존재하지 않습니다.
        </Typography>;
    } else {
        datagrid = <DataGrid
            headerHeight={40}
            rowHeight={30}
            columns={columns}
            rows={product.rows}
            pageSize={7}
            onRowClick={handleChange}
        />;
    }
    // [Render] ######################################################################  
    return (
        <React.Fragment>
            <Toolbar className={classes.toolbar}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={6}>
                        <Typography variant="h6">당일 접수 수선품</Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>
            </Toolbar>
            <div
                className={classes.grid}
            >
                {datagrid}
            </div>
        </React.Fragment>
    );
};

export default MainList;