/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux chatbot gallery store
**/
// TYPES
import {
  BOT_GALY_ADD,
  BOT_GALY_DEL,
  BOT_GALY_FAIL,
  BOT_GALY_GET,
  BOT_GALY_GET_INFO,
  BOT_GALY_UPD
} from "../types";
import { _isEmpty, _setGallyImgFormat } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows:[],
  images:{},    // 성능 개선을 위한 이미지 저장소  
  info:{},
  isEdit: false
};

// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case BOT_GALY_GET:         // 챗봇사진 전체 조회)
      return { 
        ...state,
        rows: action.payload,
        images: _setGallyImgFormat(action.payload)
       };
    case BOT_GALY_GET_INFO:    // 챗봇사진 개별 조회    
      return { 
        ...state,
        info: action.payload[0],
        isEdit: _isEmpty(action.payload) ? false : true
       };       
    case BOT_GALY_ADD:         // 챗봇사진 저장
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
       };    
    case BOT_GALY_UPD:         // 챗봇사진 수정
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
       };
    case BOT_GALY_DEL:         // 챗봇사진 삭제
      return { 
        ...state
      };             
    case BOT_GALY_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}