/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : Customer Add page
**/
/* @material import */
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  InputBase,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Close, DoubleArrow, Search } from '@material-ui/icons';
import { Formik } from 'formik';
/* react import */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList } from 'react-window';
import * as Yup from 'yup';
import {
  _isEmpty,
  _setPhone
} from "../../utils/Common";
import FormSelect from "../../utils/form/FormSelect";
import { getCust, getCustInfo } from "../../utils/redux/actions/CustAction";
/* ######################################################################
 * style 
 * ###################################################################### */
const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    width: '100%',
    minHeight: 30,
    color: '#fff',
    backgroundColor: '#1976d2',
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 30
  },
  paperList: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },   
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  }, 
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  box: {
    margin: theme.spacing(1)
  }
}));

/* ######################################################################
 * component 
 * ###################################################################### */
const ComnCustAdd = () => {
  const classes = useStyles();
  const dispatch = useDispatch();  // dispatch hook

  // [state] ######################################################################
  const customer = useSelector((state:any) => state.customer);    // customer
  const consult = useSelector((state:any) => state.consult);      // consult
  const receipt = useSelector((state:any) => state.receipt);      // receipt
  const botRcpt = useSelector((state:any) => state.botRcpt);      // botRcpt
  const common = useSelector((state:any) => state.common);                      // common
  
  // [Events] ######################################################################
  //@ 고객검색
  const hendleSearch = useCallback((v) => {

    let body = {}; // param 구성

    if(v.search_key === '01') {  // 고객명
      body = {
        cust_name: v.search_text
      };
    } else {                     // 연락처
      body = {
        phone: _setPhone(v.search_text)
      };
    }
    // 초기화
    customer.list = [];
    customer.info = [];
    customer.isEdit = false;
    
    // 고객목록
    getCust(body).then((res:any) => dispatch(res))
              .then((res) => {
                if(res.payload.length < 1) {
                  common.msg = '검색 결과가 존재하지 않습니다.';
                  common.isMsg = true;        
                  return dispatch({type:'COM_ALERT'});       // 안내메시지
                }
              });

  },[common, customer, dispatch]);

  //@ 페이지 이동
  const move = useCallback(() => {

    if(_isEmpty(customer.info.cust_no)) {
      common.msg = '고객을 선택해주세요';
      common.isMsg = true;        
      dispatch({type:'COM_ALERT'});       // 안내메시지
    } else {
      //# 고객명 입력      
      if(common.custPage === '01') {         // 상담
        consult.info.cust_no = customer.info.cust_no; 
        consult.info.cust_name = customer.info.cust_name;
        consult.info.phone = customer.info.phone;     // HSH 추가 2020-12-30
        dispatch({ type: 'CNSLT_GET_INFO', payload: consult.info });
      } else if(common.custPage === '02') {  // 접수
        receipt.info.cust_no = customer.info.cust_no;
        receipt.info.cust_name = customer.info.cust_name;
        dispatch({ 
          type: 'RCPT_GET_INFO',
          payload: receipt.info
        });
      } else if(common.custPage === '03') {  // 챗봇 수거 접수
        botRcpt.info['cust_no'] = customer.info.cust_no;
        botRcpt.info['cust_name'] = customer.info.cust_name;
        botRcpt.info['cust_stat'] = '01';
        let payload = [];
        payload.push(botRcpt.info);
        dispatch({ 
          type: 'BOT_RCPT_GET_INFO',
          payload: payload
        });
      }
      //# 고객검색창 닫기
      common.isCustAdd = false;
      dispatch({ type: 'COM_CUST_SEARCH', payload: common });
    }

  },[botRcpt, customer, common, consult, receipt, dispatch]);

  //@ 고객정보선택
  const handleCustInfo = useCallback((v) => {
    getCustInfo(v).then((res:any) => dispatch(res));
  },[dispatch]);

  //@ 초기화
  const handleinit = useCallback(() => {
    dispatch({
      type:'CUST_GET',
      payload: {}
    });
    dispatch({
      type:'CUST_GET_INFO',
      payload: {}
    });    
  },[dispatch]);

  // [init] ######################################################################
  //@ 최초 실행
  useEffect(() => {   
    handleinit();
  }, [handleinit]);
    
  // [Method] ######################################################################  
  // 고객 목록 조회
  const getCustList = (props:any) => {

    const { data, index, style } = props;

    return (
      <ListItem 
        button 
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "9px",
          background: customer.info.cust_no === data[index].cust_no ? "#9ac0e6" : "white",
          cursor: "pointer"
        }} 
        key={index}
        onClick={() => {
          handleCustInfo(data[index].cust_no);
        }}        
        >
        <ListItemText 
          primary={
            <Typography variant="subtitle2">
              {data[index].cust_name+', '+data[index].phone}
            </Typography>       
          } 
        />        
      </ListItem>
    );
  };

  //@ 팝업 닫기
  const handleClose = useCallback(() => {
    common.isCustAdd = false;
    dispatch({ 
      type: 'COM_CUST_SEARCH',
      payload: common
    });
  },[common, dispatch]);

  // [Render] ######################################################################  
  return (
    <Dialog open={common.isCustAdd} onClose={handleClose} aria-labelledby="form-dialog-title">
      {/* 타이틀 */}
      <Toolbar className={classes.toolbar}>
        <Grid 
        container
        justify="center"
        alignItems="center"
        >       
          <Grid item xs={11}>
            <Typography variant="h6">고객검색</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <Close />
            </IconButton>            
          </Grid>              
        </Grid>
      </Toolbar>          
      {/* 검색 */}
      <Box
        mt={1}
        display="flex"
        justifyContent="center"
        className={classes.box}         
      >
          <Formik
                enableReinitialize
                initialValues={{
                  search_key  : '01',
                  search_text : ''
                }}
                validationSchema={Yup.object().shape({
                    search_text: Yup.string()
                                  .min(2, '2자 이상 입력해주세요')
                                  .required('검색어를 입력해주세요'),
                    search_key: Yup.string().required('검색 유형을 선택해주세요'),                    
                })}                  
                onSubmit={(v) => {
                  hendleSearch(v);  // 검색
                }}
                >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (   
                    <form onSubmit={handleSubmit}>
                        <Grid
                          container
                          justify="center"
                        >  
                          <Grid item xs={4}>
                            <FormSelect
                              id='search_key' 
                              firstDefault={false}
                              value={values.search_key}
                              item={[
                                {code:'01', name:'고객명'},
                                {code:'02', name:'연락처'}
                              ]}
                              errors={errors.search_key}
                              touched={touched.search_key}                      
                              blur={handleBlur} 
                              change={handleChange} />  
                          </Grid>
                          <Grid item xs={8}>
                            <Paper className={classes.paper}>
                              <InputBase
                                className={classes.input}
                                placeholder="검색어를 입력해주세요"
                                id='search_text'
                                name='search_text'
                                value={values.search_text}
                                type='text'
                                error={Boolean(touched.search_text && errors.search_text)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <IconButton 
                                type="submit" 
                                className={classes.iconButton} 
                                aria-label="search">
                                <Search />
                              </IconButton>
                            </Paper>
                          </Grid>
                        </Grid>                                                                  
                    </form>          
                )}
            </Formik>                                                    
      </Box>
      {/* 검색결과 */}
      {customer.list.length > 0 && 
        <Box
          mt={1}
          display="flex"
          justifyContent="center" 
          className={classes.box}     
        >
          <Paper className={classes.paperList} elevation={4}>
            <FixedSizeList 
              height={customer.list.length*35} 
              width={330}
              itemSize={35}
              itemCount={customer.list.length} 
              itemData={customer.list}
              >                       
              {getCustList}
            </FixedSizeList>
          </Paper>
        </Box>
      }        
      {/* 버튼 */}
      <Box
        ml={1} mr={1}
        display="flex"
        justifyContent="flex-end"
        className={classes.box}      
        >
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<DoubleArrow />}
          onClick={move}
        >
          추가
        </Button>
      </Box>      
    </Dialog>    
  );
};

export default ComnCustAdd;
