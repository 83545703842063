/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux menders store
**/
// TYPES
import {
  MNDR_ADD,
  MNDR_FAIL,
  MNDR_GET,
  MNDR_GET_INFO,
  MNDR_UPD
} from "../types";
import { _dataAddID, _isEmpty } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows:[],
  info:[],
  isEdit: false
};

// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case MNDR_GET:         // 수선 작업자 전체 조회     
      return { 
        ...state,
        rows: _dataAddID(action.payload)
       };
    case MNDR_GET_INFO:    // 수선 작업자 개별 조회    
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
       };       
    case MNDR_ADD:         // 수선 작업자 저장
      return { 
        ...state,
        info: action.payload,
        isOpen: false,
        isEdit: _isEmpty(action.payload) ? false : true
       };    
    case MNDR_UPD:         // 수선 작업자 수정
      return { 
        ...state,
        info: action.payload,
        isOpen: false,
        isEdit: _isEmpty(action.payload) ? false : true
       };      
    case MNDR_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}