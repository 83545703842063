/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux images store
**/
// TYPES
import { _getImageParse, _isEmpty } from "../../Common";
import {
  IMG_ADD,
  IMG_DEL,
  IMG_FAIL,
  IMG_GET,
  IMG_GET_INFO,
  IMG_GET_URL,
  IMG_UPD
} from "../types";

// state 초기 데이터
const initialState = {
  rows:[],
  info:[],
  imgURL: '',
  isEdit: false
};

// action type 별 상태 관리
export default (state = initialState, action:any) => {
  switch (action.type) {
    case IMG_GET:         // 이미지 전체 조회     
      return { 
        ...state,
        rows: _getImageParse(action.payload)
       };
    case IMG_GET_INFO:    // 이미지 개별 조회    
      return { 
        ...state,
        info: action.payload[0],
        isEdit: _isEmpty(action.payload) ? false : true
       };       
    case IMG_ADD:         // 이미지 저장
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
       };    
    case IMG_UPD:         // 이미지 수정
      return { 
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
       };   
    case IMG_DEL:         // 이미지 삭제
      return { 
        ...state
      };  
    case IMG_GET_URL:     // 이미지 URL 정보 조회 
      return { 
        ...state,
        imgURL: action.payload.urls,
       };                   
    case IMG_FAIL:        // 이미지 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}