/**
 * date         : 2020.10.01
 * creater      : nuriggum
 * description  : redux products store
**/
// TYPES
import {
  PROD_SEND,
  PROD_ADD,
  PROD_DASHBOARD,
  PROD_FAIL,
  PROD_GET,
  PROD_GET_CHK,
  PROD_GET_INFO,
  PROD_GET_SRCH,
  PROD_UPD
} from "../types";
import { _dataAddID, _dataAddIDAndPay, _isEmpty } from "./../../Common";

// state 초기 데이터
const initialState = {
  rows: [],         // 전체 목록
  info: [],         // 선택된 정보
  checked: [],      // 선택된 목록
  dashboard: [],    // 대시보드용
  srch: {},         // 검색  
  isEdit: false,   // 수정여부
  isOpen: false,   // 수선품등록레이어 오픈 여부
  isView: false,    // 작업지시서
  excel: []         // excel 데이터
};

// action type 별 상태 관리
export default (state = initialState, action: any) => {
  switch (action.type) {
    case PROD_GET:         // 수선품 전체 조회     
      return {
        ...state,
        rows: _dataAddIDAndPay(action.payload),
        excel: _dataAddIDAndPay(action.payload)
      };
    case PROD_GET_INFO:    // 수선품 개별 조회    
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload) ? false : true
      };
    case PROD_GET_SRCH:    // 출고 조회
      return {
        ...state,
        info: _dataAddIDAndPay(action.payload)[0],
        isEdit: _isEmpty(action.payload) ? false : true
      };
    case PROD_GET_CHK:    // 출고 조회    
      return {
        ...state,
        checked: action.payload
      };
    case PROD_ADD:         // 수선품 저장
      return {
        ...state,
        info: action.payload,
        isOpen: false,
        isEdit: _isEmpty(action.payload) ? false : true
      };
    case PROD_UPD:         // 수선품 수정
      return {
        ...state,
        info: action.payload,
        isOpen: false,
        isEdit: _isEmpty(action.payload) ? false : true
      };
    case PROD_DASHBOARD:    // 수선품 개별 조회    
      return {
        ...state,
        dashboard: _dataAddID(action.payload)
      };
    case PROD_SEND:         // 카카오 알림톡 전송
      return {
        ...state,
        info: action.payload,
        isEdit: _isEmpty(action.payload.prod_no) ? false : true
      };
    case PROD_FAIL:        // 결과 실패
      return { ...state, ...action.payload };
    default:
      return state;
  }
}